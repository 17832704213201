import AntdForm from 'antd/lib/form';
import {
  FormAllValidationRules,
  FormAllValidationErrors,
  FormItemRulesType,
  FormAllValidationRuleKey,
  FormItemType,
} from './FormItem.types';
import { formItemRule } from './FormItem.utils';

const { useFormInstance } = AntdForm;

interface UseRulesReturnType {
  rules: FormItemRulesType;
}

interface UseRulesParams<T> {
  errors: Partial<FormAllValidationErrors<T>>;
  validation: Partial<FormAllValidationRules<T>>;
  type: FormItemType;
}

export const useRules = <T>({
  validation,
  errors,
  type,
}: UseRulesParams<T>): UseRulesReturnType => {
  const form = useFormInstance<T>();

  const rules: FormItemRulesType = (
    Object.keys(validation) as FormAllValidationRuleKey<T>[]
  )
    .map((key) => {
      return formItemRule[key]({
        form,
        value: validation[key],
        message: errors[key],
        type,
      });
    })
    .filter((item) => Object.keys(item).length);

  return { rules };
};
