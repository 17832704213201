export const THEME = {
  spacing: (val: number) => `${val * 4}px`,
  palette: {
    black: '#000000',
    metropolisGrey: '#111111',
    anthraciteGrey: '#1B1B1B',
    icarusGrey: '#222222',
    asphaltGrey: '#363636',
    wolfGrey: '#555555',
    londonGrey: '#666666',
    pearlGrey: '#999999',
    stardustGrey: '#CCCCCC',
    white: '#FFFFFF',
    spintelGreen: '#82B955',
    guacamoleGreen: '#5EA702',
    feverRed: '#FF5050',
    vividRed: '#FF1A1A',
    sundance: '#A9DC7E',
    shamrockGreen: '#419401',
    canariasYellow: '#FFE24B',
    tangerine: '#FFA336',
    lavaOrange: '#F57600',
    madridRed: '#D9A5DA',
    supernovaPink: '#FF87A3',
    destinyViolet: '#BA83E6',
    venusViolet: '#A063CF',
    twisterBlue: '#6973F6',
    electricBlue: '#4A57FF',
    freedomBlue: '#0488D2',
    genesisBlue: '#BFBEFC',
    turquoise: '#00C8BC',
    mintGreen: '#10B36E',
    transparent: 'transparent',
  },
};

export const PALETTE_COLOR_NAME_MAP: Record<
  string,
  keyof typeof THEME.palette
> = Object.entries(THEME.palette).reduce(
  (res, [key, value]) => ({ ...res, [value]: key }),
  {}
);
