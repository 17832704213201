import { FC, useCallback, Key } from 'react';
import { set, castArray } from 'lodash';
import { FormItem, FormItemProps } from '../FormItem/FormItem';
import { FormCheckboxSelectionContext } from '../../../contexts/FormCheckboxSelection.context';
import { useFormInstance, useFormWatch } from '../Form.hooks';

const DEFAULT_VALUE: unknown[] = [];

interface FormCheckboxSelectionProps extends FormItemProps {}

export const FormCheckboxSelection: FC<FormCheckboxSelectionProps> = ({
  children,
  ...props
}) => {
  const { name } = props;
  const form = useFormInstance();
  const selection = useFormWatch(name, form) || DEFAULT_VALUE;

  const onChange = useCallback(
    (v: boolean, rowKey: Key) => {
      const value = v
        ? [...selection, rowKey]
        : castArray(selection).filter((v) => v !== rowKey);
      const res = set({}, name, value);
      form.setFieldsValue(res);
    },
    [selection, form, name]
  );

  const onToggle = useCallback(
    (v: boolean, values: Key[]) => {
      const value = v ? values : [];
      const res = set({}, name, value);
      form.setFieldsValue(res);
    },
    [name, form]
  );

  return (
    <FormCheckboxSelectionContext.Provider
      value={{ name, onChange, onToggle, value: selection }}
    >
      <FormItem {...props}>{children}</FormItem>
    </FormCheckboxSelectionContext.Provider>
  );
};
