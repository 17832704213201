import { FC } from 'react';
import { toNumber } from 'lodash';
import { useTranslation } from '@spintel/localization';
import { useRoute, Link } from '@spintel/navigation';
import { Search, Menu, Empty, MenuItem, useDrawers } from '@spintel/uikit';
import { useSong, useSongsList } from '@common/hooks';
import { SongSelectorLabel } from '../SongSelectorLabel/SongSelectorLabel';
import { Searchbar, Content, Subtitle } from './SongSelectorOverlay.style';

export const SongSelectorOverlay: FC = () => {
  const { t } = useTranslation();
  const [{ path, params, search: searchParams }] = useRoute();

  const [{ song }, update] = useSong();

  const { closeAll } = useDrawers();

  const [{ songs, total, loading }, { load, search }] = useSongsList();

  const items: MenuItem[] = songs.map(({ songId, ...rest }) => ({
    key: `${songId}`,
    label: (
      <Link to={path} params={{ ...params, songId }} search={searchParams}>
        <SongSelectorLabel song={{ ...rest, songId }} />
      </Link>
    ),
  }));

  const handleMenuClick = (v: string | number) => {
    update(toNumber(v));
    closeAll();
  };

  return (
    <>
      <Searchbar>
        <Search
          debounce={300}
          loading={loading}
          onChange={search}
          placeholder={t('placeholders.song')}
        />
      </Searchbar>
      <Content onScroll={load} length={songs.length} total={total}>
        {!!total && <Subtitle>{t('forms.allSongs')}</Subtitle>}
        {!total && (
          <Empty
            size="sm"
            icon="song"
            title={t('empty.noSongsFoundForYourRequest')}
          />
        )}
        <Menu
          items={items}
          value={[`${song.songId}`]}
          onClick={handleMenuClick}
        />
      </Content>
    </>
  );
};
