import styled, { createGlobalStyle } from 'styled-components';
import { Spin } from '../Spin/Spin';

export const Global = createGlobalStyle`
    .leaflet-bottom {
        margin-bottom: ${({ theme: { spacing } }) => spacing(4)};
        &.leaflet-right {
            margin-right: ${({ theme: { spacing } }) => spacing(4)};
            .leaflet-control-zoom {}
        }
    }

    .leaflet-touch {
        .leaflet-bar {
            border: none;

            a {
                width: ${({ theme: { spacing } }) => spacing(8)};
                height: ${({ theme: { spacing } }) => spacing(9)};
                color: ${({ theme }) => theme.palette.white};
                background-color: ${({ theme }) =>
                  theme.palette.metropolisGrey};
                border-width: 1px;
                border-style: solid;
                border-color: ${({ theme }) => theme.palette.wolfGrey};
                font-size: 16px;
                position: relative;
                z-index: 1;
                user-select: none;

                &:hover {
                    &:not(&.leaflet-disabled) {
                        border-color: ${({ theme }) => theme.palette.white};
                        z-index: 2;
                    }

                    &:focus {
                        span {
                            opacity: .5;
                        }
                    }
                }

                &:first-child {
                    border-radius: ${({ theme: { spacing } }) =>
                      `${spacing(4)} ${spacing(4)} 0 0`};
                }

                &:last-child {
                    margin-top: -1px;
                    border-radius: ${({ theme: { spacing } }) =>
                      `0 0 ${spacing(4)} ${spacing(4)}`};
                }

                &.leaflet-disabled {
                    background-color: ${({ theme }) =>
                      theme.palette.anthraciteGrey};
                    border-color: ${({ theme }) => theme.palette.londonGrey};
                    cursor: default;
                    pointer-events: none;

                    span {
                        opacity: .5;
                    }
                }
            }
        }
    }
`;

export const Root = styled('div')`
  position: relative;

  .mapboxgl-ctrl-logo {
    display: none;
  }
  .mapboxgl-ctrl {
    margin-bottom: ${({ theme: { spacing } }) => spacing(4)};
    margin-right: ${({ theme: { spacing } }) => spacing(4)};
  }
  .mapboxgl-ctrl-group {
    background: transparent;
    &:has(> .mapboxgl-ctrl-zoom-in) {
      button {
        width: ${({ theme: { spacing } }) => spacing(8)};
        height: ${({ theme: { spacing } }) => spacing(9)};
        background-color: ${({ theme }) => theme.palette.metropolisGrey};
        border-width: 1px;
        border-style: solid;
        border-color: ${({ theme }) => theme.palette.wolfGrey};
        font-size: 16px;
        position: relative;
        z-index: 1;
        user-select: none;
        span {
          filter: invert(100%) contrast(200%);
          background-size: 80%;
        }
        &:hover {
          &:not(:disabled) {
            border-color: ${({ theme }) => theme.palette.white};
            z-index: 2;
          }
          &:focus {
            span {
              opacity: 0.5;
            }
          }
        }
        &:first-child {
          border-radius: ${({ theme: { spacing } }) =>
            `${spacing(4)} ${spacing(4)} 0 0`};
        }
        &:last-child {
          margin-top: -1px;
          border-radius: ${({ theme: { spacing } }) =>
            `0 0 ${spacing(4)} ${spacing(4)}`};
        }
        &:disabled {
          background-color: ${({ theme }) => theme.palette.anthraciteGrey};
          border-color: ${({ theme }) => theme.palette.londonGrey};
          cursor: default;
          pointer-events: none;
          span {
            opacity: 0.5;
          }
        }
      }
    }
  }
`;

export const Loader = styled(Spin)`
  &.ant-spin-spinning {
    min-height: 100%;
    background: ${({ theme }) => theme.palette.anthraciteGrey};
  }
`;
