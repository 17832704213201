import styled from 'styled-components';
import AntdSpin from 'antd/lib/spin';
import { Icon } from '../Icon/Icon';
import { rotateAnimation } from '../../styles/Rotate.style';

export const Root = styled(AntdSpin)``;

export const Indicator = styled(Icon)`
  animation: ${rotateAnimation} 1s linear infinite;
`;
