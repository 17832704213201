import React, { FC } from 'react';
import { FormItem, FormItemProps } from '../FormItem/FormItem';
import { RootRadio as Radio } from '../../Input/Input.style';
import { FormRadioValidationRules } from '../FormItem/FormItem.types';

interface FormRadioProps
  extends Omit<FormItemProps<FormRadioValidationRules>, 'placeholder'> {}

export const FormRadio: FC<FormRadioProps> = ({
  label,
  disabled,
  ...props
}) => {
  return (
    <FormItem {...props}>
      <Radio disabled={disabled}>{label}</Radio>
    </FormItem>
  );
};
