import { useContext, Key, useEffect } from 'react';
import { isEqual, isNil, values } from 'lodash';
import AntdForm from 'antd/lib/form';
import { UiKitLocalizationContext } from '../../../../contexts/UiKitLocalization.context';
import { Form } from '../../../Form/Form';
import { Button } from '../../../Button/Button';
import { TableFilterComponent } from '../TableFilters.types';
import { Root, Content, Footer, Between, And } from './TableFilterRange.style';
import { useFilterOptions } from './TableFilterRange.hooks';
import { TableFilterRangeForm } from './TableFilterRange.types';
import { TableFilterRangeComparator } from './TableFilterRange.const';

const { useForm, useWatch } = AntdForm;

export const TableFilterRange: TableFilterComponent = ({
  confirm,
  selectedKeys,
  setSelectedKeys,
  visible,
}) => {
  const [form] = useForm<TableFilterRangeForm>();
  const { table: locale } = useContext(UiKitLocalizationContext);
  const { options } = useFilterOptions();
  const comparator = useWatch('comparator', form);
  const value = useWatch('value', form);
  const initialValues = selectedKeys[0] as TableFilterRangeForm;
  const formCurrentValues = { comparator, value };

  const handleOnSubmit = (form: TableFilterRangeForm) => {
    setSelectedKeys([form as Key]);
    confirm({ closeDropdown: true });
  };

  const formInitialValues = initialValues || {
    comparator: TableFilterRangeComparator.EQ,
    value: { eq: undefined },
  };

  useEffect(() => {
    if (visible) {
      form.resetFields(['value']);
      form.setFieldsValue(formInitialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return visible ? (
    <Root>
      <Form<TableFilterRangeForm>
        form={form}
        initialValues={formInitialValues}
        onSubmit={handleOnSubmit}
      >
        <Content>
          <Form.RadioGroup
            name="comparator"
            validation={{ required: true }}
            options={options}
          />
          {comparator === TableFilterRangeComparator.EQ && (
            <Form.Number
              hideLabel
              label={locale.filtersRangeEqLabel}
              name={['value', 'eq']}
              validation={{ required: true }}
              placeholder={'0'}
            />
          )}
          {comparator === TableFilterRangeComparator.GT && (
            <Form.Number
              hideLabel
              label={locale.filtersRangeGtLabel}
              name={['value', 'gt']}
              validation={{ required: true }}
              placeholder={'0'}
            />
          )}
          {comparator === TableFilterRangeComparator.LT && (
            <Form.Number
              hideLabel
              label={locale.filtersRangeLtLabel}
              name={['value', 'lt']}
              validation={{ required: true }}
              placeholder={'0'}
            />
          )}
          {comparator === TableFilterRangeComparator.BT && (
            <Between>
              <Form.Number
                hideLabel
                label={locale.filtersRangeBtLabel}
                name={['value', 'gt']}
                validation={{ required: true }}
                placeholder={'0'}
              />
              <And>{locale.dropdownFilterBetweenAnd}</And>
              <Form.Number
                hideLabel
                label={locale.filtersRangeBtLabel}
                name={['value', 'lt']}
                validation={{ required: true }}
                placeholder={'0'}
              />
            </Between>
          )}
        </Content>
        <Footer>
          <Button onClick={() => confirm({ closeDropdown: true })}>
            {locale.dropdownFilterCancel}
          </Button>
          <Form.Submit
            variant="primary"
            disabled={
              values(formCurrentValues.value).some(isNil) ||
              isEqual(formInitialValues, formCurrentValues)
            }
          >
            {locale.dropdownFilterConfirm}
          </Form.Submit>
        </Footer>
      </Form>
    </Root>
  ) : null;
};
