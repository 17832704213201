import { FC, PropsWithChildren } from 'react';
import { useSong } from '@common/hooks';
import { LinkVoid, useRoute } from '@spintel/navigation';
import { RoutePath } from '@common/constants';

type SongSelectorLinkProps = PropsWithChildren<{
  songId: number;
}>;

export const SongSelectorLink: FC<SongSelectorLinkProps> = ({
  songId,
  children,
}) => {
  const [, update] = useSong();
  const [, { push }] = useRoute();

  const onClick = () => {
    update(songId).then(() => {
      push({ to: RoutePath.SONG });
    });
  };

  return <LinkVoid onClick={onClick}>{children}</LinkVoid>;
};
