import { FC, PropsWithChildren } from 'react';
import { Root } from './ModalContent.style';

type ModalContentProps = PropsWithChildren<{
  className?: string;
}>;

export const ModalContent: FC<ModalContentProps> = ({
  className,
  children = null,
}) => {
  return <Root className={className}>{children}</Root>;
};
