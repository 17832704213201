module.exports = {
  firstNamePattern: 'First Name field has invalid format',
  lastNamePattern: 'Last Name field has invalid format',
  panelName:
    'Panel name allows latin letters, numeric, dot, comma, dash and space',
  passwordPattern:
    'Passwords must be at least 8 characters long, include both uppercase and lowercase letters, numbers, and at least one special character.',
  passwordsMatch: 'Password and Password Confirmation fields do not match',
  passwordsResetMatch:
    'Provided values do not match, please input same password values for your new password',
  phoneNumberPattern: 'Phone Number field has invalid format',
  teamNamePattern:
    'Team name allows latin letters, numeric, dot, comma, dash and space',
};
