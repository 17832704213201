import { FC, useCallback } from 'react';
import { Alert, Layout, Splash } from '@spintel/uikit';
import {
  DTO,
  useMutation,
  useQuery,
  RequestParams,
} from '@spintel/network/app';
import { useUser } from '@spintel/network/auth';
import { Outlet } from '@spintel/navigation';
import { Suspense } from '../Suspense/Suspense';
import { ShellContext } from '../../contexts/Shell.context';
import { Nav } from './Nav/Nav';
import { Header } from './Header/Header';
import { Root, Content } from './UserShell.style';
import { Prompt } from './Prompt/Prompt';

export const UserShell: FC = () => {
  const [
    {
      loading: userLoading,
      user: { selectedPanelId, selectedTeamId },
    },
  ] = useUser();

  const [{ data: song, loading: songLoading }, refetchSong] = useQuery<
    [],
    DTO.IGetSongSelectorResponse
  >(({ SongSelector }) => SongSelector.songSelectorList, []);

  const [{ data: panels, loading: panelsLoading }, refetchPanels] = useQuery<
    [RequestParams],
    DTO.IPanelInfoModel[]
  >(
    ({ Panel }) => Panel.mainSelectorList,
    [
      {
        headers: {
          Intercept: false,
        },
      },
    ],
    {
      polling: 30000,
    }
  );

  const [{ loading: songSaving }, saveSong] = useMutation<[number]>(
    ({ SongSelector }) => SongSelector.songSelectorCreate
  );

  const ready = !!song && !!panels;

  const progress = songLoading || songSaving;

  const setSong = useCallback(
    (songId: number) => {
      return new Promise<number>((resolve) => {
        saveSong([songId], {
          onSuccess: () => {
            refetchSong();
            resolve(songId);
          },
        });
      });
    },
    [saveSong, refetchSong]
  );

  return ready ? (
    <ShellContext.Provider
      value={{ setSong, song, progress, panels, refetchPanels }}
    >
      <Prompt panels={panels} loading={userLoading || panelsLoading}>
        <Root hasSider>
          <Nav />
          <Layout>
            <Header />
            <Alert.Snackbar />
            <Content>
              <Suspense spinner>
                <Outlet key={`${selectedPanelId}-${selectedTeamId}`} />
              </Suspense>
            </Content>
          </Layout>
        </Root>
      </Prompt>
    </ShellContext.Provider>
  ) : (
    <Splash />
  );
};
