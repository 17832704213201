import styled, { css } from 'styled-components';
import { UiKitDiffType } from '../../types/uikit';
import { THEME } from '../../constants/theme.const';

interface RootProps {
  value: number;
  type: UiKitDiffType;
  monospaced: boolean;
}

const COLOR_MAP: Record<UiKitDiffType, string> = {
  negative: THEME.palette.feverRed,
  positive: THEME.palette.spintelGreen,
  neutral: THEME.palette.londonGrey,
};

const MonospacedMixin = css`
  font-variant: tabular-nums;
`;

const ArrowMixin = css<RootProps>`
  &:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    margin-right: ${({ theme: { spacing } }) => spacing(0.5)};
    border-top-width: ${({ type }) =>
      type === UiKitDiffType.positive ? 0 : 4}px;
    border-right-width: 3px;
    border-bottom-width: ${({ type }) =>
      type === UiKitDiffType.positive ? 4 : 0}px;
    border-left-width: 3px;
    border-top-color: ${({ type, theme: { palette } }) =>
      type === UiKitDiffType.positive ? palette.transparent : palette.feverRed};
    border-right-color: ${({ theme: { palette } }) => palette.transparent};
    border-bottom-color: ${({ type, theme: { palette } }) =>
      type === UiKitDiffType.positive
        ? palette.spintelGreen
        : palette.transparent};
    border-left-color: ${({ theme: { palette } }) => palette.transparent};
  }
`;

export const Root = styled('span')<RootProps>`
  display: flex;
  align-items: center;
  color: ${({ type }) => COLOR_MAP[type]};
  ${({ monospaced }) => monospaced && MonospacedMixin}
  ${({ value }) => !!value && ArrowMixin}
`;
