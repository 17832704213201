import { PropsWithChildren, ReactElement } from 'react';
import { Root, Section } from './Toolbar.style';

type ToolbarProps = PropsWithChildren<{
  className?: string;
}>;

export interface ToolbarComponent {
  (props: ToolbarProps): ReactElement<any, any> | null;
  Section: typeof Section;
}

export const Toolbar: ToolbarComponent = ({ className, children }) => {
  return <Root className={className}>{children}</Root>;
};

Toolbar.Section = Section;
