import { FC } from 'react';
import { useRoute, Redirect } from '@spintel/navigation';
import { useTranslation } from '@spintel/localization';
import { DTO, useSignup, useEmailVerification } from '@spintel/network/auth';
import { AuthRoutePath } from '@common/constants';
import { Form, Grid, Splash, Prompt } from '@spintel/uikit';
import { AuthPage } from '../../partitions/AuthPage/AuthPage';
import {
  PHONE_NUMBER_PREFIX,
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PATTERN,
  NAME_PATTERN,
} from './Signup.const';

export const Signup: FC = () => {
  const { t } = useTranslation();
  const [{ params }] = useRoute<DTO.IEmailVerificationDto>();
  const [{ data: signupData, loading: signupLoading }, signup] = useSignup();
  const [{ data: emailData, loading: emailLoading, error }] =
    useEmailVerification({
      token: params?.token,
    });

  const handleSubmit = (form: DTO.ISignUpUserRequestDto) =>
    signup({
      ...form,
      phoneNumber:
        form.phoneNumber && `${PHONE_NUMBER_PREFIX}${form.phoneNumber}`,
    });

  if (error) {
    return <Redirect to={AuthRoutePath.LOGIN} />;
  }

  if (emailLoading) {
    return <Splash />;
  }

  if (signupData?.token && signupData?.isMfaRequired) {
    return (
      <Redirect
        to={{
          path: AuthRoutePath.MFA_SETUP,
          search: { token: signupData.token },
        }}
      />
    );
  }

  if (signupData?.token) {
    return (
      <Redirect
        to={{
          path: AuthRoutePath.CALLBACK,
          search: { token: signupData.token },
        }}
      />
    );
  }

  return (
    <AuthPage errorCode={error?.errorCode}>
      <Prompt title={t('forms.signUp')}>
        <Form
          initialValues={{ email: emailData?.email }}
          onSubmit={handleSubmit}
        >
          <Prompt.Content>
            <Grid.Row gutter={[6, 0]}>
              <Grid.Col span={12}>
                <Form.Input
                  autofocus
                  label={t('forms.firstName')}
                  name="firstName"
                  validation={{
                    min: 1,
                    pattern: NAME_PATTERN,
                    required: true,
                  }}
                  errors={{
                    pattern: t('validation.firstNamePattern'),
                  }}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Form.Input
                  label={t('forms.lastName')}
                  name="lastName"
                  validation={{
                    pattern: NAME_PATTERN,
                    required: true,
                  }}
                  errors={{
                    pattern: t('validation.lastNamePattern'),
                  }}
                />
              </Grid.Col>
            </Grid.Row>
            <Form.Input
              label={t('forms.email')}
              name="email"
              disabled
              validation={{
                required: true,
              }}
            />
            <Form.Input
              label={t('forms.phoneNumber')}
              placeholder={t('placeholders.phoneNumber')}
              name="phoneNumber"
              mask={PHONE_NUMBER_MASK}
              prefix={PHONE_NUMBER_PREFIX}
              validation={{
                pattern: PHONE_NUMBER_PATTERN,
              }}
              errors={{
                pattern: t('validation.phoneNumberPattern'),
              }}
            />
            <Form.Password
              label={t('forms.password')}
              name="password"
              validation={{
                password: true,
                required: true,
              }}
              help={t('help.password')}
            />
            <Form.Password<DTO.ILoginUserDto>
              label={t('forms.passwordConfirm')}
              name="passwordConfirmation"
              validation={{
                equal: ({ password }) => password,
                required: true,
              }}
              errors={{
                equal: t('validation.passwordsMatch'),
              }}
            />
          </Prompt.Content>
          <Prompt.Actions>
            <Form.Submit loading={signupLoading} size="lg" variant="primary">
              {t('actions.next')}
            </Form.Submit>
          </Prompt.Actions>
        </Form>
      </Prompt>
    </AuthPage>
  );
};
