import { FC } from 'react';
import { noop } from 'lodash';
import { Source, Layer } from 'react-map-gl';
import { THEME } from '../../../constants/theme.const';
import { UiKitColor } from '../../../types/uikit';
import { MapPosition } from '../Map.types';
import { useOnClick } from '../Map.hooks';

interface MapCircleProps {
  id: string;
  radius?: number;
  hitboxRadius?: number;
  position: MapPosition;
  color?: UiKitColor;
  onClick?: () => void;
}

export const MapCircle: FC<MapCircleProps> = ({
  id,
  position: { lat, lng },
  radius = 4,
  hitboxRadius = 8,
  color = 'pearlGrey',
  onClick = noop,
}) => {
  useOnClick({ id, onClick });

  return (
    <Source
      tolerance={0}
      type="geojson"
      data={{
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lng, lat],
        },
        properties: {},
      }}
    >
      <Layer
        type="circle"
        paint={{
          'circle-color': THEME.palette[color],
          'circle-radius': radius,
        }}
      />
      <Layer
        id={id}
        type="circle"
        paint={{
          'circle-color': 'transparent',
          'circle-radius': hitboxRadius,
        }}
      />
    </Source>
  );
};
