import styled from 'styled-components';
import { Icon } from '../../../Icon/Icon';

export const Root = styled('span')(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flex: '0 0 auto',
  height: spacing(6),
  borderRadius: spacing(3),
  background: palette.spintelGreen,
  paddingLeft: spacing(3),
  paddingRight: spacing(2),
  gap: spacing(2),
  color: palette.metropolisGrey,
  alignItems: 'center',
}));

export const Text = styled('span')(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(1),
}));

export const Title = styled('span')`
  &::after {
    content: ':';
  }
`;

export const Value = styled('span')(() => ({
  fontWeight: 600,
}));

export const Remove = styled(Icon)(() => ({
  cursor: 'pointer',
}));
