import { FC } from 'react';
import { useTranslation } from '@spintel/localization';
import { AuthRoutePath } from '@common/constants';
import { useRoute } from '@spintel/navigation';
import { Button, Prompt } from '@spintel/uikit';

export const SessionTimeout: FC = () => {
  const { t } = useTranslation();
  const [, { push }] = useRoute();

  const handleClickOk = () => {
    push({ to: AuthRoutePath.LOGIN });
  };

  return (
    <Prompt
      title={t('prompt.sessionExpired.title')}
      subtitle={t('prompt.sessionExpired.subtitle')}
    >
      <Prompt.Actions>
        <Button size="lg" variant="primary" onClick={handleClickOk}>
          {t('actions.login')}
        </Button>
      </Prompt.Actions>
    </Prompt>
  );
};
