import {
  Chart,
  ChartData,
  ChartOptions,
  Plugin,
  ChartDataset,
  ScatterDataPoint,
} from 'chart.js';
import { cloneDeep, merge } from 'lodash';
import { FC, useContext, useEffect, useRef } from 'react';
import { Line as ChartJSLine } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartContext } from '../Chart.context';
import { getCanvasTransparentGradient } from '../Chart.utils';
import { THEME } from '../../../constants/theme.const';
import { defaultDataset } from './ChartLine.const';
import { Root } from './ChartLine.style';
import { useOptions } from './ChartLine.hooks';

interface LineProps {
  data?: Partial<ChartData<'line'>>;
  options?: Partial<ChartOptions<'line'>>;
  plugins?: Plugin[];
  className?: string;
}

export const ChartLine: FC<LineProps> = ({
  data: customChartData = {},
  options: customChartOptions = {},
  plugins: customPlugins = [],
  className,
}) => {
  const { data, reverse, labels, color, setInstance, selectedIndex } =
    useContext(ChartContext);
  const chartRef = useRef<Chart<'line'>>(null);

  useEffect(() => {
    if (chartRef.current) {
      setInstance(chartRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef]);

  const { options, smallestY, mouseLine } = useOptions(customChartOptions);

  // if we have negative Y we lifts up chart on the absolute value of the smallest Y value
  // and move down Y ticks and move down labels value on the same smallest Y value
  const nData =
    smallestY < 0
      ? data.map((p) => ({ x: p.x, y: p.y + Math.abs(smallestY) }))
      : data;

  const defaultChartDataset: ChartDataset<'line', ScatterDataPoint[]> = {
    borderColor: THEME.palette[color],
    backgroundColor: (context) => getCanvasTransparentGradient(color, context),
    pointHoverBackgroundColor: THEME.palette[color],
    pointBackgroundColor: ({ dataIndex }) =>
      dataIndex === selectedIndex
        ? THEME.palette.white
        : THEME.palette.transparent,
    pointHoverRadius: 4,
    pointRadius: 4,
    data: nData,
    datalabels: {
      display: (context) => {
        return !!context.chart
          .getDatasetMeta(0)
          .xScale.ticks.filter(({ value }) => value === context.dataIndex)
          .length;
      },
      color: () => THEME.palette.black,
      backgroundColor: ({ dataIndex }) => {
        return dataIndex === selectedIndex
          ? THEME.palette.white
          : THEME.palette[color];
      },
    },
    // fill 'start' to move the background under the line in the reverse mode
    fill: reverse ? 'start' : 'origin',
  };
  const defaultChartData: ChartData<'line'> = {
    datasets: [merge(cloneDeep(defaultDataset), defaultChartDataset)],
    labels,
  };
  const chartData = merge(defaultChartData, customChartData);

  const chartPlugins: Plugin<'line'>[] = [
    ...customPlugins,
    ChartDataLabels,
    mouseLine,
  ];

  return (
    <Root className={className}>
      <ChartJSLine
        ref={chartRef}
        data={chartData}
        options={options}
        plugins={chartPlugins}
      />
    </Root>
  );
};
