import { FC } from 'react';
import { get } from 'lodash';
import { AvatarProps as AntdAvatarProps } from 'antd/lib/avatar';
import { UiKitColor } from '../../types/uikit';
import { Root } from './Avatar.style';
import { AvatarSize } from './Avatar.types';
import { AVATAR_SIZE_MAP } from './Avatar.const';

interface AvatarProps extends Omit<AntdAvatarProps, 'size'> {
  className?: string;
  color?: UiKitColor;
  size?: AvatarSize | number;
}

export const Avatar: FC<AvatarProps> = ({
  className,
  size = 'md',
  color = 'wolfGrey',
  ...props
}) => {
  return (
    <Root
      color={color}
      className={className}
      size={get(AVATAR_SIZE_MAP, size, size)}
      {...props}
    />
  );
};

export type { AvatarProps };
