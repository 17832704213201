import { Moment } from 'moment';
import { DatePickerPicker } from './CalendarPicker.types';

type ValueFormatter = (value: Moment) => string;

export const VALUE_FORMATTER: Record<DatePickerPicker, ValueFormatter> = {
  date: (value) => value.utc().startOf('day').toISOString(),
  week: (value) => value.utc().startOf('week').toISOString(),
  month: (value) => value.utc().startOf('month').toISOString(),
  year: (value) => value.utc().startOf('year').toISOString(),
  quarter: (value) => value.utc().startOf('quarter').toISOString(),
  time: (value) => value.utc().toISOString(),
};
