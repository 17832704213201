import styled from 'styled-components';
import AntdRadio from 'antd/lib/radio';
import { RootRadio as Radio } from '../../Input/Input.style';
import { FormRadioGroupDirection } from './FormRadioGroup.types';

const { Group } = AntdRadio;

export const Root = styled(Group)<{
  direction: FormRadioGroupDirection;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  ${Radio} {
    margin-left: ${({ direction, theme: { spacing } }) =>
      direction === 'column' ? 0 : spacing(2)};
    + {
      ${Radio} {
        margin-top: ${({ direction, theme: { spacing } }) =>
          direction === 'column' ? spacing(2) : 0};
      }
    }
  }
`;
