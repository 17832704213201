import { useQuery } from '../useQuery/useQuery.hook';
import { IMfaSetupResponseDto } from '../../__generated__/data-contracts';
import { RequestParams } from '../../__generated__/http-client';
import { UseMfaSetupReturnType } from './useMfaSetup.types';

export type UseMfaSetupParams = {
  token: string;
};

export const useMfaSetup = ({
  token,
}: UseMfaSetupParams): UseMfaSetupReturnType => {
  const [state] = useQuery<[RequestParams], IMfaSetupResponseDto>(
    ({ Auth }) => Auth.mfaSetupList,
    [
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Intercept: false,
        },
      },
    ]
  );

  return [state];
};
