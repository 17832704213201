import { useCallback } from 'react';
import { useMutation } from '../useMutation/useMutation.hook';
import {
  IMfaResponseDto,
  IJwtTokenResponseDto,
} from '../../__generated__/data-contracts';
import { RequestParams } from '../../__generated__/http-client';
import {
  UseMfaConfirmParams,
  UseMfaConfirmReturnType,
} from './useMfaConfirm.types';

export const useMfaConfirm = (): UseMfaConfirmReturnType => {
  const [state, execute] = useMutation<
    [IMfaResponseDto, RequestParams],
    IJwtTokenResponseDto
  >(({ Auth }) => Auth.mfaValidateCreate);

  const confirm = useCallback(
    ({ token, ...rest }: UseMfaConfirmParams) => {
      execute([
        rest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Intercept: false,
          },
        },
      ]);
    },
    [execute]
  );

  return [state, confirm];
};
