import { FC, Key } from 'react';
import { EmptyProps } from '../../Empty/Empty';
import { Root } from './TableEmpty.style';

interface TableEmptyProps extends Omit<EmptyProps, 'fill'> {
  height?: Key;
}

export const TableEmpty: FC<TableEmptyProps> = ({
  height,
  children,
  ...props
}) => {
  return (
    <Root height={height} {...props}>
      {children}
    </Root>
  );
};
