import styled, { css } from 'styled-components';
import AntdAlert from 'antd/lib/alert';
import { Icon } from '../Icon/Icon';
import { AlertRoot } from './Notification.types';

const RootClosableMixin = css`
  &.ant-alert-with-description {
    padding-right: 0;
  }
  .ant-notification-notice-message {
    padding-right: 0;
  }
`;

export const Root = styled<AlertRoot>(AntdAlert)`
  border: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  .ant-notification-notice-message {
    padding-right: 0;
  }
  &.ant-alert-with-description {
    padding-left: ${({ theme: { spacing } }) => spacing(6)};
    padding-right: ${({ theme: { spacing } }) => spacing(6)};
  }
  .ant-alert-content {
    font-size: 14px;
    line-height: 16px;
  }
  .ant-alert-action {
    margin-left: ${({ theme: { spacing } }) => spacing(4)};
    + {
      .ant-alert-close-icon {
        margin-left: ${({ theme: { spacing } }) => spacing(10)};
      }
    }
  }
  .ant-alert-close-icon {
    margin-left: ${({ theme: { spacing } }) => spacing(10)};
    margin-right: ${({ theme: { spacing } }) => spacing(4)};
    position: relative;
    overflow: visible;
    &:before {
      content: '';
      display: flex;
      width: 1px;
      position: absolute;
      height: ${({ theme: { spacing } }) => spacing(1250)};
      top: -${({ theme: { spacing } }) => spacing(625)};
      left: -${({ theme: { spacing } }) => spacing(4)};
    }
  }
  .ant-alert-close-icon {
    &:before {
      background: ${({ theme }) => theme.palette.white};
    }
  }
  ${({ closable }) => closable && RootClosableMixin}
`;

export const Close = styled(Icon)(({ theme: { palette } }) => ({
  color: palette.white,
}));
