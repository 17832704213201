import { TabsProps } from 'antd/lib/tabs';
import { DefaultTheme } from 'styled-components';

export type UiKitColor = keyof DefaultTheme['palette'];

export type UiKitSize = TabsProps['size'];

export enum UiKitDiffType {
  negative = 'negative',
  neutral = 'neutral',
  positive = 'positive',
}

export type UiKitBaseSize = 'lg' | 'md' | 'sm' | 'xs';

export type UiKitColumns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
