import { useCallback } from 'react';
import { UseMutationState } from '@shared/types';
import { useMutation } from '../useMutation/useMutation.hook';
import {
  ILoginUserDto,
  ILoginUserResponseDto,
  IErrorResponse,
} from '../../__generated__/data-contracts';
import { RequestParams } from '../../__generated__/http-client';

type LoginFunction = (form: ILoginUserDto) => void;

type UseLoginReturnType = [
  UseMutationState<ILoginUserResponseDto, IErrorResponse>,
  LoginFunction
];

export const useLogin = (): UseLoginReturnType => {
  const [state, execute] = useMutation<
    [ILoginUserDto, RequestParams],
    ILoginUserResponseDto
  >(({ Auth }) => Auth.loginCreate);

  const signIn: LoginFunction = useCallback(
    (form) => {
      execute([
        form,
        {
          headers: {
            Intercept: false,
          },
        },
      ]);
    },
    [execute]
  );

  return [state, signIn];
};
