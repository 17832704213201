import { FC } from 'react';
import { Redirect, useRoute } from '@spintel/navigation';
import { DTO, useMfaConfirm } from '@spintel/network/auth';
import { Form, Button, Prompt } from '@spintel/uikit';
import { AuthRoutePath } from '@common/constants';
import { useTranslation, Translate } from '@spintel/localization';
import { AuthPage } from '../../../partitions/AuthPage/AuthPage';

export const MfaConfirm: FC = () => {
  const { t } = useTranslation();

  const [
    {
      search: { token },
    },
    { back },
  ] = useRoute<unknown, DTO.ILoginUserResponseDto>();

  const [{ data, loading, error }, confirm] = useMfaConfirm();

  const handleOnSubmit = (form: DTO.IMfaResponseDto) => {
    confirm({ ...form, token });
  };

  if (data) {
    return <Redirect to={{ path: AuthRoutePath.CALLBACK, search: data }} />;
  }

  if (error?.errorCode === DTO.IErrorCode.UserNotAuthenticated) {
    return <Redirect to={{ path: AuthRoutePath.SESSION_TIMEOUT }} />;
  }

  return (
    <AuthPage errorCode={error?.errorCode}>
      <Prompt
        title={t('forms.multiFactorAuthentication')}
        subtitle={<Translate i18nKey="help.enterTotpCode" />}
      >
        <Form
          initialValues={{ totpCode: '' }}
          onSubmit={handleOnSubmit}
          errors={error?.errors}
        >
          <Prompt.Content>
            <Form.Input
              autofocus
              placeholder=""
              name="totpCode"
              label={t('forms.verificationCode')}
              hideLabel
              maxLength={12}
              validation={{
                max: 12,
                required: true,
              }}
            />
          </Prompt.Content>
          <Prompt.Actions justify="space-between">
            <Button size="lg" variant="link" onClick={back}>
              {t('actions.back')}
            </Button>
            <Form.Submit loading={loading} variant="primary" size="lg">
              {t('actions.submit')}
            </Form.Submit>
          </Prompt.Actions>
        </Form>
      </Prompt>
    </AuthPage>
  );
};
