/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IErrorResponse,
  ISongOwnershipGridResponse,
  ISongOwnershipMapResponse,
  ISongOwnershipPieChartResponse,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class SongOwnership<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SongOwnership
   * @name OwnershipPieChartDetail
   * @summary Get Ownership Pie Chart
   * @request GET:/songs/{songId}/ownership/pie-chart
   * @secure
   */
  ownershipPieChartDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<ISongOwnershipPieChartResponse[], IErrorResponse>({
      path: `/songs/${songId}/ownership/pie-chart`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongOwnership
   * @name OwnershipGridDetail
   * @summary Get ownership grid
   * @request GET:/songs/{songId}/ownership/grid
   * @secure
   */
  ownershipGridDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<ISongOwnershipGridResponse[], IErrorResponse>({
      path: `/songs/${songId}/ownership/grid`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongOwnership
   * @name OwnershipGridExportDetail
   * @summary Get ownership grid export
   * @request GET:/songs/{songId}/ownership/grid/export
   * @secure
   */
  ownershipGridExportDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/ownership/grid/export`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongOwnership
   * @name OwnershipMapDetail
   * @summary Get ownership map
   * @request GET:/songs/{songId}/ownership/map
   * @secure
   */
  ownershipMapDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<ISongOwnershipMapResponse[], IErrorResponse>({
      path: `/songs/${songId}/ownership/map`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
