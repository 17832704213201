import moment from 'moment';

const date = moment;

// Components
export { Translate } from './components/Translate/Translate';
export { LocalizationProvider } from './components/LocalizationProvider/LocalizationProvider';
export { DateFormat } from './components/DateFormat/DateFormat';

// Hooks
export { useDate } from './hooks/useDate/useDate.hook';
export { useTranslation } from 'react-i18next';
export { useNumber } from './hooks/useNumber/useNumber.hook';

export { date };
