import React, { PropsWithChildren } from 'react';

interface LinkVoidProps {
  onClick?: (evt?: React.MouseEvent) => void;
}

export const LinkVoid = ({
  onClick,
  children,
}: PropsWithChildren<LinkVoidProps>): React.ReactElement => {
  const handleClick = (evt?: React.MouseEvent) => {
    evt.preventDefault();
    onClick(evt);
  };

  return (
    <a onClick={handleClick} href="/#">
      {children}
    </a>
  );
};
