import { FC, PropsWithChildren } from 'react';
import { useUser } from '@spintel/network/auth';
import { ErrorBoundary, LoggerOrigin } from '@spintel/network/logger';
import { ErrorBoundaryFallback } from '../../ErrorBoundaryFallback/ErrorBoundaryFallback';

type ErrorBoundaryBootstrapProps = PropsWithChildren<unknown>;

export const ErrorBoundaryBootstrap: FC<ErrorBoundaryBootstrapProps> = ({
  children,
}) => {
  const [{ user }] = useUser();

  return (
    <ErrorBoundary
      origin={LoggerOrigin.ADMIN}
      params={{ email: user?.email }}
      fallback={({ id }) => <ErrorBoundaryFallback id={id} />}
    >
      {children}
    </ErrorBoundary>
  );
};
