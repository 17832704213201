import { useContextSelector } from 'use-context-selector';
import { groupBy, get } from 'lodash';
import { SelectOptions } from '@spintel/uikit';
import { DTO } from '@spintel/network/app';
import { useTranslation } from '@spintel/localization';
import { ShellContext, ShellContextProps } from '../../contexts/Shell.context';
import { PANELS_SCOPES } from './usePanel.const';

type UsePanelReturnType = [
  {
    options: SelectOptions;
    panels: DTO.IPanelInfoModel[];
  },
  () => void
];

export const usePanel = (): UsePanelReturnType => {
  const { t } = useTranslation();

  const panels = useContextSelector<ShellContextProps, DTO.IPanelInfoModel[]>(
    ShellContext,
    ({ panels }) => panels
  );

  const refetchPanels = useContextSelector<ShellContextProps, () => void>(
    ShellContext,
    ({ refetchPanels }) => refetchPanels
  );

  const groups = groupBy(panels, 'panelScopeType');

  const options: SelectOptions = PANELS_SCOPES.map((scope) => ({
    label: scope !== DTO.IPanelScopeType.National ? scope : null,
    options: get(groups, scope, []).map(
      ({ name: label, id: value, stationCount }) => ({
        label,
        value,
        hint: t('hints.stationsInfo', {
          count: stationCount,
          postProcess: 'interval',
        }),
      })
    ),
  })).filter(({ options }) => !!options.length);

  return [{ panels, options }, refetchPanels];
};
