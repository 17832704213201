module.exports = {
  artist: 'Artist',
  callsign: 'Callsign',
  city: 'City',
  email: 'Email',
  enterPanelName: 'Enter panel name',
  enterTeamName: 'Enter team name',
  enterYourEmail: 'Enter your email',
  enterYourPassword: 'Enter your password',
  market: 'Market',
  member: 'Member',
  name: 'Name',
  owner: 'Owner',
  panel: 'Panel',
  phoneNumber: '123-456-7890',
  song: 'Song',
  state: 'State',
  stateName: 'State name',
  station: 'Station',
  stationCallsign: 'Station callsign',
  team: 'Team',
  weight: 'Weight',
};
