import { UiKitBaseSize } from '../../types/uikit';
import { DRAWER_SIZE_MAP } from './Drawer.const';
import { DrawerAppendTo } from './Drawer.types';

interface GetDrawerWidthParams {
  size: UiKitBaseSize;
  appendTo?: DrawerAppendTo;
}

export const getDrawerWidth = ({
  size,
  appendTo,
}: GetDrawerWidthParams): string => {
  const el = appendTo && appendTo();
  if (el) {
    return `calc((${el.offsetWidth}px - 384px) / 12 * ${
      DRAWER_SIZE_MAP[size]
    } + ${(DRAWER_SIZE_MAP[size] + 1) * 24}px)`;
  }
  return `calc((100vw - 384px) / 12 * ${DRAWER_SIZE_MAP[size]} + ${
    (DRAWER_SIZE_MAP[size] + 1) * 24
  }px)`;
};
