import { ColumnProps } from 'antd/lib/table/Column';
import { TableFilter } from '../Table.types';
import { TableFilterSearch } from './TableFilterSearch/TableFilterSearch';
import { TableFilterRange } from './TableFilterRange/TableFilterRange';
import { TableFilterOptions } from './TableFilterOptions/TableFilterOptions';
import { TableFilterIcon } from './TableFilterIcon/TableFilterIcon';
import {
  getSearchFilterPredicate,
  getRangeFilterPredicate,
} from './TableFilters.utils';

export { getSearchFilterPredicate, getRangeFilterPredicate };

interface GetColumnFilterParams<T> {
  filter?: TableFilter;
  data: readonly T[];
  dataIndex: string | string[];
  formatter: (v: unknown) => unknown;
}

export const getColumnFilter = <T extends object>(
  params: GetColumnFilterParams<T>
): Partial<ColumnProps<T>> => {
  if (params?.filter?.type === 'search') {
    return {
      filterIcon: TableFilterIcon,
      filterDropdown: (props) => <TableFilterSearch {...params} {...props} />,
      onFilter: getSearchFilterPredicate(params.dataIndex),
    };
  }
  if (params?.filter?.type === 'range') {
    return {
      filterIcon: TableFilterIcon,
      filterDropdown: (props) => <TableFilterRange {...params} {...props} />,
      onFilter: getRangeFilterPredicate({
        formatter: params.formatter,
        dataIndex: params.dataIndex,
      }),
    };
  }
  if (params?.filter?.type === 'options') {
    return {
      filterIcon: TableFilterIcon,
      filterDropdown: (props) => <TableFilterOptions {...params} {...props} />,
      onFilter: getSearchFilterPredicate(params.dataIndex),
    };
  }
  return {};
};
