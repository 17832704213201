import { FC, ReactNode } from 'react';
import { getDiff } from '../../utils/getDiff/getDiff.utils';
import { Dash } from '../Dash/Dash';
import { Root } from './Diff.style';

interface DiffProps {
  value: number;
  monospaced?: boolean;
  formatter?: (value: number) => ReactNode;
}

export const Diff: FC<DiffProps> = ({
  value,
  monospaced = true,
  formatter = (value) => value,
}) => {
  const { diffValue, diffType } = getDiff(value);

  return (
    <Root value={diffValue} type={diffType} monospaced={monospaced}>
      {diffValue !== null ? formatter(diffValue) : <Dash />}
    </Root>
  );
};
