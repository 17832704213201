import { ReactElement, useRef } from 'react';
import { InputRef } from 'antd';
import { useMount } from 'react-use';
import { FormItem, FormItemProps } from '../FormItem/FormItem';
import { FormPasswordValidationRules } from '../FormItem/FormItem.types';
import { RootPassword as Password } from '../../Input/Input.style';

interface FormPasswordProps<T>
  extends FormItemProps<FormPasswordValidationRules<T>> {
  help?: string;
  autofocus?: boolean;
}

interface FormPasswordComponent {
  <T>(props: FormPasswordProps<T>): ReactElement<any, any> | null;
}

export const FormPassword: FormPasswordComponent = ({
  disabled,
  placeholder,
  autofocus,
  ...props
}) => {
  const ref = useRef<InputRef>();

  useMount(() => {
    if (autofocus && ref.current) {
      ref.current.focus({
        cursor: 'start',
      });
    }
  });

  return (
    <FormItem hasFeedback {...props}>
      <Password ref={ref} placeholder={placeholder} disabled={disabled} />
    </FormItem>
  );
};
