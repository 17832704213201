import { Dropdown } from '@spintel/uikit';
import styled from 'styled-components';

export const StyledConfirm = styled(Dropdown.Confirm)<{
  isEmptyStyle?: boolean;
}>(({ isEmptyStyle, theme }) =>
  isEmptyStyle
    ? {
        position: 'absolute',
        top: theme.spacing(-55),
        left: '50%',
        transform: 'translateX(-50%)',
        filter: `drop-shadow(
            ${theme.spacing(0)} 
            ${theme.spacing(1)} 
            ${theme.spacing(7.5)} 
            ${theme.palette.black}33)
        `,
      }
    : {}
);
