import { FC, PropsWithChildren, ReactNode } from 'react';
import { Typography } from '../../Typography/Typography';
import { Tooltip } from '../../Tooltip/Tooltip';
import { Root, Left, Right, Info } from './SectionHeader.style';

type SectionHeaderProps = PropsWithChildren<{
  title?: ReactNode;
  hint?: string;
}>;

const { Heading3 } = Typography;

export const SectionHeader: FC<SectionHeaderProps> = ({
  title: initialTitle,
  hint: initialHint,
  children,
}) => {
  const title = (() => {
    if (typeof initialTitle === 'string') {
      return <Heading3>{initialTitle}</Heading3>;
    }
    return initialTitle;
  })();

  const hint = initialHint && (
    <Tooltip content={initialHint} placement="rightTop">
      <span>
        <Info name="info" size="sm" />
      </span>
    </Tooltip>
  );

  return (
    <Root>
      <Left>
        {title}
        {hint}
      </Left>
      <Right>{children}</Right>
    </Root>
  );
};
