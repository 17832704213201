import { useContext } from 'use-context-selector';
import {
  AuthProviderContext,
  AuthProviderContextProps,
} from '../../contexts/AuthProvider.context';

type UseUserState = Omit<AuthProviderContextProps, 'refetch'>;

type UseUserReturnType = [UseUserState, () => void];

export const useUser = (): UseUserReturnType => {
  const { refetch, ...data } = useContext(AuthProviderContext);

  return [data, refetch];
};
