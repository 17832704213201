import { FC } from 'react';
import { useUser } from '@spintel/network/auth';
import { Dropdown } from '@spintel/uikit';
import { ChangePasswordModal } from '@spintel/shared';
import { useTranslation } from '@spintel/localization';
import { Root, UserMenuToggle } from './User.style';
import { useUserMenu } from './User.hooks';

export const User: FC = () => {
  const { t } = useTranslation();

  const [
    {
      user: { firstName, lastName, selectedTeamId, teams },
    },
  ] = useUser();

  const [{ updating, menu }] = useUserMenu();

  const subtitle = updating
    ? t('empty.updating')
    : teams.find(({ id }) => id === selectedTeamId)?.name;

  return (
    <Root>
      <ChangePasswordModal />
      <Dropdown placement="bottomRight">
        <Dropdown.Toggle>
          <UserMenuToggle
            subtitle={subtitle}
            title={t('forms.fullName', { firstName, lastName })}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu width={200} value={[`${selectedTeamId}`]} items={menu} />
      </Dropdown>
    </Root>
  );
};
