import { FC, useRef } from 'react';
import moment, { Moment } from 'moment';
import { invoke, noop } from 'lodash';
import { DATE_PICKER_COMMON_PROPS } from '../DatePicker/DatePicker.const';
import { DatePickerDateCell } from '../DatePicker/DatePickerDateCell/DatePickerDateCell';
import { Root, DatePicker } from './CalendarPicker.style';
import { DatePickerPicker } from './CalendarPicker.types';
import { VALUE_FORMATTER } from './CalendarPicker.utils';

interface DatePickerProps {
  value?: string;
  current?: string;
  allowClear?: boolean;
  range?: [string, string?];
  picker?: DatePickerPicker;
  onChange?: (value: string) => void;
}

export const CalendarPicker: FC<DatePickerProps> = ({
  value,
  range,
  current,
  allowClear,
  picker = 'date',
  onChange = noop,
}) => {
  const ref = useRef<HTMLDivElement>();

  const disabledDate = (current: Moment): boolean => {
    if (range) {
      const [min, max] = range;
      return !current.utc().isBetween(min, max, null, '[]');
    }
    return false;
  };

  const maxDate = (current: Moment) => {
    if (range) {
      const [_, max] = range;
      return current.isSame(max || moment(), 'date');
    }
    return false;
  };

  const DATE_PICKER_SELECTION_CLASS = 'ant-picker-selection-visible';
  const toggleDiffStyles = (date?: Moment) => {
    const nodeClasses = ref.current.classList;
    const selection = DATE_PICKER_SELECTION_CLASS;
    if (!date) {
      nodeClasses.add(selection);
      return;
    }

    const diff = Math.abs(date.diff(moment(value), 'months', true)) > 1;
    if (diff) {
      nodeClasses.remove(selection);
    } else {
      nodeClasses.add(selection);
    }
  };

  return (
    <Root ref={ref} className={DATE_PICKER_SELECTION_CLASS}>
      <DatePicker
        open
        picker={picker}
        showToday={false}
        allowClear={allowClear}
        disabledDate={disabledDate}
        value={value && moment(value)}
        getPopupContainer={() => ref.current}
        dateRender={(date) => (
          <DatePickerDateCell
            date={date}
            current={current}
            max={maxDate(date)}
          />
        )}
        onChange={(value) => {
          onChange(invoke(VALUE_FORMATTER, picker, value));
          toggleDiffStyles();
        }}
        onPanelChange={toggleDiffStyles}
        {...DATE_PICKER_COMMON_PROPS}
      />
    </Root>
  );
};
