import { createContext } from 'react';
import {
  TABLE_LOCALIZATION,
  FORM_VALIDATE_MESSAGES,
  FORM_ITEM_PLACEHOLDER,
  DROPDOWN_LOCALIZATION,
  TOOLTIP_LOCALIZATION,
  EMPTY_LOCALIZATION,
} from '../constants/localization.const';
import {
  FormItemPlaceholder,
  DropdownLocalization,
  TooltipLocalization,
  EmptyLocalization,
} from '../types/localization.types';

export interface FormValidationErrorParams {
  message: string;
  name: string;
  label?: string;
}

export type FormValidationErrorType = (
  params: FormValidationErrorParams
) => string;

export const defaultFormValidationError: UiKitContextProps['formValidationError'] =
  ({ message }) => message;

export interface UiKitContextProps {
  formItemPlaceholder: FormItemPlaceholder;
  formValidationError: FormValidationErrorType;
  formValidationMessages: typeof FORM_VALIDATE_MESSAGES;
  table: typeof TABLE_LOCALIZATION;
  dropdown: DropdownLocalization;
  tooltip: TooltipLocalization;
  empty: EmptyLocalization;
}

export const UiKitLocalizationContext = createContext<UiKitContextProps>({
  dropdown: DROPDOWN_LOCALIZATION,
  formValidationError: defaultFormValidationError,
  formValidationMessages: FORM_VALIDATE_MESSAGES,
  table: TABLE_LOCALIZATION,
  formItemPlaceholder: FORM_ITEM_PLACEHOLDER,
  tooltip: TOOLTIP_LOCALIZATION,
  empty: EMPTY_LOCALIZATION,
});
