import { useState, useEffect } from 'react';
import { noop, get } from 'lodash';
import { SegmentedValue } from '../Segmented.types';
import { SegmentedComponent } from '../Segmented.types';
import { Root, Option } from '../SegmentedSplit/SegmentedSplit.style';
import { Status } from '../../../styles/Status.style';
import { Tooltip } from '../../Tooltip/Tooltip';

export const SegmentedSplitMultiple: SegmentedComponent = ({
  toggle,
  value: initialValue,
  options,
  disabled,
  size = 'md',
  layout = 'horizontal',
  onChange = noop,
  className,
  darken = false,
  wrap = false,
  opacity = 0.4,
}) => {
  const defaultValue = get(options, ['0', 'value']);

  const [value, setValue] = useState<SegmentedValue[]>([
    initialValue || defaultValue,
  ]);

  const isToggleActive = value.length === options.length;

  const handleOnClick = (v: SegmentedValue) => {
    const index = value.findIndex((i) => i === v);
    const res = index > -1 ? value.filter((i) => i !== v) : [...value, v];
    setValue(res);
    onChange(res);
  };

  const handleOnToggle = () => {
    if (isToggleActive) {
      setValue([]);
      onChange([]);
    } else {
      const res = options.map(({ value }) => value);
      setValue(res);
      onChange(res);
    }
  };

  useEffect(() => {
    if (Array.isArray(initialValue)) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <Root className={className} layout={layout} wrap={wrap}>
      {!!toggle && (
        <Option
          size={size}
          darken={darken}
          disabled={disabled}
          active={isToggleActive}
          withStatus={false}
          variant={isToggleActive ? 'secondary' : 'tertiary'}
          onClick={handleOnToggle}
        >
          {toggle}
        </Option>
      )}
      {options.map(
        ({ value: v, label, status, disabled: disabledOption, hint }) => {
          const content = (
            <Option
              key={v}
              size={size}
              darken={darken}
              disabled={disabled || disabledOption}
              active={value.includes(v)}
              withStatus={!!status}
              variant={value.includes(v) ? 'secondary' : 'tertiary'}
              onClick={() => handleOnClick(v)}
            >
              {!!status && (
                <Status opacity={opacity} status={status} size={size} />
              )}
              {label}
            </Option>
          );
          return hint ? <Tooltip content={hint}>{content}</Tooltip> : content;
        }
      )}
    </Root>
  );
};
