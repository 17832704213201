import { PropsWithChildren, Key, ReactElement } from 'react';
import { useSet } from 'react-use';
import { SwitchContext } from './Switch.context';
import { SwitchCase } from './SwitchCase/SwitchCase';
import { SwitchDefault } from './SwitchDefault/SwitchDefault';

type SwitchProps = PropsWithChildren<{
  condition: Key;
}>;

export interface SwitchComponent {
  (props: SwitchProps): ReactElement<any, any> | null;
  Case: typeof SwitchCase;
  Default: typeof SwitchDefault;
}

export const Switch: SwitchComponent = ({ children, condition }) => {
  const [set, { add }] = useSet<Key>();

  return (
    <SwitchContext.Provider value={{ condition, add, set }}>
      {children}
    </SwitchContext.Provider>
  );
};

Switch.Case = SwitchCase;
Switch.Default = SwitchDefault;
