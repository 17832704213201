import styled, { keyframes, css } from 'styled-components';

export const animation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export const Animation = css`
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(
      90deg,
      rgba(34, 34, 34, 1) 25%,
      rgba(255, 255, 255, 0.05) 37%,
      rgba(34, 34, 34, 1) 63%
    );
    animation: ${animation} 1.4s ease infinite;
  }
`;

export const Fade = css`
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    background: linear-gradient(180deg, #1b1b1b 0%, rgba(27, 27, 27, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
`;

export const Root = styled('div')<{ cols: number }>`
  ${Animation}
  ${Fade}
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: ${({ theme }) => theme.palette.icarusGrey};
`;

export const Grid = styled('div')<{ cols: number }>`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: repeating-linear-gradient(
      to bottom,
      ${({ theme }) => theme.palette.transparent} 0 44px,
      ${({ theme }) => theme.palette.icarusGrey} 44px 45px
    ),
    repeating-linear-gradient(
      to right,
      ${({ theme }) => theme.palette.transparent} 0 calc(100% - 1px),
      ${({ theme }) => theme.palette.icarusGrey} calc(100% - 1px) 100%
    );
  background-size: calc(100% / ${({ cols }) => cols})
    calc(${({ theme: { spacing } }) => spacing(11)} + 1px);
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    bottom: 0;
    z-index: 4;
    background: ${({ theme }) => theme.palette.anthraciteGrey};
  }
`;

export const Cells = styled('div')<{ cols: number }>`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: repeating-linear-gradient(
      to bottom,
      ${({ theme }) => theme.palette.anthraciteGrey} 0 12px,
      ${({ theme }) => theme.palette.transparent} 12px 32px,
      ${({ theme }) => theme.palette.anthraciteGrey} 32px 44px,
      ${({ theme }) => theme.palette.anthraciteGrey} 44px 45px
    ),
    repeating-linear-gradient(
      to right,
      ${({ theme }) => theme.palette.anthraciteGrey} 0 12px,
      ${({ theme }) => theme.palette.transparent} 12px calc(100% - 12px),
      ${({ theme }) => theme.palette.anthraciteGrey} calc(100% - 12px) 100%
    );
  background-size: calc(100% / ${({ cols }) => cols})
    calc(${({ theme: { spacing } }) => spacing(11)} + 1px);
`;
