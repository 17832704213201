import styled, { css } from 'styled-components';
import AntdCard from 'antd/lib/card';

const DEFAULT_GUTTER = [2, 6, 6, 6];

const RootWithBarTabsMixin = css<{ barTabCount: number }>`
  .ant-card-head {
    padding: 0;
    .ant-tabs {
      .ant-tabs-nav {
        .ant-tabs-ink-bar {
          z-index: 3;
        }
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            width: 100%;
            .ant-tabs-tab {
              padding: 0;
              max-width: calc(${({ barTabCount }) => 100 / barTabCount}% - 1px);
              min-width: calc(${({ barTabCount }) => 100 / barTabCount}% - 1px);
              width: calc(${({ barTabCount }) => 100 / barTabCount}% - 1px);
              .ant-tabs-tab-btn {
                width: 100%;
                opacity: 1;
                overflow: hidden;
              }
              + .ant-tabs-tab {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
`;

const RootStretchMixin = css`
  flex: 1 1 auto;
  height: 100%;
`;

export const Root = styled(AntdCard).withConfig({
  shouldForwardProp: (prop) =>
    !['withBarTabs', 'barTabCount', 'stretch'].includes(prop),
})<{
  barTabCount: number;
  gutter?: number[];
  stretch?: boolean;
  progress: boolean;
}>`
  &.ant-card {
    display: flex;
    padding-top: ${({ gutter: [top] = DEFAULT_GUTTER, theme: { spacing } }) =>
      spacing(top)};
    padding-right: ${({
      gutter: [, right] = DEFAULT_GUTTER,
      theme: { spacing },
    }) => spacing(right)};
    padding-bottom: ${({
      gutter: [, , bottom] = DEFAULT_GUTTER,
      theme: { spacing },
    }) => spacing(bottom)};
    padding-left: ${({
      gutter: [, , , left] = DEFAULT_GUTTER,
      theme: { spacing },
    }) => spacing(left)};
    flex-direction: column;
    .ant-card-head {
      border: none;
      margin-bottom: ${({ theme: { spacing } }) => spacing(6)};
      flex: 0 0 auto;
      min-height: ${({ progress, theme: { spacing } }) =>
        progress && spacing(12)};
      .ant-tabs {
        .ant-tabs-nav {
          margin: 0;
          &::before {
            display: ${({ progress }) => progress && 'none'};
            border-bottom: 1px solid ${({ theme }) => theme.palette.wolfGrey};
            z-index: 1;
          }
        }
      }
    }
    .ant-card-actions {
      border: none;
      padding: 0;
      flex: 0 0 auto;
    }
    .ant-card-body {
      padding: 0;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }
    ${({ stretch }) => stretch && RootStretchMixin}
  }
  ${RootWithBarTabsMixin}
`;
