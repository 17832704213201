import {
  TableGlobalColumnsExtendedType,
  TableColumnSelection,
} from '../../components/Table/Table.types';

interface FilterColumnsBySelectionParams<T extends object> {
  columns: TableGlobalColumnsExtendedType<T>;
  selection: TableColumnSelection['0'];
}

export const filterColumnsBySelection = <T extends object>({
  columns,
  selection,
}: FilterColumnsBySelectionParams<T>): TableGlobalColumnsExtendedType<T> => {
  return columns.reduce((res, props) => {
    switch (props.type) {
      case 'group':
        return (() => {
          const children = filterColumnsBySelection({
            selection,
            columns: props.children,
          });
          return children.length ? [...res, { ...props, children }] : res;
        })();
      case 'column':
        return (() => {
          if (props.select) {
            const show = selection.includes(props.id);
            return show ? [...res, props] : res;
          }
          return [...res, props];
        })();
      default:
        return res;
    }
  }, [] as TableGlobalColumnsExtendedType<T>);
};
