import { FC, PropsWithChildren, useId, useContext, useState } from 'react';
import { castArray } from 'lodash';
import AntdTooltip, { TooltipPlacement } from 'antd/lib/tooltip';
import { Modal } from '../Modal/Modal';
import { useModal } from '../../hooks/useModal/useModal.hook';
import { UiKitLocalizationContext } from '../../contexts/UiKitLocalization.context';
import { PREVIEW_TEXT_LENGTH } from './Tooltip.const';
import { More } from './Tooltip.style';
import { getPreview } from './Tooltip.utils';
import { Text } from './Tooltip.style';

export type TooltipProps = PropsWithChildren<{
  title?: string;
  content: string | string[];
  placement?: TooltipPlacement;
  truncated?: boolean;
}>;

export const Tooltip: FC<TooltipProps> = ({
  title,
  children,
  placement,
  content = '',
  truncated = true,
}) => {
  const id = useId();
  const [visible, setVisible] = useState<boolean>();

  const [, toggle] = useModal(id);

  const {
    tooltip: { more },
  } = useContext(UiKitLocalizationContext);

  const paragraphs = castArray(content).filter((v) => !!v);

  const preview = getPreview({ v: paragraphs, truncated });

  const showMore =
    truncated &&
    paragraphs.reduce((res, v) => res + v, '').length > PREVIEW_TEXT_LENGTH;

  const handleOnMoreClick = () => {
    toggle(true);
    setVisible(false);
  };

  const action = showMore && (
    <More variant="link" size="xs" onClick={handleOnMoreClick}>
      {more}
    </More>
  );

  const overlay = (
    <>
      {preview}
      {action}
    </>
  );

  return (
    <>
      {paragraphs.length ? (
        <AntdTooltip
          visible={visible}
          overlay={overlay}
          destroyTooltipOnHide
          placement={placement}
          onVisibleChange={setVisible}
        >
          {children}
        </AntdTooltip>
      ) : (
        children
      )}
      {showMore && (
        <Modal name={id} title={title}>
          <Modal.Content>
            {paragraphs.map((str, index) => (
              <Text key={`${str}-${index}`} color="white">
                {str}
              </Text>
            ))}
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
