import { SegmentedComponent } from './Segmented.types';
import { SegmentedDefault } from './SegmentedDefault/SegmentedDefault';
import { SegmentedSplit } from './SegmentedSplit/SegmentedSplit';
import { SegmentedSplitMultiple } from './SegmentedSplitMultiple/SegmentedSplitMultiple';

export const Segmented: SegmentedComponent = ({
  variant = 'default',
  multiple = false,
  ...props
}) => {
  if (variant === 'split' && !multiple) {
    return <SegmentedSplit {...props} />;
  }

  if (variant === 'split' && multiple) {
    return <SegmentedSplitMultiple {...props} />;
  }

  return <SegmentedDefault {...props} />;
};
