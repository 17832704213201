import { createContext } from 'react';
import { IStationListItem, IStationDetails } from './StationDetails.types';

interface StationDetailsContextParams {
  data: IStationDetails;
  loading: boolean;
  stations: IStationListItem[];
  switchable: boolean;
}
export const StationDetailsContext = createContext<StationDetailsContextParams>(
  {
    data: null,
    loading: true,
    stations: null,
    switchable: false,
  }
);
