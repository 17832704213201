import { useRef, useCallback } from 'react';
import { useCustomCompareEffect } from 'react-use';
import { get } from 'lodash';
import { useTranslation } from '@spintel/localization';
import { DTO } from '@spintel/network/app';
import { Notification } from '@spintel/uikit';

export interface UseShowAddNotificationParams {
  songs: DTO.ISongComparisonModel[];
}

export const useAddSongsNotification = ({
  songs,
}: UseShowAddNotificationParams) => {
  const { t } = useTranslation();
  const newSongsIds = useRef<number[]>(null);

  const notify = useCallback(
    (addedSongs: DTO.ISongComparisonModel[]) => {
      if (addedSongs?.length) {
        Notification.show({
          message: t('notifications.songComparisonsSuccessfullyAdd', {
            postProcess: 'interval',
            count: addedSongs.length,
            songName: get(addedSongs[0], 'name'),
            artistName: get(addedSongs[0], 'artistModels[0].name'),
          }),
        });
      }
    },
    [t]
  );

  useCustomCompareEffect(
    () => {
      if (newSongsIds.current) {
        notify(songs.filter((s) => newSongsIds.current.includes(s.songId)));
        newSongsIds.current = null;
      }
    },
    [songs],
    (prevDeps, nextDeps) => {
      // only trigger when the number of songs increases
      return prevDeps[0].length < nextDeps.length;
    }
  );

  const pushAddSongsNotification = (songIds: number[]) => {
    newSongsIds.current = songIds;
  };

  return [pushAddSongsNotification];
};
