import { forwardRef, PropsWithChildren } from 'react';
import cn from 'classnames';
import { noop } from 'lodash';
import { UiKitColor } from '../../types/uikit';
import { IconName } from './__generated__/icon.types';
import { IconSize } from './Icon.types';
import { Root } from './Icon.style';
import './__generated__/icon.less';

export type IconProps = PropsWithChildren<{
  size?: IconSize;
  className?: string;
  name: IconName;
  onClick?: () => void;
  force?: boolean;
  color?: UiKitColor;
}>;

export const Icon = forwardRef<HTMLSpanElement, IconProps>(function Icon(
  { name, size = 'md', className, onClick = noop, force = false, color },
  ref
) {
  return (
    <Root
      ref={ref}
      force={force}
      className={cn(className, `icon-${name}`)}
      size={size}
      onClick={onClick}
      color={color}
    />
  );
});
