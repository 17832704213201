import { useContext } from 'react';
import { UseDownload, UseDownloadOptions } from '@shared/types';
import { useCommonDownload } from '@shared/hooks';
import { IErrorResponse } from '../../__generated__/data-contracts';
import {
  HttpProviderContext,
  HttpProviderContextProps,
} from '../../contexts/HttpProvider.context';

type CallbackReturn = (...args: any) => any;

export function useDownload<
  Req extends any[] = never[],
  Err extends object = IErrorResponse
>(
  callback: (context: HttpProviderContextProps) => CallbackReturn,
  options: UseDownloadOptions
): UseDownload<Req, Blob, Err> {
  const services = useContext(HttpProviderContext);

  return useCommonDownload<HttpProviderContextProps, Req, Err>(
    services,
    callback,
    options
  );
}
