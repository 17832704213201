import { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import { DTO } from '@spintel/network/app';
import { ShellContext, ShellContextProps } from '../../contexts/Shell.context';

interface UseSongState {
  loading: boolean;
  song: DTO.IGetSongSelectorResponse;
}

type UseSongDispatch = (id: number) => Promise<number>;

type UseSongReturnType = [UseSongState, UseSongDispatch];

export const useSong = (): UseSongReturnType => {
  const song = useContextSelector<ShellContextProps, ShellContextProps['song']>(
    ShellContext,
    ({ song }) => song
  );

  const loading = useContextSelector<
    ShellContextProps,
    ShellContextProps['progress']
  >(ShellContext, ({ progress }) => progress);

  const setSong = useContextSelector<
    ShellContextProps,
    ShellContextProps['setSong']
  >(ShellContext, ({ setSong }) => setSong);

  return useMemo(() => [{ song, loading }, setSong], [song, loading, setSong]);
};
