import { UseQuery } from '@shared/types';
import { useQuery } from '../useQuery/useQuery.hook';
import {
  IValidateTokenDto,
  IErrorResponse,
} from '../../__generated__/data-contracts';
import { RequestParams } from '../../__generated__/http-client';

export const useValidateResetToken = ({
  token,
}: IValidateTokenDto): UseQuery<IValidateTokenDto, IErrorResponse> => {
  return useQuery<[IValidateTokenDto, RequestParams]>(
    ({ Auth }) => Auth.validateResetTokenCreate,
    [
      { token },
      {
        headers: {
          Intercept: false,
        },
      },
    ]
  );
};
