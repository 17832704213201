import { FC } from 'react';
import { Translate } from '@spintel/localization';
import { DTO } from '@spintel/network/app';
import { Root } from './SongSelectorLabel.style';

export const SongSelectorLabel: FC<{
  song: DTO.IGetSongSelectorResponse;
}> = ({ song }) => {
  const { rank, ...rest } = song;
  return (
    <Root>
      <Translate
        i18nKey={'options.rankSongNameArtistName'}
        values={{ ...rest, rank: rank || `\u2013` }}
      />
    </Root>
  );
};
