import {
  useState,
  useCallback,
  ReactElement,
  ReactNode,
  PropsWithChildren,
  forwardRef,
  ForwardRefExoticComponent,
  useImperativeHandle,
  RefAttributes,
} from 'react';
import { DropdownProps as AntdDropdownProps } from 'antd/lib/dropdown';
import { DropdownContext } from '../../contexts/Dropdown.context';
import { DropdownToggle } from './DropdownToggle/DropdownToggle';
import { DropdownMenu } from './DropdownMenu/DropdownMenu';
import { DropdownOverlay } from './DropdownOverlay/DropdownOverlay';
import { DropdownConfirm } from './DropdownConfirm/DropdownConfirm';
import { DropdownState } from './Dropdown.types';
import { Root } from './Dropdown.style';
import { DropdownInstance, DropdownAppendTo } from './Dropdown.types';

export type DropdownProps = PropsWithChildren<{
  disabled?: boolean;
  appendTo?: DropdownAppendTo;
  onChange?: (v: boolean) => void;
  placement?: AntdDropdownProps['placement'];
  overlay?: {
    width?: number;
    height?: number;
    position?: 'fixed' | 'absolute';
  };
}>;

export interface DropdownComponent
  extends ForwardRefExoticComponent<
    DropdownProps & RefAttributes<DropdownInstance>
  > {
  Toggle?: typeof DropdownToggle;
  Menu?: typeof DropdownMenu;
  Overlay?: typeof DropdownOverlay;
  Confirm?: typeof DropdownConfirm;
}

export const Dropdown: DropdownComponent = forwardRef<
  DropdownInstance,
  DropdownProps
>(function Dropdown(
  { overlay, disabled, children, placement, appendTo = 'body' },
  ref
) {
  const [isOpen, toggle] = useState(false);

  const [{ overlayNode, toggleNode }, setElement] = useState<DropdownState>({
    overlayNode: null,
    toggleNode: null,
  });

  const set = useCallback(
    (key: keyof DropdownState, el: ReactElement | ReactNode) => {
      setElement((els) => ({ ...els, [key]: el }));
    },
    []
  );

  const getPopupContainer: AntdDropdownProps['getPopupContainer'] = useCallback(
    (triggerNode: HTMLElement) => {
      if (appendTo === 'body') {
        return document.body;
      }
      if (appendTo === 'parent') {
        return triggerNode.parentElement;
      }
      if (typeof appendTo === 'function') {
        return appendTo(triggerNode);
      }
    },
    [appendTo]
  );

  const ready = toggleNode && overlayNode;

  useImperativeHandle(ref, () => ({
    toggle: (v) => toggle(v),
  }));

  return (
    <DropdownContext.Provider value={{ set, toggle, isOpen }}>
      {children}
      {ready && (
        <Root
          getPopupContainer={getPopupContainer}
          visible={isOpen}
          destroyPopupOnHide
          disabled={disabled}
          trigger={['click']}
          overlay={overlayNode}
          placement={placement}
          onVisibleChange={toggle}
          overlayStyle={{
            width: overlay?.width,
            maxWidth: overlay?.width,
            minWidth: overlay?.width,
            maxHeight: overlay?.height,
            position: overlay?.position,
          }}
        >
          {toggleNode}
        </Root>
      )}
    </DropdownContext.Provider>
  );
});

Dropdown.Toggle = DropdownToggle;
Dropdown.Menu = DropdownMenu;
Dropdown.Overlay = DropdownOverlay;
Dropdown.Confirm = DropdownConfirm;
