import { DefaultTheme } from 'styled-components';
import { isNumber, mapValues } from 'lodash';
import { Gutter } from 'antd/lib/grid/row';

export function adjustGutterWithTheme(gutter: Gutter, theme: DefaultTheme) {
  if (isNumber(gutter)) {
    return parseFloat(theme.spacing(gutter)) as Gutter;
  }

  if (gutter && !isNumber(gutter)) {
    return mapValues(gutter, (g) => parseFloat(theme.spacing(g)));
  }
}

export function calcGutter(
  gutter: Gutter | [Gutter, Gutter],
  theme: DefaultTheme
) {
  return Array.isArray(gutter)
    ? ([
        adjustGutterWithTheme(gutter[0], theme),
        adjustGutterWithTheme(gutter[1], theme),
      ] as [Gutter, Gutter])
    : adjustGutterWithTheme(gutter, theme);
}
