import { FC, memo, useLayoutEffect, useRef } from 'react';
import { Moment } from 'moment';
import { Root, Text } from './DatePickerDateCell.style';

interface DatePickerDateCellProps {
  date: Moment;
  current?: string;
  max?: boolean;
}

export const DatePickerDateCell: FC<DatePickerDateCellProps> = memo(
  ({ date, current, max }) => {
    const cell = useRef<HTMLDivElement>();
    const active = current ? date.isSame(current, 'date') : false;

    useLayoutEffect(() => {
      if (!max) return;
      const node = cell?.current?.parentElement;
      const cls = 'ant-picker-cell-max';
      if (node) {
        node.classList.add(cls);
      }
      return () => {
        if (node && max) {
          node.classList.remove(cls);
        }
      };
    });

    return (
      <Root bold={true} active={active} ref={cell}>
        <Text active={active}>{date.format('D')}</Text>
      </Root>
    );
  }
);
