import { FC } from 'react';
import { Redirect, useRoute } from '@spintel/navigation';
import { Form, Splash, Notification, Prompt } from '@spintel/uikit';
import { useTranslation } from '@spintel/localization';
import {
  DTO,
  useResetPassword,
  useValidateResetToken,
} from '@spintel/network/auth';
import { AuthRoutePath, SystemRoutePath } from '@common/constants';
import { AuthPage } from '../../partitions/AuthPage/AuthPage';

export const ResetPassword: FC = () => {
  const { t } = useTranslation();
  const [{ params }, { push }] = useRoute<{ token: string }>();
  const [{ error, loading }, resetPassword] = useResetPassword();
  const [{ error: validationError, loading: validating }] =
    useValidateResetToken({
      token: params?.token,
    });

  if (validating) {
    return <Splash />;
  }

  if (validationError) {
    return (
      <Redirect
        to={{
          path: SystemRoutePath.NOT_FOUND,
        }}
      />
    );
  }

  const handleSubmit = (
    form: Omit<DTO.IResetPasswordDto, 'resetPasswordToken'>
  ) => {
    resetPassword({ ...form, resetPasswordToken: params.token }, () => {
      Notification.show({
        message: t('notifications.passwordSuccessfullyUpdated'),
      });
      push({ to: AuthRoutePath.LOGIN });
    });
  };

  return (
    <AuthPage errorCode={error?.errorCode}>
      <Prompt title={t('forms.resetYourPassword')}>
        <Form initialValues={{}} onSubmit={handleSubmit}>
          <Prompt.Content>
            <Form.Password
              autofocus
              label={t('forms.newPassword')}
              name="newPassword"
              validation={{
                password: true,
                required: true,
              }}
              errors={{
                password: t('validation.passwordPattern'),
              }}
              help={t('help.password')}
            />
            <Form.Password<{ newPassword: string }>
              label={t('forms.repeatNewPassword')}
              name="passwordConfirmation"
              validation={{
                equal: ({ newPassword }) => newPassword,
                required: true,
              }}
              errors={{
                equal: t('validation.passwordsResetMatch'),
              }}
            />
          </Prompt.Content>
          <Prompt.Actions justify="flex-end">
            <Form.Submit size="lg" loading={loading} variant="primary">
              {t('actions.reset')}
            </Form.Submit>
          </Prompt.Actions>
        </Form>
      </Prompt>
    </AuthPage>
  );
};
