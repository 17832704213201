import { FC } from 'react';
import { merge } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import { Search } from '../../Search/Search';
import { TableColumnDataIndex } from '../Table.types';
import { EmptyProps } from '../../Empty/Empty';
import {
  TableLocalContext,
  TableLocalContextProps,
} from '../../../contexts/Table.context';

interface TableSearchProps {
  placeholder?: string;
  empty?: EmptyProps;
  dataIndex: TableColumnDataIndex[];
}

export const TableSearch: FC<TableSearchProps> = ({
  empty,
  dataIndex,
  placeholder,
}) => {
  const setSearchState = useContextSelector<
    TableLocalContextProps,
    TableLocalContextProps['setSearchState']
  >(TableLocalContext, ({ setSearchState }) => setSearchState);

  const handleOnChange = (q: string) => {
    setSearchState(({ empty: prev }) => ({
      q,
      dataIndex,
      empty: merge({}, prev, empty),
    }));
  };

  return <Search placeholder={placeholder} onChange={handleOnChange} />;
};
