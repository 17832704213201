import { FC } from 'react';
import { FormListHeaderButton } from './FormListHeader.style';

interface FormListHeaderProps {
  label: string;
  onAdd: () => void;
  anchor?: string;
  max: number;
  total: number;
}

export const FormListHeader: FC<FormListHeaderProps> = ({
  label,
  anchor,
  onAdd,
  total,
  max,
}) => {
  return (
    <>
      {label}
      {anchor && total < max && (
        <FormListHeaderButton variant="link" onClick={() => onAdd()}>
          {anchor}
        </FormListHeaderButton>
      )}
    </>
  );
};
