import { lazy } from 'react';
import { RoutePropsWithChildren } from '@spintel/navigation';
import { RoutePath } from '@common/constants';

const Stations = lazy(() =>
  import('./Stations/Stations').then((m) => ({ default: m.Stations }))
);

const Station = lazy(() =>
  import('./Station/Station').then((m) => ({ default: m.Station }))
);

const StationMySongs = lazy(() =>
  import('./Station/StationMySongs/StationMySongs').then((m) => ({
    default: m.StationMySongs,
  }))
);

const StationOverview = lazy(() =>
  import('./Station/StationOverview/StationOverview').then((m) => ({
    default: m.StationOverview,
  }))
);

const StationPossibleSpace = lazy(() =>
  import('./Station/StationPossibleSpace/StationPossibleSpace').then((m) => ({
    default: m.StationPossibleSpace,
  }))
);

const StationMusicStack = lazy(() =>
  import('./Station/StationMusicStack/StationMusicStack').then((m) => ({
    default: m.StationMusicStack,
  }))
);

const StationPlaylist = lazy(() =>
  import('./Station/StationPlaylist/StationPlaylist').then((m) => ({
    default: m.StationPlaylist,
  }))
);

const StationDetails = lazy(() =>
  import('./Station/StationDetails/StationDetails').then((m) => ({
    default: m.StationDetails,
  }))
);

export const ROUTES: RoutePropsWithChildren[] = [
  {
    element: <Stations />,
    path: RoutePath.STATIONS,
  },
  {
    element: <Station />,
    path: RoutePath.STATION,
    childRoutes: [
      {
        element: <StationMySongs />,
        index: true,
      },
      {
        element: <StationOverview />,
        path: RoutePath.STATION_OVERVIEW,
      },
      {
        element: <StationMusicStack />,
        path: RoutePath.STATION_MUSIC_STACK,
      },
      {
        element: <StationPlaylist />,
        path: RoutePath.STATION_PLAYLIST,
      },
      {
        element: <StationPossibleSpace />,
        path: RoutePath.STATION_POSSIBLE_SPACE,
      },
      {
        element: <StationDetails />,
        path: RoutePath.STATION_DETAILS,
      },
    ],
  },
];
