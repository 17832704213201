import { THEME } from '../../constants/theme.const';

const {
  palette: { white, pearlGrey },
} = THEME;

export function getContrastYIQ(hexcolor: string) {
  const str = hexcolor.replace('#', '');
  const r = parseInt(str.substring(0, 2), 16);
  const g = parseInt(str.substring(2, 4), 16);
  const b = parseInt(str.substring(4), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? pearlGrey : white;
}
