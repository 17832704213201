import {
  ReactElement,
  useState,
  useMemo,
  useRef,
  PropsWithChildren,
  useContext,
} from 'react';
import { omit, noop, flow, castArray, get, isObject } from 'lodash';
import AntdTable, {
  ColumnsType as AntdColumnsType,
  TableProps as AntdTableProps,
} from 'antd/lib/table';
import AntdConfigProvider from 'antd/lib/config-provider';
import { Skeleton } from '../Skeleton/Skeleton';
import { TableLocalContext } from '../../contexts/Table.context';
import { UiKitProviderContext } from '../../contexts/UiKitProvider.context';
import { TableColumnsSelect } from './TableColumnsSelect/TableColumnsSelect';
import { TableFiltersHeader } from './TableFiltersHeader/TableFiltersHeader';
import { TableSearch } from './TableSearch/TableSearch';
import { TableSummary } from './TableSummary/TableSummary';
import { TableEmpty } from './TableEmpty/TableEmpty';
import { Root, TableWrapper, Table as StyledTable } from './Table.style';
import {
  getColumnsConfig,
  getPaginationConfig,
  getVisibleColumns,
  getTotalFixedWidth,
  getRowClassName,
  getExpanderColumns,
  getActionsColumn,
  getMinimalColumnSizes,
} from './Table.utils';
import { TableProps, TableSorting, TableRowType } from './Table.types';
import {
  useTableLayout,
  useTableVirtualize,
  useTableCheckbox,
  useTableSearch,
} from './Table.hooks';
import { DEFAULT_GUTTER } from './Table.const';

const { Summary } = AntdTable;

export interface TableComponent {
  <T extends object, S = TableSorting>(
    props: PropsWithChildren<TableProps<T, S>>
  ): ReactElement<any, any> | null;
  ColumnsSelect: typeof TableColumnsSelect;
  FiltersHeader: typeof TableFiltersHeader;
  Search: typeof TableSearch;
}

export const Table: TableComponent = ({
  row,
  layout,
  virtual,
  actions,
  children,
  stretchTo,
  columns = [],
  defaultSorting,
  expander = true,
  loading = false,
  onChange = noop,
  data: initialData,
  scroll: initialScroll,
  sorting: initialSorting,
  empty: initialEmptyData,
  filters: initialFilters = {},
  pagination: initialPagination,
  gutter: initialGutter = DEFAULT_GUTTER,
  ...props
}) => {
  const tableRef = useRef<HTMLDivElement>();
  const filtersRef = useRef<HTMLDivElement>();
  const {
    table: {
      cell: { formatter },
    },
  } = useContext(UiKitProviderContext);

  const [{ sorting, filters }, setState] = useState({
    ...initialPagination,
    sorting: castArray(initialSorting),
    filters: initialFilters,
  });

  const totalFixedLeftWidth = useMemo(
    () => getTotalFixedWidth(columns, 'left'),
    [columns]
  );

  const tableCols = useMemo(() => {
    if (!initialData?.length) return [{}];
    return flow([
      getVisibleColumns,
      getMinimalColumnSizes,
      (columns) => getExpanderColumns({ columns, expander }),
      (columns) => getActionsColumn({ columns, actions }),
      (columns) =>
        getColumnsConfig({
          columns,
          sorting,
          filters,
          data: initialData,
          totalFixedLeftWidth,
          formatter: formatter,
        }),
    ])(columns);
  }, [
    formatter,
    columns,
    actions,
    expander,
    sorting,
    initialData,
    filters,
    totalFixedLeftWidth,
  ]);

  const tablePagination = getPaginationConfig(initialPagination);

  const [{ data }, setSearchState] = useTableSearch({ data: initialData });

  const {
    scroll,
    gutter,
    background,
    showSummary,
    empty: emptyData,
    scrollInner,
  } = useTableLayout({
    data,
    filters,
    columns,
    loading,
    tableRef,
    stretchTo,
    filtersRef,
    scroll: initialScroll,
    gutter: initialGutter,
    empty: initialEmptyData,
  });

  const [{ rowSelection }] = useTableCheckbox({
    data: initialData,
    rowKey: props.rowKey,
  });

  const handleOnChange: AntdTableProps<object>['onChange'] = (
    { current, pageSize },
    _filters,
    _sort,
    { action }
  ) => {
    if (action === 'paginate') {
      setState((prev) => ({
        ...prev,
        pageSize,
        pageNumber: current,
      }));
      onChange({
        pageSize,
        pageNumber: current,
      });
    }
    if (action === 'filter') {
      const filters = Object.entries(_filters).reduce((res, [key, value]) => {
        return value ? { ...res, [key]: value } : res;
      }, {});
      setState((prev) => ({
        ...prev,
        filters,
      }));
      onChange({ filters });
    }
    if (action === 'sort') {
      const calculatedSort = castArray(_sort)
        .map(({ order, field, columnKey }) => {
          return order
            ? ({
                column: field || columnKey,
                order: order,
              } as any)
            : defaultSorting;
        })
        .filter((item) => get(item, 'column') && get(item, 'order'));
      const withDefaultSort =
        !calculatedSort.length && defaultSorting
          ? [defaultSorting]
          : calculatedSort;
      const finalSorting =
        withDefaultSort.length > 1 ? withDefaultSort : withDefaultSort[0];
      setState((prev) => ({
        ...prev,
        sorting: finalSorting,
      }));
      onChange({ sort: finalSorting });
    }
  };

  const handleOnRemoveFilter = (key: string) => {
    const res = omit(filters, key);
    setState((prev) => ({
      ...prev,
      filters: res,
    }));
    onChange({ filters: res });
  };

  const handleOnClearAll = () => {
    setState((prev) => ({
      ...prev,
      filters: {},
    }));
    onChange({ filters: undefined });
  };

  const virtualize = useTableVirtualize({ virtual, scroll });
  const yScrollbar = isObject(stretchTo && stretchTo()) || !!initialScroll?.y;
  return (
    <TableLocalContext.Provider value={{ columns, setSearchState }}>
      {children}
      <AntdConfigProvider
        renderEmpty={() => (
          <TableEmpty height={scrollInner?.y} {...emptyData} />
        )}
      >
        <Root background={background}>
          <TableFiltersHeader
            ref={filtersRef}
            filters={filters}
            columns={tableCols}
            onRemoveFilter={handleOnRemoveFilter}
            onClearAll={handleOnClearAll}
          />

          <TableWrapper gutter={gutter} background={background}>
            {/* @ts-ignore */}
            <StyledTable
              ref={tableRef}
              loading={{
                spinning: loading,
                indicator: (
                  <>
                    <Skeleton variant="grid" />
                  </>
                ),
              }}
              // @ts-ignore
              background={background}
              showHeader={!!data?.length}
              columns={tableCols as AntdColumnsType<object>}
              dataSource={data}
              showSorterTooltip={false}
              pagination={tablePagination}
              tableLayout={layout}
              onChange={handleOnChange}
              scroll={scroll}
              // @ts-ignore
              rowSelection={rowSelection}
              yScrollbar={yScrollbar}
              rowClassName={getRowClassName(row as TableRowType<object>)}
              summary={
                showSummary
                  ? (data) => (
                      <Summary fixed={!!scroll?.y}>
                        <TableSummary data={data} columns={tableCols} />
                      </Summary>
                    )
                  : null
              }
              {...props}
              {...virtualize}
            />
          </TableWrapper>
        </Root>
      </AntdConfigProvider>
    </TableLocalContext.Provider>
  );
};

Table.ColumnsSelect = TableColumnsSelect;
Table.FiltersHeader = TableFiltersHeader;
Table.Search = TableSearch;
