import styled from 'styled-components';
import { Icon } from '../../Icon/Icon';
import { Label } from '../../Select/Select.style';

export const SelectArrow = styled(Icon)(({ theme }) => ({
  color: theme.palette.white,
}));

export const Root = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Tag = styled('div')<{ suffix: string }>`
  ${Label} {
    &::after {
      content: ${({ suffix }) => `'${suffix}'`};
      margin-right: ${({ theme: { spacing } }) => spacing(1)};
    }
  }
`;
