import { FC, PropsWithChildren, CSSProperties } from 'react';
import { Root } from './ModalFooter.style';

type ModalFooterProps = PropsWithChildren<{
  justify?: CSSProperties['justifyContent'];
}>;

export const ModalFooter: FC<ModalFooterProps> = ({
  children = null,
  justify = 'flex-end',
}) => {
  return <Root justify={justify}>{children}</Root>;
};
