import {
  FC,
  PropsWithChildren,
  useRef,
  useContext,
  useLayoutEffect,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { get, uniqueId, castArray, flatten, noop } from 'lodash';
import { useLifecycles } from 'react-use';
import { TableColumnsSelectDropdownContext } from '../../TableColumnsSelectDropdown/TableColumnsSelectDropdown.context';
import { useFormInstance, useFormWatch } from '../../../../Form/Form.hooks';
import { TableColumnsSelectForm } from '../../TableColumnsSelect.types';
import { COLUMN_SELECT_ALL_DATA_INDEX } from '../../../Table.const';
import { INDETERMINATE_CLASS_NAME } from './TableColumnsSelectFormLabel.const';

type TableColumnsSelectFormLabelProps = PropsWithChildren<{
  parent: string;
  group?: string;
  index: number;
}>;

export const TableColumnsSelectFormLabel: FC<
  TableColumnsSelectFormLabelProps
> = ({ children, parent = '', index, group: initialGroup }) => {
  const ref = useRef<HTMLSpanElement>();
  const customGroup = useRef(uniqueId('group'));
  const group = initialGroup || customGroup.current;
  const form = useFormInstance<TableColumnsSelectForm>();
  const selection = useFormWatch('selection', form);

  const { groups, setGroup, resetGroup } = useContext(
    TableColumnsSelectDropdownContext
  );

  useLifecycles(
    () => setGroup(parent, group, `${group}${index}`),
    () => resetGroup(parent)
  );

  useLayoutEffect(() => {
    if (ref.current) {
      const menu = ref.current.parentElement.parentElement.parentElement;
      const item = ref.current.parentElement.parentElement;
      if (menu.tagName === 'UL' && item.tagName === 'LI') {
        const all = get(groups, parent, {});
        const max = Object.entries(all).reduce((res, [, value]) => {
          return value.length > res ? value.length : res;
        }, 0);
        const matrix = Object.entries(all).map(([, val]) => {
          if (val.length < max) {
            const last = val[val.length - 1];
            const rest = new Array(max - val.length).fill(last);
            return [...val, ...rest];
          }
          return val;
        });
        menu.style.display = 'grid';
        menu.style.gridAutoRows = 'min-content';
        menu.style.gridTemplateAreas = `"${matrix
          .map((v = []) => castArray(v).join(' '))
          .join(`" "`)}"`;
        item.style.gridArea = `${group}${index}`;
        item.dataset.group = group;
      }
    }
  }, [parent, groups, group, index]);

  useLayoutEffect(() => {
    const observer = new MutationObserver(() => {
      const indeterminate = ref.current.classList.contains(
        INDETERMINATE_CLASS_NAME
      );
      const el = ref.current.parentElement.parentElement.firstElementChild;
      if (indeterminate) {
        el.classList.add('ant-cascader-checkbox-indeterminate');
      } else {
        el.classList.remove('ant-cascader-checkbox-indeterminate');
      }
    });
    if (ref.current && group === COLUMN_SELECT_ALL_DATA_INDEX) {
      observer.observe(ref.current, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      return () => observer.disconnect();
    }
    return noop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const indeterminate = useMemo(() => {
    const flat = flatten(selection);
    return !flat.includes(COLUMN_SELECT_ALL_DATA_INDEX) && !!flat.length;
  }, [selection]);
  // ant-cascader-checkbox-indeterminate

  return (
    <span
      ref={ref}
      className={classNames({
        [INDETERMINATE_CLASS_NAME]: indeterminate,
      })}
    >
      {children}
    </span>
  );
};
