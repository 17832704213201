import { ComponentType } from 'react';
import { Typography } from '../Typography/Typography';
import { UserpicSize } from './Userpic.types';

export const TITLE_COMPONENTS_MAP: Record<UserpicSize, ComponentType> = {
  lg: Typography.Subtitle1,
  sm: Typography.Body3,
  md: Typography.Subtitle2,
};

export const SUBTITLE_COMPONENTS_MAP: Record<UserpicSize, ComponentType> = {
  lg: Typography.Body2,
  sm: Typography.Body4,
  md: Typography.Body4,
};
