import { FC, PropsWithChildren } from 'react';
import { useContextSelector } from 'use-context-selector';
import { SwitchContext, SwitchContextProps } from '../Switch.context';

type SwitchDefaultProps = PropsWithChildren<unknown>;

export const SwitchDefault: FC<SwitchDefaultProps> = ({ children }) => {
  const condition = useContextSelector<
    SwitchContextProps,
    SwitchContextProps['condition']
  >(SwitchContext, ({ condition }) => condition);

  const set = useContextSelector<SwitchContextProps, SwitchContextProps['set']>(
    SwitchContext,
    ({ set }) => set
  );

  if (!Array.from(set).includes(condition)) {
    return <>{children}</>;
  }

  return null;
};
