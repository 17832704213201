module.exports = {
  deletePanel: {
    content: 'Are you sure you want to delete {{panelName}}?',
    title: 'Delete panel',
  },
  deleteTeam: {
    content: 'Are you sure you want to delete {{name}} team',
    contentLastTeam:
      'Are you sure you want to delete the last team?\nYou will not be able to create activities outside the team',
    title: 'Delete team',
  },
  removeTeamMember: {
    content:
      'Are you sure you want to remove {{memberName}} from {{teamName}} team?',
    title: 'Remove member from team',
  },
  removeTeamPanel: {
    content:
      'Are you sure you want to remove {{panelName}} from {{teamName}} team?',
    title: 'Remove panel from team',
  },
  removeTeamSong: {
    content:
      'Are you sure you want to remove {{rank}}. {{songName}} - {{artistName}} from {{teamName}} team?',
    title: 'Remove song from team',
  },
};
