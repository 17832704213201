import { FC, PropsWithChildren, useContext, Key } from 'react';
import { castArray } from 'lodash';
import { useMount } from 'react-use';
import { FormItemProps as AntdFormItemProps } from 'antd/lib/form';
import { FormContext } from '../Form.context';
import { Root } from './FormItem.style';
import { useRules } from './FormItem.hooks';
import { FormItemType } from './FormItem.types';

export type FormItemProps<V extends object = {}> = PropsWithChildren<{
  name: Key | Key[];
  label?: string;
  hideLabel?: boolean;
  validation?: V;
  errors?: Partial<Record<keyof V, string>>;
  placeholder?: string;
  disabled?: boolean;
  hasFeedback?: boolean;
  dependencies?: AntdFormItemProps['dependencies'];
  help?: string;
  className?: string;
  type?: FormItemType;
  preserve?: boolean;
  valuePropName?: string;
}>;

export const FormItem: FC<FormItemProps> = ({
  children,
  label,
  hideLabel,
  name,
  help,
  validation = {},
  errors = {},
  hasFeedback,
  dependencies,
  className,
  valuePropName,
  type = 'string',
  preserve = false,
}) => {
  const { setLabel } = useContext(FormContext);
  const { rules } = useRules({ validation, errors, type });
  useMount(() => setLabel({ name: castArray(name).join('.'), label }));

  return (
    <Root
      validateFirst
      rules={rules}
      name={name}
      label={label}
      hideLabel={hideLabel}
      valuePropName={valuePropName}
      extra={help}
      dependencies={dependencies}
      hasFeedback={hasFeedback}
      className={className}
      preserve={preserve}
    >
      {children}
    </Root>
  );
};
