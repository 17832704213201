import { DTO } from '@spintel/network/app';
import { UiKitColor, getFeaturePoint, getFeaturePolygon } from '@spintel/uikit';

export interface RotationStation extends DTO.ISongRotationItemResponse {
  color: UiKitColor;
}

export interface OutlookStation extends DTO.ISongOutlookItemResponse {
  color: UiKitColor;
}

export interface AirplayStation extends DTO.IGetSongAirplayResponse {
  color: UiKitColor;
}

type MapTypes = RotationStation & OutlookStation & AirplayStation;

export interface Station extends MapTypes {
  station: DTO.IStationModel;
}

export const getStationMapTiles = (data: Station[]) => {
  return data.reduce(
    (acc, station) => {
      const {
        color,
        station: { lat, lng, id, contours },
      } = station;

      return {
        circles: [
          ...acc.circles,
          getFeaturePoint({
            coordinates: [lng, lat],
            id,
            color,
          }),
        ],
        polygons: [
          ...acc.polygons,
          getFeaturePolygon({
            coordinates: [contours],
            id,
            color,
          }),
        ],
      };
    },
    {
      circles: [],
      polygons: [],
    }
  );
};
