import { FC } from 'react';
import { useCopyToClipboard } from 'react-use';
import { Empty, Button } from '@spintel/uikit';
import { useTranslation, Translate } from '@spintel/localization';
import { Title, Id, Content } from './ErrorBoundaryFallback.style';

interface ErrorBoundaryFallbackProps {
  id: string;
}

export const ErrorBoundaryFallback: FC<ErrorBoundaryFallbackProps> = ({
  id,
}) => {
  const { t } = useTranslation();
  const [, copy] = useCopyToClipboard();

  return (
    <Empty
      fill
      size="lg"
      icon="error"
      background="gradient"
      title={<Title>{t('empty.errorBoundary.title')}</Title>}
      subtitle={<Translate i18nKey={'empty.errorBoundary.message'} />}
    >
      <Content>
        <Id>{t('empty.errorBoundary.extra', { id })}</Id>
        <Button size="sm" onClick={() => copy(id)}>
          {t('actions.copy')}
        </Button>
      </Content>
    </Empty>
  );
};
