import { FC } from 'react';
import { useTranslation } from '@spintel/localization';
import { AuthRoutePath } from '@common/constants';
import { useRoute } from '@spintel/navigation';
import { Button, Typography, Prompt } from '@spintel/uikit';

const { Body2 } = Typography;

export const UserDeactivated: FC = () => {
  const { t } = useTranslation();
  const [, { push }] = useRoute();

  const handleClickOk = () => {
    push({ to: AuthRoutePath.LOGIN });
  };

  return (
    <Prompt title={t('notifications.deactivated.title')}>
      <Prompt.Content gutter={6}>
        <Body2>{t('notifications.deactivated.description')}</Body2>
      </Prompt.Content>
      <Prompt.Actions>
        <Button size="lg" variant="primary" onClick={handleClickOk}>
          {t('actions.ok')}
        </Button>
      </Prompt.Actions>
    </Prompt>
  );
};
