/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IAddEditPanelModel,
  IErrorResponse,
  IItemModel,
  IPanelFilterDto,
  IPanelInfoModel,
  IPanelModel,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Panel<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Panel
   * @name PanelsCreate
   * @summary Add new personal panel
   * @request POST:/panels
   * @secure
   */
  panelsCreate = (data: IAddEditPanelModel, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/panels`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Panel
   * @name PanelsUpdate
   * @summary Edit panel
   * @request PUT:/panels
   * @secure
   */
  panelsUpdate = (data: IAddEditPanelModel, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/panels`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Panel
   * @name SelectorList
   * @summary Get list of panel
   * @request GET:/panels/selector
   * @secure
   */
  selectorList = (params: RequestParams = {}) =>
    this.http.request<IItemModel[], IErrorResponse>({
      path: `/panels/selector`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Panel
   * @name MainSelectorList
   * @summary Get list of panels info according to selected team.
   * @request GET:/panels/main-selector
   * @secure
   */
  mainSelectorList = (params: RequestParams = {}) =>
    this.http.request<IPanelInfoModel[], IErrorResponse>({
      path: `/panels/main-selector`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Panel
   * @name PostPanel
   * @summary Get list of panels info.
   * @request POST:/panels/all
   * @secure
   */
  postPanel = (data: IPanelFilterDto, params: RequestParams = {}) =>
    this.http.request<IPanelInfoModel[], IErrorResponse>({
      path: `/panels/all`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Panel
   * @name PanelsDetail
   * @summary Get panel details.
   * @request GET:/panels/{panelId}
   * @secure
   */
  panelsDetail = (panelId: number, params: RequestParams = {}) =>
    this.http.request<IPanelModel, IErrorResponse>({
      path: `/panels/${panelId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Panel
   * @name PanelsDelete
   * @summary Delete panel.
   * @request DELETE:/panels/{panelId}
   * @secure
   */
  panelsDelete = (panelId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/panels/${panelId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Panel
   * @name ExportCreate
   * @summary Export panel stations.
   * @request POST:/panels/{panelId}/export
   * @secure
   */
  exportCreate = (panelId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/panels/${panelId}/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
}
