import { MutableRefObject, Key } from 'react';
import { get, isEqual } from 'lodash';
import { ScriptableContext, ChartDataset } from 'chart.js';
import { getTransparentColorHex } from '../../../utils/getTransparentHex/getTransparentHex.utils';
import { UiKitColor } from '../../../types/uikit';
import { THEME } from '../../../constants/theme.const';
import { GraphLineDatapoint } from './GraphLine.types';
import { ACTIVE_LINE_WIDTH, DEFAULT_LINE_WIDTH } from './GraphLine.const';
import { GraphLineVariant, GraphLineBackground } from './GraphLine.types';

interface GetBorderWidthParams {
  active: boolean;
  selected: MutableRefObject<number>;
}

interface GetPointHitRadiusParams {
  selected: MutableRefObject<number>;
  datalabel: GraphLineVariant['datalabel'];
}

interface GetPointRadiusParams {
  datapoint: MutableRefObject<GraphLineDatapoint>;
  datalabel: GraphLineVariant['datalabel'];
}

interface GetBackgroundColorParams {
  color: UiKitColor;
  active: boolean;
  background: GraphLineBackground;
  selected: MutableRefObject<number>;
}

interface GetDatalabelDisplayParams {
  active: boolean;
  selected: MutableRefObject<number>;
  datapoint?: MutableRefObject<GraphLineDatapoint>;
}

interface GetDatalabelBackgroundColorParams {
  color: UiKitColor;
  datapoint?: MutableRefObject<GraphLineDatapoint>;
}

export const datasetBackgroundGradient = (
  context: ScriptableContext<'line'>,
  color: UiKitColor
) => {
  const { ctx, chartArea } = context.chart;
  if (chartArea) {
    const { top, bottom } = chartArea;
    const gradient = ctx.createLinearGradient(0, top, 0, bottom);
    gradient.addColorStop(
      0,
      getTransparentColorHex(THEME.palette[color], '40%')
    );
    gradient.addColorStop(
      1,
      getTransparentColorHex(THEME.palette[color], '0%')
    );
    return gradient;
  }
};

export const getBorderWidth = ({
  active,
  selected,
}: GetBorderWidthParams): ChartDataset<'line'>['borderWidth'] => {
  return ({ datasetIndex }) => {
    if (active || selected.current === datasetIndex) {
      return ACTIVE_LINE_WIDTH;
    }
    return DEFAULT_LINE_WIDTH;
  };
};

export const getPointHitRadius = ({
  selected,
}: GetPointHitRadiusParams): ChartDataset<'line'>['pointHitRadius'] => {
  return ({ datasetIndex }) => {
    const isSelected = selected.current === datasetIndex;
    return isSelected ? 20 : 0;
  };
};

export const getPointRadius = ({
  datapoint,
}: GetPointRadiusParams): ChartDataset<'line'>['pointHitRadius'] => {
  return ({
    dataIndex,
    chart: {
      data: { labels },
    },
  }) => {
    const index = labels.findIndex((v) => v === datapoint.current?.x);
    return index === dataIndex ? 4 : 0;
  };
};

export const getBackgroundColor = ({
  color,
  background,
  selected,
}: GetBackgroundColorParams): ChartDataset<'line'>['backgroundColor'] => {
  return (context: ScriptableContext<'line'>) => {
    const { datasetIndex } = context;
    if (background === 'gradient' && selected.current === -1) {
      return datasetBackgroundGradient(context, color);
    }
    if (datasetIndex === selected.current) {
      return datasetBackgroundGradient(context, color);
    }
    if (background === 'fill') {
      return THEME.palette[color];
    }
    return THEME.palette.transparent;
  };
};

export const getDatalabelAlign: ChartDataset<'line'>['datalabels']['align'] = ({
  chart,
  dataIndex,
}) => {
  const ticks = chart.getDatasetMeta(0).xScale.ticks;
  switch (dataIndex) {
    case ticks[0].value:
      return 'right';
    case ticks[ticks.length - 1].value:
      return 'left';
    default:
      return 'center';
  }
};

export const getDatalabelDisplay = ({
  selected,
  datapoint,
  active: isActive,
}: GetDatalabelDisplayParams): ChartDataset<'line'>['datalabels']['display'] => {
  return ({ datasetIndex, dataIndex, chart }) => {
    const x = chart.data.labels[dataIndex];
    const isSelected = datasetIndex === selected.current;
    const isCurrent = datapoint.current?.x === x;
    if (isSelected && isCurrent) return true;
    if (isActive && isCurrent) return 'auto';
    return false;
  };
};

export const getDatalabelBackgroundColor = ({
  color,
  datapoint,
}: GetDatalabelBackgroundColorParams): ChartDataset<'line'>['datalabels']['backgroundColor'] => {
  return ({ chart, dataIndex, dataset }) => {
    const x = get(chart, ['data', 'labels', dataIndex]) as Key;
    const y = get(dataset, ['data', dataIndex]) as number;
    const currentDatalebel = { x, y, label: dataset?.label };
    return isEqual(datapoint.current, currentDatalebel)
      ? THEME.palette.white
      : THEME.palette[color];
  };
};
