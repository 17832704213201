import {
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import {
  Chart,
  ChartDataset,
  ArcElement,
  Plugin,
  ActiveElement,
  ChartEvent,
  ChartOptions,
  TooltipModel,
  TooltipOptions,
} from 'chart.js';
import { get, sum } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import {
  GraphDataset,
  GraphLegendItem,
  GraphTooltipConfig,
} from '../Graph.types';
import { getTransparentColorHex } from '../../../utils/getTransparentHex/getTransparentHex.utils';
import { GraphContext, GraphContextProps } from '../Graph.context';
import { GraphDoughnutVariant } from './GraphDoughnut.types';
import {
  getDefinedVariant,
  getMappedColors,
  defaultFormatter,
} from './GraphDoughnut.utils';
import { TOP_LINE_COLOR, BOTTOM_LINE_COLOR } from './GraphDoughnut.const';

interface UseDatasetParams {
  dataset: GraphDataset;
  variant: GraphDoughnutVariant;
}

type UseDatasetReturnType = ChartDataset<'doughnut'>;

export const useDataset = ({
  dataset: initialDataset,
  variant: initialVariant,
}: UseDatasetParams): UseDatasetReturnType => {
  return useMemo(() => {
    if (!initialDataset) return null;

    const variant = getDefinedVariant(initialVariant);

    const dataset: ChartDataset<'doughnut'> = {
      label: initialDataset.label,
      data: initialDataset.data,
      borderWidth: 0,
      backgroundColor: getMappedColors(variant.backgroundColors),
      hoverBackgroundColor: getMappedColors(variant.backgroundColors),
    };

    return dataset;
  }, [initialDataset, initialVariant]);
};

interface UseOptionsParams {
  id: string;
  tooltipConfig: GraphTooltipConfig;
  labels: string[];
  chartRef: MutableRefObject<Chart<'doughnut'>>;
  dataset: ChartDataset<'doughnut'>;
  variant: GraphDoughnutVariant;
  formatter: (value: number) => string;
}

type UseOptionsReturnType = [
  {
    options: ChartOptions<'doughnut'>;
    size: number;
  },
  Plugin<'doughnut'>,
  Plugin<'doughnut'>,
  Plugin<'doughnut'>,
  Plugin<'doughnut'>
];

export const useOptions = ({
  id,
  tooltipConfig,
  labels,
  chartRef,
  dataset,
  variant: initialVariant,
  formatter: initialFormatter,
}: UseOptionsParams): UseOptionsReturnType => {
  const setLegend = useContextSelector<
    GraphContextProps,
    GraphContextProps['setLegend']
  >(GraphContext, ({ setLegend }) => setLegend);

  const chartActionsRef = useContextSelector<
    GraphContextProps,
    GraphContextProps['chartActions']
  >(GraphContext, ({ chartActions }) => chartActions);

  const legendActionsRef = useContextSelector<
    GraphContextProps,
    GraphContextProps['legendActions']
  >(GraphContext, ({ legendActions }) => legendActions);

  const hovered = useRef<number>(-1);
  const variant = useMemo(
    () => getDefinedVariant(initialVariant),
    [initialVariant]
  );

  const getCenterText = (
    dataIndex: number = -1
  ): { textTop: string; textBottom: string } => {
    const formatter = initialFormatter || defaultFormatter;
    const s = sum(dataset?.data);

    if (dataIndex === -1) {
      return {
        textTop: s ? formatter(s) : '',
        textBottom: s ? get(dataset, 'label', '100%') : '',
      };
    }

    const value: number = get(dataset, `data[${dataIndex}]`, 0);

    return {
      textTop: s && value ? formatter(value) : '',
      textBottom: s && value ? `${formatter((value / s) * 100)}%` : '',
    };
  };

  const text = useRef<{ textTop: string; textBottom: string }>(getCenterText());

  const updateCenterText = (dataIndex: number = -1) => {
    const { textTop, textBottom } = getCenterText(dataIndex);
    text.current.textTop = textTop;
    text.current.textBottom = textBottom;
  };

  const reset = () => {
    hovered.current = -1;
    updateCenterText();
    if (chartRef.current) chartRef.current.update('none');
  };

  const highlight = (dataIndex: number, hover: boolean = false) => {
    if (chartRef.current && dataIndex >= 0) {
      const ctx = chartRef.current.ctx;
      const meta = chartRef.current.getDatasetMeta(0);

      if (!meta?.data.length || meta?.data.length < 2) return;

      reset();
      updateCenterText(dataIndex);

      const element = meta.data[dataIndex] as any;
      const angleExpansion = (Math.PI / 180) * variant.angleDegreeExpansion;

      // change opacity of the not hovered elements
      // the manual call of the highlight function works properly without plugin below
      if (!hover) {
        meta.data.forEach((e, index) => {
          const element = e as unknown as ArcElement;
          if (index !== dataIndex) {
            element.options.backgroundColor = getTransparentColorHex(
              element.options.backgroundColor.toString(),
              '30%'
            );
          }
        });
        chartRef.current.draw();
      }

      hovered.current = dataIndex;

      if (element) {
        // + to outer radius
        // - to inner radius
        element.innerRadius =
          element.innerRadius - variant.radiusLengthExpansion;
        element.outerRadius =
          element.outerRadius + variant.radiusLengthExpansion;

        // expand angle
        element.startAngle = element.startAngle - angleExpansion;
        element.endAngle = element.endAngle + angleExpansion;
        element.circumference = element.circumference + angleExpansion * 2;

        element.draw(ctx);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(), [dataset]);

  useImperativeHandle(chartActionsRef, () => ({
    highlight,
    reset,
  }));

  // chart hover effect breaks the mutation of opacity not hovered element in the highlight function
  // force mutation opacity after hover styles already updated to fix that
  const highlightHelperPlugin: Plugin<'doughnut'> = {
    id: 'highlightHelperPlugin',
    afterEvent(chart: Chart) {
      const meta = chart.getDatasetMeta(0);
      if (hovered.current !== -1 && meta?.data) {
        meta.data.forEach((e, index) => {
          const element = e;
          if (index !== hovered.current) {
            element.options.backgroundColor = getTransparentColorHex(
              element.options.backgroundColor.toString(),
              '30%'
            );
          }
        });
      }
    },
  };

  const textCenterPlugin: Plugin<'doughnut'> = {
    id: 'textCenterPlugin',
    afterDatasetsDraw(chart: Chart) {
      const { ctx } = chart;
      const element = chart.getDatasetMeta(0)?.data?.[0];

      if (element) {
        // center of the chart
        const { x, y } = element;
        ctx.save();

        // render top line
        ctx.textAlign = 'center';
        ctx.fillStyle = TOP_LINE_COLOR;
        ctx.textBaseline = 'middle';
        ctx.font = '700 20px Montserrat';
        ctx.fillText(String(text.current.textTop), x, y);

        // render bottom line
        ctx.fillStyle = BOTTOM_LINE_COLOR;
        ctx.font = '400 12px Montserrat';
        ctx.fillText(text.current.textBottom, x, y + 20);

        ctx.restore();
      }
    },
  };

  const legendPlugin: Plugin<'doughnut', { labels: string[] }> = useMemo(
    () => ({
      id: 'legendPlugin',
      afterUpdate: (chart, _args, options) => {
        const legend: GraphLegendItem[] = [];
        const dataset = chart.data.datasets[0];
        const data = dataset?.data || [];

        if (data) {
          const colorsBase = variant.backgroundColors?.length || Infinity;

          data.forEach((value, i) => {
            legend.push({
              value: value as number,
              label: options.labels?.[i],
              status: variant.backgroundColors?.[i % colorsBase],
              hidden: false,
            });
          });
        }

        setLegend(id, legend);
      },
      defaults: {
        labels: [],
      },
    }),
    [setLegend, id, variant.backgroundColors]
  );

  const clearHoverPlugin: Plugin<'doughnut'> = {
    id: 'clearHoverPlugin',
    beforeEvent: (_chart, args) => {
      const { event } = args;
      if (event.type === 'mouseout') {
        legendActionsRef?.current?.reset();
        chartActionsRef.current?.reset();
      }
    },
  };

  const handleHover: ChartOptions<'doughnut'>['onHover'] = (
    _: ChartEvent,
    elements: ActiveElement[]
  ) => {
    if (elements?.length) {
      // WARNING
      // there is a case when the hover events fire on a few elements during moving between adjacent elements
      const nextElementIndex =
        elements?.length >= 2
          ? hovered.current === elements[0].index
            ? 1
            : 0
          : 0;
      const index = elements[nextElementIndex].index;
      if (index === hovered.current) return;
      legendActionsRef?.current?.highlight(index);
      highlight(index, true);
    } else if (hovered.current !== -1) {
      legendActionsRef?.current?.reset();
      reset();
    }
  };

  const externalTooltipConfig = useExternalTooltip(
    tooltipConfig?.id,
    tooltipConfig?.dataIndex || -1
  );

  const options: ChartOptions<'doughnut'> = {
    layout: {
      padding: variant.radiusLengthExpansion,
    },
    maintainAspectRatio: true,
    animation: false,
    radius: variant.radius,
    onHover: handleHover,
    interaction: {
      intersect: true,
      mode: 'point',
      axis: 'xy',
      includeInvisible: false,
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: externalTooltipConfig,
      },
      legendPlugin: {
        labels,
      },
    } as ChartOptions<'doughnut'>['plugins'],
  };

  // two radiuses and two radius expansions
  const size = variant.radius * 2 + variant.radiusLengthExpansion * 2;

  return [
    { options, size },
    highlightHelperPlugin,
    textCenterPlugin,
    legendPlugin,
    clearHoverPlugin,
  ];
};

const useExternalTooltip = (
  id: string,
  dataIndex: number
): TooltipOptions<'doughnut'>['external'] => {
  const timer = useRef<ReturnType<typeof setTimeout>>(null);
  const handleMouseOver = useRef<() => void>(null);
  const handleMouseLeave = useRef<() => void>(null);

  useEffect(() => {
    return () => {
      const tooltipEl = document.getElementById(id);

      if (tooltipEl) {
        tooltipEl.removeEventListener('mouseover', handleMouseOver.current);
        tooltipEl.removeEventListener('mouseleave', handleMouseLeave.current);
        handleMouseOver.current = null;
        handleMouseLeave.current = null;

        tooltipEl.style.display = 'none';
      }
    };
  }, [id]);

  if (dataIndex === -1) return null;

  return (context) => {
    const { tooltip: tooltipModel, chart } = context;
    const tooltipEl = document.getElementById(id);

    if (!tooltipEl || !tooltipModel.dataPoints) return;

    if (isVisibleTooltipTarget(tooltipModel, dataIndex)) {
      clearTimeout(timer.current);
      timer.current = null;
    }

    if (!isVisibleTooltipTarget(tooltipModel, dataIndex)) {
      if (timer.current === null && !handleMouseLeave.current) {
        // hide tooltip if not target dataIndex or tooltip is not active;
        // reason for timer - move cursor into tooltip through chart;
        // do not start timer if cursor in tooltip;
        timer.current = setTimeout(() => {
          timer.current = null;
          tooltipEl.style.display = 'none';
        }, 200);
      }
      return;
    }

    // clear listeners
    tooltipEl.removeEventListener('mouseover', handleMouseOver.current);
    tooltipEl.removeEventListener('mouseleave', handleMouseLeave.current);
    handleMouseOver.current = null;
    handleMouseLeave.current = null;

    handleMouseOver.current = () => {
      handleMouseLeave.current = () => {
        // clear the mouseleave listener
        // hide tooltip on leave tooltip
        tooltipEl.removeEventListener('mouseleave', handleMouseLeave.current);
        handleMouseLeave.current = null;
        tooltipEl.style.display = 'none';
      };

      // clear the hide tooltip timer that could be activated on moving through the chart
      clearTimeout(timer.current);
      timer.current = null;

      // clear the mouseover listener while cursor in tooltip
      tooltipEl.removeEventListener('mouseover', handleMouseOver.current);
      handleMouseOver.current = null;

      tooltipEl.addEventListener('mouseleave', handleMouseLeave.current);
    };

    tooltipEl.addEventListener('mouseover', handleMouseOver.current);

    const position = context.chart.canvas.getBoundingClientRect();
    const radius = (chart?.chartArea?.height || 0) / 2;

    // Display and position
    tooltipEl.style.display = 'inherit';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left =
      position.left +
      window.pageXOffset +
      tooltipModel.caretX +
      radius / 2 +
      'px';
    tooltipEl.style.top =
      position.top + window.pageYOffset + tooltipModel.caretY + 'px';
  };
};

function isVisibleTooltipTarget(
  model: TooltipModel<'doughnut'>,
  dataIndex: number
) {
  return model.dataPoints[0].dataIndex === dataIndex && model.opacity !== 0;
}
