import styled, { css } from 'styled-components';
import { UiKitColor } from '../../types/uikit';
import { IconSize } from './Icon.types';
import { ICON_SIZE_MAP } from './Icon.const';

interface RootProps {
  size: IconSize;
  force: boolean;
  color?: UiKitColor;
}

const RootWithColorMixin = css<RootProps>`
  color: ${({ theme, color }) => theme.palette[color]};
`;

export const Root = styled('span')<RootProps>`
  font-size: ${({ size }) => ICON_SIZE_MAP[size]}px${({ force }) => (force ? ' !important' : '')};
  line-height: ${({ size }) => ICON_SIZE_MAP[size]}px${({ force }) => (force ? ' !important' : '')};
  display: inline-block;
  ${({ color }) => color && RootWithColorMixin}

  &.icon-more {
    font: ${({ size }) => ICON_SIZE_MAP[size]}px /
        ${({ size }) => ICON_SIZE_MAP[size]}px tahoma,
      arial, \\5b8b\\4f53, sans-serif;
  }
`;
