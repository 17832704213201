import { FC, PropsWithChildren, CSSProperties } from 'react';
import { Root } from './CardFooter.style';

type CardFooterProps = PropsWithChildren<{
  className?: string;
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
}>;

export const CardFooter: FC<CardFooterProps> = ({
  children,
  className,
  align = 'center',
  justify = 'flex-end',
}) => {
  return (
    <Root className={className} align={align} justify={justify}>
      {children}
    </Root>
  );
};
