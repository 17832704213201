import { FC } from 'react';
import { toNumber } from 'lodash';
import { Col, ColProps } from 'antd/lib/grid';
import { GridCellContext } from '../../../contexts/GridCell.context';

export const GridCol: FC<ColProps> = ({ span, children, ...props }) => {
  return (
    <GridCellContext.Provider value={toNumber(span) / 2}>
      <Col span={span} {...props}>
        {children}
      </Col>
    </GridCellContext.Provider>
  );
};
