export function getArrowsState(list: HTMLDivElement, forceDisable: boolean) {
  const prevArrow = prevArrowVisible(list) && !forceDisable;
  const nextArrow = nextArrowVisible(list) && !forceDisable;

  return { prevArrow, nextArrow };
}

export function getPrevItemScrollOffset(
  list: HTMLDivElement,
  overlayWidth: number = 0
) {
  const visibleItems = getVisibleItems(list, overlayWidth);

  if (visibleItems.length) {
    const item = visibleItems[visibleItems.length - 1];

    return (
      item.offsetLeft + item.offsetWidth - list.offsetWidth + overlayWidth - 1
    );
  }

  return 0;
}

export function getNextItemScrollOffset(
  list: HTMLDivElement,
  overlayWidth: number = 0
) {
  const visibleItems = getVisibleItems(list, overlayWidth);
  return visibleItems.length
    ? visibleItems[0].offsetLeft - (overlayWidth - 1)
    : 0;
}

function getVisibleItems(list: HTMLDivElement, overlayWidth: number = 0) {
  const items = getItems(list);
  const visibleItems = items.filter((item) => {
    const leftBoundInView = item.offsetLeft - list.scrollLeft >= overlayWidth;
    const rightBoundInView = (list.scrollLeft + list.offsetWidth) - (item.offsetLeft + item.offsetWidth) >= overlayWidth; // prettier-ignore

    return leftBoundInView && rightBoundInView;
  });

  return visibleItems;
}

function nextArrowVisible(list: HTMLDivElement) {
  const items = getItems(list);

  if (items.length > 1) {
    const last = items[items.length - 1];
    const rightBoundLast = last.offsetLeft + last.offsetWidth;
    const rightBoundList = list.scrollLeft + list.offsetWidth;

    return rightBoundLast - rightBoundList > 0;
  }

  return false;
}

function prevArrowVisible(list: HTMLDivElement) {
  const items = getItems(list);

  if (items.length > 1) {
    const first = items[0];
    return list.scrollLeft - first.offsetLeft > 0;
  }

  return false;
}

function getItems(list: HTMLDivElement) {
  return Array.from(list?.children || []) as HTMLElement[];
}
