import { Locale } from '../../types/locale.types';
import { UseDateLongDateFormatSpec } from './useDate.types';

export const USE_DATE_LONG_DATE_FORMAT: Record<
  Locale,
  Partial<UseDateLongDateFormatSpec>
> = {
  en: {
    lls: 'MMM DD',
    llm: 'YYYY-DD-MM',
  },
};
