import { ReactNode } from 'react';
import { createContext } from 'use-context-selector';

export interface NavigationContextProps {
  loginUrl: string;
  defaultUrl: string;
  role: string | string[];
  protectedFallback: ReactNode;
}

export const NavigationContext = createContext<NavigationContextProps>({
  role: null,
  defaultUrl: '/',
  loginUrl: '/login',
  protectedFallback: null,
});
