import { FC, PropsWithChildren } from 'react';
import { useContextSelector } from 'use-context-selector';
import { CatalogContext, CatalogContextProps } from '../Catalog.context';
import { Root, Loader } from './CatalogContent.style';

type CatalogContentProps = PropsWithChildren<{
  loading?: boolean;
  className?: string;
}>;

export const CatalogContent: FC<CatalogContentProps> = ({
  loading: contentLoading,
  className,
  children,
}) => {
  const rootLoading = useContextSelector<CatalogContextProps, boolean>(
    CatalogContext,
    ({ loading, total }) => loading && !total
  );

  const loading = rootLoading || contentLoading;

  return (
    <Root className={className} loading={loading}>
      {children}
      {loading && <Loader variant="grid" />}
    </Root>
  );
};
