import { UiKitColor } from '../../types/uikit';

const LENGTH_2: UiKitColor[] = ['guacamoleGreen', 'feverRed'];

const LENGTH_3: UiKitColor[] = ['guacamoleGreen', 'canariasYellow', 'feverRed'];

const LENGTH_4: UiKitColor[] = [
  'guacamoleGreen',
  'canariasYellow',
  'tangerine',
  'feverRed',
];

const LENGTH_5: UiKitColor[] = [
  'guacamoleGreen',
  'spintelGreen',
  'canariasYellow',
  'tangerine',
  'feverRed',
];

const LENGTH_6: UiKitColor[] = [
  'guacamoleGreen',
  'spintelGreen',
  'sundance',
  'canariasYellow',
  'tangerine',
  'feverRed',
];

const LENGTH_7: UiKitColor[] = [
  'guacamoleGreen',
  'spintelGreen',
  'sundance',
  'canariasYellow',
  'tangerine',
  'lavaOrange',
  'feverRed',
];

const LENGTH_8: UiKitColor[] = [
  'guacamoleGreen',
  'spintelGreen',
  'sundance',
  'canariasYellow',
  'tangerine',
  'lavaOrange',
  'feverRed',
  'vividRed',
];

const LENGTH_9: UiKitColor[] = [
  'shamrockGreen',
  'guacamoleGreen',
  'spintelGreen',
  'sundance',
  'canariasYellow',
  'tangerine',
  'lavaOrange',
  'feverRed',
  'vividRed',
];

export const COLORS_LENGTH_MAP: Record<number, UiKitColor[]> = {
  2: LENGTH_2,
  3: LENGTH_3,
  4: LENGTH_4,
  5: LENGTH_5,
  6: LENGTH_6,
  7: LENGTH_7,
  8: LENGTH_8,
  9: LENGTH_9,
};
