import styled, { StyledComponent, DefaultTheme, css } from 'styled-components';
import AntdTable from 'antd/lib/table';
import { Root as Select } from '../Select/Select.style';
import { Root as Dropdown } from '../Dropdown/Dropdown.style';
import { Status as BadgeStatus } from '../Badge/Badge.style';
import { UiKitColor } from '../../types/uikit';
import { Root as Userpic } from '../Userpic/Userpic.style';
import { Root as TableFilterIcon } from './TableFilters/TableFilterIcon/TableFilterIcon.style';
import { Icon as TableSorterIcon } from './TableColumnTitle/TableColumnTitle.style';

interface TableWrapperProps {
  gutter: number[];
  background: UiKitColor;
}

interface TableProps {
  background: UiKitColor;
  yScrollbar?: boolean;
}

interface RootProps {
  background: UiKitColor;
}

export const StickyColumnGroupHeader = styled('span')<{ left: number }>`
  position: sticky;
  left: ${({ left }) => left + 11}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: block;
`;

export const Root = styled('div')<RootProps>`
  background: ${({ theme, background }) => theme.palette[background]};
`;

export const TableWrapper = styled('div')<TableWrapperProps>`
  background: ${({ theme, background }) => theme.palette[background]};
  padding-top: ${({ gutter: [top], theme: { spacing } }) => spacing(top)};
  padding-right: ${({ gutter: [, right], theme: { spacing } }) =>
    spacing(right)};
  padding-left: ${({ gutter: [, , , left], theme: { spacing } }) =>
    spacing(left)};
  padding-bottom: ${({ gutter: [, , bottom], theme: { spacing } }) =>
    spacing(bottom)};
`;

const CellExpanderMixin = css`
  content: '';
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  z-index: 2;
  user-select: none;
  pointer-events: none;
`;

const ShadowCommon = css`
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: none;
  height: calc(100% - 2px);
`;

const ShadowLeft = css`
  ${ShadowCommon}
  background: linear-gradient(90deg, rgba(27, 27, 27, 0) 0.55%, ${({ theme }) =>
    theme.palette.anthraciteGrey} 100%);
  transform: translateX(100%) matrix(-1, 0, 0, 1, 0, 0);
  right: ${({ theme }) => theme.spacing(-0.5)};
  bottom: 1px;
  top: auto;
`;

const ShadowRight = css`
  ${ShadowCommon}
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(27, 27, 27, 0) 0.55%,
    ${({ theme }) => theme.palette.anthraciteGrey} 50%
  );
  right: 0;
  top: 1px;
  width: ${({ theme }) => theme.spacing(7.5)};
`;

export const CellExpander = styled('div')`
  &:after {
    content: '';
    ${ShadowRight}
  }
`;

export const Table: StyledComponent<
  typeof AntdTable,
  DefaultTheme,
  {},
  never
> = styled(AntdTable)<TableProps>`
  .ant-table {
    &.ant-table-empty {
      background: ${({ theme, background }) => theme.palette[background]};
      .ant-table-expanded-row-fixed {
        padding: 0;
        margin: 0;
      }
      .ant-table-body {
        overflow: hidden !important;
      }
    }
    &:not(.ant-table-ping-right):not(.ant-table-ping-left) {
      .ant-table-thead > tr > th.ant-table-cell-fix-left-last,
      .ant-table-tbody > tr > td.ant-table-cell-fix-left-last {
        border-right-width: 1px !important;
      }
    }
  }
  .ant-table-expanded-row-fixed {
    &:has(.ant-empty) {
      margin: 0;
      padding: 0;
    }
  }
  .ant-table-thead {
    > tr {
      > th {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        padding-bottom: ${({ theme: { spacing } }) => spacing(3.75)};
        &:before {
          display: none;
        }

        &:hover {
          .ant-table-filter-trigger {
            visibility: visible;
          }
          ${TableSorterIcon} {
            visibility: visible;
          }
        }
        &:first-child {
          ${StickyColumnGroupHeader} {
            position: static;
          }
        }
        &.ant-table-cell-fluid {
          white-space: nowrap;
        }
        &.ant-table-cell-scrollbar {
          border-right: 0;
          border-bottom-color: ${({ theme }) =>
            theme.palette.transparent} !important;
        }
        &.ant-table-cell-group-header {
          font-size: 14px;
          line-height: 18px;
          &[colspan] {
            text-align: left;
          }
          &:not(:last-child) {
            border-right: 1px solid
              ${({ theme: { palette } }) => palette.asphaltGrey};
          }
          &.ant-table-cell-group-header-hidden {
            padding: 0;
            border-bottom: 0px;
          }
          &:has(+ .ant-table-cell-scrollbar) {
            border-right: 0;
          }
        }
        &.ant-table-cell-fix-left-last {
          border-right: 2px solid
            ${({ theme: { palette } }) => palette.asphaltGrey} !important;

          &:after {
            ${ShadowLeft}
          }

          &:first-child {
            border-right: 2px solid
              ${({ theme: { palette } }) => palette.asphaltGrey};
          }
        }

        &.ant-table-cell-fix-right-first {
          &.ant-table-cell-fix-right {
            &:not(:has(.cell-expander)) {
              right: auto !important;
              z-index: auto;
            }
          }
          &:after {
            display: none;
          }
        }

        &.ant-table-column-has-sorters {
          &:hover {
            background: ${({ theme }) => theme.palette.anthraciteGrey};
          }
        }
        .ant-table-filter-trigger {
          visibility: hidden;
          transition: none;
          &.active,
          &.ant-dropdown-open {
            visibility: visible;
          }
        }

        .cell-expander {
          &:after {
            ${CellExpanderMixin}
            ${ShadowRight}
          }
        }

        &.ant-table-cell-has-group:not(:last-child) {
          &.ant-table-cell-is-last-in-group {
            border-right: 1px solid
              ${({ theme: { palette } }) => palette.asphaltGrey};
          }
        }
      }
      &:not(:last-child) > th {
        border-bottom: 1px solid ${({ theme }) => theme.palette.asphaltGrey};
      }
    }
  }
  .ant-table-body {
    min-height: ${({ scroll }) => scroll.y && `${scroll.y}px`};
  }
  .ant-table-tbody {
    > tr {
      &:last-child > td {
        border-bottom: ${({ theme, dataSource }) =>
          dataSource?.length
            ? `1px solid ${theme.palette.asphaltGrey}`
            : 'none'};
      }
      > td {
        line-height: 16px;
        padding-bottom: ${({ theme: { spacing } }) => spacing(3.75)};
        a {
          color: ${({ theme }) => theme.palette.stardustGrey};
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        &.ant-table-cell-fix-right {
          background-color: ${({ theme: { palette } }) =>
            palette.anthraciteGrey} !important;
        }
        &.ant-table-cell-fluid {
          white-space: nowrap;
        }

        &.ant-table-cell-fix-left-last {
          border-right: 2px solid
            ${({ theme: { palette } }) => palette.asphaltGrey} !important;
          &:after {
            ${ShadowLeft}
          }
        }
        &.ant-table-cell-has-group:not(:last-child) {
          &.ant-table-cell-is-last-in-group {
            border-right: 1px solid
              ${({ theme: { palette } }) => palette.asphaltGrey};
          }
        }
        &:has(> * ${Userpic}),
        &:has(> * ${Select}),
        &:has(> ${Select}),
        &:has(> * ${Dropdown}),
        &:has(> ${Dropdown}) {
          padding-top: 10px;
          padding-bottom: 9px;
        }
        ${BadgeStatus} {
          width: inherit;
          overflow: hidden;
          line-height: 1;
          .ant-badge-status-text {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &.ant-table-placeholder {
        .ant-table-cell {
          padding: 0;
        }
        &:hover {
          .ant-table-cell {
            background: ${({ theme }) => theme.palette.transparent};
          }
        }
      }
      &.ant-table-row-highlighted-bold {
        > td {
          font-weight: 600;
          /* override default bgcolor on row hover  */
          background-color: ${({ theme }) =>
            theme.palette.asphaltGrey} !important;
          &.ant-table-cell-fix-left-last {
            &:after {
              background: linear-gradient(
                90deg,
                rgba(54, 54, 54, 0) 0.55%,
                ${({ theme }) => theme.palette.asphaltGrey} 100%
              );
            }
          }
          ${CellExpander} {
            &:after {
              background: linear-gradient(
                90deg,
                rgba(54, 54, 54, 0) 0.55%,
                ${({ theme }) => theme.palette.asphaltGrey} 50%
              );
            }
          }
        }
      }
      &.ant-table-row-highlighted-normal {
        > td {
          /* override default bgcolor on row hover  */
          background-color: ${({ theme }) =>
            theme.palette.icarusGrey} !important;
          &.ant-table-cell-fix-left-last {
            &:after {
              background: linear-gradient(
                90deg,
                rgba(34, 34, 34, 0) 0.55%,
                ${({ theme }) => theme.palette.icarusGrey} 100%
              );
            }
          }
          ${CellExpander} {
            &:after {
              background: linear-gradient(
                90deg,
                rgba(34, 34, 34, 0) 0.55%,
                ${({ theme }) => theme.palette.icarusGrey} 50%
              );
            }
          }
        }
      }
    }
  }
  .ant-table-summary {
    > tr {
      > td {
        line-height: 16px;
        padding-bottom: ${({ theme: { spacing } }) => spacing(3.75)};
        &.ant-table-cell-fluid {
          white-space: nowrap;
        }
        &.ant-table-cell-fix-left-last {
          border-right: 2px solid
            ${({ theme: { palette } }) => palette.asphaltGrey} !important;
        }
        &.ant-table-cell-has-group:not(:last-child) {
          &.ant-table-cell-is-last-in-group {
            border-right: 1px solid
              ${({ theme: { palette } }) => palette.asphaltGrey};
          }
        }
      }
      &:last-child {
        > td {
          border-bottom: none;
        }
      }
    }
  }
  .ant-table-column-sorter {
    display: none;
  }
  .ant-table-filter-trigger {
    margin: 0;
    &.ant-dropdown-open {
      ${TableFilterIcon} {
        color: ${({ theme }) => theme.palette.spintelGreen};
      }
    }
  }
  .ant-table-filter-trigger {
    &:hover {
      background: ${({ theme }) => theme.palette.transparent};
      color: ${({ theme }) => theme.palette.londonGrey};
    }
  }
  .ant-table-cell-row-hover.ant-table-column-highlighted {
    background: ${({ theme }) => theme.palette.icarusGrey} !important;
  }
  .ant-table-column-highlighted {
    background: ${({ theme }) => theme.palette.icarusGrey} !important;
    transition: background 1s;
    &:hover {
      background: ${({ theme }) => theme.palette.icarusGrey} !important;
    }
  }
  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
  }
  .ant-table-ping-left {
    .ant-table-thead,
    .ant-table-tbody,
    .ant-table-summary {
      & > tr {
        & > td,
        & > th {
          &.ant-table-cell-fix-left-last {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .ant-table-ping-right {
    .ant-table-tbody {
      & > tr {
        & > td,
        & > th {
          &.ant-table-cell-fix-right-first {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .ant-table-container {
    position: relative;
    &:after {
      opacity: 0;
      background: linear-gradient(
        90deg,
        rgba(27, 27, 27, 0) 0.55%,
        ${({ theme }) => theme.palette.anthraciteGrey} 50%
      );
    }
  }
  .ant-table-tbody {
    .ant-table-cell-fix-right-first,
    .ant-table-cell-fix-right-last {
      &:after {
        display: none;
      }
    }
  }
  .ant-table-ping-right {
    .ant-table-summary {
      .ant-table-cell-fix-right-first,
      .ant-table-cell-fix-right-last {
        &:after {
          box-shadow: none;
          background: linear-gradient(
            90deg,
            rgba(27, 27, 27, 0) 0.55%,
            ${({ theme }) => theme.palette.anthraciteGrey} 50%
          );
        }
      }
    }
  }

  .ant-table-summary {
    .ant-table-cell {
      &.ant-table-cell-fix-left-last {
        &:after {
          content: '';
          ${ShadowLeft}
        }
      }
    }
  }

  .ant-table-thead {
    th {
      &:has(.ant-table-head-action) {
        background: none;
      }
    }
  }

  .ant-table-ping-right {
    .ant-table-tbody {
      .ant-table-cell {
        ${CellExpander} {
          &:after {
            opacity: 1;
          }
        }
        &.ant-table-cell-is-last-in-group.ant-table-cell-fix-right-first {
          ${CellExpander} {
            &:after {
              opacity: 0;
            }
          }
        }
      }
    }
    .ant-table-container {
      &:after {
        opacity: 1;
      }
    }
  }

  .ant-table-container {
    &:after {
      right: ${({ yScrollbar, theme }) => (yScrollbar ? theme.spacing(4) : 0)};
    }
  }

  .ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container {
    &:before {
      box-shadow: inset ${({ theme }) => theme.spacing(3)} 0
        ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(-2)}
        ${({ theme }) => theme.palette.anthraciteGrey};
    }
  }
`;
