import { Key } from 'react';
import styled from 'styled-components';
import { Empty } from '../../Empty/Empty';

interface RootProps {
  height?: Key;
}

export const Root = styled(Empty)<RootProps>`
  height: ${({ height }) => height && `${height}px`};
`;
