import {
  useState,
  useEffect,
  useLayoutEffect,
  MutableRefObject,
  useMemo,
  useContext,
  Key,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import { noop, merge, get } from 'lodash';
import { useVT } from 'virtualizedtableforantd4';
import { useContextSelector } from 'use-context-selector';
import { EmptyProps } from '../Empty/Empty';
import { UiKitLocalizationContext } from '../../contexts/UiKitLocalization.context';
import { UiKitColor } from '../../types/uikit';
import {
  FormCheckboxSelectionContext,
  FormCheckboxSelectionContextProps,
} from '../../contexts/FormCheckboxSelection.context';
import { Checkbox } from '../Checkbox/Checkbox';
import { ADDITIONAL_ROWS_COUNT, SUMMARY_ROW_HEIGHT } from './Table.const';
import { getShowSummary, getTableScrollbarSizes } from './Table.utils';
import { TableGlobalColumnsType, TableSearchState } from './Table.types';

interface UseTableStretchLayoutParams {
  showSummary: boolean;
  filters: object;
  gutter: number[];
  stretchTo?: () => HTMLElement | void;
  tableRef: MutableRefObject<HTMLDivElement>;
  filtersRef: MutableRefObject<HTMLDivElement>;
}

interface UseTableLayoutParams<T extends object>
  extends Omit<UseTableStretchLayoutParams, 'showSummary'> {
  scroll: AntdTableProps<T>['scroll'];
  loading: boolean;
  data: readonly T[];
  empty: Partial<EmptyProps>;
  columns: TableGlobalColumnsType<T>;
}

interface UseTableCheckboxParams<T extends object> {
  rowKey: AntdTableProps<T>['rowKey'];
  data: readonly T[];
}

type UseTableCheckboxReturnType<T extends object> = [
  {
    rowSelection?: AntdTableProps<T>['rowSelection'];
  }
];

interface UseTableSearchParams<T extends object> {
  data: readonly T[];
}

type UseTableSearchReturnType<T extends object> = [
  { data: readonly T[]; empty: Partial<EmptyProps> },
  Dispatch<SetStateAction<TableSearchState>>
];

export const useTableStretchLayout = ({
  showSummary,
  tableRef,
  filtersRef,
  filters,
  stretchTo,
  gutter: [gutterTop, , gutterBottom],
}: UseTableStretchLayoutParams) => {
  const [scrollY, setScrollY] = useState<{ inner: number; outer: number }>();
  const [headerH, setHeaderH] = useState<number>(0);
  const [filtersH, setFiltersH] = useState<number>(0);
  const summaryH = showSummary ? SUMMARY_ROW_HEIGHT : 0;
  useEffect(() => {
    const element = stretchTo && stretchTo();
    if (element) {
      const { xScrollHeight } = getTableScrollbarSizes(element);

      const observer = new ResizeObserver(
        ([
          {
            borderBoxSize: [{ blockSize }],
          },
        ]) => {
          const outer =
            blockSize -
            gutterTop * 4 -
            gutterBottom * 4 -
            headerH -
            filtersH -
            summaryH;
          return setScrollY({ outer, inner: outer - xScrollHeight });
        }
      );
      observer.observe(element);
      return () => observer.disconnect();
    }
    return noop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerH, filtersH, summaryH]);

  useLayoutEffect(() => {
    if (tableRef.current && stretchTo) {
      const resizeObserver = new ResizeObserver(
        ([
          {
            borderBoxSize: [{ blockSize }],
          },
        ]) => setHeaderH(blockSize)
      );
      const mutationObserver = new MutationObserver(() => {
        const header = tableRef.current?.querySelector('.ant-table-header');
        if (header) {
          mutationObserver.disconnect();
          resizeObserver.observe(header);
        }
      });
      mutationObserver.observe(tableRef.current, {
        subtree: true,
        childList: true,
      });
      return () => resizeObserver.disconnect();
    }
    return noop;
  }, [stretchTo, tableRef]);

  useLayoutEffect(() => {
    if (filtersRef.current && stretchTo) {
      const observer = new ResizeObserver(
        ([
          {
            borderBoxSize: [{ blockSize }],
          },
        ]) => setFiltersH(blockSize)
      );
      observer.observe(filtersRef.current);
      return () => observer.disconnect();
    }
    return noop;
  }, [filters, filtersRef, stretchTo]);

  return useMemo(
    () => ({
      scroll: { y: scrollY?.outer },
      scrollInner: { y: scrollY?.inner },
    }),
    [scrollY]
  );
};

export const useTableLayout = <T extends object>({
  data,
  loading,
  filters,
  columns,
  tableRef,
  stretchTo,
  filtersRef,
  gutter: initialGutter,
  scroll: initialScroll,
  empty: initialEmpty,
}: UseTableLayoutParams<T>) => {
  const { table } = useContext(UiKitLocalizationContext);

  const showSummary = useMemo(
    () => (data?.length ? getShowSummary(columns) : false),
    [columns, data]
  );

  const { scroll: stretchScroll, scrollInner } = useTableStretchLayout({
    gutter: initialGutter,
    tableRef,
    stretchTo,
    filtersRef,
    filters,
    showSummary,
  });

  const scroll: AntdTableProps<T>['scroll'] = useMemo(() => {
    if (!data?.length && !loading) {
      return {};
    }
    return merge({}, initialScroll, stretchScroll);
  }, [stretchScroll, initialScroll, data?.length, loading]);

  const gutter = useMemo(() => {
    if (!data?.length && !loading) {
      return [0, 0, 0, 0];
    }
    return initialGutter;
  }, [data?.length, loading, initialGutter]);

  const empty = useMemo(() => {
    return merge<{}, EmptyProps, Partial<EmptyProps>>(
      {},
      {
        size: 'lg',
        title: table.noData,
        background: 'transparent',
      },
      initialEmpty
    );
  }, [initialEmpty, table?.noData]);

  const background: UiKitColor =
    empty.background === 'gradient' && !data?.length && !loading
      ? 'transparent'
      : 'anthraciteGrey';

  return {
    scroll,
    gutter,
    empty,
    background,
    showSummary,
    scrollInner,
  };
};

export const useTableVirtualize = <T extends object>({
  virtual,
  scroll,
}: {
  virtual: boolean;
  scroll: AntdTableProps<T>['scroll'];
}) => {
  const [VT] = useVT(
    () => ({
      scroll: { y: scroll?.y || 0 },
      overscanRowCount: ADDITIONAL_ROWS_COUNT,
    }),
    [scroll]
  );
  const components = useMemo(
    () => (virtual && scroll?.y ? VT : null),
    [VT, virtual, scroll]
  );

  return { components };
};

export const useTableCheckbox = <T extends object>({
  rowKey,
  data,
}: UseTableCheckboxParams<T>): UseTableCheckboxReturnType<T> => {
  const name = useContextSelector<
    FormCheckboxSelectionContextProps,
    Key | Key[]
  >(FormCheckboxSelectionContext, ({ name }) => name);

  const onChange = useContextSelector<
    FormCheckboxSelectionContextProps,
    (v: boolean, rowKey: Key) => void
  >(FormCheckboxSelectionContext, ({ onChange }) => onChange);

  const onToggle = useContextSelector<
    FormCheckboxSelectionContextProps,
    (v: boolean, values: Key[]) => void
  >(FormCheckboxSelectionContext, ({ onToggle }) => onToggle);

  const selectedRowKeys = useContextSelector<
    FormCheckboxSelectionContextProps,
    Key[]
  >(FormCheckboxSelectionContext, ({ value }) => value);

  const columnTitle = useMemo(() => {
    const value = selectedRowKeys.length === data?.length;
    const indeterminate = selectedRowKeys.length
      ? selectedRowKeys.length !== data?.length
      : false;
    const values = data?.map((item) => {
      return typeof rowKey === 'string' ? get(item, rowKey) : rowKey(item);
    });
    return (
      <Checkbox
        value={value}
        onChange={(v) => onToggle(indeterminate ? false : v, values)}
        indeterminate={indeterminate}
      />
    );
  }, [selectedRowKeys, data, onToggle, rowKey]);

  const renderCell: AntdTableProps<T>['rowSelection']['renderCell'] =
    useCallback(
      (value: boolean, item: T) => {
        const key =
          typeof rowKey === 'string' ? get(item, rowKey) : rowKey(item);
        return <Checkbox value={value} onChange={(v) => onChange(v, key)} />;
      },
      [onChange, rowKey]
    );

  const rowSelection: AntdTableProps<T>['rowSelection'] = useMemo(() => {
    if (name) {
      return {
        renderCell,
        columnTitle,
        columnWidth: 40,
        selectedRowKeys,
        preserveSelectedRowKeys: true,
        fixed: 'left',
      };
    }
    return null;
  }, [name, selectedRowKeys, renderCell, columnTitle]);

  return [{ rowSelection }];
};

export const useTableSearch = <T extends object>({
  data: initialData,
}: UseTableSearchParams<T>): UseTableSearchReturnType<T> => {
  const [{ q, empty, dataIndex }, setSearchState] = useState<TableSearchState>({
    q: '',
    dataIndex: [],
    empty: {},
  });

  const data = useMemo(() => {
    if (q) {
      return initialData?.filter((item) => {
        return dataIndex.some((prop) => {
          const value: string = get(item, prop);
          return value
            ? value.toString().toLowerCase().includes(q.toLowerCase())
            : false;
        });
      });
    }
    return initialData;
  }, [q, initialData, dataIndex]);

  return [{ data, empty }, setSearchState];
};
