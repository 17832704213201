import { FC, PropsWithChildren } from 'react';
import { castArray, intersection, isEqual } from 'lodash';
import { useContext } from 'use-context-selector';
import { NavigationContext } from '../../contexts/Navigation.context';
import { Redirect } from '../Redirect/Redirect';
import { NULL } from './Protected.const';

type ProtectedProps = PropsWithChildren<{
  roles: string[];
  redirect?: boolean | string;
}>;

export const Protected: FC<ProtectedProps> = ({
  roles: allowed,
  children,
  redirect,
}) => {
  const { role, protectedFallback, loginUrl, defaultUrl } =
    useContext(NavigationContext);

  const roles = castArray(role);

  if (allowed.length) {
    if (isEqual(roles, NULL) && !isEqual(allowed, NULL)) {
      return <Redirect to={loginUrl} />;
    }
    if (!intersection(allowed, roles).length) {
      if (typeof redirect === 'boolean' && redirect) {
        return <Redirect to={defaultUrl} />;
      }
      if (typeof redirect === 'string') {
        return <Redirect to={redirect} />;
      }
      return <>{protectedFallback}</>;
    }
  }

  return <>{children}</>;
};
