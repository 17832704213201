import { ReactElement } from 'react';
import { FormItem, FormItemProps } from '../FormItem/FormItem';
import { FormNumberValidationRules } from '../FormItem/FormItem.types';
import { Icon } from '../../Icon/Icon';
import { Number } from './FormNumber.styles';

interface FormNumberProps extends FormItemProps<FormNumberValidationRules> {
  maxLength?: number;
}

interface FormNumberComponent {
  (props: FormNumberProps): ReactElement<any, any> | null;
}

export const FormNumber: FormNumberComponent = ({
  disabled,
  placeholder,
  maxLength,
  ...props
}) => {
  return (
    <FormItem hasFeedback type="number" {...props}>
      <Number
        disabled={disabled}
        placeholder={placeholder}
        controls={{
          upIcon: <Icon name="arrow-triangular-up" size="sm" />,
          downIcon: <Icon name="arrow-triangular-down" size="sm" />,
        }}
        maxLength={maxLength}
        min={props?.validation?.min}
        max={props?.validation?.max}
      />
    </FormItem>
  );
};
