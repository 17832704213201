import { FC, PropsWithChildren } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Link } from 'react-router-dom';
import {
  NavigationContext,
  NavigationContextProps,
} from '../../contexts/Navigation.context';

export const DefaultLink: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const defaultUrl = useContextSelector<NavigationContextProps, string>(
    NavigationContext,
    ({ defaultUrl }) => defaultUrl
  );

  return <Link to={defaultUrl}>{children}</Link>;
};
