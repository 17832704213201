module.exports = {
  above: 'Above',
  addOpportunities: 'Add opportunities',
  averageAdds: 'Average Adds',
  below: 'Below',
  biggestIncreasesDecreases: 'Biggest Increases/Decreases',
  chart: 'Chart',
  chartMonitor: 'Chart Monitor',
  competition: 'Competition',
  details: 'Details',
  high: 'High',
  historical: 'Historical',
  low: 'Low',
  members: 'Members',
  mostAddedCurrents: 'Most added currents',
  musicStack: 'Music stack',
  mySongs: 'My songs',
  overview: 'Overview',
  ownership: 'Ownership',
  panelCharts: 'Panel Charts',
  panels: 'Panels',
  playIncrease: 'Play increase',
  playlist: 'Playlist',
  possibleSpace: 'Possible space',
  rotationGrid: 'Rotation Grid',
  songComparison: 'Song comparison',
  songOverview: 'Song Overview',
  songs: 'Songs',
  stationAirplay: 'Station airplay',
  stationOwners: 'Station Owners',
  stations: 'Stations',
  teams: 'Teams',
};
