import {
  ButtonShape as AntdButtonShape,
  ButtonType as AntdButtonType,
  ButtonSize as AntdButtonSize,
} from 'antd/lib/button';
import { IconSize } from '../Icon/Icon.types';
import { UiKitBaseSize } from '../../types/uikit';
import { ButtonVariant } from './Button.types';

export const BUTTON_SIZE_MAP: Record<UiKitBaseSize, AntdButtonSize> = {
  lg: 'large',
  md: 'middle',
  sm: 'small',
  xs: 'small',
};

export const BUTTON_SHAPE_MAP: Record<ButtonVariant, AntdButtonShape> = {
  primary: 'round',
  secondary: 'round',
  tertiary: 'round',
  danger: 'round',
  link: 'default',
  text: 'default',
  muted: 'default',
};

export const BUTTON_TYPE_MAP: Record<ButtonVariant, AntdButtonType> = {
  primary: 'primary',
  secondary: 'default',
  tertiary: 'default',
  link: 'link',
  text: 'text',
  muted: 'text',
  danger: 'primary',
};

export const SPINNER_SIZE_MAP: Record<AntdButtonSize, number> = {
  large: 16,
  middle: 11,
  small: 8,
};

export const SPINNER_BORDER_WIDTH_MAP: Record<AntdButtonSize, number> = {
  large: 2,
  middle: 2,
  small: 1.5,
};

export const ICON_SIZE_MAP: Record<UiKitBaseSize, IconSize> = {
  lg: 'md',
  md: 'sm',
  sm: 'xs',
  xs: 'xs',
};

export const CHEVRON_WIDTH_MAP: Record<AntdButtonSize, number> = {
  large: 10,
  middle: 7,
  small: 7,
};

export const CHEVRON_HEIGHT_MAP: Record<AntdButtonSize, number> = {
  large: 5,
  middle: 3.5,
  small: 3.5,
};
