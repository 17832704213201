import { FC } from 'react';
import { Card } from '@spintel/uikit';

export const NotFound: FC = () => {
  return (
    <Card>
      <Card.Content>
        <p>Page not found</p>
      </Card.Content>
    </Card>
  );
};
