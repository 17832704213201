import styled, { css } from 'styled-components';

const RootStretchMixin = css`
  flex: 1 1 auto;
  height: 100%;
`;

export const Root = styled('div')<{ stretch: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { spacing } }) => spacing(4)};
  ${({ stretch }) => stretch && RootStretchMixin}
`;
