import { COLOR_SEQUENCE_GRADUAL } from '../../../constants/colors.const';
import { THEME } from '../../../constants/theme.const';
import { GraphDoughnutVariant } from './GraphDoughnut.types';

export const DEFAULT_DOUGHNUT_VARIANT_BACKGROUND_COLORS =
  COLOR_SEQUENCE_GRADUAL;
export const DEFAULT_DOUGHNUT_VARIANT_RADIUS = 120;
export const DEFAULT_DOUGHNUT_VARIANT_ANGLE_DEGREE_EXPANSION = 1;
export const DEFAULT_DOUGHNUT_VARIANT_RADIUS_LENGTH_EXPANSION = 4;

export const DEFAULT_DOUGHNUT_VARIANT: Required<GraphDoughnutVariant> = {
  backgroundColors: DEFAULT_DOUGHNUT_VARIANT_BACKGROUND_COLORS,
  radius: DEFAULT_DOUGHNUT_VARIANT_RADIUS,
  angleDegreeExpansion: DEFAULT_DOUGHNUT_VARIANT_ANGLE_DEGREE_EXPANSION,
  radiusLengthExpansion: DEFAULT_DOUGHNUT_VARIANT_RADIUS_LENGTH_EXPANSION,
};

export const TOP_LINE_COLOR = THEME.palette['white'];
export const BOTTOM_LINE_COLOR = THEME.palette['pearlGrey'];
