import { useCallback, useMemo, useState } from 'react';
import { Notification, TabPaneProps } from '@spintel/uikit';
import { useTranslation } from '@spintel/localization';
import { RoutePath } from '@common/constants';
import { generatePath } from '@spintel/navigation';
import { DTO, FileType, useDownload } from '@spintel/network/app';
import { useSong } from '@common/hooks';
import {
  OverviewRotationType,
  OverviewSongHistoryTab,
} from './Overview/Overview.types';
import {
  ROTATION_TYPE_MAP,
  SONG_HISTORY_TYPE_MAP,
} from './Overview/Overview.const';
import { SONG_FETCH_PAGE_STATUS_DEFAULT } from './Song.const';
import { SongFetchPageStatus } from './Song.context';

export const useTabs = () => {
  const { t } = useTranslation();
  const [{ song }] = useSong();

  const songId = song.songId.toString();

  const tabs: TabPaneProps[] = [
    {
      tab: t('navigation.overview'),
      tabKey: generatePath(RoutePath.SONG, { songId }),
    },
    {
      tab: t('navigation.stationAirplay'),
      tabKey: generatePath(RoutePath.SONG_STATION_AIRPLAY, { songId }),
    },
    {
      tab: t('navigation.ownership'),
      tabKey: generatePath(RoutePath.SONG_OWNERSHIP, { songId }),
    },
    {
      tab: t('navigation.addOpportunities'),
      tabKey: generatePath(RoutePath.SONG_ADD_OPPORTUNITIES, { songId }),
    },
    {
      tab: t('navigation.playIncrease'),
      tabKey: generatePath(RoutePath.SONG_PLAY_INCREASE, { songId }),
    },
    {
      tab: t('navigation.songComparison'),
      tabKey: generatePath(RoutePath.SONG_SONG_COMPARISON, { songId }),
    },
    {
      tab: t('navigation.competition'),
      tabKey: generatePath(RoutePath.SONG_COMPETITION, { songId }),
    },
  ];

  return { tabs };
};

interface ApiParams {
  rotationType?: OverviewRotationType;
  songHistoryTabType?: OverviewSongHistoryTab;
  dateTo?: string;
}

type UseExportReturnType = [{ exporting: boolean }, () => void];
type ApiCallback = Parameters<typeof useDownload>[0];

type UseExportFunction = (
  callback: ApiCallback,
  params?: ApiParams
) => UseExportReturnType;

type UseDownloadApiParams = DTO.IRotationExportDetailParams | number;

export const useExport: UseExportFunction = (callback, params) => {
  const { t } = useTranslation();

  const [
    {
      song: { songId },
    },
  ] = useSong();

  const onSuccessExport = ({ filename }: { filename: string }) => {
    Notification.show({
      message: t('notifications.fileSuccessfullyExported', { filename }),
    });
  };

  const [{ loading: exporting }, exportCall] = useDownload<
    | [UseDownloadApiParams, any]
    | [UseDownloadApiParams, DTO.IExportSongHistoryGridRequestDto, any]
  >(callback, {
    type: FileType.CSV,
    onSuccess: onSuccessExport,
  });

  return useMemo(
    (): [{ exporting: boolean }, () => void] => [
      { exporting },
      () => {
        if (params?.rotationType) {
          // rotation export
          exportCall([
            { songId, type: ROTATION_TYPE_MAP[params.rotationType] },
            { format: 'blob' },
          ]);
        } else if (params?.songHistoryTabType && params?.dateTo) {
          // song history export
          exportCall([
            songId,
            {
              songHistoryTabType:
                SONG_HISTORY_TYPE_MAP[params.songHistoryTabType],
              dateTo: params.dateTo,
            },
            { format: 'blob' },
          ]);
        } else {
          // simple export
          exportCall([songId, { format: 'blob' }]);
        }
      },
    ],
    [
      exporting,
      params?.rotationType,
      params?.songHistoryTabType,
      params?.dateTo,
      songId,
      exportCall,
    ]
  );
};

export const useFetchPageStatus = () => {
  const [status, _updateStatus] = useState<SongFetchPageStatus>(
    SONG_FETCH_PAGE_STATUS_DEFAULT
  );

  const updateStatus = useCallback(
    (newStatus: Partial<SongFetchPageStatus>) =>
      _updateStatus({ ...status, ...newStatus }),
    [status]
  );

  return { status, updateStatus };
};
