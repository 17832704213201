import { FC } from 'react';
import { Button, ButtonProps } from '../../Button/Button';

export const FormSubmit: FC<ButtonProps> = ({
  children,
  type: _type,
  onClick: _click,
  ...props
}) => {
  return (
    <Button type="submit" {...props}>
      {children}
    </Button>
  );
};
