import { FC, PropsWithChildren } from 'react';
import { useHttpClient } from '@spintel/network/app';
import { AuthProvider, HttpClient } from '@spintel/network/auth';
import { Splash } from '@spintel/uikit';
import { AuthRoutePath } from '@spintel/shared';

type AuthBootstrapProps = PropsWithChildren<Partial<Window['APP_CONFIG']>>;

export const AuthBootstrap: FC<AuthBootstrapProps> = ({
  children,
  apiHost,
  apiBase,
}) => {
  const { httpClient } = useHttpClient<HttpClient>();

  return (
    <AuthProvider
      host={apiHost}
      base={apiBase}
      fallback={<Splash />}
      httpClient={httpClient}
      logoutUrl={AuthRoutePath.LOGOUT}
    >
      {children}
    </AuthProvider>
  );
};
