import { Key } from 'react';
import { noop } from 'lodash';
import { createContext } from 'use-context-selector';

export interface SwitchContextProps {
  set: Set<Key>;
  condition: Key;
  add: (v: Key) => void;
}

export const SwitchContext = createContext<SwitchContextProps>({
  add: noop,
  set: new Set(),
  condition: null,
});
