import { createContext } from 'react';
import { Locale } from '../types/locale.types';

export interface LocalizationContextProps {
  locale: Locale;
}

export const LocalizationContext = createContext<LocalizationContextProps>({
  locale: null,
});
