import { useCallback, useMemo, useContext } from 'react';
import { get } from 'lodash';
import { DrawersContext } from '../../contexts/Drawer.context';

type UseDrawerToggle<T> = (isOpen: boolean, params?: T) => void;

type UseDrawerReturnType<T> = [
  { isOpen: boolean; params: T },
  UseDrawerToggle<T>
];

type UseDrawersReturnType = {
  closeAll: () => void;
};

export const useDrawer = <T = unknown>(
  name: string
): UseDrawerReturnType<T> => {
  const [drawers, setDrawers] = useContext(DrawersContext);

  const isOpen = get(drawers, [name, 'isOpen'], false);

  const params = useMemo(
    () => get(drawers, [name, 'params']),
    [drawers, name]
  ) as T;

  const toggle = useCallback(
    (isOpen: boolean, params?: T): void => {
      setDrawers({ ...drawers, [name]: { isOpen, params } });
    },
    [name, setDrawers, drawers]
  );

  return [{ isOpen, params }, toggle];
};

export const useDrawers = (): UseDrawersReturnType => {
  const [_, setDrawers] = useContext(DrawersContext);

  const closeAll = useCallback(() => {
    setDrawers((prev) => {
      return Object.fromEntries(
        Object.entries(prev).map(([name, drawer]) => [
          name,
          {
            ...drawer,
            isOpen: false,
          },
        ])
      );
    });
  }, [setDrawers]);

  return { closeAll };
};
