import styled, { css } from 'styled-components';
import Input, { InputProps } from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Checkbox from 'antd/lib/checkbox';
import Radio from 'antd/lib/radio';
import { ActionHoverMixin } from '../Form/FormInputAction/FormInputAction.style';
import {
  RootCheckboxComponent,
  RootRadioComponent,
  RootInputComponent,
  RootTextareaComponent,
} from './Input.types';

export const CheckboxAndRadioInput = (
  type: 'checkbox' | 'radio' | 'cascader-checkbox'
) => {
  return css`
    .ant-${type} {
      top: 4px;
      &.ant-${type}-indeterminate {
        .ant-${type}-inner {
          &:after {
            width: 10px;
            height: 2px;
            background-color: ${({ theme }) => theme.palette.white};
          }
        }
      }
      .ant-${type}-inner {
        box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.wolfGrey};
        background-color: ${({ theme }) => theme.palette.metropolisGrey};
        margin: 0px 1px;
      }
      + span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
      &.ant-${type}-disabled {
        .ant-${type}-inner {
          background-color: ${({ theme }) => theme.palette.metropolisGrey};
          box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.wolfGrey}40;
        }
        &.ant-${type}-indeterminate {
          .ant-${type}-inner {
            box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.white}40;
            &:after {
              background-color: ${({ theme }) => theme.palette.white}40;
            }
          }
        }
        &.ant-${type}-checked {
          .ant-${type}-inner {
            box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.white}40;
          }
          &:after {
            border-color: ${({ theme }) => theme.palette.transparent};
          }
        }
      }
      &:after {
        display: none;
      }
      &.ant-${type}-checked {
        .ant-${type}-inner {
          box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.white};
        }
        + span {
          text-shadow: 0.7px 0 0 currentColor;
        }
      }
    }
    &.ant-${type}-disabled {
      .ant-${type}-inner {
        box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.wolfGrey};
      }
      + span {
        color: ${({ theme }) => theme.palette.white}40;
      }
    }
    &.ant-${type}-wrapper {
      &:not(.ant-${type}-wrapper-disabled) {
        &:hover {
          .ant-${type} {
            &:not(.ant-radio-checked) {
              .ant-${type}-inner {
                box-shadow: 0px 0px 0px 1px
                  ${({ theme }) => theme.palette.guacamoleGreen};
              }
            }
          }
        }
      }
    }
  `;
};

const TextInput = css<InputProps>`
  &.ant-input-number-affix-wrapper {
    width: 100%;
  }
  &.ant-input-affix-wrapper,
  &.ant-input-number-affix-wrapper {
    border-radius: ${({ theme: { spacing } }) => spacing(1)};
    &.ant-input-affix-wrapper-status-error,
    &.ant-input-number-affix-wrapper-status-error {
      border-color: ${({ theme }) => theme.palette.feverRed};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.feverRed} !important;
    }
    &.ant-input-affix-wrapper-focused,
    &.ant-input-number-affix-wrapper-focused {
      &:not(.ant-input-affix-wrapper-status-error) {
        &:not(.ant-input-number-affix-wrapper-status-error) {
          box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.guacamoleGreen};
          border-color: ${({ theme }) => theme.palette.guacamoleGreen};
        }
      }
    }

    &.ant-input-affix-wrapper-lg,
    &.ant-input-number-affix-wrapper-lg {
      &:before {
        height: ${({ theme: { spacing } }) => spacing(6)};
      }
      .ant-input-suffix,
      .ant-input-number-suffix {
        margin-right: -6px;
      }
      .ant-input-number-suffix {
        position: static;
        height: auto;
      }
    }
    &:before {
      height: ${({ theme: { spacing } }) => spacing(4)};
    }
    .ant-input,
    .ant-input-number {
      caret-color: ${({ theme }) => theme.palette.guacamoleGreen};
      font-size: 12px;
      line-height: 16px;
      &.ant-input-lg,
      &.ant-input-number-lg {
        font-size: 14px;
        line-height: 18px;
      }
      &::placeholder {
        opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
      }
      &:-webkit-autofill,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0px 50px ${({ theme }) => theme.palette.metropolisGrey}
          inset !important;
        -webkit-text-fill-color: ${({ theme }) => theme.palette.white};
      }
    }
    .ant-input-suffix,
    .ant-input-number-suffix {
      display: flex;
      justify-content: center;
      margin-left: ${({ theme: { spacing } }) => spacing(2)};
      margin-right: -2px;
      & > {
        * {
          margin-right: 0;
        }
        * + * {
          margin-left: ${({ theme: { spacing } }) => spacing(2)};
        }
      }
      .ant-form-item-feedback-icon {
        display: flex;
        align-items: center;
        &.ant-form-item-feedback-icon-success {
          display: none;
        }
        &.ant-form-item-feedback-icon-error {
          > span {
            width: ${({ theme: { spacing } }) => spacing(5)};
            height: ${({ theme: { spacing } }) => spacing(5)};
            border-radius: ${({ theme: { spacing } }) => spacing(2.5)};
            background-color: ${({ theme }) => theme.palette.feverRed};
            display: flex;
            align-items: center;
            justify-content: center;
            &:after {
              content: '!';
              color: ${({ theme }) => theme.palette.metropolisGrey};
              font-weight: bold;
            }
            > svg {
              display: none;
            }
          }
        }
      }
    }
  }
  &.ant-input-number-lg {
    input {
      height: ${({ theme: { spacing } }) => spacing(6)};
    }
  }
`;

export const RootCheckbox = styled<RootCheckboxComponent>(Checkbox)`
  ${CheckboxAndRadioInput('checkbox')}
`;

export const RootRadio = styled<RootRadioComponent>(Radio)`
  ${CheckboxAndRadioInput('radio')}
`;

export const RootInput = styled<RootInputComponent>(Input)`
  ${TextInput}
`;

export const RootNumber = styled(InputNumber)`
  ${TextInput}
`;

export const RootPassword = styled<RootInputComponent>(Input.Password)`
  ${TextInput}
  &.ant-input-affix-wrapper {
    .ant-input-suffix {
      .ant-input-password-icon {
        svg {
          width: 18px;
          height: 18px;
          color: ${({ theme }) => theme.palette.londonGrey};
          opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
          cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
          ${({ disabled }) => !disabled && ActionHoverMixin}
        }
      }
    }
  }
`;

export const RootTextarea = styled<RootTextareaComponent>(Input.TextArea)`
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  caret-color: ${({ theme }) => theme.palette.guacamoleGreen};
  resize: none;
  &::placeholder {
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  }
  &.ant-input-status-error {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.feverRed};
    &:focus {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.feverRed} !important;
    }
  }
  &:focus {
    border-color: ${({ theme }) => theme.palette.guacamoleGreen};
    box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.guacamoleGreen};
  }
`;
