import { FC, PropsWithChildren } from 'react';
import { Root } from './DrawerContent.style';
import { DEFAULT_CONTENT_GUTTER } from './DrawerContent.const';

type DrawerContentProps = PropsWithChildren<{
  gutter?: number[];
  className?: string;
}>;

export const DrawerContent: FC<DrawerContentProps> = ({
  className,
  children = null,
  gutter = DEFAULT_CONTENT_GUTTER,
}) => {
  return (
    <Root className={className} gutter={gutter}>
      {children}
    </Root>
  );
};
