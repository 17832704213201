import styled, { createGlobalStyle } from 'styled-components';
import Modal from 'antd/lib/modal';
import { Typography } from '../Typography/Typography';

export const Root = styled(Modal)`
  border-radius: 0;
  top: ${({ theme: { spacing } }) => spacing(30)};
  .ant-modal-header {
    padding-right: ${({ theme: { spacing } }) => spacing(17)};
  }
  .ant-modal-close {
    color: ${({ theme }) => theme.palette.white};
    right: ${({ theme: { spacing } }) => spacing(5)};
    top: ${({ theme: { spacing } }) => spacing(6)};
    .ant-modal-close-x {
      line-height: ${({ theme: { spacing } }) => spacing(7)};
    }
  }
`;

export const ConfirmTitle = styled(Typography.Heading4)`
  padding: 24px;
`;

export const Global = createGlobalStyle`
  .ant-modal-confirm {
    top: ${({ theme: { spacing } }) => spacing(30)};
    .ant-modal-content {
      border-radius: 0;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-confirm-body {
      .ant-modal-confirm-content {
        margin: 0;
        padding-right: ${({ theme: { spacing } }) => spacing(6)};
        padding-left: ${({ theme: { spacing } }) => spacing(6)};
      }
    }
    .ant-modal-confirm-btns {
      margin-top: 0;
      padding: ${({ theme: { spacing } }) => spacing(6)};

      .ant-btn + .ant-btn {
        margin-left: ${({ theme: { spacing } }) => spacing(3)};
      }
    }

    .ant-btn-ghost {
      &:hover {
        color: ${({ theme }) => theme.palette.white};
        border-color: ${({ theme }) => theme.palette.white};
      }
      &:active {
        color: ${({ theme }) => theme.palette.white};
        border-color: ${({ theme }) => theme.palette.white};
        > * {
          opacity: 0.4;
        }
      }
      &:focus {
        color: ${({ theme }) => theme.palette.white};
        border-color: ${({ theme }) => theme.palette.wolfGrey};
      }
      &[disabled],
      &[disabled]:hover {
        color: ${({ theme }) => theme.palette.white};
        border-color: ${({ theme }) => theme.palette.wolfGrey};
        opacity: 0.4;
      }
    }

    .ant-btn-dangerous {
      color: ${({ theme }) => theme.palette.metropolisGrey};
      background: ${({ theme }) => theme.palette.feverRed};

      &:hover,
      &:active,
      &:focus {
        color: ${({ theme }) => theme.palette.black};
        background: ${({ theme }) => theme.palette.vividRed};
        border-color: ${({ theme }) => theme.palette.vividRed};
      }
      &[disabled],
      &[disabled]:hover {
        border-color: ${({ theme }) => theme.palette.feverRed};
        background: ${({ theme }) => theme.palette.feverRed};
      }
    }
  }
`;
