import { DTO } from '@spintel/network/auth';
import { Icon } from '@spintel/uikit';
import { LinkExternal, UrlType } from '@spintel/navigation';
import { stationContacts } from './StationContacts.const';
import { StationDetailsContactsItem } from './StationContacts.types';

export const getLink = (url: string, type: UrlType) => {
  if (type) {
    return (
      <LinkExternal href={url} type={type}>
        {url}
      </LinkExternal>
    );
  }

  return url;
};

const transformStationContact = ({
  type,
  value,
}: DTO.IStationContactModel | DTO.ISocialModel): StationDetailsContactsItem => {
  const item = stationContacts.find(({ key }) => key === type);

  if (!item || !value) return;

  return {
    icon: <Icon name={item.icon} size="sm" />,
    label: item.label,
    value: item.urlType ? (
      <LinkExternal href={value} type={item.urlType}>
        {value}
      </LinkExternal>
    ) : (
      value
    ),
  };
};

export const getStationContacts = (
  arr: DTO.IStationContactModel[] | DTO.ISocialModel[]
): StationDetailsContactsItem[] =>
  arr.map(transformStationContact).filter((el) => el);

const ORDER = [
  DTO.ISocialTypeValue.Instagram,
  DTO.ISocialTypeValue.Facebook,
  DTO.ISocialTypeValue.Twitter,
  DTO.ISocialTypeValue.Youtube,
];

export const sortSocials = (a: DTO.ISocialModel, b: DTO.ISocialModel) =>
  ORDER.indexOf(a.type) - ORDER.indexOf(b.type);
