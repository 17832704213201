import { UrlType } from './LinkExternal.types';

export function transformLink(
  url: string,
  type: UrlType = UrlType.External
): string {
  return TransformByUrlType[type](url);
}

const TransformByUrlType: Record<UrlType, typeof transformLink> = {
  [UrlType.External]: transformLinkToExternal,
  [UrlType.Email]: transformLinkToMail,
  [UrlType.Phone]: transformLinkToTel,
};

function transformLinkToExternal(link: string) {
  const re = /^(http|https):\/\//i;
  return re.test(link) ? link : `//${link}`;
}

function transformLinkToMail(link: string) {
  const re = /^mailto:/i;
  return re.test(link) ? link : `mailto:${link}`;
}

function transformLinkToTel(link: string) {
  const re = /^tel:/i;
  return re.test(link) ? link : `tel:${link}`;
}
