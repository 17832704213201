import styled from 'styled-components';
import { Root as RootDatePicker } from '../DatePicker/DatePicker.style';

export const Root = styled('div')`
  position: relative;
  .ant-picker-week-panel-row-selected td {
    background: none;
  }
  .ant-picker-dropdown {
    top: 0;
    left: 0;
    opacity: 1 !important;
    position: static !important;

    .ant-picker-year-btn,
    .ant-picker-month-btn,
    .ant-picker-decade-btn {
      color: ${({ theme }) => theme.palette.white};

      &:hover {
        color: ${({ theme }) => theme.palette.stardustGrey};
      }
    }

    .ant-picker-decade-btn {
      pointer-events: none;
    }

    .ant-picker-year-panel,
    .ant-picker-month-panel {
      .ant-picker-cell-inner {
        height: ${({ theme }) => theme.spacing(8)};
        line-height: ${({ theme }) => theme.spacing(8)};
      }
    }

    .ant-picker-decade-panel {
      .ant-picker-header-view {
        font-weight: 600;
        font-size: 12px;
      }
      .ant-picker-cell {
        .ant-picker-cell-inner {
          font-size: 10px;
        }
      }
    }
    .ant-picker-year-panel,
    .ant-picker-month-panel {
      .ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background: ${({ theme }) => theme.palette.spintelGreen};
        }
      }
    }

    .ant-picker-year-panel,
    .ant-picker-month-panel,
    .ant-picker-decade-panel {
      .ant-picker-cell-inner {
        font-size: 12px;
        font-weight: 600;
        border-radius: 16px;
      }
      .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) {
        .ant-picker-cell-inner {
          background: none !important;
          color: ${({ theme }) => theme.palette.stardustGrey};
        }
      }
    }
    .ant-picker-week-panel-row {
      .ant-picker-cell-disabled {
        &:before {
          display: none;
        }
      }

      &:hover,
      &.ant-picker-week-panel-row-selected {
        .ant-picker-cell-disabled {
          background: none;

          & + .ant-picker-cell:not(.ant-picker-cell-disabled) {
            border-top-left-radius: ${({ theme }) => theme.spacing(5)};
            border-bottom-left-radius: ${({ theme }) => theme.spacing(5)};

            &:last-child {
              &:before {
                border-top-left-radius: ${({ theme }) => theme.spacing(5)};
                border-bottom-left-radius: ${({ theme }) => theme.spacing(5)};
              }
            }
          }
        }
        .ant-picker-cell-max {
          border-top-right-radius: ${({ theme }) => theme.spacing(5)};
          border-bottom-right-radius: ${({ theme }) => theme.spacing(5)};
          &:nth-child(2) {
            &:before {
              border-top-right-radius: ${({ theme }) => theme.spacing(5)};
              border-bottom-right-radius: ${({ theme }) => theme.spacing(5)};
            }
          }
        }
      }
    }
  }
`;

export const DatePicker = styled(RootDatePicker)`
  &.ant-picker {
    display: none;
    + div {
      position: static !important;
      .ant-picker-panel-container {
        background: ${({ theme }) => theme.palette.transparent};
        box-shadow: none;
      }
    }
  }
`;
