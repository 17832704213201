import { useContextSelector } from 'use-context-selector';
import {
  CommonHttpContext,
  CommonHttpContextProps,
} from '../../contexts/CommonHttp.context';

export const useHttpClient = <T>() => {
  const httpClient = useContextSelector<
    CommonHttpContextProps,
    CommonHttpContextProps['httpClient']
  >(CommonHttpContext, ({ httpClient }) => httpClient);

  return { httpClient: httpClient as T };
};
