import { useCallback, useMemo, useContext } from 'react';
import { get } from 'lodash';
import { ModalsContext } from '../../contexts/Modals.context';

type UseModalToggle<T> = (isOpen: boolean, params?: T) => void;

type UseModalReturnType<T> = [
  { isOpen: boolean; params: T },
  UseModalToggle<T>
];

export const useModal = <T = unknown>(name: string): UseModalReturnType<T> => {
  const [modals, setModals] = useContext(ModalsContext);

  const isOpen = get(modals, [name, 'isOpen'], false);

  const params = useMemo(
    () => get(modals, [name, 'params']),
    [modals, name]
  ) as T;

  const toggle = useCallback(
    (isOpen: boolean, params?: T): void => {
      setModals({ ...modals, [name]: { isOpen, params } });
    },
    [name, setModals, modals]
  );

  return [{ isOpen, params }, toggle];
};
