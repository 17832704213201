import { UserRole } from '@common/constants';

export const getPermissionChangeAdminRole = ({
  roles,
  changedRole,
}: {
  roles: UserRole[];
  changedRole: UserRole;
}): UserRole[] => {
  return roles.filter((r) =>
    changedRole !== UserRole.SuperAdmin ? true : r === UserRole.SuperAdmin
  );
};
