import { FC, PropsWithChildren, useMemo, useState } from 'react';
import { merge } from 'lodash';
import { ThemeProvider } from 'styled-components';
import { Global as GlobalNotification } from '../Notification/NotificationShow/NotificationShow.style';
import { Global as GlobalPagination } from '../Pagination/Pagination.style';
import { Global as GlobalModalConfirm } from '../Modal/Modal.style';
import { Global as GlobalSelect } from '../Select/Select.style';
import { Global as GlobalCascader } from '../Cascader/Cascader.style';
import { Global as GlobalUiKit } from '../../styles/Global.style';
import { Global as GlobalTooltip } from '../../components/Tooltip/Tooltip.style';
import { Global as DropdownGlobal } from '../Dropdown/Dropdown.style';
import { Global as MenuGlobal } from '../Menu/Menu.style';
import { Drawers, DrawersContext } from '../../contexts/Drawer.context';
import { ModalsContext, Modals } from '../../contexts/Modals.context';
import {
  UiKitProviderContext,
  UiKitProviderContextProps,
} from '../../contexts/UiKitProvider.context';
import { THEME } from '../../constants/theme.const';
import './UiKitProvider.less';
import './UiKitProvider.module.css';

type UiKitProviderProps = PropsWithChildren<UiKitProviderContextProps>;

export const UiKitProvider: FC<UiKitProviderProps> = ({
  children,
  ...props
}) => {
  const [drawers, setDrawers] = useState<Drawers>({});
  const [modals, setModals] = useState<Modals>({});

  const context = useMemo(() => {
    return merge(
      {},
      {
        table: {
          cell: {
            formatter: (v: unknown) => v,
          },
        },
      },
      props
    );
  }, [props]);

  return (
    <ThemeProvider theme={THEME}>
      <UiKitProviderContext.Provider value={context}>
        <GlobalNotification />
        <GlobalPagination />
        <GlobalModalConfirm />
        <GlobalSelect />
        <GlobalCascader />
        <GlobalUiKit />
        <GlobalTooltip />
        <DropdownGlobal />
        <MenuGlobal />
        <DrawersContext.Provider value={[drawers, setDrawers]}>
          <ModalsContext.Provider value={[modals, setModals]}>
            {children}
          </ModalsContext.Provider>
        </DrawersContext.Provider>
      </UiKitProviderContext.Provider>
    </ThemeProvider>
  );
};
