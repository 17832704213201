import styled from 'styled-components';

export const Root = styled.div<{ visible: boolean; x: number; y: number }>`
  position: absolute;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  pointer-events: none;
  height: 0;
  align-items: center;
`;
