import { UiKitColor } from '../types/uikit';

export const COLOR_SEQUENCE_MIXED: UiKitColor[] = [
  'spintelGreen',
  'supernovaPink',
  'turquoise',
  'genesisBlue',
  'canariasYellow',
  'mintGreen',
  'tangerine',
  'destinyViolet',
  'freedomBlue',
  'feverRed',
];

export const COLOR_SEQUENCE_GRADUAL: UiKitColor[] = [
  'twisterBlue',
  'electricBlue',
  'freedomBlue',
  'turquoise',
  'mintGreen',
  'shamrockGreen',
  'guacamoleGreen',
  'spintelGreen',
  'sundance',
  'canariasYellow',
  'tangerine',
  'lavaOrange',
  'feverRed',
  'supernovaPink',
  'madridRed',
  'destinyViolet',
  'venusViolet',
  'genesisBlue',
];
