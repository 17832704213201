import { useState, useImperativeHandle, Key } from 'react';
import { useContextSelector } from 'use-context-selector';
import { GraphContext, GraphContextProps } from '../../Graph.context';
import { GraphLegendItem } from '../../Graph.types';
import { GraphLegendListProps } from './GraphLegendList';

export type UseHoverReturnType = {
  inactive: (index: number) => boolean;
  handleMouseEnterFactory: (index: number) => () => void;
  handleMouseLeave: () => void;
};

export const useHover = (): UseHoverReturnType => {
  const [hovered, setHovered] = useState<number>(-1);

  const changeHovered = (index: number = -1) => {
    setHovered(index);
  };

  const highlight = (index: number) => changeHovered(index);
  const reset = () => changeHovered();

  const inactive = (index: number) => {
    return hovered !== -1 && hovered !== index;
  };

  const chartActionsRef = useContextSelector<
    GraphContextProps,
    GraphContextProps['chartActions']
  >(GraphContext, ({ chartActions }) => chartActions);

  const legendActionsRef = useContextSelector<
    GraphContextProps,
    GraphContextProps['legendActions']
  >(GraphContext, ({ legendActions }) => legendActions);

  useImperativeHandle(legendActionsRef, () => ({
    highlight,
    reset,
  }));

  const handleMouseEnterFactory = (index: number) => () => {
    chartActionsRef?.current?.highlight(index);
    highlight(index);
  };

  const handleMouseLeave = () => {
    chartActionsRef?.current?.reset();
    reset();
  };

  return { inactive, handleMouseEnterFactory, handleMouseLeave };
};

export interface UseLegendParams {
  id: Key;
  label: GraphLegendListProps['label'];
}

export type UseLegendReturnType = Omit<GraphLegendItem, 'value'>[];

export const useLegend = ({
  id,
  label,
}: UseLegendParams): UseLegendReturnType => {
  const legend = useContextSelector<GraphContextProps, GraphLegendItem[]>(
    GraphContext,
    ({ legend }) => legend[id] || []
  );

  return legend.map((item, index) => ({
    ...item,
    label: label({ item, index }),
  }));
};
