import { Key } from 'react';
import classNames from 'classnames';
import { merge, castArray } from 'lodash';
import { SelectProps } from 'antd/lib/select';
import { Icon } from '../Icon/Icon';
import { Status } from '../../styles/Status.style';
import { Typography } from '../Typography/Typography';
import { Label, Hint, Option, Check } from './Select.style';
import { SelectOptions } from './Select.types';

const { Subtitle1 } = Typography;

interface GetOptionsParams {
  multiple?: boolean;
  options: SelectOptions;
  value?: unknown | unknown[];
}

export const getOptions = ({
  value: values,
  options,
  multiple = false,
}: GetOptionsParams): SelectProps['options'] => {
  return options?.map(
    ({ options, value, label, icon, hint, status, ...rest }) => {
      return merge(
        {
          ...rest,
          value: value as Key,
          className: classNames('spntl-select-item-option', {
            'ant-select-item-option-multiple': multiple,
          }),
          label: !options ? (
            <Option>
              {multiple && <Check value={castArray(values).includes(value)} />}
              {status && <Status status={status} size="sm" opacity={0.4} />}
              {icon && <Icon name={icon} size="sm" />}
              <div style={{ maxWidth: '100%' }}>
                <Label>{label}</Label>
                {hint && <Hint>{hint}</Hint>}
              </div>
            </Option>
          ) : (
            label && <Subtitle1>{label}</Subtitle1>
          ),
        },
        options && { options: getOptions({ options, multiple, value: values }) }
      );
    }
  );
};
