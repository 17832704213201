import { FC } from 'react';
import { Dropdown, Button } from '@spintel/uikit';
import { useSong } from '../../../../hooks/useSong/useSong.hook';
import { Root, Overlay } from './SongSelector.style';
import { SongSelectorLabel } from './SongSelectorLabel/SongSelectorLabel';
import { SongSelectorOverlay } from './SongSelectorOverlay/SongSelectorOverlay';

export const SongSelector: FC = () => {
  const [{ song, loading }] = useSong();

  return (
    <Root>
      <Dropdown overlay={{ width: 400, position: 'fixed' }}>
        <Dropdown.Toggle loading={loading}>
          <Button size="lg">
            <SongSelectorLabel song={song} />
          </Button>
        </Dropdown.Toggle>
        <Overlay>
          <SongSelectorOverlay />
        </Overlay>
      </Dropdown>
    </Root>
  );
};
