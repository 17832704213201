import { Key, useRef } from 'react';
import { difference, intersection } from 'lodash';
import { usePrevious } from 'react-use';
import {
  COLOR_SEQUENCE_GRADUAL,
  COLOR_SEQUENCE_MIXED,
} from '../../constants/colors.const';
import { UiKitColor } from '../../types/uikit';

type ColorsMap = Record<Key, UiKitColor>;

const MAP: Record<'MIXED' | 'GRADUAL', UiKitColor[]> = {
  MIXED: COLOR_SEQUENCE_MIXED,
  GRADUAL: COLOR_SEQUENCE_GRADUAL,
};

interface UseMixedColorMapParams<T extends object> {
  data: T[];
  key: keyof T;
  sequence: keyof typeof MAP;
}

export const useColorSequenceMap = <T extends object>({
  data,
  key,
  sequence,
}: UseMixedColorMapParams<T>) => {
  const map = useRef<ColorsMap>({});
  const availableColors = useRef<UiKitColor[]>(MAP[sequence]);
  const currentKeys = data.map((item) => `${item[key]}`);
  const previousKeys = usePrevious<Key[]>(currentKeys) || [];

  const change = intersection(previousKeys, currentKeys);
  const removedKeys = difference(previousKeys, change);
  const addedKeys = difference(currentKeys, change);

  if (removedKeys.length) {
    const removedColors = Object.entries(map.current)
      .filter(([key]) => removedKeys.includes(key))
      .map(([, color]) => color);

    availableColors.current = [...availableColors.current, ...removedColors];

    map.current = Object.keys(map.current).reduce((res, key) => {
      if (removedKeys.includes(key)) return res;
      return { ...res, [key]: map.current[key] };
    }, {});
  }

  if (addedKeys.length) {
    map.current = addedKeys.reduce((res, key, index) => {
      return { ...res, [key]: availableColors.current[index] };
    }, map.current);

    const used = Object.values(map.current);

    availableColors.current = availableColors.current.filter(
      (color) => !used.includes(color)
    );
  }

  return { colors: map.current };
};
