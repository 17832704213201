import styled, { css } from 'styled-components';

const DEFAULT_GUTTER = [6, 6, 6, 6];

const RootStretchMixin = css`
  flex: 1 1 auto;
  height: 100%;
`;

export const Root = styled('div')<{ gutter?: number[]; stretch: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.anthraciteGrey};
  padding-top: ${({ gutter: [top] = DEFAULT_GUTTER, theme: { spacing } }) =>
    spacing(top)};
  padding-right: ${({
    gutter: [, right] = DEFAULT_GUTTER,
    theme: { spacing },
  }) => spacing(right)};
  padding-bottom: ${({
    gutter: [, , bottom] = DEFAULT_GUTTER,
    theme: { spacing },
  }) => spacing(bottom)};
  padding-left: ${({
    gutter: [, , , left] = DEFAULT_GUTTER,
    theme: { spacing },
  }) => spacing(left)};
  ${({ stretch }) => stretch && RootStretchMixin}
`;
