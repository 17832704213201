export enum UserRole {
  AccountManager = 'Account manager',
  TeamManager = 'Team manager',
  Member = 'Member',
  SuperAdmin = 'Super admin',
}

export const USER_ROLES: UserRole[] = [
  UserRole.AccountManager,
  UserRole.Member,
  UserRole.TeamManager,
  UserRole.SuperAdmin,
];
