import styled, { css, createGlobalStyle } from 'styled-components';
import AntdSelect from 'antd/lib/select';
import { Icon } from '../Icon/Icon';
import { rotateAnimation } from '../../styles/Rotate.style';
import { THEME } from '../../constants/theme.const';
import { Status } from '../../styles/Status.style';
import { Typography } from '../Typography/Typography';
import { Checkbox } from '../Checkbox/Checkbox';
import { SelectVariant } from './Select.types';

interface RootProps {
  variant: SelectVariant;
  withStatus: boolean;
}

const { Body3, Body4 } = Typography;

export const Check = styled(Checkbox)`
  width: ${({ theme: { spacing } }) => spacing(5)};
  .ant-checkbox {
    top: 1px;
  }
`;

export const Label = styled(Body3)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Hint = styled(Body4)`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.pearlGrey};
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RootDefaultMixin = css``;

const RootRoundMixin = css`
  &.ant-select {
    .ant-select-selector {
      border-radius: ${({ theme: { spacing } }) => spacing(4)};
    }
    &.ant-select-sm {
      .ant-select-selector {
        border-radius: ${({ theme: { spacing } }) => spacing(3)};
      }
    }
    &.ant-select-lg {
      .ant-select-selector {
        border-radius: ${({ theme: { spacing } }) => spacing(5)};
      }
    }
  }
`;

const RootInlineMixin = css`
  &.ant-select {
    .ant-select-selector {
      color: ${({ theme: { palette } }) => palette.white};
      padding-left: 0 !important;
      border: none;

      .ant-select-selection-placeholder {
        color: ${({ theme: { palette } }) => palette.white};
        font-size: 12px;
        padding-top: 1px;
        font-weight: 600;
      }
    }

    &:hover {
      &:not(.ant-select-disabled) {
        .ant-select-selection-item,
        .ant-select-selection-placeholder,
        .ant-select-arrow > span {
          color: ${({ theme: { palette } }) => palette.stardustGrey};
        }
      }
    }

    &.ant-select-focused {
      &:not(.ant-select-disabled) {
        .ant-select-selector {
          box-shadow: none;
        }
      }
    }

    &.ant-select-open {
      &:not(.ant-select-disabled) {
        .ant-select-selection-item,
        .ant-select-arrow > span,
        .ant-select-selection-placeholder {
          color: ${({ theme: { palette } }) => palette.spintelGreen};
        }
      }
    }

    .ant-select-selection-item {
      font-weight: 600;
      padding-right: ${({ theme: { spacing } }) => spacing(3)} !important;
    }

    &.ant-select-lg {
      .ant-select-selection-placeholder {
        font-size: 14px;
        padding-right: ${({ theme: { spacing } }) => spacing(6)};
      }
      .ant-select-selection-item {
        padding-right: ${({ theme: { spacing } }) => spacing(4)} !important;
      }
    }
  }
`;

const RootSmMixin = css<RootProps>`
  &.ant-select {
    &.ant-select-sm {
      .ant-select-selector {
        padding-left: calc(
          ${({ withStatus, theme: { spacing } }) => spacing(withStatus ? 1 : 3)} -
            1px
        );
        padding-right: calc(${({ theme: { spacing } }) => spacing(3)} - 2px);
        .ant-select-selection-item {
          font-size: ${({ theme: { spacing } }) => spacing(3)};
        }
        .ant-select-selection-placeholder {
          font-size: ${({ theme: { spacing } }) => spacing(3)};
        }
      }
      &.ant-select-show-arrow {
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          padding-right: ${({ theme: { spacing } }) => spacing(3.5)};
        }
      }

      .ant-select-arrow {
        right: ${({ theme: { spacing } }) => spacing(1.5)};
      }
    }
  }
`;

const RootLgMixin = css<RootProps>`
  &.ant-select {
    &.ant-select-lg {
      .ant-select-selector {
        padding-left: ${({ withStatus, theme: { spacing } }) =>
          spacing(withStatus ? 1 : 5)};
        padding-right: ${({ theme: { spacing } }) => spacing(3)};
        .ant-select-selection-item {
          font-size: 14px;
        }
        .ant-select-selection-placeholder {
          font-size: 14px;
        }
      }
      .ant-select-arrow {
        right: ${({ theme: { spacing } }) => spacing(3)};
      }
      &.ant-select-show-arrow {
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          padding-right: ${({ theme: { spacing } }) => spacing(7)};
        }
      }
    }
  }
`;

const RootWithStatusMixin = css`
  &.ant-select {
    .ant-select-selector {
      .ant-select-selection-item {
        position: relative;
        padding-left: ${({ theme }) => theme.spacing(6)};
        &:before {
          display: none;
        }
        ${Status} {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          border-radius: 50%;
          height: ${({ theme: { spacing } }) => spacing(4)};
          width: ${({ theme: { spacing } }) => spacing(4)};
        }
      }
    }
  }
  ${Object.entries(THEME.palette).map(
    ([key, color]) => css`
      &.select-with-status-${key} {
        .ant-select-selector {
          .ant-select-selection-item {
            &::before {
              background-color: ${color};
              opacity: 0.4;
            }
          }
        }
      }
    `
  )}
`;

export const Root = styled(AntdSelect).withConfig({
  shouldForwardProp: (v) => !['withStatus'].includes(v),
})<RootProps>`
  &.ant-select {
    transition: none;
    &.ant-select-disabled {
      opacity: 0.4;
    }
    &.ant-select-focused {
      &:not(.ant-select-disabled) {
        .ant-select-selector {
          border-color: ${({ theme }) => theme.palette.spintelGreen};
          box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.spintelGreen};
        }
      }
    }
    .ant-select-selector {
      border-radius: ${({ theme: { spacing } }) => spacing(1)};
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        gap: ${({ theme: { spacing } }) => spacing(2)};
        font-size: ${({ theme: { spacing } }) => spacing(3)};
        ${Hint} {
          display: none;
        }
      }
      .ant-select-selection-overflow-item {
        ${Check}, ${Hint} {
          display: none;
        }
      }
      .ant-select-selection-placeholder {
        color: ${({ theme }) => theme.palette.pearlGrey};
      }
    }
  }
  ${({ variant }) => variant === 'default' && RootDefaultMixin}
  ${({ variant }) => variant === 'round' && RootRoundMixin}
  ${({ variant }) => variant === 'inline' && RootInlineMixin}
  ${({ size }) => size === 'small' && RootSmMixin}
  ${({ size }) => size === 'large' && RootLgMixin}
  ${({ withStatus }) => withStatus && RootWithStatusMixin}
`;

const SuffixIconRotateMixin = css`
  animation: ${rotateAnimation} 1s linear infinite;
`;

export const SuffixIcon = styled(Icon)<{ loading: boolean }>`
  ${({ loading }) => loading && SuffixIconRotateMixin}
`;

export const Global = createGlobalStyle`
  .ant-select-item-empty {
    padding-top: ${({ theme: { spacing } }) => spacing(7)};
    padding-bottom: ${({ theme: { spacing } }) => spacing(7)};
    padding-left: ${({ theme: { spacing } }) => spacing(4)};
    padding-right: ${({ theme: { spacing } }) => spacing(4)};
  }
  .ant-select-item {
    &.ant-select-item-group {
      padding-left: ${({ theme: { spacing } }) => spacing(4)};
      padding-right: ${({ theme: { spacing } }) => spacing(4)};
      padding-top: ${({ theme: { spacing } }) => spacing(4)};
      &:not(:has(> *)) {
        display: none;
      }
    }
    &.ant-select-item-option {
      &.spntl-select-item-option {
        padding-left: ${({ theme: { spacing } }) => spacing(4)};
        padding-right: ${({ theme: { spacing } }) => spacing(4)};
        .ant-select-item-option-content {
          display: flex;
          align-items: center;
          gap: ${({ theme: { spacing } }) => spacing(2)};
        }
      }
      &.ant-select-item-option-selected {
        ${Label} {
          color: ${({ theme }) => theme.palette.metropolisGrey};
        }
      }
      &.ant-select-item-option-multiple {
        &.ant-select-item-option-selected {
          color: ${({ theme }) => theme.palette.white};
          background-color: ${({ theme }) => theme.palette.transparent};
          ${Label} {
            color: ${({ theme }) => theme.palette.white};
          }
          &.ant-select-item-option-active {
            background-color: ${({ theme }) => theme.palette.asphaltGrey};
          }
        }
      }
      + .ant-select-item-group {
        margin-top: ${({ theme: { spacing } }) => spacing(1)};
        position: relative;
        &:before {
          content: '';
          display: block;
          border-top: 1px solid ${({ theme }) => theme.palette.asphaltGrey};
          position: absolute;
          top: 0;
          left: ${({ theme: { spacing } }) => spacing(4)};
          right: ${({ theme: { spacing } }) => spacing(4)};
        }
      }
    }
  }
  .ant-select-dropdown {
    .rc-virtual-list-holder {
      overflow-y: auto !important;
    }
    .rc-virtual-list-scrollbar {
      display: none !important;
    }
  }
`;
