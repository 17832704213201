import { FC, memo, useCallback } from 'react';
import { Layer, MapLayerMouseEvent, Source } from 'react-map-gl';
import { Point } from 'geojson';
import { useContext } from 'use-context-selector';
import { noop } from 'lodash';
import { DEFAULT_MAP_TRANSITION } from '../Map.const';
import { getFeaturesCollection, getLayerId } from '../Map.utils';
import { MapCirclesProps } from '../Map.types';
import { MapContext } from '../Map.context';
import { useOnClick } from '../Map.hooks';

const { delay, duration } = DEFAULT_MAP_TRANSITION;

export const MapCircles: FC<MapCirclesProps> = memo(
  ({
    data,
    onClick = noop,
    styles: { radius = 4, hitboxRadius = 8, opacity = 1, color = 'pearlGrey' },
  }) => {
    const { circlesId } = useContext(MapContext);
    const layerId = getLayerId(circlesId);

    const handleClick = useCallback(
      (event: MapLayerMouseEvent) => {
        const feature = event.features && event.features[0];
        if (feature) {
          onClick({
            id: feature.id,
            type: feature.layer.type,
          });
        }
      },
      [onClick]
    );
    // TODO: remove as VoidFunction after update rest maps
    useOnClick({
      id: layerId,
      onClick: handleClick as VoidFunction,
    });

    const collection = getFeaturesCollection<Point>({
      data,
      defaultColor: color,
    });
    const strokeSize = hitboxRadius - radius > 0 ? hitboxRadius - radius : 0;

    return (
      <Source id={circlesId} tolerance={0} type="geojson" data={collection}>
        <Layer
          id={layerId}
          type="circle"
          paint={{
            'circle-stroke-width': strokeSize,
            'circle-stroke-color': 'transparent',
            'circle-color': ['get', 'color'],
            'circle-radius': radius,
            'circle-opacity': opacity,
            'circle-opacity-transition': {
              delay,
              duration,
            },
          }}
        />
      </Source>
    );
  }
);
