import type {
  Point,
  Polygon,
  Geometry,
  Feature,
  FeatureCollection,
} from 'geojson';
import { UiKitColor } from '../../types/uikit';
import { THEME } from '../../constants/theme.const';
import { LAYERS_ID_PREFIX } from './Map.const';
import { FeatureParams, FeatureType, MapLayer } from './Map.types';

export const getFeaturePoint = ({
  coordinates,
  id,
  color,
}: FeatureParams<[number, number]>): FeatureType<Point> => {
  return {
    id,
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates,
    },
    properties: {
      color,
    },
  };
};

export const getFeaturePolygon = ({
  coordinates,
  id,
  color,
}: FeatureParams<number[][][]>): FeatureType<Polygon> => {
  return {
    id,
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates,
    },
    properties: {
      color,
    },
  };
};

export const getLayerId = (id: string) => `${LAYERS_ID_PREFIX}${id}`;

export const getActiveLayerIds = (data: MapLayer[]) => {
  return data.filter((l) => l.isClickable).map(({ id }) => getLayerId(id));
};

export const getColor = (color: UiKitColor) => THEME.palette[color];

export const getFeaturesCollection = <T extends Geometry>({
  data,
  defaultColor,
}: {
  data: Array<Feature<T>>;
  defaultColor: UiKitColor;
}): FeatureCollection<T> => {
  return {
    type: 'FeatureCollection',
    features: data.map((feat) => ({
      ...feat,

      properties: {
        ...feat.properties,
        id: feat.id,
        color: getColor(feat.properties?.color || defaultColor),
      },
    })),
  };
};
