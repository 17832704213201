import styled, { css } from 'styled-components';
import { Typography } from '../Typography/Typography';
import { Root as Tabs } from '../Tabs/Tabs.style';

const { Body2, Heading2 } = Typography;

const EllipsisHeadingMixin = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Title = styled(Heading2)<{ truncate?: boolean }>`
  ${({ truncate }) => truncate && EllipsisHeadingMixin}
`;

export const Root = styled('div')`
  display: flex;
  gap: ${({ theme: { spacing } }) => spacing(4)};
  margin-bottom: ${({ theme: { spacing } }) => spacing(6)};
  &:has(+ ${Tabs}) {
    margin-bottom: ${({ theme: { spacing } }) => spacing(4)};
  }
`;

export const Left = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  gap: spacing(4),
}));

export const Right = styled('div')(() => ({
  flex: '0 0 auto',
}));

export const Content = styled('div')<{ loading: boolean }>`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  gap: ${({ loading, theme: { spacing } }) => spacing(loading ? 1.5 : 0.5)};
`;

export const Subtitle = styled(Body2)`
  color: ${({ theme }) => theme.palette.stardustGrey};
`;
