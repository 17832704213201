import slider from 'antd/lib/slider';
import styled from 'styled-components';

export const StyledSlider = styled(slider)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  &.ant-slider {
    &:hover {
      .ant-slider-track {
        background: rgba(85, 85, 85, 0.3);
      }

      .ant-slider-rail {
        background: transparent;
      }
    }

    .ant-slider-rail {
      background: transparent;
    }

    .ant-slider-track {
      height: 100%;
      background: rgba(85, 85, 85, 0.3);
      border: 1px solid ${({ theme }) => theme.palette.white};
    }

    .ant-slider-handle {
      bottom: 0;
      top: calc(50% - 12px);
      margin: 0;
      padding: 0;
      background: ${({ theme }) => theme.palette.white};
      height: 24px;
      width: 9px;
      border-radius: 0;
      border: none;

      &::after {
        display: inline-block;
        width: 3px;
        height: 8px;
        border-left: 1px solid ${({ theme }) => theme.palette.black};
        border-right: 1px solid ${({ theme }) => theme.palette.black};
        position: absolute;
        left: calc(50% - 1.5px);
        top: calc(50% - 4px);
        pointer-events: none;
        cursor: pointer;
      }
    }
  }
`;

export const Root = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: end;
`;

export const RangeContainer = styled('div').withConfig({
  shouldForwardProp: (prop) => !['width'].includes(prop),
})<{ width: number }>(
  ({ width, theme }) => `
  position: relative;
  height: 100%;
  width: ${width}px;
  border: 1px solid ${theme.palette.wolfGrey};
  display: flex;
  justify-content: end;
  margin-right: ${theme.spacing(2)};
`
);
