import { DTO } from '@spintel/network/app';
import { TabCard } from '@spintel/uikit';

export enum OverviewRotationType {
  PLAYS = 'playsRotationTypeId',
  RANK = 'rankRotationTypeId',
}

export enum OverviewOnTheMapView {
  ROTATION = 'rotation',
  OUTLOOK = 'outlook',
}

export enum OverviewOnTheMapRotationType {
  PLAYS,
  RANK,
}

export enum OverviewSongHistoryTab {
  PLAYS = 'plays',
  PLAYS_CHANGE = 'playsChange',
  RANK = 'rank',
  RANK_CHANGE = 'rankChange',
  STATIONS_ON = 'stationsOn',
  NEW_ADDS = 'stationsAdds',
  DROPS = 'stationsDrops',
}

export interface OverviewRouteSearch {
  history?: {
    tab?: OverviewSongHistoryTab;
  };
  noChanges?: {
    tab?: DTO.IWeekType;
  };
  increases?: {
    tab?: DTO.IWeekType;
  };
  decreases?: {
    tab?: DTO.IWeekType;
  };
  rotation?: {
    type?: OverviewRotationType;
    tab?: string;
  };
  outlook?: {
    tab?: string;
  };
  map?: {
    view?: OverviewOnTheMapView;
    type?: number[];
    rotationType?: OverviewOnTheMapRotationType;
    coverage?: number;
  };
}

export interface OverviewRouteParams {
  songId: number;
}

export type CardBarTabProps = Parameters<typeof TabCard>['0']['tabs']['0'];

export interface TabConfig {
  value: number;
  diff?: number;
}
