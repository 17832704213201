import { createContext } from 'react';
import { noop } from 'lodash';
import { FormInstance } from 'antd';
import { FormErrors } from './Form.types';

export interface FormContextSetLabelProps {
  name: string;
  label: string;
}

export interface FormContextProps {
  errors?: FormErrors;
  setLabel: (props: FormContextSetLabelProps) => void;
  instance: FormInstance;
}

export const FormContext = createContext<FormContextProps>({
  setLabel: noop,
  instance: null,
});
