import { useTranslation } from '@spintel/localization';
import { FileType, HttpResponseType, useDownload } from '@spintel/network/app';
import { Notification } from '@spintel/uikit';
import { useMemo } from 'react';

export type UseExportReturnType = [
  { exporting: boolean },
  (id: number) => void
];
type ApiCallback = Parameters<typeof useDownload>[0];

interface DownloadFormat {
  format: HttpResponseType;
}

export function useExport<T>(callback: ApiCallback): UseExportReturnType {
  const { t } = useTranslation();

  const onSuccessExport = ({ filename }: { filename: string }) => {
    Notification.show({
      message: t('notifications.fileSuccessfullyExported', { filename }),
    });
  };

  const [{ loading: exporting }, exportCall] = useDownload<
    [T | number, DownloadFormat]
  >(callback, {
    type: FileType.CSV,
    onSuccess: onSuccessExport,
  });

  return useMemo(
    (): [{ exporting: boolean }, (id: number) => void] => [
      { exporting },
      (id: number) => {
        return exportCall([id, { format: 'blob' }]);
      },
    ],
    [exporting, exportCall]
  );
}
