import styled from 'styled-components';
import { Icon } from '../../Icon/Icon';

export const Root = styled('div')`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
`;

export const Left = styled('div')`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme: { spacing } }) => spacing(2)};
`;

export const Right = styled('div')`
  flex: 0 0 auto;
  display: flex;
  gap: ${({ theme: { spacing } }) => spacing(4)};
`;

export const Info = styled(Icon)`
  display: block;
  color: ${({ theme }) => theme.palette.londonGrey};
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  cursor: pointer;
`;
