import styled from 'styled-components';
import { Typography } from '../../../Typography/Typography';

export const Root = styled('div')(({ theme: { spacing } }) => ({
  width: spacing(75),
}));

export const Content = styled('div')(({ theme: { spacing } }) => ({
  padding: spacing(4),
  paddingBottom: 0,
}));

export const Footer = styled('div')(({ theme: { spacing } }) => ({
  padding: spacing(4),
  paddingTop: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: spacing(2),
}));

export const Between = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(3),
}));

export const And = styled(Typography.Body3)(({ theme: { spacing } }) => ({
  lineHeight: spacing(10),
  flex: '0 0 auto',
}));
