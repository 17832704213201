import { FC, PropsWithChildren } from 'react';
import { Root } from './CardContent.style';

type CardContentProps = PropsWithChildren<{
  className?: string;
}>;

export const CardContent: FC<CardContentProps> = ({ children, className }) => {
  return <Root className={className}>{children}</Root>;
};
