import { FC, PropsWithChildren } from 'react';
import { CardFooter } from './CardFooter/CardFooter';
import { CardContent } from './CardContent/CardContent';
import { CardHeader } from './CardHeader/CardHeader';
import { Root } from './Card.style';

export interface CardComponents {
  Footer: typeof CardFooter;
  Content: typeof CardContent;
  Header: typeof CardHeader;
}

export type CardProps = PropsWithChildren<{
  className?: string;
  gutter?: number[];
  stretch?: boolean;
}>;

export const Card: FC<CardProps> & CardComponents = ({
  children,
  className,
  gutter,
  stretch = false,
}) => {
  return (
    <Root gutter={gutter} className={className} stretch={stretch}>
      {children}
    </Root>
  );
};

Card.Footer = CardFooter;
Card.Content = CardContent;
Card.Header = CardHeader;
