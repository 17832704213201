import { ChartOptions, Plugin } from 'chart.js';
import { merge } from 'lodash';
import { useMemo, useContext, useRef, useCallback, Key } from 'react';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { THEME } from '../../../constants/theme.const';
import { getTransparentColorHex } from '../../../utils/getTransparentHex/getTransparentHex.utils';
import { ChartContext } from '../Chart.context';
import { defaultOptions, TOOLTIP_OFFSET } from './ChartLine.const';

export const useOptions = (
  customChartOptions: Partial<ChartOptions<'line'>>
) => {
  const {
    data,
    range,
    reverse,
    tooltipRef,
    onClick,
    setTooltipDataIndex,
    selectedIndex,
  } = useContext(ChartContext);

  const hovered = useRef<Key>();
  const smallestY = (data || []).reduce(
    (r, p) => (p.y < r ? p.y : r),
    Infinity
  );

  const handleClick = useCallback<ChartOptions['onClick']>(
    (event, _, chart) => {
      if (chart && event) {
        const [{ index: dataIndex }] = chart.getElementsAtEventForMode(
          event.native,
          'nearest',
          {
            intersect: false,
            axis: 'x',
            includeInvisible: false,
          },
          true
        );

        onClick?.({ dataIndex });
      }
    },
    [onClick]
  );

  const annotation: ChartOptions<'line'>['plugins']['annotation'] = useMemo(
    () => ({
      clip: false,
      animations: null,
      annotations: data.map<AnnotationOptions>((_, index) => ({
        id: `${index}`,
        value: index,
        type: 'line',
        scaleID: 'x',
        drawTime: 'beforeDraw',
        display: () => index === hovered.current || index === selectedIndex,
        borderWidth: 1,
        borderDash: [3],
        borderColor: THEME.palette.wolfGrey,
      })),
    }),
    [data, selectedIndex]
  );

  const mouseLine: Plugin<'line'> = useMemo(
    () => ({
      id: 'mouseLine',
      beforeTooltipDraw: (chart, { tooltip: { dataPoints } }) => {
        const [{ dataIndex }] = dataPoints;

        if (hovered.current !== dataIndex) {
          chart.canvas.style.cursor = onClick ? 'pointer' : 'default';
          hovered.current = dataIndex;
          chart.update();
        }
      },
    }),
    [hovered, onClick]
  );

  const options = useMemo<ChartOptions<'line'>>(
    () =>
      merge<ChartOptions<'line'>, ChartOptions<'line'>, ChartOptions<'line'>>(
        {
          scales: {
            x: {
              min: range ? range.x[0] : data[1].x,
              max: range ? range.x[1] : undefined,
              ticks: {
                color: (ctx) =>
                  getTransparentColorHex(
                    THEME.palette.white,
                    ctx.index !== selectedIndex ? '40%' : '100%'
                  ),
              },
            },
            y: {
              suggestedMin:
                smallestY >= 0 && !reverse ? 0 : Math.abs(smallestY),
              beginAtZero: smallestY >= 0 && !reverse,
              reverse,
              ticks: {
                callback:
                  smallestY < 0
                    ? (v) => (Number(v) - Math.abs(smallestY)).toFixed(0)
                    : (v) => Number(v).toFixed(0),
                color: getTransparentColorHex(THEME.palette.white, '40%'),
              },
            },
          },
          plugins: {
            datalabels: {
              labels: {
                title: {
                  formatter:
                    smallestY < 0
                      ? ({ y }) => Number(y) - Math.abs(smallestY)
                      : ({ y }) => y,
                },
              },
            },
            tooltip: {
              external: (context) => {
                const { tooltip: tooltipModel, chart } = context;
                const { width } = context.chart.canvas.getBoundingClientRect();
                let tooltip = tooltipRef?.current;

                if (tooltipModel.opacity === 0) {
                  tooltip.setVisible(false);

                  hovered.current = null;
                  chart.update();

                  return;
                }

                const x = tooltipModel.caretX;
                const y = tooltipModel.caretY;

                const isTooltipWithinBounds =
                  x + tooltip.node.offsetWidth + TOOLTIP_OFFSET < width;

                setTooltipDataIndex(tooltipModel.dataPoints[0].dataIndex);
                tooltip.setVisible(true);
                tooltip.setTooltip({
                  x: isTooltipWithinBounds
                    ? x + TOOLTIP_OFFSET
                    : x - tooltip.node.clientWidth - TOOLTIP_OFFSET,
                  y,
                });
              },
            },
            annotation,
          },
          onClick: handleClick,
        },
        defaultOptions,
        customChartOptions
      ),
    [
      customChartOptions,
      data,
      range,
      reverse,
      setTooltipDataIndex,
      smallestY,
      tooltipRef,
      selectedIndex,
      handleClick,
      annotation,
    ]
  );

  return {
    options,
    smallestY: smallestY,
    annotation,
    mouseLine,
  };
};
