import { FC, useRef } from 'react';
import { CommonQueryProvider, CommonHttpProvider } from '@shared/components';
import { CommonHttpProviderProps } from '@shared/types';
import { CommonHttpContext } from '@shared/contexts/CommonHttp.context';
import { HttpClient } from '../../__generated__/http-client';
import { Panel } from '../../__generated__/Panel';
import { User } from '../../__generated__/User';
import { Song } from '../../__generated__/Song';
import { SongAirplay } from '../../__generated__/SongAirplay';
import { SongAddOpportunities } from '../../__generated__/SongAddOpportunities';
import { PanelCharts } from '../../__generated__/PanelCharts';
import { Station } from '../../__generated__/Station';
import { Rotation } from '../../__generated__/Rotation';
import { Outlook } from '../../__generated__/Outlook';
import { SongComparison } from '../../__generated__/SongComparison';
import { SongSelector } from '../../__generated__/SongSelector';
import { SongPlayIncrease } from '../../__generated__/SongPlayIncrease';
import { SongOwnership } from '../../__generated__/SongOwnership';
import { Team } from '../../__generated__/Team';
import { SongCompetition } from '../../__generated__/SongCompetition';
import {
  HttpProviderContext,
  HttpProviderContextProps,
} from '../../contexts/HttpProvider.context';

export const HttpProvider: FC<CommonHttpProviderProps> = ({
  host,
  base,
  children,
  ...rest
}) => {
  const { current: httpClient } = useRef<HttpClient>(
    new HttpClient({
      baseURL: `${host}${!!base ? base : ''}`,
    })
  );

  const { current: services } = useRef<HttpProviderContextProps>({
    Panel: new Panel(httpClient),
    User: new User(httpClient),
    Song: new Song(httpClient),
    SongAirplay: new SongAirplay(httpClient),
    SongAddOpportunities: new SongAddOpportunities(httpClient),
    PanelCharts: new PanelCharts(httpClient),
    Station: new Station(httpClient),
    Rotation: new Rotation(httpClient),
    Outlook: new Outlook(httpClient),
    SongComparison: new SongComparison(httpClient),
    SongSelector: new SongSelector(httpClient),
    SongPlayIncrease: new SongPlayIncrease(httpClient),
    SongOwnership: new SongOwnership(httpClient),
    Team: new Team(httpClient),
    SongCompetition: new SongCompetition(httpClient),
  });

  return (
    <CommonHttpContext.Provider value={{ httpClient: httpClient }}>
      <CommonQueryProvider>
        <CommonHttpProvider name="app" instance={httpClient.instance} {...rest}>
          <HttpProviderContext.Provider value={services}>
            {children}
          </HttpProviderContext.Provider>
        </CommonHttpProvider>
      </CommonQueryProvider>
    </CommonHttpContext.Provider>
  );
};
