import { noop } from 'lodash';
import { createContext } from 'use-context-selector';

export interface SwitchContextProps {
  map: Record<string, boolean>;
  roles: unknown[];
  set: (key: string, value: boolean) => void;
}

export const SwitchContext = createContext<SwitchContextProps>({
  set: noop,
  map: {},
  roles: [],
});
