import { FC, useContext, Fragment } from 'react';
import { upperCase } from 'lodash';
import {
  Icon,
  Typography,
  Subheader,
  MenuItem,
  Dropdown,
} from '@spintel/uikit';
import { DTO } from '@spintel/network/auth';
import { useTranslation } from '@spintel/localization';
import { StationDetailsContext } from '../StationDetails.context';
import { Frequency, Overlay, Dot, Title } from './StationHeader.style';
import { StationHeaderSearch } from './StationHeaderSearch/StationHeaderSearch';

const { Heading2, Body1 } = Typography;

export interface StationHeaderProps {
  actions?: MenuItem[];
}

export const StationHeader: FC<StationHeaderProps> = ({ actions }) => {
  const { t } = useTranslation();
  const { data, switchable, loading } = useContext(StationDetailsContext);

  const title = switchable ? (
    <Title>
      <Dropdown overlay={{ width: 240 }}>
        <Dropdown.Toggle>
          <span>{data?.callsign}</span>
        </Dropdown.Toggle>
        <Overlay>
          <StationHeaderSearch />
        </Overlay>
      </Dropdown>
    </Title>
  ) : (
    data?.callsign
  );

  const getStationAddress = ({
    city,
    stateName,
  }: Pick<DTO.IStationDetailsModel, 'city' | 'stateName'>) => {
    if (city && stateName) {
      return t('forms.stateNameCity', {
        city,
        stateName,
      });
    }

    return city || stateName;
  };

  const subtitle = (
    <>
      {[
        data?.name,
        typeof data?.owner === 'string' ? data.owner : data?.owner?.name,
        getStationAddress({ city: data?.city, stateName: data?.stateName }),
      ]
        .filter((v) => !!v)
        .map((v, index) => {
          if (index !== 0) {
            return (
              <Fragment>
                <Dot />
                {v}
              </Fragment>
            );
          }
          return v;
        })}
    </>
  );

  return (
    <Subheader
      title={title}
      actions={actions}
      loading={loading}
      subtitle={subtitle}
      avatar={{
        size: 56,
        shape: 'square',
        icon: <Icon name="radiostation" size="lg" />,
      }}
    >
      {data?.frequency?.value && data?.frequency?.type && (
        <Frequency>
          <Heading2>{data.frequency.value}</Heading2>
          <Body1>{upperCase(data.frequency.type)}</Body1>
        </Frequency>
      )}
    </Subheader>
  );
};
