import { PropsWithChildren, ReactElement } from 'react';
import { useMap } from 'react-use';
import { castArray } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import {
  AuthProviderContext,
  AuthProviderContextProps,
} from '../../contexts/AuthProvider.context';
import { SwitchContext } from './UserSwitchRole.context';
import { UserSwitchRoleCase } from './UserSwitchRoleCase/UserSwitchRoleCase';
import { UserSwitchRoleDefault } from './UserSwitchRoleDefault/UserSwitchRoleDefault';

type SwitchProps = PropsWithChildren<unknown>;

export interface UserSwitchRoleComponent {
  (props: SwitchProps): ReactElement<any, any> | null;
  Case: typeof UserSwitchRoleCase;
  Default: typeof UserSwitchRoleDefault;
}

export const UserSwitchRole: UserSwitchRoleComponent = ({ children }) => {
  const [map, { set }] = useMap<Record<string, boolean>>();

  const roles = useContextSelector<AuthProviderContextProps, unknown[]>(
    AuthProviderContext,
    ({ role }) => castArray(role)
  );

  return (
    <SwitchContext.Provider value={{ roles, set, map }}>
      {children}
    </SwitchContext.Provider>
  );
};

UserSwitchRole.Case = UserSwitchRoleCase;
UserSwitchRole.Default = UserSwitchRoleDefault;
