import { Dispatch, SetStateAction, createContext } from 'react';

type DrawersState<S> = [S, Dispatch<SetStateAction<S>>];

export type Drawers = Record<
  string,
  {
    isOpen?: boolean;
    params: unknown;
  }
>;

export const DrawersContext = createContext<DrawersState<Drawers>>(null);
