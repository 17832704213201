import { PropsWithChildren, Key, ReactElement } from 'react';
import { noop } from 'lodash';
import { ListViewOption } from './ListViewOption/ListViewOption';
import { Root } from './ListView.style';
import { ListViewContext } from './ListView.context';

type ListViewProps = PropsWithChildren<{
  value?: Key;
  className?: string;
  onChange?: (value: Key) => void;
}>;

export interface ListViewComponent {
  (props: ListViewProps): ReactElement<any, any> | null;
  Option: typeof ListViewOption;
}

export const ListView: ListViewComponent = ({
  children,
  value,
  className,
  onChange = noop,
}) => {
  return (
    <ListViewContext.Provider value={{ value, onChange }}>
      <Root className={className}>{children}</Root>
    </ListViewContext.Provider>
  );
};

ListView.Option = ListViewOption;
