import styled from 'styled-components';
import AntdBadge from 'antd/lib/badge';
import { BadgeComponent } from './Badge.types';

export const Status = styled<BadgeComponent>(AntdBadge)<{ opacity: number }>`
  display: flex;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing(2)};
  .ant-badge-status-dot {
    width: ${({ theme: { spacing } }) => spacing(4)};
    height: ${({ theme: { spacing } }) => spacing(4)};
    flex: 0 0 ${({ theme: { spacing } }) => spacing(4)};
    opacity: ${({ opacity }) => opacity};
    top: 0;
  }
  .ant-badge-status-text {
    flex: 0 1 auto;
    margin: 0;
  }
`;
