import { Typography } from '@spintel/uikit';
import styled from 'styled-components';

export const Root = styled('div')<{ border: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-top: ${({ theme, border }) =>
    border ? `1px solid ${theme.palette.asphaltGrey}` : 'none'};
  padding-top: ${({ theme, border }) => theme.spacing(border ? 6 : 0)};
  margin-top: ${({ theme, border }) => theme.spacing(border ? 2 : 0)};
`;

export const ContactsColumn = styled('div')`
  flex: 1;
  max-width: calc(100% / 3);
  padding-right: ${({ theme }) => theme.spacing(6)};
`;

export const EmptyMessage = styled(Typography.Body3)`
  color: ${({ theme }) => theme.palette.londonGrey};
`;
