import { useState, useMemo } from 'react';
import { get, set } from 'lodash';
import { TableGlobalColumnsType, Diff } from '@spintel/uikit';
import { useTranslation, useNumber } from '@spintel/localization';
import { DTO } from '@spintel/network/app';
import { stringSorter, numberSorter } from '@common/utils';
import { COLUMN_SIZES } from '@common/constants';
import { DEFAULT_SORTING } from './StationMusicStack.const';

interface UseColumnsParams {
  order: DTO.IPagedSortOrder;
}

export const useColumns = ({ order }: UseColumnsParams) => {
  const { t } = useTranslation();
  const { f } = useNumber();

  const columns: TableGlobalColumnsType<DTO.IStationMusicStackResponse> = [
    {
      children: [
        {
          children: [
            {
              dataIndex: 'songName',
              fixed: 'left',
              title: t('forms.song'),
              type: 'column',
              filter: {
                type: 'search',
                placeholder: t('placeholders.song'),
              },
              sorter: stringSorter<DTO.IStationMusicStackResponse>('songName'),
              width: COLUMN_SIZES.songName,
              ellipsis: true,
            },
          ],
          type: 'group',
          showHeader: false,
        },
      ],
      type: 'group',
      showHeader: false,
    },
    {
      children: [
        {
          children: [
            {
              dataIndex: 'artistName',
              title: t('forms.artist'),
              type: 'column',
              filter: {
                type: 'search',
                placeholder: t('placeholders.artist'),
              },
              sorter:
                stringSorter<DTO.IStationMusicStackResponse>('artistName'),
              width: COLUMN_SIZES.artistName,
              ellipsis: true,
              select: { default: true },
            },
          ],
          type: 'group',
          showHeader: false,
        },
      ],
      type: 'group',
      showHeader: false,
    },
    {
      children: [
        {
          children: [
            {
              dataIndex: 'twRank',
              title: t('forms.tw'),
              type: 'column',
              filter: { type: 'range' },
              sorter: numberSorter<DTO.IStationMusicStackResponse>(
                'twRank',
                order
              ),
              width: COLUMN_SIZES.tw,
              empty: {
                null: 'dash',
              },
              render: (value) => f('f1', value),
              select: {
                default: true,
                group: 'twRank',
                onChange: ({ selection }) => {
                  return set(
                    selection,
                    'twRankChange',
                    get(selection, 'twRank', true)
                  );
                },
              },
            },
            {
              dataIndex: 'twRankChange',
              render: (value) => (
                <Diff value={value} formatter={(val) => f('f1', val)} />
              ),
              title: '+/-',
              type: 'column',
              filter: { type: 'range', label: t('forms.twChange') },
              sorter: numberSorter<DTO.IStationMusicStackResponse>(
                'twRankChange',
                order
              ),
              width: COLUMN_SIZES.changes,
              empty: {
                null: 'dash',
              },
              select: {
                default: true,
                group: 'twRank',
                disabled: ({ selection }) => !get(selection, 'twRank'),
              },
            },
          ],
          type: 'group',
          showHeader: false,
        },
      ],
      type: 'group',
      title: t('forms.panelRank'),
      minWidth: COLUMN_SIZES.panelRank,
    },
    {
      children: [
        {
          children: [
            {
              dataIndex: 'cwPoints',
              title: t('forms.cw'),
              type: 'column',
              filter: { type: 'range' },
              sorter: numberSorter<DTO.IStationMusicStackResponse>(
                'cwPoints',
                order
              ),
              width: COLUMN_SIZES.cw,
              empty: {
                null: 'dash',
              },
              render: (value) => f('f1', value),
              select: {
                default: true,
                group: 'cwPoints',
                onChange: ({ selection }) => {
                  return set(
                    selection,
                    'cwPointsChange',
                    get(selection, 'cwPoints', true)
                  );
                },
              },
            },
            {
              dataIndex: 'cwPointsChange',
              render: (value) => (
                <Diff value={value} formatter={(val) => f('f1', val)} />
              ),
              title: '+/-',
              type: 'column',
              filter: { type: 'range', label: t('forms.cwChange') },
              sorter: numberSorter<DTO.IStationMusicStackResponse>(
                'cwPointsChange',
                order
              ),
              width: COLUMN_SIZES.changes,
              empty: {
                null: 'dash',
              },
              select: {
                default: true,
                group: 'cwPoints',
                disabled: ({ selection }) => !get(selection, 'cwPoints'),
              },
            },
          ],
          type: 'group',
          showHeader: false,
        },
        {
          children: [
            {
              dataIndex: 'twPoints',
              title: t('forms.tw'),
              type: 'column',
              filter: { type: 'range' },
              sorter: numberSorter<DTO.IStationMusicStackResponse>(
                'twPoints',
                order
              ),
              width: COLUMN_SIZES.tw,
              empty: {
                null: 'dash',
              },
              render: (value) => f('f1', value),
              select: {
                default: true,
                group: 'twPoints',
                onChange: ({ selection }) => {
                  return set(
                    selection,
                    'twPointsChange',
                    get(selection, 'twPoints', true)
                  );
                },
              },
            },
            {
              dataIndex: 'twPointsChange',
              render: (value) => (
                <Diff value={value} formatter={(val) => f('f1', val)} />
              ),
              title: '+/-',
              type: 'column',
              filter: { type: 'range', label: t('forms.twChange') },
              sorter: numberSorter<DTO.IStationMusicStackResponse>(
                'twPointsChange',
                order
              ),
              width: COLUMN_SIZES.changes,
              empty: {
                null: 'dash',
              },
              select: {
                default: true,
                group: 'twPoints',
                disabled: ({ selection }) => !get(selection, 'twPoints'),
              },
            },
          ],
          type: 'group',
          showHeader: false,
        },
        {
          children: [
            {
              dataIndex: 'allTimePoints',
              title: t('forms.at'),
              type: 'column',
              filter: { type: 'range' },
              sorter: numberSorter<DTO.IStationMusicStackResponse>(
                'allTimePoints',
                order
              ),
              width: COLUMN_SIZES.at,
              empty: {
                null: 'dash',
              },
              render: (value) => f('f1', value),
              select: { default: true },
            },
          ],
          type: 'group',
          showHeader: false,
        },
      ],
      type: 'group',
      title: t('forms.panelPoints'),
      minWidth: COLUMN_SIZES.panelPoints,
    },
    {
      children: [
        {
          children: [
            {
              dataIndex: 'cwPlays',
              title: t('forms.cw'),
              type: 'column',
              filter: { type: 'range' },
              sorter: numberSorter<DTO.IStationMusicStackResponse>(
                'cwPlays',
                order
              ),
              width: COLUMN_SIZES.cw,
              empty: {
                null: 'dash',
              },
              render: (value) => f('f1', value),
              select: {
                default: true,
                group: 'cwPlays',
                onChange: ({ selection }) => {
                  return set(
                    selection,
                    'cwPlaysChange',
                    get(selection, 'cwPlays', true)
                  );
                },
              },
            },
            {
              dataIndex: 'cwPlaysChange',
              render: (value) => (
                <Diff value={value} formatter={(val) => f('f1', val)} />
              ),
              title: '+/-',
              type: 'column',
              filter: { type: 'range', label: t('forms.cwChange') },
              sorter: numberSorter<DTO.IStationMusicStackResponse>(
                'cwPlaysChange',
                order
              ),
              width: COLUMN_SIZES.cw,
              empty: {
                null: 'dash',
              },
              select: {
                default: true,
                group: 'cwPlays',
                disabled: ({ selection }) => !get(selection, 'cwPlays'),
              },
            },
          ],
          type: 'group',
          showHeader: false,
        },
        {
          children: [
            {
              dataIndex: 'twPlays',
              title: t('forms.tw'),
              type: 'column',
              filter: { type: 'range' },
              sorter: numberSorter<DTO.IStationMusicStackResponse>(
                'twPlays',
                order
              ),
              width: COLUMN_SIZES.tw,
              empty: {
                null: 'dash',
              },
              render: (value) => f('f1', value),
              select: {
                default: true,
                group: 'twPlays',
                onChange: ({ selection }) => {
                  return set(
                    selection,
                    'twPlaysChange',
                    get(selection, 'twPlays', true)
                  );
                },
              },
            },
            {
              dataIndex: 'twPlaysChange',
              render: (value) => (
                <Diff value={value} formatter={(val) => f('f1', val)} />
              ),
              title: '+/-',
              type: 'column',
              filter: { type: 'range', label: t('forms.twChange') },
              sorter: numberSorter<DTO.IStationMusicStackResponse>(
                'twPlaysChange',
                order
              ),
              width: COLUMN_SIZES.changes,
              empty: {
                null: 'dash',
              },
              select: {
                default: true,
                group: 'twPlays',
                disabled: ({ selection }) => !get(selection, 'twPlays'),
              },
            },
          ],
          type: 'group',
          showHeader: false,
        },
        {
          children: [
            {
              dataIndex: 'allTimePlays',
              title: t('forms.at'),
              type: 'column',
              filter: { type: 'range' },
              sorter: numberSorter<DTO.IStationMusicStackResponse>(
                'allTimePlays',
                order
              ),
              width: COLUMN_SIZES.at,
              empty: {
                null: 'dash',
              },
              render: (value) => f('f1', value),
              select: { default: true },
            },
          ],
          type: 'group',
          showHeader: false,
        },
      ],
      type: 'group',
      title: t('forms.panelPlays'),
      minWidth: COLUMN_SIZES.panelPlays,
    },
  ];

  return { columns };
};

export const useSorting = () => {
  const [sorting, setSorting] = useState<DTO.IPagedSort>();

  return useMemo(() => {
    return { sorting: sorting || DEFAULT_SORTING, setSorting };
  }, [sorting]);
};
