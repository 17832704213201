import styled, { css } from 'styled-components';
import { UiKitColor, UiKitBaseSize } from '../types/uikit';

const StatusSmMixin = css`
  min-width: ${({ theme: { spacing } }) => spacing(4)};
  width: ${({ theme: { spacing } }) => spacing(4)};
  min-height: ${({ theme: { spacing } }) => spacing(4)};
  height: ${({ theme: { spacing } }) => spacing(4)};
`;

const BlendColorsMixin = (
  colors: [UiKitColor, UiKitColor],
  fill: number
) => css`
  position: relative;
  &::after,
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: ${fill};
  }
  &:after {
    background-color: ${({ theme }) => theme.palette[colors[0]]};
    z-index: 2;
  }
  &:before {
    background-color: ${({ theme }) => theme.palette[colors[1]]};
    z-index: 1;
  }
`;

export const Status = styled('span')<{
  status: UiKitColor;
  size: UiKitBaseSize;
  opacity?: number;
}>`
  border-radius: 50%;
  ${({ size = 'sm' }) => size === 'sm' && StatusSmMixin}
  ${({ status, opacity = 0.4 }) =>
    BlendColorsMixin([status, 'icarusGrey'], opacity)};
`;
