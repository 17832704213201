import styled from 'styled-components';
import { Link } from '@spintel/navigation';
import { Layout, Icon } from '@spintel/uikit';

const { Sider } = Layout;

export const Root = styled(Sider)`
  height: 100vh;
  overflow: auto;
  position: sticky;
  top: 0;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme: { spacing } }) => spacing(4)};
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme: { spacing } }) => spacing(4)};
`;

export const ListItem = styled.li`
  margin-bottom: ${({ theme: { spacing } }) => spacing(6)};
  &.active {
  }
`;

export const ListIcon = styled(Icon)``;

export const ListLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme: { spacing } }) => spacing(10)};
  height: ${({ theme: { spacing } }) => spacing(10)};
  color: ${({ theme }) => theme.palette.londonGrey};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.palette.pearlGrey};
  }
  &.active {
    color: ${({ theme }) => theme.palette.white};
  }
`;
