import React, { FC, PropsWithChildren } from 'react';
import { UrlType } from './LinkExternal.types';
import { transformLink } from './LinkExternal.utils';

export interface LinkExternalProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'type'> {
  href: string;
  type: UrlType;
}

export const LinkExternal: FC<PropsWithChildren<LinkExternalProps>> = ({
  children,
  type,
  href,
  ...props
}) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={transformLink(href, type)}
      {...props}
    >
      {children}
    </a>
  );
};
