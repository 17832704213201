import { FC } from 'react';
import { useContext, useContextSelector } from 'use-context-selector';
import { GridCellContext } from '../../../contexts/GridCell.context';
import {
  TableContext,
  TableContextProps,
} from '../../../contexts/Table.context';
import { Root, Cells, Grid } from './SkeletonTable.style';

interface TableSkeletonProps {
  className?: string;
}

export const TableSkeleton: FC<TableSkeletonProps> = ({ className }) => {
  const gridCols = useContext(GridCellContext);

  const skeletonCols = useContextSelector<TableContextProps, number>(
    TableContext,
    ({ skeleton }) => skeleton
  );

  const cols = skeletonCols || gridCols;

  return (
    <Root cols={cols} className={className}>
      <Cells cols={cols}>
        <Grid cols={cols} />
      </Cells>
    </Root>
  );
};
