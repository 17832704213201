import { toNumber, get } from 'lodash';
import { DTO } from '@spintel/network/app';

const getSortTransformedValue = (
  value: string | number | number[],
  order: DTO.IPagedSortOrder
): number => {
  if (value === null && order === DTO.IPagedSortOrder.Ascend) {
    return Number.MAX_SAFE_INTEGER;
  }
  if (value === null && order === DTO.IPagedSortOrder.Descend) {
    return Number.MIN_SAFE_INTEGER;
  }
  return toNumber(value);
};

export const stringSorter = <T extends object>(
  key: keyof T,
  labelsMap?: Record<any, string>
) => {
  return (a: T, b: T) => {
    // null the last if a-z and first if z-a
    if (a[key] === null || b[key] === null) {
      return a[key] === null ? 1 : -1;
    }

    return labelsMap
      ? labelsMap[a[key]].toString().localeCompare(labelsMap[b[key]].toString())
      : a[key].toString().localeCompare(b[key].toString());
  };
};

export const pinedStringSorter = <T extends object>(
  key: keyof T,
  pinedKey: keyof T,
  pinedValue: unknown,
  labelsMap?: Record<any, string>
) => {
  return (a: T, b: T) => {
    if (get(a, pinedKey) === pinedValue || get(b, pinedKey) === pinedValue) {
      return 0;
    }

    return stringSorter(key, labelsMap)(a, b);
  };
};

export const numberSorter = <T extends object>(
  key: keyof T,
  order: DTO.IPagedSortOrder
) => {
  return (a: T, b: T) => {
    return (
      getSortTransformedValue(get(a, key) as any, order) -
      getSortTransformedValue(get(b, key) as any, order)
    );
  };
};

export const pinedNumberSorter = <T extends object>(
  key: keyof T,
  order: DTO.IPagedSortOrder,
  pinedKey: keyof T,
  pinedValue: unknown
) => {
  return (a: T, b: T) => {
    if (get(a, pinedKey) === pinedValue || get(b, pinedKey) === pinedValue) {
      return 0;
    }

    return numberSorter(key, order)(a, b);
  };
};

export const calcTotal = <T extends object>(
  data: readonly T[],
  key: keyof T
): number | null => {
  const { count, total } = data.reduce(
    ({ count, total }, item) => {
      const value = get(item, key, 0);
      if (typeof value === 'number') {
        return {
          count: count + 1,
          total: total + value,
        };
      }
      return { count, total };
    },
    { count: 0, total: 0 }
  );
  return count ? total : null;
};

export const calcAvg = <T extends object>(
  data: readonly T[],
  key: keyof T
): number | null => {
  const total = data.length || 1;
  const { count, res } = data.reduce(
    ({ count, res }, item) => {
      const value = get(item, key, 0);
      if (typeof value === 'number') {
        return {
          count: count + 1,
          res: res + value,
        };
      }
      return { count, res };
    },
    { count: 0, res: 0 }
  );
  return count ? res / total : null;
};

export const isHighlightedRow = ({
  songId,
  currentSongId,
  songIds = [],
}: {
  songId: number;
  currentSongId: number;
  songIds?: number[];
}) => {
  if (songId === currentSongId) return 'bold';
  if (songIds.includes(currentSongId)) return 'normal';
  return null;
};

interface TableColumnSelectionTree {
  [key: string]: boolean | TableColumnSelectionTree;
}

export interface TableColumnSelectCallbackParams {
  selection: TableColumnSelectionTree;
}
