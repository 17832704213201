import {
  IErrorResponse,
  IHealthCheckModel,
} from '../__generated__/data-contracts';
import { HttpClient, RequestParams } from '../__generated__/http-client';
import { LoggerOrigin } from '../constants/origin.const';

interface LoggerLogData extends Object {
  id: string;
  href: string;
  errorName: string;
  errorStack: string;
  errorMessage: string;
  origin: LoggerOrigin;
  componentStack: string;
}

export class Logger<SecurityDataType = unknown> {
  constructor(
    private http: HttpClient<SecurityDataType>,
    private path: string
  ) {}

  log = (data: LoggerLogData, params: RequestParams = {}) =>
    this.http.request<IHealthCheckModel, IErrorResponse>({
      path: this.path,
      method: 'POST',
      secure: true,
      format: 'json',
      body: data,
      ...params,
    });
}
