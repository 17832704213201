/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IDateRangeRequestDto,
  IEmptyDtoPagedRequest,
  IErrorResponse,
  IExportSongHistoryGridRequestDto,
  IGetSongOverviewHeaderTileResponse,
  IGetSongOverviewHistoryGridResponse,
  IGetSongOverviewMapStationHistoryGraphResponse,
  IGetSongOverviewPlaysTileResponse,
  IGetSongOverviewPointsTileResponse,
  IGetSongOverviewRanksComparisonResponse,
  IGetSongOverviewRanksTileResponse,
  IGetSongOverviewRotationGridResponse,
  IGetSongOverviewStationsAddsTileResponse,
  IGetSongOverviewStationsAverageTileResponse,
  IGetSongOverviewStationsDropsTileResponse,
  IGetSongOverviewStationsOnTileResponse,
  IIncDecQueryDtoPagedRequest,
  IOutlookMovetoSectionCreateParams,
  IRotationExportDetailParams,
  ISongAddsCwResponseDto,
  ISongAddsTwResponseDto,
  ISongDecreasesResponseDto,
  ISongDropsResponseDto,
  ISongHistoryGridRequestDto,
  ISongIncreasesResponseDto,
  ISongNoChangesResponseDto,
  ISongOverviewOutlookGridResponse,
  ISongResponse,
  IWeekPointMetricsModel,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Song<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Song
   * @name SongsDetail
   * @summary Get song by songId
   * @request GET:/songs/{songId}
   * @secure
   */
  songsDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<ISongResponse, IErrorResponse>({
      path: `/songs/${songId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name HistoryGraphCreate
   * @summary Get song history plays by week
   * @request POST:/songs/{songId}/history-graph
   * @secure
   */
  historyGraphCreate = (
    songId: number,
    data: IDateRangeRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<Record<string, IWeekPointMetricsModel>, IErrorResponse>({
      path: `/songs/${songId}/history-graph`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name HistoryGridCreate
   * @summary Song History Grid
   * @request POST:/songs/{songId}/history-grid
   * @secure
   */
  historyGridCreate = (
    songId: number,
    data: ISongHistoryGridRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<IGetSongOverviewHistoryGridResponse[], IErrorResponse>({
      path: `/songs/${songId}/history-grid`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name HistoryGridExportCreate
   * @summary Song History Grid
   * @request POST:/songs/{songId}/history-grid/export
   * @secure
   */
  historyGridExportCreate = (
    songId: number,
    data: IExportSongHistoryGridRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/history-grid/export`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name RotationDetail
   * @summary Get song rotation grid
   * @request GET:/songs/{songId}/rotation
   * @secure
   */
  rotationDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<IGetSongOverviewRotationGridResponse, IErrorResponse>({
      path: `/songs/${songId}/rotation`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name RotationExportDetail
   * @summary Export rotation grid
   * @request GET:/songs/{songId}/rotation/export
   * @secure
   */
  rotationExportDetail = (
    { songId, ...query }: IRotationExportDetailParams,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/rotation/export`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name OutlookDetail
   * @summary Get song outlook grid
   * @request GET:/songs/{songId}/outlook
   * @secure
   */
  outlookDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<ISongOverviewOutlookGridResponse, IErrorResponse>({
      path: `/songs/${songId}/outlook`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name OutlookExportDetail
   * @summary Export song outlook grid
   * @request GET:/songs/{songId}/outlook/export
   * @secure
   */
  outlookExportDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/outlook/export`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name OutlookMovetoSectionCreate
   * @summary Move song to anather section in outlook grid
   * @request POST:/songs/{songId}/outlook-moveto-section
   * @secure
   */
  outlookMovetoSectionCreate = (
    { songId, ...query }: IOutlookMovetoSectionCreateParams,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/outlook-moveto-section`,
      method: 'POST',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name IncreasesCreate
   * @summary Get song increases
   * @request POST:/songs/{songId}/increases
   * @secure
   */
  increasesCreate = (
    songId: number,
    data: IIncDecQueryDtoPagedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ISongIncreasesResponseDto, IErrorResponse>({
      path: `/songs/${songId}/increases`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name IncreasesExportCreate
   * @summary Export song increases
   * @request POST:/songs/{songId}/increases/export
   * @secure
   */
  increasesExportCreate = (songId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/increases/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name DecreasesCreate
   * @summary Get song decreases
   * @request POST:/songs/{songId}/decreases
   * @secure
   */
  decreasesCreate = (
    songId: number,
    data: IIncDecQueryDtoPagedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ISongDecreasesResponseDto, IErrorResponse>({
      path: `/songs/${songId}/decreases`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name DecreasesExportCreate
   * @summary Export song decreases
   * @request POST:/songs/{songId}/decreases/export
   * @secure
   */
  decreasesExportCreate = (songId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/decreases/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name NoChangesCreate
   * @summary Get song no-changes
   * @request POST:/songs/{songId}/no-changes
   * @secure
   */
  noChangesCreate = (
    songId: number,
    data: IIncDecQueryDtoPagedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ISongNoChangesResponseDto, IErrorResponse>({
      path: `/songs/${songId}/no-changes`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name NoChangesExportCreate
   * @summary Export song no-changes
   * @request POST:/songs/{songId}/no-changes/export
   * @secure
   */
  noChangesExportCreate = (songId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/no-changes/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name AddsCwCreate
   * @summary Get song adds cw
   * @request POST:/songs/{songId}/adds-cw
   * @secure
   */
  addsCwCreate = (
    songId: number,
    data: IEmptyDtoPagedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ISongAddsCwResponseDto, IErrorResponse>({
      path: `/songs/${songId}/adds-cw`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name AddsCwExportCreate
   * @summary Export song adds cw
   * @request POST:/songs/{songId}/adds-cw/export
   * @secure
   */
  addsCwExportCreate = (songId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/adds-cw/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name AddsTwCreate
   * @summary Get song adds tw
   * @request POST:/songs/{songId}/adds-tw
   * @secure
   */
  addsTwCreate = (
    songId: number,
    data: IEmptyDtoPagedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ISongAddsTwResponseDto, IErrorResponse>({
      path: `/songs/${songId}/adds-tw`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name AddsTwExportCreate
   * @summary Export song adds tw
   * @request POST:/songs/{songId}/adds-tw/export
   * @secure
   */
  addsTwExportCreate = (songId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/adds-tw/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name DropsCreate
   * @summary Get song drops
   * @request POST:/songs/{songId}/drops
   * @secure
   */
  dropsCreate = (
    songId: number,
    data: IEmptyDtoPagedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ISongDropsResponseDto, IErrorResponse>({
      path: `/songs/${songId}/drops`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name DropsExportCreate
   * @summary Export song drops
   * @request POST:/songs/{songId}/drops/export
   * @secure
   */
  dropsExportCreate = (songId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/drops/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name HeaderTileDetail
   * @summary Get song header tile
   * @request GET:/songs/{songId}/header-tile
   * @secure
   */
  headerTileDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<IGetSongOverviewHeaderTileResponse, IErrorResponse>({
      path: `/songs/${songId}/header-tile`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name RanksTileDetail
   * @summary Get song ranks tile
   * @request GET:/songs/{songId}/ranks-tile
   * @secure
   */
  ranksTileDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<IGetSongOverviewRanksTileResponse, IErrorResponse>({
      path: `/songs/${songId}/ranks-tile`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name PlaysTileDetail
   * @summary Get song plays tile
   * @request GET:/songs/{songId}/plays-tile
   * @secure
   */
  playsTileDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<IGetSongOverviewPlaysTileResponse, IErrorResponse>({
      path: `/songs/${songId}/plays-tile`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name PointsTileDetail
   * @summary Get song points tile
   * @request GET:/songs/{songId}/points-tile
   * @secure
   */
  pointsTileDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<IGetSongOverviewPointsTileResponse, IErrorResponse>({
      path: `/songs/${songId}/points-tile`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name StationsOnTileDetail
   * @summary Get song stations on tile
   * @request GET:/songs/{songId}/stations-on-tile
   * @secure
   */
  stationsOnTileDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<IGetSongOverviewStationsOnTileResponse, IErrorResponse>({
      path: `/songs/${songId}/stations-on-tile`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name StationsAddsTileDetail
   * @summary Get song stations adds tile
   * @request GET:/songs/{songId}/stations-adds-tile
   * @secure
   */
  stationsAddsTileDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<IGetSongOverviewStationsAddsTileResponse, IErrorResponse>(
      {
        path: `/songs/${songId}/stations-adds-tile`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }
    );
  /**
   * No description
   *
   * @tags Song
   * @name StationsDropsTileDetail
   * @summary Get song stations drops tile
   * @request GET:/songs/{songId}/stations-drops-tile
   * @secure
   */
  stationsDropsTileDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<
      IGetSongOverviewStationsDropsTileResponse,
      IErrorResponse
    >({
      path: `/songs/${songId}/stations-drops-tile`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name StationsAveragePlaysTileDetail
   * @summary Get song stations average plays tile
   * @request GET:/songs/{songId}/stations-average-plays-tile
   * @secure
   */
  stationsAveragePlaysTileDetail = (
    songId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetSongOverviewStationsAverageTileResponse,
      IErrorResponse
    >({
      path: `/songs/${songId}/stations-average-plays-tile`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name RankComparisonTileDetail
   * @summary Get song rank comparison tile
   * @request GET:/songs/{songId}/rank-comparison-tile
   * @secure
   */
  rankComparisonTileDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<IGetSongOverviewRanksComparisonResponse, IErrorResponse>({
      path: `/songs/${songId}/rank-comparison-tile`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Song
   * @name StationsHistoryGraphCreate
   * @summary Get map station song history plays by week
   * @request POST:/songs/{songId}/stations/{stationId}/history-graph
   * @secure
   */
  stationsHistoryGraphCreate = (
    songId: number,
    stationId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetSongOverviewMapStationHistoryGraphResponse[],
      IErrorResponse
    >({
      path: `/songs/${songId}/stations/${stationId}/history-graph`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
}
