import { FC, memo, useRef, MutableRefObject, useImperativeHandle } from 'react';
import { Chart } from 'chart.js';
import { useContextSelector } from 'use-context-selector';
import { Doughnut } from 'react-chartjs-2';
import { GraphContext, GraphContextProps } from '../Graph.context';
import { DEFAULT_GRAPH_ID } from '../Graph.const';
import { GraphTooltipConfig } from '../Graph.types';
import { TooltipContainer } from './TooltipContainer/TooltipContainer';
import { GraphDoughnutVariant } from './GraphDoughnut.types';
import { useDataset, useOptions } from './GraphDoughnut.hooks';
import { Root } from './GraphDoughnut.style';

interface GraphDoughnutProps {
  id?: string;
  variant?: GraphDoughnutVariant;
  labels?: string[];
  tooltipConfig?: GraphTooltipConfig;
  formatter?: (value: number) => string;
}

export const GraphDoughnut: FC<GraphDoughnutProps> = memo(
  function GraphDoughnut({
    id = DEFAULT_GRAPH_ID,
    tooltipConfig,
    variant,
    labels,
    formatter,
  }) {
    const initialDatasets = useContextSelector<
      GraphContextProps,
      GraphContextProps['datasets']
    >(GraphContext, ({ datasets }) => datasets);
    const chartRef: MutableRefObject<Chart<'doughnut'>> = useRef();

    const graph = useContextSelector<
      GraphContextProps,
      GraphContextProps['graph']
    >(GraphContext, ({ graph }) => graph);

    const dataset = useDataset({ dataset: initialDatasets?.[0], variant });
    const [
      { options, size },
      highlightHelperPlugin,
      textCenterPlugin,
      legendPlugin,
      clearHoverPlugin,
    ] = useOptions({
      id,
      tooltipConfig,
      labels,
      chartRef,
      dataset,
      variant,
      formatter,
    });

    useImperativeHandle(graph, () => ({
      chart: chartRef,
    }));

    const datasets = [dataset];

    const plugins = [
      highlightHelperPlugin,
      textCenterPlugin,
      legendPlugin,
      clearHoverPlugin,
    ];

    return (
      <Root size={size}>
        <Doughnut
          ref={chartRef}
          data={{ datasets }}
          options={options}
          plugins={plugins}
        />
        {tooltipConfig && (
          <TooltipContainer id={tooltipConfig.id}>
            {tooltipConfig.tooltip}
          </TooltipContainer>
        )}
      </Root>
    );
  }
);
