import { useContext, useMemo } from 'react';
import { Button, Dropdown, TableActions } from '@spintel/uikit';
import { useToggleComparison } from '@common/hooks';
import { DTO } from '@spintel/network/app';
import { StationPossibleSpaceContext } from '../../StationPossibleSpace.context';
import { Root } from './StationPossibleSpaceTableActions.styles';

export const StationPossibleSpaceTableActions: TableActions<
  DTO.ISongAddOpportunitiesHighLowResponse
> = ({ item }) => {
  const { stationId } = useContext(StationPossibleSpaceContext);
  const comparisonMenuItem = useToggleComparison({
    stationId,
    songId: item.songId,
    artistName: item.artistName,
    songName: item.songName,
  });

  const menuItems = useMemo(
    () => [...(comparisonMenuItem ? [comparisonMenuItem] : [])],
    [comparisonMenuItem]
  );

  if (!menuItems.length) return null;

  return (
    <Root>
      <Dropdown placement="bottomRight">
        <Dropdown.Toggle chevron={false}>
          <Button circle size="sm" icon="more" />
        </Dropdown.Toggle>
        <Dropdown.Menu items={menuItems} />
      </Dropdown>
    </Root>
  );
};

StationPossibleSpaceTableActions.width = 45;
