import { FC, PropsWithChildren, useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

type CommonQueryProviderProps = PropsWithChildren<unknown>;

export const CommonQueryProvider: FC<CommonQueryProviderProps> = ({
  children,
}) => {
  const { current: client } = useRef(new QueryClient());

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};
