import {
  FC,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
  useTransition,
} from 'react';
import {
  flatten,
  differenceWith,
  isEqual,
  fromPairs,
  toPairs,
  merge,
  omit,
} from 'lodash';
import { useContext } from 'use-context-selector';
import { Dropdown } from '../../Dropdown/Dropdown';
import { TableContext } from '../../../contexts/Table.context';
import { getColumnSelectionTree } from '../Table.utils';
import { COLUMN_SELECT_ALL_DATA_INDEX } from '../Table.const';
import {
  TableColumnsSelectFormOnChangeParams,
  TableColumnsSelectFormRef,
} from './TableColumnsSelect.types';
import {
  getColumnSelectionDataIndexTree,
  getSelectionOnChangeHandlers,
  getSelectionFromDataIndexTree,
} from './TableColumnsSelect.utils';
import { TableColumnsSelectForm } from './TableColumnsSelectForm/TableColumnsSelectForm';
import { useTableColumnsSelectColumns } from './TableColumnsSelect.hooks';

type TableColumnsSelectProps = PropsWithChildren<{
  toggleAll?: string;
  disabled?: boolean;
}>;

export const TableColumnsSelect: FC<TableColumnsSelectProps> = ({
  children,
  disabled,
}) => {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [transitionInProgress, startTransition] = useTransition();
  const formRef = useRef<TableColumnsSelectFormRef>({
    values: { selection: [] },
  });

  const { selection, setSelection } = useContext(TableContext);

  const [{ columns }] = useTableColumnsSelectColumns();

  const flatSelection = useRef(flatten(selection));

  const handleOnChange = ({
    value,
    form,
  }: TableColumnsSelectFormOnChangeParams) => {
    const currentFlatSelection = flatten(value);
    const currentSelectionTree = getColumnSelectionTree(
      columns,
      currentFlatSelection
    );
    const currentSelectionDataIndexTree = getColumnSelectionDataIndexTree(
      columns,
      currentFlatSelection
    );
    const previousSelectionTree = getColumnSelectionTree(
      columns,
      flatSelection.current
    );
    const diff = fromPairs(
      differenceWith(
        toPairs(currentSelectionTree),
        toPairs(previousSelectionTree),
        isEqual
      )
    );
    const diffIds = Object.keys(diff);
    const handlers = getSelectionOnChangeHandlers(columns, diffIds);
    const updatedSelectionDataIndexTreeByCb = handlers.reduce(
      (res, callback) => {
        return merge({}, res, callback({ selection: res }));
      },
      currentSelectionDataIndexTree
    );
    const updatedSelectionDataIndexTree = (() => {
      const selection = omit(
        updatedSelectionDataIndexTreeByCb,
        COLUMN_SELECT_ALL_DATA_INDEX
      );
      const all = Object.values(selection).every((v) => v);
      return merge({}, selection, { [COLUMN_SELECT_ALL_DATA_INDEX]: all });
    })();
    const updatedSelection = getSelectionFromDataIndexTree(
      columns,
      updatedSelectionDataIndexTree
    );
    setIsFormDirty(form.isFieldsTouched());
    flatSelection.current = flatten(updatedSelection);
    form.setFieldsValue({ selection: updatedSelection });
  };

  const handleOnConfirm = useCallback(() => {
    const { selection } = formRef.current.values;
    startTransition(() => setSelection(selection));
  }, [formRef, startTransition, setSelection]);

  return (
    <Dropdown placement="bottomRight" disabled={disabled}>
      <Dropdown.Toggle loading={transitionInProgress}>
        {children}
      </Dropdown.Toggle>
      <Dropdown.Confirm onConfirm={handleOnConfirm} disabled={!isFormDirty}>
        <TableColumnsSelectForm ref={formRef} onChange={handleOnChange} />
      </Dropdown.Confirm>
    </Dropdown>
  );
};
