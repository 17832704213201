import { isEqual, last } from 'lodash';
import { FormInstance } from 'antd/lib/form';
import {
  FormItemRuleType,
  FormAllValidationRuleKey,
  FormItemEqRuleCallback,
  FormItemType,
} from './FormItem.types';
import { PASSWORD_PATTERN } from './FormItem.const';

export const formItemRule: Record<
  FormAllValidationRuleKey<unknown>,
  <T>(params: {
    value: string | number | boolean | RegExp | FormItemEqRuleCallback<T>;
    message?: string;
    form: FormInstance<T>;
    type: FormItemType;
  }) => FormItemRuleType
> = {
  email: ({ value, message }) => {
    return typeof value === 'boolean'
      ? {
          message: message,
          validator: async ({ message }, currentFormValue: string) => {
            if (currentFormValue) {
              const atIndx = currentFormValue.lastIndexOf('@');
              const local = currentFormValue.substring(0, atIndx);
              const domain = currentFormValue.substring(atIndx + 1);
              const dns = domain.split('.');

              const isLocalValid =
                // special character, lower/upper case latin, numeric, min 1, max 64
                /^[!#$%&'*+\-/=?^_`{|}~.a-zA-Z0-9]{1,64}$/.test(local) &&
                // do not start with special character
                !/^[!#$%&'*+\-/=?^_`{|}~.]$/.test(local[0]) &&
                // do not finish with special character
                !/^[!#$%&'*+\-/=?^_`{|}~.]$/.test(local[local.length - 1]);

              const isDomainValid =
                // lower/upper case latin, numberic, dish, dot, min 4, max 255
                /^[a-zA-Z0-9-.]{4,255}$/.test(domain) &&
                // not all-numeric (not test@4444.com, only test@d444.com)
                domain.split('.').every((d) => /(?!^\d+$)^.+$/.test(d)) &&
                // do not start/finish with dot or dish, no dot/dish before/after dot
                /^(?![-.])(?!.*[-.]$)(?!.*\.[-.])(?!.*[-.]\.).*$/.test(
                  domain
                ) &&
                // at least 2 dns
                dns.length > 1 &&
                // min 1, max 63
                dns.every((d) => /^.{1,63}$/.test(d)) &&
                // min 2, max 63 the last (highest dns)
                /^.{2,63}$/.test(last(dns));

              if (!isLocalValid || !isDomainValid) {
                throw new Error(
                  (message || 'Provided email ID has invalid format') as string
                );
              }
            }
          },
        }
      : {};
  },
  max: ({ value, message, type }) => {
    return typeof value === 'number'
      ? {
          max: value,
          message,
          type,
        }
      : {};
  },
  min: ({ value, message, type }) => {
    return typeof value === 'number'
      ? {
          min: value,
          message,
          type,
        }
      : {};
  },
  password: ({ value, message }) => {
    return value
      ? {
          pattern: PASSWORD_PATTERN,
          // eslint-disable-next-line no-template-curly-in-string
          message: message || "'${label}' field should be strong password",
        }
      : {};
  },
  pattern: ({ value, message }) => {
    return value instanceof RegExp
      ? {
          pattern: value,
          message,
        }
      : {};
  },
  required: ({ value, message }) => {
    return typeof value === 'boolean'
      ? {
          required: value,
          message,
        }
      : {};
  },
  equal: ({ value, message, form: { getFieldsValue } }) => {
    return typeof value === 'function'
      ? {
          message: message,
          validator: async ({ message }, currentFormValue) => {
            const formValues = getFieldsValue();
            const compareValue = value(formValues);
            if (currentFormValue && !isEqual(currentFormValue, compareValue)) {
              throw new Error(message as string);
            }
          },
        }
      : {};
  },
  whitespace: ({ value, message }) => {
    return typeof value === 'boolean'
      ? {
          whitespace: value,
          message,
        }
      : {};
  },
  url: ({ value, message }) => {
    return typeof value === 'boolean' && value
      ? {
          type: 'url',
          message,
        }
      : {};
  },
};
