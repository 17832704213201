import { FC, PropsWithChildren, useState } from 'react';
import { noop } from 'lodash';
import { useUpdateEffect } from 'react-use';
import { useModal } from '../../hooks/useModal/useModal.hook';
import { Alert, AlertProps } from '../Alert/Alert';
import { Typography } from '../Typography/Typography';
import { Icon } from '../Icon/Icon';
import { ModalClose } from './ModalClose/ModalClose';
import { ModalFooter } from './ModalFooter/ModalFooter';
import { ModalContent } from './ModalContent/ModalContent';
import { Root } from './Modal.style';
import { ModalContext } from './Modal.context';
import { modalConfirm } from './Modal.utils';

export type ModalProps = PropsWithChildren<{
  name: string;
  alert?: AlertProps;
  title?: string;
  width?: number;
  onClosed?: () => void;
}>;

interface ModalComponent extends FC<ModalProps> {
  Close: typeof ModalClose;
  Footer: typeof ModalFooter;
  Content: typeof ModalContent;
  confirm: typeof modalConfirm;
}

const { Heading4 } = Typography;

export const Modal: ModalComponent = ({
  children,
  name,
  title,
  width,
  alert,
  onClosed = noop,
}) => {
  const [visible, setVisible] = useState(false);
  const [{ isOpen }, toggle] = useModal(name);

  useUpdateEffect(() => setVisible(isOpen), [isOpen]);

  const handleAfterClose = () => {
    toggle(false);
    onClosed();
  };

  return isOpen ? (
    <ModalContext.Provider value={{ name, setVisible }}>
      <Root
        footer={null}
        width={width}
        visible={visible}
        afterClose={handleAfterClose}
        onCancel={() => setVisible(false)}
        title={<Heading4>{title}</Heading4>}
        closeIcon={<Icon name="close" size="sm" />}
      >
        {alert ? <Alert {...alert} /> : null}
        {children}
      </Root>
    </ModalContext.Provider>
  ) : null;
};

Modal.Close = ModalClose;
Modal.Footer = ModalFooter;
Modal.Content = ModalContent;
Modal.confirm = modalConfirm;
