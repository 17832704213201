/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IDefaultQueryModel,
  IDefaultQueryModelPagedRequest,
  IEmptyQueryModelPagedRequest,
  IErrorResponse,
  IGetStationOverviewSongRecentlyAddedDroppedResponsePagedResult,
  IGetStationOverviewSongsDecreasesResponsePagedResult,
  IGetStationOverviewSongsIncreasesResponsePagedResult,
  IGetStationsAirplayResponse,
  IMusicStackExportCreateParams,
  IPlaylistExportCreateParams,
  IPossibleSpaceExportCreateParams,
  IStationDetailsModel,
  IStationInfoModel,
  IStationModel,
  IStationMusicStackResponse,
  IStationMySongsResponse,
  IStationPlaylistResponse,
  IStationPossibleSpaceResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Station<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Station
   * @name StationsList
   * @summary Get list of stations info
   * @request GET:/stations
   * @secure
   */
  stationsList = (params: RequestParams = {}) =>
    this.http.request<IStationInfoModel[], IErrorResponse>({
      path: `/stations`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name CoordinatesList
   * @summary Get all station with coordinates
   * @request GET:/stations/coordinates
   * @secure
   */
  coordinatesList = (params: RequestParams = {}) =>
    this.http.request<IStationModel[], IErrorResponse>({
      path: `/stations/coordinates`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name DetailsDetail
   * @summary Get station details
   * @request GET:/stations/{stationId}/details
   * @secure
   */
  detailsDetail = (stationId: number, params: RequestParams = {}) =>
    this.http.request<IStationDetailsModel, IErrorResponse>({
      path: `/stations/${stationId}/details`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name IncreasesCreate
   * @summary Get station songs increases
   * @request POST:/stations/{stationId}/increases
   * @secure
   */
  increasesCreate = (
    stationId: number,
    data: IEmptyQueryModelPagedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetStationOverviewSongsIncreasesResponsePagedResult,
      IErrorResponse
    >({
      path: `/stations/${stationId}/increases`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name IncreasesExportCreate
   * @summary Export station songs increases
   * @request POST:/stations/{stationId}/increases/export
   * @secure
   */
  increasesExportCreate = (stationId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/stations/${stationId}/increases/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name DecreasesCreate
   * @summary Get station songs decreases
   * @request POST:/stations/{stationId}/decreases
   * @secure
   */
  decreasesCreate = (
    stationId: number,
    data: IEmptyQueryModelPagedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetStationOverviewSongsDecreasesResponsePagedResult,
      IErrorResponse
    >({
      path: `/stations/${stationId}/decreases`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name DecreasesExportCreate
   * @summary Export station songs decreases
   * @request POST:/stations/{stationId}/decreases/export
   * @secure
   */
  decreasesExportCreate = (stationId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/stations/${stationId}/decreases/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name RecentlyAddedCreate
   * @summary Get recently added songs on specified station.
   * @request POST:/stations/{stationId}/recently-added
   * @secure
   */
  recentlyAddedCreate = (
    stationId: number,
    data: IDefaultQueryModelPagedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetStationOverviewSongRecentlyAddedDroppedResponsePagedResult,
      IErrorResponse
    >({
      path: `/stations/${stationId}/recently-added`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name RecentlyDroppedCreate
   * @summary Get recently dropped songs on specified station.
   * @request POST:/stations/{stationId}/recently-dropped
   * @secure
   */
  recentlyDroppedCreate = (
    stationId: number,
    data: IDefaultQueryModelPagedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetStationOverviewSongRecentlyAddedDroppedResponsePagedResult,
      IErrorResponse
    >({
      path: `/stations/${stationId}/recently-dropped`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name RecentlyAddedExportCreate
   * @summary Export station recently added songs.
   * @request POST:/stations/{stationId}/recently-added/export
   * @secure
   */
  recentlyAddedExportCreate = (
    stationId: number,
    data: IDefaultQueryModel,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/stations/${stationId}/recently-added/export`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name RecentlyDroppedExportCreate
   * @summary Export station recently dropped songs.
   * @request POST:/stations/{stationId}/recently-dropped/export
   * @secure
   */
  recentlyDroppedExportCreate = (
    stationId: number,
    data: IDefaultQueryModel,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/stations/${stationId}/recently-dropped/export`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name GridDetail
   * @summary Get station list statistics for user's song
   * @request GET:/stations/grid/{songId}
   * @secure
   */
  gridDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<IGetStationsAirplayResponse[], IErrorResponse>({
      path: `/stations/grid/${songId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name GridExportCreate
   * @summary Export station list statistics for user's song
   * @request POST:/stations/grid/{songId}/export
   * @secure
   */
  gridExportCreate = (songId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/stations/grid/${songId}/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name PossibleSpaceDetail
   * @summary Get stations possible space
   * @request GET:/stations/{stationId}/possible-space/{songId}
   * @secure
   */
  possibleSpaceDetail = (
    songId: number,
    stationId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<IStationPossibleSpaceResponse[], IErrorResponse>({
      path: `/stations/${stationId}/possible-space/${songId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name PossibleSpaceExportCreate
   * @summary Export stations possible space
   * @request POST:/stations/{stationId}/possible-space/{songId}/export
   * @secure
   */
  possibleSpaceExportCreate = (
    { songId, stationId, ...query }: IPossibleSpaceExportCreateParams,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/stations/${stationId}/possible-space/${songId}/export`,
      method: 'POST',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name PlaylistDetail
   * @summary Get  stations playlist
   * @request GET:/stations/{stationId}/playlist/{songId}
   * @secure
   */
  playlistDetail = (
    songId: number,
    stationId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<IStationPlaylistResponse[], IErrorResponse>({
      path: `/stations/${stationId}/playlist/${songId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name PlaylistExportCreate
   * @summary Export stations playlist
   * @request POST:/stations/{stationId}/playlist/{songId}/export
   * @secure
   */
  playlistExportCreate = (
    { songId, stationId, ...query }: IPlaylistExportCreateParams,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/stations/${stationId}/playlist/${songId}/export`,
      method: 'POST',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name MusicStackDetail
   * @summary Get stations music stack
   * @request GET:/stations/{stationId}/music-stack/{songId}
   * @secure
   */
  musicStackDetail = (
    songId: number,
    stationId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<IStationMusicStackResponse[], IErrorResponse>({
      path: `/stations/${stationId}/music-stack/${songId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name MusicStackExportCreate
   * @summary Export stations music stack
   * @request POST:/stations/{stationId}/music-stack/{songId}/export
   * @secure
   */
  musicStackExportCreate = (
    { songId, stationId, ...query }: IMusicStackExportCreateParams,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/stations/${stationId}/music-stack/${songId}/export`,
      method: 'POST',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name MySongsDetail
   * @summary Get my songs on selected station
   * @request GET:/stations/{stationId}/my-songs
   * @secure
   */
  mySongsDetail = (stationId: number, params: RequestParams = {}) =>
    this.http.request<IStationMySongsResponse[], IErrorResponse>({
      path: `/stations/${stationId}/my-songs`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Station
   * @name MySongsExportCreate
   * @summary Export my songs on selected station
   * @request POST:/stations/{stationId}/my-songs/export
   * @secure
   */
  mySongsExportCreate = (stationId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/stations/${stationId}/my-songs/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
}
