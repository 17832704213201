import { ReactNode } from 'react';
import { Typography } from '../../Typography/Typography';

const { Body2 } = Typography;

export const getPrefixOrSuffix = (value: ReactNode): ReactNode => {
  if (typeof value === 'string') {
    return <Body2>{value}</Body2>;
  }
  return value;
};
