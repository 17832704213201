import { FC } from 'react';
import AntdDescription from 'antd/lib/descriptions';
import { Typography } from '../Typography/Typography';
import { DescriptionValue } from './DescriptionValue/DescriptionValue';
import { Root } from './Description.style';
import { DescriptionItemProps } from './Description.types';

const { Subtitle2 } = Typography;

export interface DescriptionProps {
  className?: string;
  column?: number;
  items: DescriptionItemProps[];
  ellipsis?: boolean;
}

export const Description: FC<DescriptionProps> = ({
  column,
  items,
  ellipsis = false,
}) => {
  return (
    <Root layout="vertical" colon={false} column={column} ellipsis={ellipsis}>
      {items.map(({ label, value }) => {
        return (
          <AntdDescription.Item
            key={label}
            label={<Subtitle2>{label}</Subtitle2>}
          >
            <DescriptionValue ellipsis={ellipsis}>{value}</DescriptionValue>
          </AntdDescription.Item>
        );
      })}
    </Root>
  );
};
