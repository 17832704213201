import { createContext } from 'react';

export interface UiKitProviderContextProps {
  version?: string;
  mapbox?: {
    token?: string;
    tenantId?: string;
    styleId?: string;
  };
  table?: {
    cell?: {
      formatter?: (v: unknown) => unknown;
    };
  };
}

export const UiKitProviderContext = createContext<UiKitProviderContextProps>(
  {}
);
