import { useTranslation } from '@spintel/localization';
import { MenuItem } from '@spintel/uikit';
import { useCallback, useMemo } from 'react';
import { useSongsComparison } from '../useSongsComparison/useSongsComparison';

interface UseToggleComparisonParams {
  stationId: number;
  songId: number;
  artistName: string;
  songName: string;
}

const MAXIMUM_COUNT_OF_SONGS = 10;

export const useToggleComparison = ({
  songId,
  stationId,
  artistName,
  songName,
}: UseToggleComparisonParams): MenuItem | null => {
  const [{ song, songIds, loading }, { onAdd, onRemove }] = useSongsComparison({
    stationId,
  });
  const { t } = useTranslation();
  const isLimit = songIds.length === MAXIMUM_COUNT_OF_SONGS - 1;

  const isComparedSong = useMemo(
    () => songIds.includes(songId),
    [songId, songIds]
  );

  const isCurrentSelected = useMemo(
    () => song.songId === songId,
    [song.songId, songId]
  );

  const handleUpdate = useCallback(
    () =>
      isComparedSong
        ? onRemove({ id: songId, artistName, songName })
        : onAdd({ id: songId, artistName, songName }),
    [songId, isComparedSong, onAdd, onRemove, songName, artistName]
  );

  const label = useMemo(
    () =>
      t(
        isComparedSong
          ? 'actions.removeFromComparison'
          : 'actions.addToComparison'
      ),
    [isComparedSong, t]
  );

  return useMemo(() => {
    if (isCurrentSelected) return null;

    return {
      label,
      icon: 'comparison',
      onClick: handleUpdate,
      key: 'toggleComparison',
      hint:
        isLimit && !isComparedSong
          ? t('hints.songComparisonLimit', { count: MAXIMUM_COUNT_OF_SONGS })
          : null,
      disabled: loading || (isLimit && !isComparedSong),
    };
  }, [
    isCurrentSelected,
    label,
    handleUpdate,
    loading,
    isLimit,
    isComparedSong,
    t,
  ]);
};
