import { FC } from 'react';
import { UiKitColor } from '../../types/uikit';
import { Root } from './Dash.style';

export interface DashProps {
  className?: string;
  color?: UiKitColor;
}

export const Dash: FC<DashProps> = ({ className, color = 'londonGrey' }) => (
  <Root color={color} className={className}>
    &mdash;
  </Root>
);
