import {
  AuthRoutePath,
  Callback,
  ForgotPassword,
  Login,
  Logout,
  Mfa,
  MfaConfirm,
  MfaSetup,
  ResetPassword,
  SessionTimeout,
  Signup,
  UserDeactivated,
} from '@spintel/shared';
import { RoutePropsWithChildren } from '@spintel/navigation';

export interface AuthRoute extends RoutePropsWithChildren {
  roles: string[];
}

export const ROUTES: AuthRoute[] = [
  {
    element: <Login />,
    path: AuthRoutePath.LOGIN,
    roles: [null],
  },
  {
    element: <Logout />,
    path: AuthRoutePath.LOGOUT,
    roles: [],
  },
  {
    element: <Callback />,
    path: AuthRoutePath.CALLBACK,
    roles: [null],
  },
  {
    childRoutes: [
      {
        element: <MfaSetup />,
        path: AuthRoutePath.MFA_SETUP,
      },
      {
        element: <MfaConfirm />,
        path: AuthRoutePath.MFA_CONFIRM,
      },
    ],
    element: <Mfa />,
    path: AuthRoutePath.MFA,
    roles: [null],
  },
  {
    element: <Signup />,
    path: AuthRoutePath.SIGNUP,
    roles: [null],
  },
  {
    element: <ResetPassword />,
    path: AuthRoutePath.RESET_PASSWORD,
    roles: [null],
  },
  {
    element: <SessionTimeout />,
    path: AuthRoutePath.SESSION_TIMEOUT,
    roles: [null],
  },
  {
    element: <UserDeactivated />,
    path: AuthRoutePath.DEACTIVATED,
    roles: [null],
  },
  {
    element: <ForgotPassword />,
    path: AuthRoutePath.FORGOT_PASSWORD,
    roles: [null],
  },
];
