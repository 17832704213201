import React, { useCallback } from 'react';
import { assign } from 'lodash';
import { stringify, parse } from 'qs';
import { generatePath } from 'react-router';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { PushParams, UseRoute } from './useRoute.types';
import { decoder, getPath } from './useRoute.utils';

export function useRoute<
  P = PlainObject,
  S extends PlainObject = {}
>(): UseRoute<P, S> {
  const navigate = useNavigate();
  const { search: locationSearch, pathname } = useLocation();
  const params = useParams();
  const [, setSearchParams] = useSearchParams();
  const path = getPath(pathname, params);

  const search = React.useMemo((): S => {
    return parse(locationSearch, {
      decoder: decoder,
      ignoreQueryPrefix: true,
      strictNullHandling: true,
    }) as S;
  }, [locationSearch]);

  const setSearch = useCallback(
    (s: S) => {
      setSearchParams(s);
    },
    [setSearchParams]
  );

  const push = React.useCallback(
    ({ to, params: p = {}, search: s = {} }: PushParams): void => {
      const pathname = generatePath(to, p);
      const search = stringify(s, { addQueryPrefix: true, encode: false });
      navigate(pathname + search);
    },
    [navigate]
  );

  const updateSearch = useCallback(
    (s: Partial<S>) => {
      const updated = assign({}, search, s);
      const stringified = stringify(updated, {
        addQueryPrefix: true,
        encode: false,
        strictNullHandling: true,
      });
      navigate(pathname + stringified);
    },
    [search, pathname, navigate]
  );

  const back = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return React.useMemo(
    (): UseRoute<P, S> => [
      {
        path,
        params: params as unknown as P,
        pathname,
        search,
      },
      { push, setSearch, updateSearch, back },
    ],
    [path, search, updateSearch, params, setSearch, push, pathname, back]
  );
}
