import { createContext, Dispatch, SetStateAction } from 'react';
import { noop } from 'lodash';
import { Auth } from '../__generated__/Auth';
import { User } from '../__generated__/User';
import { HttpClient } from '../__generated__/http-client';
import { ILoginUserResponseDto } from '../__generated__/data-contracts';

export interface HttpServices {
  Auth: Auth;
  User: User;
}

interface HttpProviderContextProps {
  httpClient: HttpClient;
  services: HttpServices;
  token: ILoginUserResponseDto;
  setToken: Dispatch<SetStateAction<ILoginUserResponseDto>>;
}

export const HttpProviderContext = createContext<HttpProviderContextProps>({
  httpClient: null,
  token: null,
  setToken: noop,
  services: {
    Auth: null,
    User: null,
  },
});
