import { ReactElement } from 'react';
import { Root, Close } from './Notification.style';
import {
  hideNotification,
  showNotification,
} from './NotificationShow/NotificationShow.utils';

export interface NotificationProps {
  message: React.ReactNode;
  closable?: boolean;
}

interface NotificationComponent {
  (props: NotificationProps): ReactElement<any, any> | null;
  show: typeof showNotification;
  hide: typeof hideNotification;
}

export const Notification: NotificationComponent = ({ message, closable }) => {
  return (
    <Root
      type="info"
      closeIcon={<Close name="close" size="sm" />}
      closable={closable}
      description={message}
    />
  );
};

Notification.show = showNotification;
Notification.hide = hideNotification;
