import { PropsWithChildren, forwardRef, useEffect } from 'react';
import { useContextSelector } from 'use-context-selector';
import {
  DropdownContext,
  DropdownContextProps,
} from '../../../contexts/Dropdown.context';
import { Root } from './DropdownOverlay.style';

type DropdownOverlayProps = PropsWithChildren<{
  className?: string;
}>;

export const DropdownOverlay = forwardRef<HTMLDivElement, DropdownOverlayProps>(
  function DropdownOverlay({ className, children }, ref) {
    const set = useContextSelector<
      DropdownContextProps,
      DropdownContextProps['set']
    >(DropdownContext, ({ set }) => set);

    useEffect(() => {
      set(
        'overlayNode',
        <Root ref={ref} className={className}>
          {children}
        </Root>
      );
    }, [set, children, className, ref]);

    return null;
  }
);
