import styled from 'styled-components';
import { Avatar } from '../Avatar/Avatar';
import { UserpicSize } from './Userpic.types';
import { TITLE_COMPONENTS_MAP, SUBTITLE_COMPONENTS_MAP } from './Userpic.const';

export const Root = styled('div')`
  display: flex;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing(3)};
  &.ant-dropdown-trigger {
    cursor: pointer;
    &.ant-dropdown-trigger-with-chevron {
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: ${({ theme }) => theme.palette.stardustGrey} transparent
          transparent transparent;
        margin-left: ${({ theme: { spacing } }) => spacing(5)};
        transition: all 300ms ease;
      }
      &.ant-dropdown-open {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
`;

export const Pic = styled(Avatar)`
  flex-grow: 0;
  flex-shrink: 0;
`;

export const Info = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled.p.attrs<{
  size: UserpicSize;
}>(({ size }) => ({
  as: SUBTITLE_COMPONENTS_MAP[size],
}))<{
  size: UserpicSize;
}>`
  opacity: 0.7,
  overflow: 'hidden',
  'white-space': 'nowrap',
  'text-overflow': 'ellipsis',
`;

export const Title = styled.p.attrs<{
  size: UserpicSize;
}>(({ size }) => ({
  as: TITLE_COMPONENTS_MAP[size],
}))<{
  size: UserpicSize;
}>``;
