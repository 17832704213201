import { IconName } from '@spintel/uikit';
import { DTO } from '@spintel/network/auth';
import { UrlType } from '@spintel/navigation';

export const MAP_CONTACTS_DATA: Record<
  DTO.IStationContactTypeValue,
  { label: string; icon: IconName; urlType: UrlType }
> = {
  [DTO.IStationContactTypeValue.StudioLine]: {
    label: 'forms.studioLine',
    icon: 'phone',
    urlType: UrlType.Phone,
  },
  [DTO.IStationContactTypeValue.BusinessLine]: {
    label: 'forms.businessLine',
    icon: 'phone',
    urlType: UrlType.Phone,
  },
  [DTO.IStationContactTypeValue.Website]: {
    label: 'forms.website',
    icon: 'website',
    urlType: UrlType.External,
  },
  [DTO.IStationContactTypeValue.Email]: {
    label: 'forms.email',
    icon: 'email',
    urlType: UrlType.Email,
  },
};

const MAP_SOCIALS_DATA: Record<
  DTO.ISocialTypeValue,
  { label: string; icon: IconName }
> = {
  [DTO.ISocialTypeValue.Facebook]: {
    label: 'forms.facebook',
    icon: 'facebook',
  },
  [DTO.ISocialTypeValue.Instagram]: {
    label: 'forms.instagram',
    icon: 'instagram',
  },
  [DTO.ISocialTypeValue.Twitter]: {
    label: 'forms.twitter',
    icon: 'twitter',
  },
  [DTO.ISocialTypeValue.Youtube]: {
    label: 'forms.youtube',
    icon: 'youtube',
  },
};

const socials = Object.values(DTO.ISocialTypeValue).map((value) => ({
  key: value,
  icon: MAP_SOCIALS_DATA[value].icon,
  label: MAP_SOCIALS_DATA[value].label,
  urlType: UrlType.External,
}));

const contacts = Object.values(DTO.IStationContactTypeValue).map((value) => ({
  key: value,
  icon: MAP_CONTACTS_DATA[value].icon,
  label: MAP_CONTACTS_DATA[value].label,
  urlType: MAP_CONTACTS_DATA[value].urlType,
}));

const stationContacts = [...socials, ...contacts];

export { stationContacts };
