import React, {
  FC,
  PropsWithChildren,
  Key,
  useLayoutEffect,
  useState,
  ReactNode,
} from 'react';
import moment from 'moment';
import {
  LocalizationContext,
  LocalizationContextProps,
} from '../../contexts/Localization.context';
import { I18N } from './LocalizationProvider.service';

type LocalizationProviderProps = PropsWithChildren<
  LocalizationContextProps & {
    resources: Record<Key, PlainObject>;
    fallback: ReactNode;
  }
>;

export const LocalizationProvider: FC<LocalizationProviderProps> = ({
  children,
  locale,
  resources,
  fallback,
}) => {
  const [ready, setReady] = useState(false);

  useLayoutEffect(() => {
    moment.locale(locale);
  }, [locale]);

  useLayoutEffect(() => {
    I18N.init(locale, resources).then(() => {
      setReady(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocalizationContext.Provider value={{ locale }}>
      {ready ? children : fallback}
    </LocalizationContext.Provider>
  );
};
