import styled from 'styled-components';
import { FormItem } from '../../Form/FormItem/FormItem';
import { Root as FormCascader } from '../../Cascader/Cascader.style';

export const Content = styled('div')`
  .ant-cascader-menus {
    align-items: stretch;
  }
`;

export const Field = styled(FormItem)`
  display: none;
  margin-bottom: 0;
  + div {
    position: static !important;
    .ant-select-dropdown {
      opacity: 1 !important;
      box-shadow: none;
      position: static !important;
    }
  }
`;

export const Cascader = styled(FormCascader)``;
