import { createContext, Dispatch, SetStateAction } from 'react';
import { noop } from 'lodash';

interface ModalContextProps {
  name: string;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const ModalContext = createContext<ModalContextProps>({
  name: null,
  setVisible: noop,
});
