import styled from 'styled-components';
import { Typography } from '@spintel/uikit';

export const Text = styled(Typography.Body3)(({ theme: { spacing } }) => ({
  marginBottom: spacing(4),
}));

export const Link = styled('a')(() => ({
  textDecoration: 'underline',
}));

export const QRContainer = styled('div')(({ theme: { spacing } }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: spacing(4),
  padding: spacing(4),
}));
