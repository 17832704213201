import { DTO } from '@spintel/network/app';
import { OverviewRotationType, OverviewSongHistoryTab } from './Overview.types';

export const ROTATION_TYPE_MAP: Record<
  OverviewRotationType,
  DTO.IRotationTabType
> = {
  [OverviewRotationType.PLAYS]: DTO.IRotationTabType.Plays,
  [OverviewRotationType.RANK]: DTO.IRotationTabType.Rank,
};

export const SONG_HISTORY_TYPE_MAP: Record<
  OverviewSongHistoryTab,
  DTO.ISongHistoryTabType
> = {
  [OverviewSongHistoryTab.PLAYS]: DTO.ISongHistoryTabType.Plays,
  [OverviewSongHistoryTab.PLAYS_CHANGE]: DTO.ISongHistoryTabType.PlaysChange,
  [OverviewSongHistoryTab.RANK]: DTO.ISongHistoryTabType.Rank,
  [OverviewSongHistoryTab.RANK_CHANGE]: DTO.ISongHistoryTabType.RankChange,
  [OverviewSongHistoryTab.STATIONS_ON]: DTO.ISongHistoryTabType.StationsOn,
  [OverviewSongHistoryTab.NEW_ADDS]: DTO.ISongHistoryTabType.StationsAdds,
  [OverviewSongHistoryTab.DROPS]: DTO.ISongHistoryTabType.StationsDrops,
};

export const ROTATION_OUTLOOK_DEFAULT_COLS = [12, 12];
export const ROTATION_EXPANDED_COLS = [16, 8];
export const OUTLOOK_EXPANDED_COLS = [8, 16];
