import { FC, useRef, useImperativeHandle, memo, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotation from 'chartjs-plugin-annotation';
import { useContextSelector } from 'use-context-selector';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import { cloneDeep } from 'lodash';
import { GraphContext, GraphContextProps } from '../Graph.context';
import { GraphRangeBounds } from '../Graph.types';
import { DEFAULT_GRAPH_ID } from '../Graph.const';
import { Root } from './GraphLine.style';
import { useOptions, useDatasets } from './GraphLine.hooks';
import {
  GraphLineScales,
  GraphLineDatapoint,
  GraphLineVariant,
  GraphLineTooltip,
  GraphLineDataValue,
  GraphLineOnClickParams,
} from './GraphLine.types';

interface GraphLineProps {
  id?: string;
  className?: string;
  responsive?: boolean;
  scales?: GraphLineScales;
  tooltip?: GraphLineTooltip;
  variants?: GraphLineVariant[];
  dataline?: GraphLineDataValue;
  datapoint?: GraphLineDatapoint;
  onClick?: (v: GraphLineOnClickParams) => void;
}

Chart.register(ChartAnnotation);

export const GraphLine: FC<GraphLineProps> = memo(function GraphLine({
  dataline,
  datapoint,
  className,
  responsive,
  variants = [],
  id = DEFAULT_GRAPH_ID,
  onClick: onGraphClick,
  tooltip: tooltipOptions,
  scales: scalesOptions = {},
}) {
  const line = useRef<Chart<'line'>>();

  const _initialDatasets = useContextSelector<
    GraphContextProps,
    GraphContextProps['datasets']
  >(GraphContext, ({ datasets }) => datasets);

  const _labels = useContextSelector<
    GraphContextProps,
    GraphContextProps['labels']
  >(GraphContext, ({ labels }) => labels);

  const graph = useContextSelector<
    GraphContextProps,
    GraphContextProps['graph']
  >(GraphContext, ({ graph }) => graph);

  const rangeBounds = useContextSelector<GraphContextProps, GraphRangeBounds>(
    GraphContext,
    ({ rangeBounds }) => rangeBounds[id]
  );

  const labels = useMemo(() => {
    const l = [..._labels];
    return rangeBounds ? l.slice(rangeBounds.start, rangeBounds.end + 1) : l;
  }, [_labels, rangeBounds]);

  const initialDatasets = useMemo(() => {
    const d = cloneDeep(_initialDatasets);
    d.forEach((item) => {
      item.data = rangeBounds
        ? item.data.slice(rangeBounds.start, rangeBounds.end + 1)
        : item.data;
    });
    return d;
  }, [_initialDatasets, rangeBounds]);

  const [{ datasets }, { setActiveDatalabel, setActiveDataLine, onClick }] =
    useDatasets({
      variants,
      datapoint,
      onGraphClick,
      datasets: initialDatasets,
    });

  const [
    { tooltip, interaction, scales, legend, annotation, range },
    { mouseLine },
  ] = useOptions({
    id,
    labels,
    datapoint,
    scales: scalesOptions,
    tooltip: tooltipOptions,
  });

  useImperativeHandle(graph, () => ({ chart: line }));

  useUpdateEffect(() => {
    if (line.current) {
      setActiveDatalabel(datapoint);
      line.current.update();
    }
  }, [datapoint]);

  useUpdateEffect(() => {
    if (line.current) {
      setActiveDataLine(dataline);
      line.current.update();
    }
  }, [dataline]);

  const plugins = [ChartDataLabels, legend, range].concat(
    tooltipOptions?.display ? [mouseLine] : []
  );

  return (
    <Root className={className}>
      <Line
        ref={line}
        data={{ labels, datasets }}
        options={{
          responsive,
          scales: scales,
          onClick: onClick,
          plugins: { tooltip, annotation },
          interaction: interaction,
          maintainAspectRatio: !responsive,
        }}
        plugins={plugins}
      />
    </Root>
  );
});
