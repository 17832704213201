import { FC } from 'react';
import { Logo, Tooltip } from '@spintel/uikit';
import { DefaultLink } from '@spintel/navigation';
import {
  Root,
  LogoWrapper,
  List,
  ListItem,
  ListIcon,
  ListLink,
} from './Nav.style';
import { useNavConfig } from './Nav.hooks';

export const Nav: FC = () => {
  const navConfig = useNavConfig();

  return (
    <Root collapsed collapsedWidth={72}>
      <LogoWrapper>
        <DefaultLink>
          <Logo />
        </DefaultLink>
      </LogoWrapper>
      <List>
        {navConfig.map(({ url, icon, label }) => {
          return (
            <Tooltip content={label} placement="right" key={icon}>
              <ListItem>
                <ListLink to={url} activeClassName="active">
                  <ListIcon name={icon} size="md" />
                </ListLink>
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Root>
  );
};
