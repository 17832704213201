import { FC, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useMount } from 'react-use';
import { useTranslation } from '@spintel/localization';
import { TableProvider, Table, Button, Dropdown } from '@spintel/uikit';
import { Toolbar } from '@common/features';
import { useExport } from '@common/hooks';
import { DTO, useQuery } from '@spintel/network/app';
import { useColumns, useSorting } from './StationMusicStack.hooks';
import { StationMusicStackTable } from './StationMusicStackTable/StationMusicStackTable';
import { TableStyles } from './StationMusicStack.types';
import { StationMusicStackContext } from './StationMusicStack.context';

interface StationMusicStackProps {
  id: string;
  songId: number;
  stationId: number;
  tableStyles?: TableStyles;
  origin: DTO.IExportNameType;
}

export const StationMusicStack: FC<StationMusicStackProps> = ({
  id,
  songId,
  stationId,
  tableStyles,
  origin: type,
}) => {
  const { t } = useTranslation();
  const search = useRef<HTMLDivElement>();
  const { sorting, setSorting } = useSorting();
  const [{ exporting }, onExport] = useExport(
    ({ Station }) =>
      () =>
        Station.musicStackExportCreate({ songId, stationId, type })
  );

  const [{ data, loading }] = useQuery<
    [number, number],
    DTO.IStationMusicStackResponse[]
  >(({ Station }) => Station.musicStackDetail, [songId, stationId]);

  const { columns } = useColumns({ order: sorting?.order });

  // TODO: A bit dirty hook to force render. Will be removed during the implementation stories that are related to empty states
  useMount(() => setSorting((prev) => ({ ...prev })));

  return (
    <TableProvider id={id} columns={columns}>
      <StationMusicStackContext.Provider value={{ stationId, songId }}>
        <Toolbar>
          <Toolbar.Section ref={search} />
          <Toolbar.Section>
            <Table.ColumnsSelect toggleAll={t('options.all')}>
              <Button>{t('actions.columns')}</Button>
            </Table.ColumnsSelect>
            <Dropdown placement="bottomRight">
              <Dropdown.Toggle chevron={false}>
                <Button circle icon="more" />
              </Dropdown.Toggle>
              <Dropdown.Menu
                items={[
                  {
                    key: 'export',
                    label: t('actions.export'),
                    disabled: exporting,
                    onClick: () => onExport(songId),
                  },
                ]}
              />
            </Dropdown>
          </Toolbar.Section>
        </Toolbar>
        <StationMusicStackTable
          data={data}
          loading={loading}
          sorting={sorting}
          onSort={setSorting}
          styles={tableStyles}
        >
          {search.current &&
            createPortal(
              <Table.Search
                dataIndex={['songName']}
                placeholder={t('placeholders.song')}
              />,
              search.current
            )}
        </StationMusicStackTable>
      </StationMusicStackContext.Provider>
    </TableProvider>
  );
};
