import { FC, PropsWithChildren } from 'react';
import { NavigationProvider } from '@spintel/navigation';
import { LocalizationProvider } from '@spintel/localization';
import { useUser } from '@spintel/network/auth';
import { USER_ROLE_DEFAULT_URL } from '@common/config';
import { UserRole } from '@common/constants';
import { resources } from '@common/locale';
import { Splash } from '@spintel/uikit';
import { AuthRoutePath } from '@spintel/shared';
import { NotificationsBootstrap } from '../NotificationsBootstrap/NotificationsBootstrap';
import { Permissions } from '../../Permissions/Permissions';

type UserBootstrapProps = PropsWithChildren<Partial<Window['APP_CONFIG']>>;

export const UserBootstrap: FC<UserBootstrapProps> = ({
  children,
  basename,
}) => {
  const [{ role }] = useUser();

  return (
    <LocalizationProvider
      locale="en"
      resources={resources}
      fallback={<Splash />}
    >
      <NavigationProvider
        role={role as UserRole}
        basename={basename}
        defaultUrl={USER_ROLE_DEFAULT_URL[role as UserRole]}
        protectedFallback={<Permissions />}
        loginUrl={AuthRoutePath.LOGIN}
      >
        <NotificationsBootstrap>{children}</NotificationsBootstrap>
      </NavigationProvider>
    </LocalizationProvider>
  );
};
