import { FC, Key, createElement, FunctionComponent } from 'react';
import AntdForm from 'antd/lib/form';
import { noop } from 'lodash';
import useFormInstance from 'antd/lib/form/hooks/useFormInstance';
import { FormListValidationRules } from '../FormItem/FormItem.types';
import { FormItem } from './FormList.style';
import { FormListHeader } from './FormListHeader/FormListHeader';
import { FormListItem } from './FormListItem/FormListItem';
export interface FormListChildrenProps {
  name: number;
}

interface FormListProps {
  add?: string;
  children: FunctionComponent<FormListChildrenProps>;
  name: Key | Key[];
  validation?: FormListValidationRules;
  label?: string;
  nextInitialValue?: (form: unknown) => unknown;
}

const { List } = AntdForm;

export const FormList: FC<FormListProps> = ({
  name,
  add,
  label,
  children,
  validation = {},
  nextInitialValue = noop,
}) => {
  const { max = Number.MAX_VALUE, min = 1 } = validation;
  const form = useFormInstance();

  return (
    <List name={name}>
      {(fields, { add: onAdd, remove: onRemove }) => (
        <FormItem
          label={
            <FormListHeader
              label={label}
              anchor={add}
              onAdd={() => onAdd(nextInitialValue(form.getFieldsValue()))}
              max={max}
              total={fields.length}
            />
          }
        >
          {fields.map(({ name }) => {
            return (
              <FormListItem
                key={name}
                min={min}
                total={fields.length}
                onRemove={() => onRemove(name)}
              >
                {createElement(children, { name })}
              </FormListItem>
            );
          })}
        </FormItem>
      )}
    </List>
  );
};
