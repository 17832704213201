import { useCallback } from 'react';
import { UseMutationState } from '@shared/types';
import { useMutation } from '../useMutation/useMutation.hook';
import {
  IForgotPasswordDto,
  IErrorResponse,
} from '../../__generated__/data-contracts';
import { RequestParams } from '../../__generated__/http-client';

type ForgotPasswordFunction = (
  form: IForgotPasswordDto,
  onSuccess: () => void
) => void;

type UseForgotPasswordReturnType = [
  UseMutationState<unknown, IErrorResponse>,
  ForgotPasswordFunction
];

export const useForgotPassword = (): UseForgotPasswordReturnType => {
  const [state, execute] = useMutation<[IForgotPasswordDto, RequestParams]>(
    ({ Auth }) => Auth.forgotPasswordCreate
  );

  const forgotPassword: ForgotPasswordFunction = useCallback(
    (form, onSuccess) => {
      execute(
        [
          form,
          {
            headers: {
              Intercept: false,
            },
          },
        ],
        {
          onSuccess,
        }
      );
    },
    [execute]
  );

  return [state, forgotPassword];
};
