import styled from 'styled-components';
import { Subtitle2, Body3 } from '../../../Typography/Typography.style';

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 100%;
  gap: ${({ theme: { spacing } }) => `${spacing(3)} ${spacing(6)}`};
`;

export const Item = styled('div')`
  display: flex;
  align-items: center;

  width: ${({ theme: { spacing } }) => spacing(66.5)};
  height: ${({ theme: { spacing } }) => spacing(6)};
`;

export const Label = styled(Body3)
  .attrs({
    as: 'span',
  })
  .withConfig({
    shouldForwardProp: (prop) => !['inactive'].includes(prop),
  })<{ inactive?: boolean }>`
  color: ${({ theme: { palette }, inactive }) =>
    palette[inactive ? 'pearlGrey' : 'white']};
`;

export const Value = styled(Subtitle2).attrs({
  as: 'span',
})`
  margin-right: ${({ theme: { spacing } }) => spacing(2)};
`;

export const ItemContainer = styled('div')`
  max-width: 100%;

  :hover {
    cursor: pointer;
  }

  & .ant-badge-status-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
  }
`;
