import { FC, ReactNode } from 'react';
import { PaginationProps } from 'antd/lib/pagination';
import { Button } from '../../Button/Button';

export interface PaginationItemProps {
  page: number;
  type: Parameters<PaginationProps['itemRender']>['1'];
  original: ReactNode;
  current: number;
}

export const PaginationItem: FC<PaginationItemProps> = ({
  page,
  type,
  current,
  //   original,
}) => {
  if (type === 'prev') {
    return <Button circle icon="arrow-left" disabled={page === 0}></Button>;
  }

  if (type === 'next') {
    return (
      <Button circle icon="arrow-right" disabled={page === current}></Button>
    );
  }

  if (type === 'jump-next' || type === 'jump-prev') {
    return <Button circle icon="more" variant="muted"></Button>;
  }

  return (
    <Button circle variant={page === current ? 'secondary' : 'text'}>
      {page}
    </Button>
  );
};
