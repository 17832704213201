import { createContext } from 'use-context-selector';
import { noop } from 'lodash';
import { DTO } from '@spintel/network/app';

export interface ShellContextProps {
  progress: boolean;
  refetchPanels: () => void;
  setSong: (v: number) => Promise<number>;
  panels: DTO.IPanelInfoModel[];
  song: DTO.IGetSongSelectorResponse;
}

export const ShellContext = createContext<ShellContextProps>({
  song: null,
  panels: [],
  setSong: () => Promise.resolve(null),
  progress: false,
  refetchPanels: noop,
});
