import React, { ReactElement } from 'react';
import { FormItem, FormItemProps } from '../FormItem/FormItem';
import { FormInputValidationRules } from '../FormItem/FormItem.types';
import { RootTextarea as Textarea } from '../../Input/Input.style';

interface FormTextareaProps<T>
  extends FormItemProps<FormInputValidationRules<T>> {
  rows?: number;
}

interface FormTextareaComponent {
  <T>(props: FormTextareaProps<T>): ReactElement<any, any> | null;
}

export const FormTextarea: FormTextareaComponent = ({
  disabled,
  placeholder,
  rows = 5,
  ...props
}) => {
  return (
    <FormItem {...props}>
      <Textarea disabled={disabled} placeholder={placeholder} rows={rows} />
    </FormItem>
  );
};
