import { useContext } from 'react';
import { get, mapValues } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import {
  TableContext,
  TableContextProps,
} from '../../../contexts/Table.context';
import { TableGlobalColumnsExtendedType } from '../Table.types';
import { COLUMN_SELECT_ALL_DATA_INDEX } from '../Table.const';
import { UiKitLocalizationContext } from '../../../contexts/UiKitLocalization.context';

type UseTableColumnsSelectColumnsReturnType = [
  { columns: TableGlobalColumnsExtendedType<object> }
];

export const useTableColumnsSelectColumns =
  (): UseTableColumnsSelectColumnsReturnType => {
    const { table } = useContext(UiKitLocalizationContext);

    const columns = useContextSelector<
      TableContextProps,
      TableContextProps['columns']
    >(TableContext, ({ columns }) => columns);

    const columnsWithAllOption: TableGlobalColumnsExtendedType<object> = [
      {
        type: 'column',
        title: table.columnsSelector.toggle,
        id: COLUMN_SELECT_ALL_DATA_INDEX,
        dataIndex: COLUMN_SELECT_ALL_DATA_INDEX,
        select: {
          default: false,
          group: COLUMN_SELECT_ALL_DATA_INDEX,
          onChange: ({ selection }) => {
            const value = get(selection, 'all', false);
            return mapValues(selection, () => value);
          },
        },
      },
      ...columns,
    ];

    return [{ columns: columnsWithAllOption }];
  };
