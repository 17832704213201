import { lazy } from 'react';
import { RoutePropsWithChildren } from '@spintel/navigation';
import { RoutePath } from '@common/constants';

const Management = lazy(() =>
  import('./Management/Management').then((m) => ({ default: m.Management }))
);

const ManagementMembers = lazy(() =>
  import('./Management/Members/Members').then((m) => ({ default: m.Members }))
);

const ManagementTeams = lazy(() =>
  import('./Management/Teams/Teams').then((m) => ({ default: m.Teams }))
);

export const ROUTES: RoutePropsWithChildren[] = [
  {
    childRoutes: [
      {
        element: <ManagementMembers />,
        index: true,
      },
      {
        element: <ManagementTeams />,
        path: RoutePath.MANAGEMENT_TEAMS,
      },
    ],
    element: <Management />,
    path: RoutePath.MANAGEMENT,
  },
];
