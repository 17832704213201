import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  ScatterDataPoint,
  Tooltip as ChartJSTooltip,
  ArcElement,
} from 'chart.js';
import { FC, PropsWithChildren, useRef, useState } from 'react';
import Empty from 'antd/lib/empty';
import { UiKitColor } from '../../types/uikit';
import {
  ChartContext,
  ChartContextProps,
  Range,
  RangeParams,
} from './Chart.context';
import { ChartLine } from './ChartLine/ChartLine';
import { Range as RangeComponent } from './Range/Range';
import { Root } from './Chart.style';
import { ChartLineOnClickParams, TooltipContainerRefType } from './Chart.types';
import { Tooltip } from './Tooltip/Tooltip';
import { TooltipContainer } from './TooltipContainer/TooltipContainer';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  ChartJSTooltip,
  ArcElement
);

type ChartProps = PropsWithChildren<{
  data: ScatterDataPoint[];
  labels: string[];
  onClick?: ({ dataIndex }: ChartLineOnClickParams) => void;
  color?: UiKitColor;
  className?: string;
  selectedIndex?: number;
  emptyText?: string;
  reverse?: boolean;
}>;

interface ChartType {
  Line: typeof ChartLine;
  Range: typeof RangeComponent;
  Tooltip: typeof Tooltip;
}

export const Chart: FC<ChartProps> & ChartType = ({
  data,
  children,
  labels,
  onClick,
  color = 'spintelGreen',
  selectedIndex,
  emptyText,
  reverse,
}) => {
  const tooltipRef = useRef<TooltipContainerRefType>(null);
  const [chartRange, setChartRange] = useState<Range>({
    x: [0, data.length],
  });
  const [tooltipDataIndex, setTooltipDataIndex] = useState<number>(null);
  const [instance, setInstance] = useState<ChartJS<any>>();

  const setRange = (newRange: RangeParams) => {
    setChartRange({
      ...chartRange,
      ...newRange,
    });
  };

  const contextValue: ChartContextProps = {
    data,
    range: chartRange,
    reverse,
    labels,
    setRange,
    onClick,
    color,
    setInstance,
    instance,
    selectedIndex,
    tooltipRef: tooltipRef,
    setTooltipDataIndex: setTooltipDataIndex,
    tooltipDataIndex: tooltipDataIndex,
  };

  return (
    <ChartContext.Provider value={contextValue}>
      <Root>
        {data.length < 2 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText} />
        ) : (
          children
        )}
        <TooltipContainer ref={tooltipRef} />
      </Root>
    </ChartContext.Provider>
  );
};

Chart.Line = ChartLine;
Chart.Range = RangeComponent;
Chart.Tooltip = Tooltip;
