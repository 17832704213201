import { NumberFormat } from '../types/number.types';
import { Locale } from '../types/locale.types';

type FormatMap = Record<NumberFormat, string>;

export const NUMBER_FORMAT_MAP: Record<Locale, FormatMap> = {
  en: {
    f1: '#,##0.##',
  },
};
