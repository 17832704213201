import { useMemo } from 'react';
import { flatten } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import { TableContext, TableContextProps } from '../../contexts/Table.context';
import { TableColumnsType } from '../../components/Table/Table.types';
import { filterColumnsBySelection } from './useTableConfig.utils';

interface UseTableConfigReturnType<T extends object> {
  columns: TableColumnsType<T>;
  selected: TableColumnsType<T>;
}

export const useTableConfig = <
  T extends object
>(): UseTableConfigReturnType<T> => {
  const columns = useContextSelector<
    TableContextProps,
    TableContextProps['columns']
  >(TableContext, ({ columns }) => columns);

  const selection = useContextSelector<
    TableContextProps,
    TableContextProps['selection']
  >(TableContext, ({ selection }) => selection);

  const selected: TableColumnsType<object> = useMemo(
    () => filterColumnsBySelection({ columns, selection: flatten(selection) }),
    [columns, selection]
  );

  return {
    columns: columns as TableColumnsType<T>,
    selected: selected as TableColumnsType<T>,
  };
};
