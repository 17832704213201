import { FC, PropsWithChildren } from 'react';
import { UiKitColumns } from '../../../types/uikit';
import { Root } from './FormRow.style';
import { FormRowGutter } from './FormRow.types';

type FormRowProps = PropsWithChildren<{
  cols: UiKitColumns[];
  gutter?: FormRowGutter;
}>;

export const FormRow: FC<FormRowProps> = ({
  cols,
  children,
  gutter = 'md',
}) => {
  return (
    <Root cols={cols} gutter={gutter}>
      {children}
    </Root>
  );
};
