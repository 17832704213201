import { FC, PropsWithChildren } from 'react';
import { useContextSelector } from 'use-context-selector';
import { SwitchContext, SwitchContextProps } from '../UserSwitchRole.context';

type UserSwitchRoleDefaultProps = PropsWithChildren<unknown>;

export const UserSwitchRoleDefault: FC<UserSwitchRoleDefaultProps> = ({
  children,
}) => {
  const map = useContextSelector<SwitchContextProps, SwitchContextProps['map']>(
    SwitchContext,
    ({ map }) => map
  );

  const condition = Object.values(map).every((v) => !v);

  return condition ? <>{children}</> : null;
};
