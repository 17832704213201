import { FC, useRef, useLayoutEffect, useState, useContext } from 'react';
import { get } from 'lodash';
import { Tooltip } from '../../Tooltip/Tooltip';
import { TableColumnType, TableColumnEmpty } from '../Table.types';
import { UiKitProviderContext } from '../../../contexts/UiKitProvider.context';
import { Root, Dash } from './TableCell.style';

interface TableCellProps extends Partial<TableColumnType<any>> {
  index: number;
  item: object;
  empty: TableColumnEmpty;
}

export const TableCell: FC<TableCellProps> = ({
  render,
  item,
  index,
  dataIndex = [],
  ellipsis,
  empty,
  title,
}) => {
  const ref = useRef<HTMLDivElement>();
  const value = get(item, dataIndex);
  const {
    table: {
      cell: { formatter },
    },
  } = useContext(UiKitProviderContext);

  const calculated = (() => {
    if (typeof ellipsis === 'function') {
      return ellipsis(value, item);
    }
  })();

  const [tooltip, setTooltip] = useState<string | string[]>(calculated);

  const inner = (() => {
    if (value === null && empty?.null === 'dash') {
      return <Dash />;
    }
    if (value === null && empty?.null === 'zero') {
      return 0;
    }
    if (value === '' && empty?.empty === 'dash') {
      return <Dash />;
    }
    if (value === '' && empty?.empty === 'zero') {
      return 0;
    }
    if (render) {
      const formatted = formatter(value);
      return render(formatted, item, index);
    }
    return value;
  })();

  useLayoutEffect(() => {
    const { current } = ref;
    if (current && current.offsetWidth < current.scrollWidth) {
      setTooltip(calculated || ref.current.innerText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (ellipsis) {
    return (
      <Tooltip title={title} content={tooltip} placement="topLeft">
        <Root ref={ref}>{inner}</Root>
      </Tooltip>
    );
  }

  return inner;
};
