import { FC } from 'react';
import { Empty } from '@spintel/uikit';
import { useTranslation } from '@spintel/localization';

export const Permissions: FC = () => {
  const { t } = useTranslation();

  return (
    <Empty
      fill
      size="lg"
      icon="close"
      background="gradient"
      title={t('empty.noAccess')}
    />
  );
};
