import styled from 'styled-components';
import Tabs from 'antd/lib/tabs';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

export const Root = styled(Tabs).withConfig({
  shouldForwardProp: (prop) => !['border'].includes(prop),
})<{ size: SizeType; border: boolean }>`
  font-feature-settings: 'kern' off;
  .ant-tabs-nav {
    &:before {
      visibility: ${({ border }) => (border ? 'visible' : 'hidden')};
    }
    .ant-tabs-ink-bar {
      bottom: 1px;
    }
    .ant-tabs-tab-btn {
      &:focus,
      &:active {
        color: inherit;
      }
    }
    .ant-tabs-tab {
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme.palette.londonGrey};
      user-select: none;

      &:hover:not(.ant-tabs-tab-disabled, .ant-tabs-tab-active) {
        color: ${({ theme }) => theme.palette.pearlGrey};
      }

      &.ant-tabs-tab-active {
        color: ${({ theme }) => theme.palette.white};
      }

      &.ant-tabs-tab-disabled {
        opacity: 0.4;
      }
    }
  }
`;
