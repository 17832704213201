import { createGlobalStyle } from 'styled-components';

export const Global = createGlobalStyle`
    .ant-pagination {
        &.ant-table-pagination-left {
            .ant-pagination-prev {
                margin-right: ${({ theme: { spacing } }) => spacing(4)};
            }
            .ant-pagination-next {
                margin-left: ${({ theme: { spacing } }) => spacing(2)};
            }
            .ant-pagination-item {
                border: none;
            }
            .ant-pagination-options {
                flex: 1 1 auto;
                display: flex;
                justify-content: flex-end;
            }

            .ant-pagination-options-size-changer {
                .ant-select-selector {
                    border-radius: ${({ theme }) => theme.spacing(5)};

                    .ant-select-selection-item {
                        font-size: 12px;
                        font-weight: 600;

                        .ant-select-item-option {
                            font-size: 12px;
                            font-weight: 600;
                            padding: 0 ${({ theme }) => theme.spacing(2)};
                        }
                    }
                }

                .ant-select-arrow {
                    &:after {
                        content: '';
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 4px 3.5px 0 3.5px;
                        border-color: ${({ theme }) =>
                          theme.palette
                            .white} transparent transparent transparent;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                    .anticon-down {
                        visibility: hidden;
                    }
                }

                .ant-select-dropdown {
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 600;


                }

                &.ant-select-open,
                &.ant-select-focused {
                    .ant-select-selector {
                        border-color: ${({ theme }) =>
                          theme.palette.spintelGreen};

                          .ant-select-selection-item {
                            color: ${({ theme }) => theme.palette.white};
                          }
                    }
                }

                & .ant-select-dropdown {
                    &.ant-slide-up-appear, &.ant-slide-up-enter, &.ant-slide-up-leave {
                        animation-duration: 0s !important;
                    }
                }
            }
        }
    }
`;
