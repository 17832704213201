import styled, { createGlobalStyle } from 'styled-components';
import { Typography } from '../Typography/Typography';
import { UiKitColor } from '../../types/uikit';
import { Button } from '../Button/Button';

const { Body3 } = Typography;

export const Text = styled(Body3)<{ color: UiKitColor }>`
  color: ${({ theme, color }) => theme.palette[color]};
  & + & {
    margin-top: ${({ theme: { spacing } }) => spacing(2)};
  }
`;

export const More = styled(Button)`
  margin-top: ${({ theme: { spacing } }) => spacing(2)};
`;

export const Global = createGlobalStyle`
  .ant-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        min-height: auto;
      }
    }
  }
`;
