import { ReactElement, memo, useMemo } from 'react';
import { invoke, flatten, find } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import classNames from 'classnames';
import {
  TableContext,
  TableContextProps,
} from '../../../contexts/Table.context';
import { TableColumnsType } from '../Table.types';
import { getColumnSelectionTree, getFlattenColumns } from '../Table.utils';
import { Row, Cell } from './TableSummary.style';
import { TableSummaryCell } from './TableSummaryCell/TableSummaryCell';

interface TableSummaryProps<T extends object> {
  columns: TableColumnsType<T>;
  data: readonly T[];
}

export interface TableSummaryComponent {
  <T extends object>(props: TableSummaryProps<T>): ReactElement<
    any,
    any
  > | null;
}

export const TableSummary: TableSummaryComponent = memo(({ columns, data }) => {
  const flat = getFlattenColumns(columns);

  const selection = useContextSelector<
    TableContextProps,
    TableContextProps['selection']
  >(TableContext, ({ selection }) => selection);

  const globalColumns = useContextSelector<
    TableContextProps,
    TableContextProps['columns']
  >(TableContext, ({ columns }) => columns);

  const selectionTree = useMemo(() => {
    const flat = flatten(selection);
    return getColumnSelectionTree(globalColumns, flat);
  }, [selection, globalColumns]);

  const flattenGlobalColumns = useMemo(
    () => getFlattenColumns(globalColumns),
    [globalColumns]
  );

  return (
    <Row>
      {flat.map((item, index) => {
        const { summary, className, dataIndex } = item;
        return (
          <Cell
            key={dataIndex.toString()}
            index={index}
            colSpan={invoke(summary, 'colSpan', { selection: selectionTree })}
            className={classNames(className, { 'no-summary': !summary })}
          >
            <TableSummaryCell
              data={data}
              render={summary?.render}
              value={invoke(summary, 'value', { data })}
              empty={
                find(flattenGlobalColumns, ['dataIndex', dataIndex])?.empty
              }
            />
          </Cell>
        );
      })}
    </Row>
  );
});
