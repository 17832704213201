import { createContext } from 'react';
import { Panel } from '../__generated__/Panel';
import { User } from '../__generated__/User';
import { Song } from '../__generated__/Song';
import { SongAirplay } from '../__generated__/SongAirplay';
import { SongAddOpportunities } from '../__generated__/SongAddOpportunities';
import { PanelCharts } from '../__generated__/PanelCharts';
import { Station } from '../__generated__/Station';
import { Rotation } from '../__generated__/Rotation';
import { Outlook } from '../__generated__/Outlook';
import { SongComparison } from '../__generated__/SongComparison';
import { SongSelector } from '../__generated__/SongSelector';
import { SongPlayIncrease } from '../__generated__/SongPlayIncrease';
import { SongOwnership } from '../__generated__/SongOwnership';
import { Team } from '../__generated__/Team';
import { SongCompetition } from '../__generated__/SongCompetition';

export interface HttpProviderContextProps {
  Panel: Panel;
  User: User;
  Song: Song;
  SongAirplay: SongAirplay;
  SongAddOpportunities: SongAddOpportunities;
  PanelCharts: PanelCharts;
  Station: Station;
  Rotation: Rotation;
  Outlook: Outlook;
  SongComparison: SongComparison;
  SongSelector: SongSelector;
  SongPlayIncrease: SongPlayIncrease;
  SongOwnership: SongOwnership;
  Team: Team;
  SongCompetition: SongCompetition;
}

export const HttpProviderContext = createContext<HttpProviderContextProps>({
  Panel: null,
  User: null,
  Song: null,
  SongAirplay: null,
  SongAddOpportunities: null,
  PanelCharts: null,
  Station: null,
  Rotation: null,
  Outlook: null,
  SongComparison: null,
  SongSelector: null,
  SongPlayIncrease: null,
  SongOwnership: null,
  Team: null,
  SongCompetition: null,
});
