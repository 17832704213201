import { createContext } from 'react';

interface StationMusicStackContextParams {
  songId: number;
  stationId: number;
}

export const StationMusicStackContext =
  createContext<StationMusicStackContextParams>({
    songId: null,
    stationId: null,
  });
