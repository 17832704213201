import { FC, PropsWithChildren, useLayoutEffect } from 'react';
import { useLocation } from '@spintel/navigation';
import { Alert } from '@spintel/uikit';

interface NotificationsBootstrapProps extends PropsWithChildren {}

export const NotificationsBootstrap: FC<NotificationsBootstrapProps> = ({
  children,
}) => {
  const location = useLocation();

  useLayoutEffect(() => {
    Alert.clear();
  }, [location]);

  return <>{children}</>;
};
