import styled from 'styled-components';
import { CSSProperties } from 'react';
import { Typography } from '../Typography/Typography';

const { Body3, Heading3 } = Typography;

export const Root = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme: { spacing } }) => spacing(7)};
  overflow: auto;
  position: relative;
`;

export const Logo = styled('img')(({ theme: { spacing } }) => ({
  height: spacing(20),
  marginBottom: spacing(7),
  width: 'auto',
}));

export const Title = styled(Heading3)(({ theme: { spacing } }) => ({
  marginBottom: spacing(6),
}));

export const Subtitle = styled(Body3)(({ theme: { spacing } }) => ({
  marginBottom: spacing(6),
}));

export const Container = styled('div')(({ theme: { spacing } }) => ({
  width: spacing(106),
}));

export const Content = styled('div')<{ gutter?: number }>(
  ({ gutter = 0, theme: { spacing } }) => ({
    marginBottom: spacing(gutter),
  })
);

export const Actions = styled('div')<{
  justify?: CSSProperties['justifyContent'];
}>(({ justify = 'flex-end' }) => ({
  display: 'flex',
  justifyContent: justify,
}));
