import { createContext } from 'use-context-selector';
import { noop } from 'lodash';
import { ICurrentUserResponse } from '../__generated__/data-contracts';

export interface AuthProviderContextProps {
  role: unknown;
  loading: boolean;
  refetch: () => void;
  user: ICurrentUserResponse;
}

export const AuthProviderContext = createContext<AuthProviderContextProps>({
  user: null,
  role: null,
  refetch: noop,
  loading: false,
});
