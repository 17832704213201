import { useCallback } from 'react';
import { UseMutationState } from '@shared/types';
import { useMutation } from '../useMutation/useMutation.hook';
import {
  IResetPasswordDto,
  IErrorResponse,
} from '../../__generated__/data-contracts';
import { RequestParams } from '../../__generated__/http-client';

type ResetPasswordFunction = (
  form: IResetPasswordDto,
  onSuccess?: () => void
) => void;

type UseResetPasswordFunctionReturnType = [
  UseMutationState<unknown, IErrorResponse>,
  ResetPasswordFunction
];

export const useResetPassword = (): UseResetPasswordFunctionReturnType => {
  const [state, execute] = useMutation<[IResetPasswordDto, RequestParams]>(
    ({ Auth }) => Auth.resetPasswordCreate
  );

  const resetPassword: ResetPasswordFunction = useCallback(
    (form, onSuccess) => {
      execute(
        [
          form,
          {
            headers: {
              Intercept: false,
            },
          },
        ],
        {
          onSuccess,
        }
      );
    },
    [execute]
  );

  return [state, resetPassword];
};
