import styled from 'styled-components';
import { Typography } from '../../Typography/Typography';

const { Body3 } = Typography;

export const Root = styled('div')<{ width: number }>`
  display: flex;
  flex-direction: column;
  ${({ width }) => width && `width: ${width}px`};
  background: ${({ theme }) => theme.palette.icarusGrey};
`;

export const Footer = styled('div').withConfig({
  shouldForwardProp: (prop) => !['withHint'].includes(prop),
})<{ withHint: boolean }>`
  padding: ${({ theme: { spacing } }) => spacing(4)};
  border-top: 1px solid ${({ theme }) => theme.palette.asphaltGrey};
  display: flex;
  justify-content: ${({ withHint }) =>
    withHint ? 'space-between' : 'flex-end'};
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing(2)};
`;

export const Actions = styled('div')`
  display: flex;
  gap: ${({ theme: { spacing } }) => spacing(2)};
`;

export const Hint = styled(Body3)`
  color: ${({ theme }) => theme.palette.stardustGrey};
`;

export const Content = styled('div')``;
