import { ReactElement, Key } from 'react';
import { get, uniqBy } from 'lodash';
import AntdForm from 'antd/lib/form';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { FormItem, FormItemProps } from '../FormItem/FormItem';
import { FormCheckboxGroupValidationRules } from '../FormItem/FormItem.types';
import {
  FormCheckboxGroupOption,
  FormCheckboxGroupDirection,
} from './FormCheckboxGroup.types';
import { DEFAULT_FIELD } from './FormCheckboxGroup.const';
import { Root, Option } from './FormCheckboxGroup.style';

interface FormCheckboxGroupProps<T extends object = FormCheckboxGroupOption>
  extends Omit<FormItemProps<FormCheckboxGroupValidationRules>, 'disabled'> {
  options: T[];
  fields?: Partial<FormCheckboxGroupOption>;
  direction?: FormCheckboxGroupDirection;
  disabled?: (option: T) => boolean;
}

const { useWatch, useFormInstance } = AntdForm;

export interface FormCheckboxGroupComponent {
  <T extends object>(props: FormCheckboxGroupProps<T>): ReactElement<
    any,
    any
  > | null;
}

export const FormCheckboxGroup: FormCheckboxGroupComponent = ({
  options: initialOptions,
  fields,
  direction = 'column',
  disabled = () => false,
  preserve = false,
  ...props
}) => {
  const form = useFormInstance();
  const value: Key[] = useWatch(props.name, form) || [];

  const options: CheckboxOptionType[] = initialOptions
    .map(
      (item): CheckboxOptionType => ({
        disabled: disabled(item),
        label: get(item, fields?.label || DEFAULT_FIELD.label),
        value: get(item, fields?.value || DEFAULT_FIELD.value),
      })
    )
    .concat(
      preserve
        ? value.map((value) => ({
            value,
            label: value,
            style: { display: 'none' },
          }))
        : []
    );

  const uniq = uniqBy(options, 'value');

  return (
    <FormItem hasFeedback {...props}>
      <Root direction={direction} defaultValue={value}>
        {uniq.map(({ value, label, style, disabled }) => {
          return (
            <Option
              style={style}
              value={value}
              key={`${value}`}
              disabled={disabled}
            >
              {label}
            </Option>
          );
        })}
      </Root>
    </FormItem>
  );
};
