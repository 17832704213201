import { FC, useState } from 'react';
import { Button, Dropdown, Search, Table, TableProvider } from '@spintel/uikit';
import { Toolbar } from '@common/features';
import { useTranslation } from '@spintel/localization';
import { DTO } from '@spintel/network/app';
import { useExport } from '../../../song/routes/Song/Song.hooks';
import { TableStyles } from './StationPossibleSpace.types';
import { useTableData } from './StationPossibleSpaceTable/StationPossibleSpaceTable.hooks';
import { StationPossibleSpaceTable } from './StationPossibleSpaceTable/StationPossibleSpaceTable';
import { useColumns, useSorting } from './StationPossibleSpace.hooks';
import { StationPossibleSpaceContext } from './StationPossibleSpace.context';

interface StationPossibleSpaceProps {
  stationId: number;
  songId: number;
  tableIdSuffix: string;
  tableStyles?: TableStyles;
  origin: DTO.IExportNameType;
}

export const StationPossibleSpace: FC<StationPossibleSpaceProps> = ({
  songId,
  stationId,
  origin: type,
  tableStyles,
  tableIdSuffix,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { sorting, setSorting } = useSorting();

  const { data, loading } = useTableData({ searchTerm, songId, stationId });

  const [{ exporting }, onExport] = useExport(
    ({ Station }) =>
      () =>
        Station.possibleSpaceExportCreate({ songId, stationId, type })
  );

  const { columns } = useColumns({ order: sorting?.order });

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  return (
    <StationPossibleSpaceContext.Provider value={{ stationId }}>
      <TableProvider
        id={`station-possible-space-${tableIdSuffix}`}
        columns={columns}
      >
        <Toolbar>
          <Toolbar.Section>
            <Search
              value={searchTerm}
              onChange={handleSearch}
              placeholder={t('placeholders.song')}
            />
          </Toolbar.Section>
          <Toolbar.Section>
            <Table.ColumnsSelect toggleAll={t('options.all')}>
              <Button>{t('actions.columns')}</Button>
            </Table.ColumnsSelect>
            <Dropdown placement="bottomRight">
              <Dropdown.Toggle chevron={false}>
                <Button circle icon="more" />
              </Dropdown.Toggle>
              <Dropdown.Menu
                items={[
                  {
                    key: 'export',
                    label: t('actions.export'),
                    disabled: exporting,
                    onClick: onExport,
                  },
                ]}
              />
            </Dropdown>
          </Toolbar.Section>
        </Toolbar>
        <StationPossibleSpaceTable
          data={data}
          loading={loading}
          sorting={sorting}
          styles={tableStyles}
          onSort={setSorting}
        />
      </TableProvider>
    </StationPossibleSpaceContext.Provider>
  );
};
