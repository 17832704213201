const notifications = {
  instructionWasSentTo:
    'Instructions were successfully sent to <bold>{{email}}</bold>',
  deactivated: {
    title: 'Your account is deactivated',
    description: 'Contact your account manager to make account active',
  },
};

export default notifications;
