import { ReactElement, Key, useState, useEffect, useRef } from 'react';
import cs from 'classnames';
import { get, merge, noop } from 'lodash';
import {
  SelectProps as AntdSelectProps,
  RefSelectProps as AntdRefSelectProps,
} from 'antd/lib/select';
import { Root, SuffixIcon } from './Select.style';
import {
  SelectOption,
  SelectOptionProps,
  SelectVariant,
  SelectSize,
} from './Select.types';
import {
  DEFAULT_SELECT_OPTION_PROPS,
  SELECT_SIZE_MAP,
  SUFFIX_ICON_SIZE_MAP,
} from './Select.const';
import { getOptions } from './Select.utils';

export interface SelectProps<T extends object = SelectOption, V = Key> {
  placeholder?: string;
  value?: V;
  options: T[];
  dataProps?: SelectOptionProps;
  onChange?: (v: V) => void;
  className?: string;
  variant?: SelectVariant;
  size?: SelectSize;
  loading?: boolean;
  disabled?: boolean;
  dropdown?: {
    width?: AntdSelectProps['dropdownMatchSelectWidth'];
    placement?: AntdSelectProps['placement'];
  };
}

export interface SelectComponent {
  <T extends object = SelectOption, V = Key>(
    props: SelectProps<T, V>
  ): ReactElement<any, any> | null;
}

export const Select: SelectComponent = ({
  dropdown,
  disabled,
  className,
  size = 'lg',
  placeholder,
  onChange = noop,
  loading = false,
  variant = 'default',
  value: initialValue,
  dataProps: initialDataProps,
  options: initialOptions = [],
}) => {
  const [value, setValue] =
    useState<Parameters<SelectComponent>['0']['value']>(initialValue);

  const select = useRef<AntdRefSelectProps>();

  const dataProps = merge({}, DEFAULT_SELECT_OPTION_PROPS, initialDataProps);

  const options: SelectOption[] = initialOptions.map((item) => ({
    label: get(item, dataProps.label),
    value: get(item, dataProps.value),
    icon: get(item, dataProps.icon),
    status: get(item, dataProps.status),
    options: get(item, 'options'),
  }));

  const suffixIcon = (() => {
    return (
      <SuffixIcon
        loading={loading}
        color="white"
        name={loading ? 'spinner' : 'arrow-triangular-down'}
        size={SUFFIX_ICON_SIZE_MAP[size]}
      />
    );
  })();

  const { status: statusValue } =
    options.find(({ value: v }) => v === value) || {};

  const rootClassName = cs(className, {
    'select-with-status': !!statusValue,
    [`select-with-status-${statusValue}`]: !!statusValue,
  });

  const handleOnChange = (v: Parameters<SelectComponent>['0']['value']) => {
    setValue(v);
    onChange(v);
  };

  useEffect(() => setValue(initialValue), [initialValue]);

  return (
    <Root
      ref={select}
      value={value}
      variant={variant}
      loading={loading}
      disabled={disabled}
      suffixIcon={suffixIcon}
      onChange={handleOnChange}
      className={rootClassName}
      placeholder={placeholder}
      withStatus={!!statusValue}
      size={SELECT_SIZE_MAP[size]}
      options={getOptions({ options })}
      placement={dropdown?.placement}
      dropdownMatchSelectWidth={dropdown?.width}
      onDropdownVisibleChange={(open) => !open && select?.current?.blur()}
    />
  );
};
