import styled, { css } from 'styled-components';
import { Text } from '../DatePickerDateCell/DatePickerDateCell.style';

const RoundedRowMixin = css`
  position: relative;
  z-index: 2;
  &:last-child:not(.ant-picker-cell-disabled),
  &:nth-child(2):not(.ant-picker-cell-disabled) {
    > * {
      z-index: 2;
      position: relative;
    }
    position: relative;
    background: ${({ theme }) => theme.palette.transparent};
    &::before {
      content: '';
      transition: none;
      position: absolute;
      bottom: 0;
      display: block;
      height: 100%;
      z-index: 1;
      width: ${({ theme: { spacing } }) => spacing(11)};
    }
  }
  &:nth-child(2):not(.ant-picker-cell-disabled) {
    &::before {
      left: -${({ theme: { spacing } }) => spacing(1)};
      right: 0;
      border-top-left-radius: ${({ theme: { spacing } }) => spacing(5)};
      border-bottom-left-radius: ${({ theme: { spacing } }) => spacing(5)};
    }
  }
  &:last-child:not(.ant-picker-cell-disabled) {
    &::before {
      left: 0;
      right: -${({ theme: { spacing } }) => spacing(1)};
      border-top-right-radius: ${({ theme: { spacing } }) => spacing(5)};
      border-bottom-right-radius: ${({ theme: { spacing } }) => spacing(5)};
    }
  }
`;

export const Root = styled('div')`
  .ant-picker-panel {
    border: none;
  }
  .ant-picker-header {
    border: none;
    padding: 0 ${({ theme: { spacing } }) => spacing(3)};
    display: flex;
    align-items: center;
    height: ${({ theme: { spacing } }) => spacing(8)};
    button {
      line-height: ${({ theme: { spacing } }) => spacing(8)};
    }
    > {
      button {
        flex: 0 0 ${({ theme: { spacing } }) => spacing(4)};
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ant-picker-header-view {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${({ theme: { spacing } }) => spacing(2)};
        button {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          margin: 0;
        }
      }
    }
  }
  .ant-picker-content {
    th,
    td {
      width: ${({ theme: { spacing } }) => spacing(10)};
      min-width: ${({ theme: { spacing } }) => spacing(10)};
    }
    th {
      height: ${({ theme: { spacing } }) => spacing(10)};
      line-height: ${({ theme: { spacing } }) => spacing(10)};
      font-weight: 600;
      font-size: 12px;
    }
  }
  .ant-picker-cell {
    padding: ${({ theme: { spacing } }) => spacing(2)};
    ::before {
      transition: none;
    }
    &.ant-picker-cell-week {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    &.ant-picker-cell-disabled {
      ${Text} {
        opacity: 0.4;
      }
      &::before {
        background: ${({ theme }) => theme.palette.transparent};
      }
    }
  }
  .ant-picker-week-panel {
    width: ${({ theme: { spacing } }) => spacing(72)};
    .ant-picker-body {
      padding: ${({ theme: { spacing } }) => spacing(1)};
    }
    th[aria-label='empty cell'] {
      display: none;
    }
  }
  .ant-picker-week-panel-row {
    td {
      transition: none;
      &:first-child {
        display: none;
      }
    }
    :hover:not(.ant-picker-week-panel-row-selected) {
      td {
        ${RoundedRowMixin}
        background: ${({ theme }) => theme.palette.metropolisGrey};
        &:nth-child(2) {
          &::before {
            background: ${({ theme }) => theme.palette.metropolisGrey};
          }
        }
        &:last-child {
          &::before {
            background: ${({ theme }) => theme.palette.metropolisGrey};
          }
        }
      }
    }
  }
  .ant-picker-week-panel-row-selected {
    .ant-picker-selection-visible & {
      position: relative;
      td {
        background: ${({ theme }) => theme.palette.transparent};
      }
      td:not(.ant-picker-cell-disabled) {
        background: ${({ theme }) => theme.palette.white};
        color: ${({ theme }) => theme.palette.metropolisGrey};
        ${Text} {
          color: ${({ theme }) => theme.palette.metropolisGrey} !important;
        }
        ${RoundedRowMixin}
        &:nth-child(2) {
          &::before {
            background: ${({ theme }) => theme.palette.white};
          }
        }
        &:last-child {
          &::before {
            background: ${({ theme }) => theme.palette.white};
          }
        }
      }
    }
  }
`;
