module.exports = {
  accountManagement: 'Account management',
  activate: 'Activate',
  activity: 'Activity',
  addMembers: 'Add members',
  addNote: 'Add note',
  addPanels: 'Add panels',
  addSong: 'Add song',
  addSongs: 'Add songs',
  addSongsToCompare: 'Add songs to compare',
  addToComparison: 'Add to comparison',
  all: 'All',
  apply: 'Apply',
  back: 'Back',
  cancel: 'Cancel',
  clearAll: 'Clear all',
  columns: 'Columns',
  contactSupport: 'Contact Support',
  continue: 'Continue',
  copy: 'Copy',
  create: 'Create',
  createNewPanel: 'Create new panel',
  createNewTeam: 'Create new team',
  createPanel: 'Create panel',
  createTeam: 'Create team',
  deactivate: 'Deactivate',
  delete: 'Delete',
  duplicate: 'Duplicate',
  edit: 'Edit',
  export: 'Export',
  forgotPassword: 'Forgot password?',
  hideCoverageArea: 'Hide coverage area',
  inviteMember: 'Invite member',
  inviteNewMember: 'Invite new member',
  login: 'Log in',
  logout: 'Log out',
  next: 'Next',
  notifications: 'Notifications',
  ok: 'OK',
  preferences: 'Preferences',
  remove: 'Remove',
  removeFromComparison: 'Remove from comparison',
  removeFromTeam: 'Remove from team',
  resendInvitation: 'Resend invitation',
  reset: 'Reset',
  resetPassword: 'Reset password',
  save: 'Save',
  seeAllAddsCW: 'See all adds CW',
  seeAllAddsTW: 'See all adds TW',
  seeAllDecreases: 'See all decreases',
  seeAllDropsTW: 'See all drops TW',
  seeAllIncreases: 'See all increases',
  seeAllNoChanges: 'See all no changes',
  seeAllOutlooks: 'See all outlooks',
  seeAllRecentlyAdded: 'See all recently added',
  seeAllRecentlyDropped: 'See all recently dropped',
  seeAllRotations: 'See all rotations',
  selectedCounter: 'Selected <counter>{{count}}</counter>',
  sendInstructions: 'Send instructions',
  sendInvitation: 'Send invitation',
  sendInvitations: 'Send invitations',
  showCoverageArea: 'Show coverage area',
  submit: 'Submit',
  talkingPoints: 'Talking points',
  teamManagement: 'Team management',
  unselected: 'Unselected',
  weekDate: 'Week: {{date}}',
};
