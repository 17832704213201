export enum AuthRoutePath {
  CALLBACK = '/callback',
  LOGIN = '/login',
  LOGOUT = '/logout',
  MFA = '/mfa',
  MFA_CONFIRM = '/mfa/confirm',
  MFA_SETUP = '/mfa/setup',
  RESET_PASSWORD = '/reset-password/:token',
  FORGOT_PASSWORD = '/forgot-password',
  SESSION_TIMEOUT = '/session-timeout',
  SIGNUP = '/signup/:token',
  DEACTIVATED = '/deactivated',
}

export enum SystemRoutePath {
  NOT_FOUND = '/not-found',
}
