import { FC, PropsWithChildren } from 'react';
import { castArray, intersection } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import {
  AuthProviderContext,
  AuthProviderContextProps,
} from '../../contexts/AuthProvider.context';

type UserHasRoleProps = PropsWithChildren<{
  value: string | string[];
}>;

export const UserHasRole: FC<UserHasRoleProps> = ({ value, children }) => {
  const roles = useContextSelector<AuthProviderContextProps, unknown[]>(
    AuthProviderContext,
    ({ role }) => castArray(role)
  );

  const values = castArray(value);

  if (!!intersection(roles, values).length) {
    return <>{children}</>;
  }

  return null;
};
