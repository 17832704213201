import { FC, useContext, PropsWithChildren } from 'react';
import { Button, ButtonProps } from '../../Button/Button';
import { DrawerContext } from '../Drawer.context';

type DrawerCloseProps = PropsWithChildren<
  ButtonProps & {
    onClick?: () => void;
  }
>;

export const DrawerClose: FC<DrawerCloseProps> = ({ children, ...props }) => {
  const { onClose } = useContext(DrawerContext);

  return (
    <Button onClick={onClose} {...props}>
      {children}
    </Button>
  );
};
