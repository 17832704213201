import { StationDetailsContext } from '@common/features/StationDetails/StationDetails.context';
import { useTranslation } from '@spintel/localization';
import { useRoute, Link } from '@spintel/navigation';
import { orderBy, includes } from 'lodash';
import { useState, useContext } from 'react';
import { MenuItem } from '@spintel/uikit';
import { Searchbar, List } from './StationHeaderSearch.styles';

export const StationHeaderSearch = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const { stations } = useContext(StationDetailsContext);
  const [{ path, params, search }] = useRoute();

  const items: MenuItem[] = orderBy(stations || [], 'callsign')
    .filter(({ callsign }) => {
      return searchTerm
        ? includes(callsign.toLowerCase(), searchTerm.toLowerCase())
        : true;
    })
    .map(({ callsign, stationId }) => ({
      key: callsign,
      label: (
        <Link to={path} params={{ ...params, stationId }} search={{ search }}>
          {callsign}
        </Link>
      ),
    }));

  return (
    <>
      <Searchbar
        onChange={setSearchTerm}
        placeholder={t('placeholders.callsign')}
      />
      <List items={items} />
    </>
  );
};
