import { AlertItem } from './AlertSnackbar.services';
import { CloseTimer } from './AlertSnackbar.types';

/**
 * Get alerts that should be configured to autoclose
 */
export function getNewAlertsWithTimeout(
  timers: CloseTimer[],
  alerts: AlertItem[]
) {
  const oldTimersIds = timers.map((timer) => timer.alertId);

  return alerts.filter(
    (alert) =>
      !oldTimersIds.includes(alert.id) && alert.timeout && !alert.closable
  );
}

/**
 * Get actual timers
 */
export function getActualTimers(timers: CloseTimer[], alerts: AlertItem[]) {
  const alertIds = alerts.map((alert) => alert.id);

  return timers.filter((timer) => alertIds.includes(timer.alertId));
}
