import { useMemo } from 'react';
import { DTO, useQuery } from '@spintel/network/app';

interface UseTableDataParams {
  songId: number;
  stationId: number;
  searchTerm?: string;
}

export const useTableData = ({
  searchTerm,
  songId,
  stationId,
}: UseTableDataParams): {
  data: DTO.IStationPossibleSpaceResponse[];
  loading: boolean;
} => {
  const [{ data = [], loading }] = useQuery<
    [number, number],
    DTO.IStationPossibleSpaceResponse[]
  >(({ Station }) => Station.possibleSpaceDetail, [songId, stationId]);

  const filteredData = useMemo(() => {
    if (Array.isArray(data) && searchTerm) {
      return data.filter((row) =>
        row.songName
          ? row.songName.toLowerCase().includes(searchTerm.toLowerCase())
          : false
      );
    }

    return data;
  }, [data, searchTerm]);

  return { data: filteredData, loading };
};
