import { get } from 'lodash';

export const getFilename = (headers: PlainObject): string => {
  const disposition = get(headers, 'content-disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    var regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = regex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return '';
};
