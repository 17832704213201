import {
  PropsWithChildren,
  forwardRef,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { useUpdateEffect } from 'react-use';
import { useContextSelector } from 'use-context-selector';
import {
  DropdownContext,
  DropdownContextProps,
} from '../../../contexts/Dropdown.context';
import { Button } from '../../Button/Button';
import { UiKitLocalizationContext } from '../../../contexts/UiKitLocalization.context';
import { Root, Content, Footer, Actions, Hint } from './DropdownConfirm.style';

type DropdownConfirmProps = PropsWithChildren<{
  hint?: string;
  width?: number;
  disabled?: boolean;
  className?: string;
  onHide?: () => void;
  onConfirm: () => void;
}>;

export const DropdownConfirm = forwardRef<HTMLDivElement, DropdownConfirmProps>(
  function DropdownOverlay(
    { disabled = false, className, children, width, hint, onConfirm, onHide },
    ref
  ) {
    const set = useContextSelector<
      DropdownContextProps,
      DropdownContextProps['set']
    >(DropdownContext, ({ set }) => set);

    const toggle = useContextSelector<
      DropdownContextProps,
      DropdownContextProps['toggle']
    >(DropdownContext, ({ toggle }) => toggle);

    const isOpen = useContextSelector<
      DropdownContextProps,
      DropdownContextProps['isOpen']
    >(DropdownContext, ({ isOpen }) => isOpen);

    const { dropdown } = useContext(UiKitLocalizationContext);

    const handleOnConfirm = useCallback(() => {
      onConfirm();
      toggle(false);
    }, [onConfirm, toggle]);

    const handleOnCancel = useCallback(() => {
      toggle(false);
    }, [toggle]);

    useEffect(() => {
      set(
        'overlayNode',
        <Root ref={ref} className={className} width={width}>
          <Content>{children}</Content>
          <Footer withHint={!!hint}>
            {hint ? <Hint>{hint}</Hint> : null}
            <Actions>
              <Button variant="tertiary" onClick={handleOnCancel}>
                {dropdown.cancelBtn}
              </Button>
              <Button
                variant="primary"
                disabled={disabled}
                onClick={handleOnConfirm}
              >
                {dropdown.confirmBtn}
              </Button>
            </Actions>
          </Footer>
        </Root>
      );
    }, [
      disabled,
      children,
      set,
      className,
      dropdown.cancelBtn,
      dropdown.confirmBtn,
      hint,
      handleOnConfirm,
      handleOnCancel,
      ref,
      width,
    ]);

    useUpdateEffect(() => {
      if (!isOpen && onHide) onHide();
    }, [isOpen]);

    return null;
  }
);
