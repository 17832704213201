import { FC } from 'react';
import { noop } from 'lodash';
import { IconName } from '../../Icon/__generated__/icon.types';
import { Root, Icon } from './FormInputAction.style';

interface FormInputActionProps {
  icon: IconName;
  onClick?: () => void;
  disabled?: boolean;
}

export const FormInputAction: FC<FormInputActionProps> = ({
  icon,
  disabled,
  onClick = noop,
}) => {
  return (
    <Root>
      <Icon name={icon} onClick={onClick} size="sm" disabled={disabled} />
    </Root>
  );
};
