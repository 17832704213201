import styled, { createGlobalStyle } from 'styled-components';
import AntdDropdown from 'antd/lib/dropdown';
import { DropdownComponent } from './Dropdown.types';

export const Root = styled<DropdownComponent>(AntdDropdown)``;

export const Global = createGlobalStyle`
  .ant-dropdown {
    background: ${({ theme }) => theme.palette.icarusGrey};
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2))
      drop-shadow(0px 16px 24px rgba(0, 0, 0, 0.4));
  }
`;
