import { FC, Suspense as ReactSuspense, PropsWithChildren } from 'react';
import { Spin } from '@spintel/uikit';
import { ErrorBoundary, LoggerOrigin } from '@spintel/network/logger';
import { useUser } from '@spintel/network/auth';
import { useTranslation } from '@spintel/localization';
import { ErrorBoundaryFallback } from '../ErrorBoundaryFallback/ErrorBoundaryFallback';
import { Root } from './Suspense.style';

type SuspenseProps = PropsWithChildren<{
  spinner?: boolean;
}>;

export const Suspense: FC<SuspenseProps> = ({ spinner = false, children }) => {
  const { t } = useTranslation();
  const [{ user }] = useUser();

  const fallback = spinner ? (
    <Root>
      <Spin spinning />
    </Root>
  ) : (
    t('empty.contentLoading')
  );

  return (
    <ErrorBoundary
      origin={LoggerOrigin.APP}
      params={{ email: user?.email }}
      fallback={({ id }) => <ErrorBoundaryFallback id={id} />}
    >
      <ReactSuspense fallback={fallback}>{children}</ReactSuspense>
    </ErrorBoundary>
  );
};
