import styled from 'styled-components';
import { Typography } from '../../Typography/Typography';

const { Body3 } = Typography;

export const WithEllipsis = styled(Body3)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
`;
