import { SongFetchPageStatus } from './Song.context';

export enum SongRouteTab {
  overview = 'overview',
  stationAirplay = 'stationAirplay',
  addOpportunities = 'addOpportunities',
  ownership = 'ownership',
  newAdds = 'newAdds',
  playIncrease = 'playIncrease',
  songComparison = 'songComparison',
  competition = 'competition',
}

export const SONG_FETCH_PAGE_STATUS_DEFAULT: SongFetchPageStatus = {
  songsDetailFetched: false,
  headerTileDetailFetched: false,
  overviewTilesFetched: false,
  rotationFetched: false,
  outlookFetched: false,
  coordinatesListFetched: false,
};
