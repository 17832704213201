import styled from 'styled-components';
import { CSSProperties } from 'react';

interface RootProps {
  align: CSSProperties['alignItems'];
  justify: CSSProperties['justifyContent'];
}

export const Root = styled('div')<RootProps>(
  ({ align, justify, theme: { spacing } }) => ({
    display: 'flex',
    alignItems: align,
    justifyContent: justify,
    paddingTop: spacing(5),
  })
);
