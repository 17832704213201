/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IAddOpportunitiesStationsHighExportCreateParams,
  IAddOpportunitiesStationsLowExportCreateParams,
  IDateRangeRequestDto,
  IErrorResponse,
  IGetSongAddOpportunitiesComparisonRelativeGraphResponse,
  IGetSongAddOpportunitiesComparisonRelativeGridResponse,
  IGetSongAddOpportunitiesComparisonStandardGraphResponse,
  IGetSongAddOpportunitiesResponse,
  IRelativeGridRequestDto,
  ISongAddOpportunitiesComparisonStandardGridResponse,
  ISongAddOpportunitiesHighLowResponse,
  ISongHistoryGridRequestDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SongAddOpportunities<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SongAddOpportunities
   * @name AddOpportunitiesDetail
   * @summary Get Add Opportunities
   * @request GET:/songs/{songId}/add-opportunities
   * @secure
   */
  addOpportunitiesDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<IGetSongAddOpportunitiesResponse[], IErrorResponse>({
      path: `/songs/${songId}/add-opportunities`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongAddOpportunities
   * @name AddOpportunitiesExportCreate
   * @summary Export Add Opportunities
   * @request POST:/songs/{songId}/add-opportunities/export
   * @secure
   */
  addOpportunitiesExportCreate = (songId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/add-opportunities/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongAddOpportunities
   * @name AddOpportunitiesStationsHighDetail
   * @summary Get Add Opportunities High
   * @request GET:/songs/{songId}/add-opportunities/stations/{stationId}/high
   * @secure
   */
  addOpportunitiesStationsHighDetail = (
    songId: number,
    stationId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<ISongAddOpportunitiesHighLowResponse[], IErrorResponse>({
      path: `/songs/${songId}/add-opportunities/stations/${stationId}/high`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongAddOpportunities
   * @name AddOpportunitiesStationsHighExportCreate
   * @summary Export Add Opportunities High
   * @request POST:/songs/{songId}/add-opportunities/stations/{stationId}/high/export
   * @secure
   */
  addOpportunitiesStationsHighExportCreate = (
    {
      songId,
      stationId,
      ...query
    }: IAddOpportunitiesStationsHighExportCreateParams,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/add-opportunities/stations/${stationId}/high/export`,
      method: 'POST',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongAddOpportunities
   * @name AddOpportunitiesStationsLowDetail
   * @summary Get Add Opportunities Low
   * @request GET:/songs/{songId}/add-opportunities/stations/{stationId}/low
   * @secure
   */
  addOpportunitiesStationsLowDetail = (
    songId: number,
    stationId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<ISongAddOpportunitiesHighLowResponse[], IErrorResponse>({
      path: `/songs/${songId}/add-opportunities/stations/${stationId}/low`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongAddOpportunities
   * @name AddOpportunitiesStationsLowExportCreate
   * @summary Export Add Opportunities Low
   * @request POST:/songs/{songId}/add-opportunities/stations/{stationId}/low/export
   * @secure
   */
  addOpportunitiesStationsLowExportCreate = (
    {
      songId,
      stationId,
      ...query
    }: IAddOpportunitiesStationsLowExportCreateParams,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/add-opportunities/stations/${stationId}/low/export`,
      method: 'POST',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongAddOpportunities
   * @name AddOpportunitiesStationsStandardGraphCreate
   * @summary Get Add opportunities Standard Graph
   * @request POST:/songs/{songId}/add-opportunities/stations/{stationId}/standard-graph
   * @secure
   */
  addOpportunitiesStationsStandardGraphCreate = (
    songId: number,
    stationId: number,
    data: IDateRangeRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetSongAddOpportunitiesComparisonStandardGraphResponse[],
      IErrorResponse
    >({
      path: `/songs/${songId}/add-opportunities/stations/${stationId}/standard-graph`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongAddOpportunities
   * @name AddOpportunitiesStationsStandardGridCreate
   * @summary Get Add opportunities Standard Grid
   * @request POST:/songs/{songId}/add-opportunities/stations/{stationId}/standard-grid
   * @secure
   */
  addOpportunitiesStationsStandardGridCreate = (
    songId: number,
    stationId: number,
    data: ISongHistoryGridRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ISongAddOpportunitiesComparisonStandardGridResponse[],
      IErrorResponse
    >({
      path: `/songs/${songId}/add-opportunities/stations/${stationId}/standard-grid`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongAddOpportunities
   * @name AddOpportunitiesStationsStandardGridExportCreate
   * @summary Export Add opportunities Standard Grid
   * @request POST:/songs/{songId}/add-opportunities/stations/{stationId}/standard-grid/export
   * @secure
   */
  addOpportunitiesStationsStandardGridExportCreate = (
    songId: number,
    stationId: number,
    data: ISongHistoryGridRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/add-opportunities/stations/${stationId}/standard-grid/export`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongAddOpportunities
   * @name AddOpportunitiesStationsRelativeGraphCreate
   * @summary Get Add Opportunities Relative Graph
   * @request POST:/songs/{songId}/add-opportunities/stations/{stationId}/relative-graph
   * @secure
   */
  addOpportunitiesStationsRelativeGraphCreate = (
    songId: number,
    stationId: number,
    data: IDateRangeRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetSongAddOpportunitiesComparisonRelativeGraphResponse[],
      IErrorResponse
    >({
      path: `/songs/${songId}/add-opportunities/stations/${stationId}/relative-graph`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongAddOpportunities
   * @name AddOpportunitiesStationsRelativeGridCreate
   * @summary Get Add Opportunities Relative Grid
   * @request POST:/songs/{songId}/add-opportunities/stations/{stationId}/relative-grid
   * @secure
   */
  addOpportunitiesStationsRelativeGridCreate = (
    songId: number,
    stationId: number,
    data: IRelativeGridRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetSongAddOpportunitiesComparisonRelativeGridResponse[],
      IErrorResponse
    >({
      path: `/songs/${songId}/add-opportunities/stations/${stationId}/relative-grid`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongAddOpportunities
   * @name AddOpportunitiesStationsRelativeGridExportCreate
   * @summary Export Add Opportunities Relative Grid
   * @request POST:/songs/{songId}/add-opportunities/stations/{stationId}/relative-grid/export
   * @secure
   */
  addOpportunitiesStationsRelativeGridExportCreate = (
    songId: number,
    stationId: number,
    data: IRelativeGridRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/songs/${songId}/add-opportunities/stations/${stationId}/relative-grid/export`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
