/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ICurrentsExportListParams,
  IErrorResponse,
  IPanelChartDatesModel,
  IPanelChartRequestPagedRequest,
  IPanelChartStatisticsResponsePagedResult,
  IPossibleSpaceExportListParams,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class PanelCharts<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags PanelCharts
   * @name DatesList
   * @summary Get Panel Charts dates
   * @request GET:/panel-charts/dates
   * @secure
   */
  datesList = (params: RequestParams = {}) =>
    this.http.request<IPanelChartDatesModel, IErrorResponse>({
      path: `/panel-charts/dates`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags PanelCharts
   * @name CurrentsCreate
   * @summary Get Panel Charts Currents
   * @request POST:/panel-charts/currents
   * @secure
   */
  currentsCreate = (
    data: IPanelChartRequestPagedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<IPanelChartStatisticsResponsePagedResult, IErrorResponse>(
      {
        path: `/panel-charts/currents`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }
    );
  /**
   * No description
   *
   * @tags PanelCharts
   * @name CurrentsExportList
   * @summary Export Panel Charts Currents
   * @request GET:/panel-charts/currents/export
   * @secure
   */
  currentsExportList = (
    query: ICurrentsExportListParams,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/panel-charts/currents/export`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags PanelCharts
   * @name PossibleSpaceCreate
   * @summary Get Panel Charts Possible Space
   * @request POST:/panel-charts/possible-space
   * @secure
   */
  possibleSpaceCreate = (
    data: IPanelChartRequestPagedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<IPanelChartStatisticsResponsePagedResult, IErrorResponse>(
      {
        path: `/panel-charts/possible-space`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }
    );
  /**
   * No description
   *
   * @tags PanelCharts
   * @name PossibleSpaceExportList
   * @summary Export Panel Charts Possible Space
   * @request GET:/panel-charts/possible-space/export
   * @secure
   */
  possibleSpaceExportList = (
    query: IPossibleSpaceExportListParams,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/panel-charts/possible-space/export`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
}
