import { FC } from 'react';
import { useRoute } from '@spintel/navigation';
import { Form, Button, Notification, Prompt } from '@spintel/uikit';
import { Translate, useTranslation } from '@spintel/localization';
import { DTO, useForgotPassword } from '@spintel/network/auth';
import { AuthRoutePath } from '@common/constants';

export const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const [, { back, push }] = useRoute();
  const [{ loading }, forgotPassword] = useForgotPassword();

  const handleSubmit = (form: DTO.IForgotPasswordDto) => {
    forgotPassword(form, () => {
      Notification.show({
        message: (
          <Translate
            i18nKey={'notifications.instructionWasSentTo'}
            values={{ email: form.email }}
          />
        ),
      });
      push({ to: AuthRoutePath.LOGIN });
    });
  };

  return (
    <Prompt title={t('forms.resetPasswordTitle')}>
      <Form initialValues={{ email: '' }} onSubmit={handleSubmit}>
        <Prompt.Content>
          <Form.Input
            autofocus
            name="email"
            label={t('forms.email')}
            hideLabel
            placeholder={t('placeholders.enterYourEmail')}
            validation={{
              email: true,
              required: true,
            }}
          />
        </Prompt.Content>
        <Prompt.Actions justify="space-between">
          <Button size="lg" variant="link" onClick={back}>
            {t('actions.back')}
          </Button>
          <Form.Submit size="lg" variant="primary" loading={loading}>
            {t('actions.sendInstructions')}
          </Form.Submit>
        </Prompt.Actions>
      </Form>
    </Prompt>
  );
};
