import { FC, PropsWithChildren, useCallback } from 'react';
import { UiKitLocalizationProvider } from '@spintel/uikit';
import { useTranslation } from '@spintel/localization';

type LocalizationBootstrapProps = PropsWithChildren<unknown>;

type FormValidationError = Parameters<
  typeof UiKitLocalizationProvider
>['0']['formValidationError'];

export const LocalizationBootstrap: FC<LocalizationBootstrapProps> = ({
  children,
}) => {
  const { t } = useTranslation();

  const formValidationError: FormValidationError = useCallback(
    ({ name, message, label }) => {
      return message
        ? t(`httpValidationErrorCode.${message}`, {
            label: label || t(`forms.${name}`),
          })
        : '';
    },
    [t]
  );

  return (
    <UiKitLocalizationProvider
      formValidationError={formValidationError}
      formValidationMessages={{
        array: {
          len: t('formValidateMessages.array.len'),
          max: t('formValidateMessages.array.max'),
          min: t('formValidateMessages.array.min'),
          range: t('formValidateMessages.array.range'),
        },
        date: {
          format: t('formValidateMessages.date.format'),
          invalid: t('formValidateMessages.date.invalid'),
          parse: t('formValidateMessages.date.parse'),
        },
        default: t('formValidateMessages.default'),
        enum: t('formValidateMessages.enum'),
        number: {
          len: t('formValidateMessages.number.len'),
          max: t('formValidateMessages.number.max'),
          min: t('formValidateMessages.number.min'),
          range: t('formValidateMessages.number.range'),
        },
        pattern: {
          mismatch: t('formValidateMessages.pattern.mismatch'),
        },
        required: t('formValidateMessages.required'),
        string: {
          len: t('formValidateMessages.string.len'),
          max: t('formValidateMessages.string.max'),
          min: t('formValidateMessages.string.min'),
          range: t('formValidateMessages.string.range'),
        },
        types: {
          array: t('formValidateMessages.types.array'),
          boolean: t('formValidateMessages.types.boolean'),
          date: t('formValidateMessages.types.date'),
          email: t('formValidateMessages.types.email'),
          float: t('formValidateMessages.types.float'),
          hex: t('formValidateMessages.types.hex'),
          integer: t('formValidateMessages.types.integer'),
          method: t('formValidateMessages.types.method'),
          number: t('formValidateMessages.types.number'),
          object: t('formValidateMessages.types.object'),
          regexp: t('formValidateMessages.types.regexp'),
          string: t('formValidateMessages.types.string'),
          url: t('formValidateMessages.types.url'),
        },
        whitespace: t('formValidateMessages.whitespace'),
      }}
      table={{
        noData: t('empty.noData'),
        dropdownFilterBetweenAnd: t('forms.and'),
        dropdownFilterCancel: t('actions.cancel'),
        dropdownFilterConfirm: t('actions.apply'),
        dropdownFilterToggleAll: t('actions.all'),
        filtersAppliedText: t('forms.filtersApplied'),
        filtersClearAllBtn: t('actions.clearAll'),
        filtersRangeBtLabel: t('forms.value'),
        filtersRangeEqLabel: t('forms.value'),
        filtersRangeGtLabel: t('forms.value'),
        filtersRangeLtLabel: t('forms.value'),
        dropdownFilterEmpty: t('empty.noResultsMatch'),
        columnsSelector: {
          toggle: t('options.all'),
        },
      }}
      dropdown={{
        cancelBtn: t('actions.cancel'),
        confirmBtn: t('actions.apply'),
      }}
    >
      {children}
    </UiKitLocalizationProvider>
  );
};
