import styled, { css } from 'styled-components';
import { Typography } from '../../Typography/Typography';

const { Subtitle2, Body4 } = Typography;

export const Left = styled('div')(() => ({}));

export const Right = styled('div')(() => ({}));

export const Label = styled(Subtitle2)<{ active: boolean }>(
  ({ theme: { palette }, active }) => ({
    color: active ? palette.metropolisGrey : palette.white,
  })
);

export const Description = styled(Body4)<{ active: boolean }>(
  ({ active, theme: { palette } }) => ({
    color: active ? palette.icarusGrey : palette.pearlGrey,
  })
);

const RootActiveMixin = css`
  background: ${({ theme }) => theme.palette.white};
`;

export const Root = styled('div')<{ active: boolean }>`
  display: flex;
  padding: ${({ theme: { spacing } }) => `${spacing(2)}  ${spacing(4)}`};
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.palette.wolfGrey};
    ${Description} {
      color: ${({ theme }) => theme.palette.stardustGrey};
    }
    ${Label} {
      color: ${({ theme }) => theme.palette.white};
    }
  }
  ${({ active }) => active && RootActiveMixin}
`;
