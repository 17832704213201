import { useSong } from '@common/hooks';
import { DTO, useMutation, useQuery } from '@spintel/network/app';
import { useTranslation } from '@spintel/localization';
import { Notification } from '@spintel/uikit';

interface useSongsComparisonParams {
  stationId: number;
}

interface RequestParams {
  id: number;
  songName: string;
  artistName: string;
}

type NotificationType = 'add' | 'remove';

const NOTIFICATION_LABEL_MAP: Record<NotificationType, string> = {
  add: 'notifications.songComparisonsSuccessfullyAdd',
  remove: 'notifications.songComparisonsSuccessfullyRemove',
};

interface NotificationParams {
  type: NotificationType;
  songName: string;
  artistName: string;
}

export const useSongsComparison = ({ stationId }: useSongsComparisonParams) => {
  const [{ song, loading: selectedSongLoading }] = useSong();
  const { t } = useTranslation();

  const [{ data: songsData = [], loading }, refetch] = useQuery<
    [number, number],
    DTO.ISongComparisonStatisticsModel[]
  >(
    ({ SongComparison }) => SongComparison.stationDetail,
    [song.songId, stationId]
  );

  const songIds = songsData.reduce((res, item) => {
    return song.songId !== item.songId ? [...res, item.songId] : res;
  }, []);

  const [{ loading: removing }, remove] = useMutation<
    [number, DTO.ISongComparisonsRequestDto]
  >(({ SongComparison }) => SongComparison.removeDelete);

  const notify = ({ type, songName, artistName }: NotificationParams) => {
    return Notification.show({
      message: t(NOTIFICATION_LABEL_MAP[type], {
        postProcess: 'interval',
        songName,
        artistName,
      }),
    });
  };

  const handleRemove = ({ id, songName, artistName }: RequestParams) => {
    remove([song.songId, { songComparisons: [id] }], {
      onSuccess: () => {
        notify({ type: 'remove', songName, artistName });
        refetch();
      },
    });
  };

  const [{ loading: updating }, update] = useMutation<
    [number, DTO.ISongComparisonsRequestDto]
  >(({ SongComparison }) => SongComparison.postSongComparison);

  const handleAdd = ({ id, songName, artistName }: RequestParams) => {
    update([song.songId, { songComparisons: [id] }], {
      onSuccess: () => {
        notify({ type: 'add', songName, artistName });
        refetch();
      },
    });
  };

  return [
    {
      song,
      songIds,
      loading: selectedSongLoading || loading || removing || updating,
    },
    {
      refetch: refetch as VoidFunction,
      onRemove: handleRemove,
      onAdd: handleAdd,
    },
  ];
};
