import { MutableRefObject, Key } from 'react';
import { noop } from 'lodash';
import { createContext } from 'use-context-selector';
import { Chart } from 'chart.js';
import {
  GraphDataset,
  GraphLabels,
  GraphLegendItem,
  GraphLegendMap,
  GraphRangeItem,
  GraphRangeMap,
  GraphRangeBounds,
  GraphRangeBoundsMap,
} from './Graph.types';

export interface GraphContextProps {
  loading: boolean;
  labels: GraphLabels;
  range: GraphRangeMap;
  rangeBounds: GraphRangeBoundsMap;
  legend: GraphLegendMap;
  datasets: GraphDataset[];
  graph: MutableRefObject<{
    chart: MutableRefObject<Chart<'line' | 'doughnut'>>;
  }>;
  chartActions: MutableRefObject<{
    highlight?: (dataIndex: number, hoverEvent?: boolean) => void;
    reset?: () => void;
  }>;
  legendActions: MutableRefObject<{
    highlight?: (index: number) => void;
    reset?: () => void;
  }>;
  setRange: (key: Key, value: GraphRangeItem[]) => void;
  setRangeBounds: (key: Key, value: GraphRangeBounds) => void;
  setLegend: (key: Key, value: GraphLegendItem[]) => void;
}

export const GraphContext = createContext<GraphContextProps>({
  range: {},
  rangeBounds: {},
  labels: [],
  legend: {},
  graph: null,
  chartActions: null,
  legendActions: null,
  datasets: [],
  loading: false,
  setRange: noop,
  setRangeBounds: noop,
  setLegend: noop,
});
