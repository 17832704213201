import { Dispatch, SetStateAction } from 'react';
import { noop } from 'lodash';
import { createContext } from 'use-context-selector';
import {
  TableColumnsType,
  TableSearchState,
  TableColumnSelection,
  TableGlobalColumnsExtendedType,
} from '../components/Table/Table.types';

export interface TableContextProps {
  skeleton?: number;
  selection: TableColumnSelection;
  columns: TableGlobalColumnsExtendedType<any>;
  setSelection: Dispatch<SetStateAction<TableColumnSelection>>;
}

export interface TableLocalContextProps {
  columns: TableColumnsType<any>;
  setSearchState: Dispatch<SetStateAction<TableSearchState>>;
}

export const TableContext = createContext<TableContextProps>({
  selection: [],
  columns: [],
  setSelection: noop,
});

export const TableLocalContext = createContext<TableLocalContextProps>({
  columns: [],
  setSearchState: noop,
});
