import { useCallback, useEffect } from 'react';
import { UseMutationState } from '@shared/types';
import { useMutation } from '../useMutation/useMutation.hook';
import { useAuthToken } from '../useAuthToken/useAuthToken.hook';
import {
  IUpdatePasswordDto,
  IJwtTokenResponseDto,
  IErrorResponse,
} from '../../__generated__/data-contracts';

type UpdatePasswordFunction = (
  form: IUpdatePasswordDto,
  onSuccess?: () => void
) => void;

type ResetStateFunction = () => void;

type UseUpdatePasswordFunctionReturnType = [
  UseMutationState<unknown, IErrorResponse>,
  UpdatePasswordFunction,
  ResetStateFunction
];

export const useUpdatePassword = (): UseUpdatePasswordFunctionReturnType => {
  const [, setToken] = useAuthToken();
  const [state, execute, reset] = useMutation<
    [IUpdatePasswordDto],
    IJwtTokenResponseDto
  >(({ Auth }) => Auth.updatePasswordCreate);

  const { data } = state;

  const updatePassword: UpdatePasswordFunction = useCallback(
    (form, onSuccess) => {
      execute([form], { onSuccess });
    },
    [execute]
  );

  // setup new token
  useEffect(() => {
    if (data) {
      setToken(data);
    }
  }, [data, setToken]);

  return [state, updatePassword, reset];
};
