import { useCallback } from 'react';
import { useContextSelector } from 'use-context-selector';
import {
  AuthProviderContext,
  AuthProviderContextProps,
} from '../../contexts/AuthProvider.context';
import { useAuthToken } from '../useAuthToken/useAuthToken.hook';
import {
  IJwtTokenResponseDto,
  ICurrentUserResponse,
} from '../../__generated__/data-contracts';

interface UseAuthDispatch {
  handleLoginCallback: (data: IJwtTokenResponseDto) => void;
}

type UseAuthReturnType = [ICurrentUserResponse, UseAuthDispatch];

export const useAuth = (): UseAuthReturnType => {
  const user = useContextSelector<
    AuthProviderContextProps,
    ICurrentUserResponse
  >(AuthProviderContext, ({ user }) => user);

  const [, setToken] = useAuthToken();

  const handleLoginCallback = useCallback(
    (data: IJwtTokenResponseDto) => setToken(data),
    [setToken]
  );

  return [user, { handleLoginCallback }];
};
