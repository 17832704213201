import {
  FC,
  PropsWithChildren,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import { throttle } from 'lodash';
import smoothscroll from 'smoothscroll-polyfill';
import { Button } from '../Button/Button';
import {
  Root,
  List,
  PrevArrowOverlay,
  NextArrowOverlay,
} from './ScrollableHorizontalList.styles';

import {
  getPrevItemScrollOffset,
  getNextItemScrollOffset,
  getArrowsState,
} from './ScrollableHorizontalList.utils';

smoothscroll.polyfill(); // safari < 15.4 smooth scroll fix

const ARROW_OVERLAY_WIDTH = 64;

interface ScrollableHorizontalListProps extends PropsWithChildren {
  gap?: number;
  arrows?: boolean;
}

export const ScrollableHorizontalList: FC<ScrollableHorizontalListProps> = ({
  children,
  gap = 0,
  arrows = true,
}) => {
  const listRef = useRef<HTMLDivElement>();
  const [{ prevArrow, nextArrow }, setArrowsState] = useState({
    prevArrow: false,
    nextArrow: false,
  });

  useLayoutEffect(() => {
    const list = listRef.current;

    if (list) {
      const { prevArrow, nextArrow } = getArrowsState(list, !arrows);
      setArrowsState({ prevArrow, nextArrow });
    }
  }, [arrows, children]);

  useEffect(() => {
    const list = listRef.current;

    if (list) {
      const updateArrowsVisibility = throttle(
        () =>
          requestAnimationFrame(() => {
            const { prevArrow, nextArrow } = getArrowsState(list, !arrows);
            setArrowsState({ prevArrow, nextArrow });
          }),
        100
      );

      list.addEventListener('scroll', updateArrowsVisibility);
      window.addEventListener('resize', updateArrowsVisibility);

      return () => {
        list.removeEventListener('scroll', updateArrowsVisibility);
        window.removeEventListener('resize', updateArrowsVisibility);
      };
    }
  }, [arrows, children]);

  const handleClickPrev = throttle(() => {
    if (listRef.current) {
      const left = getPrevItemScrollOffset(
        listRef.current,
        ARROW_OVERLAY_WIDTH
      );

      listRef.current.scroll({
        left,
        behavior: 'smooth',
      });
    }
  }, 300);

  const handleClickNext = throttle(() => {
    if (listRef.current) {
      const left = getNextItemScrollOffset(
        listRef.current,
        ARROW_OVERLAY_WIDTH
      );

      listRef.current.scroll({
        left,
        behavior: 'smooth',
      });
    }
  }, 300);

  return (
    <Root>
      {prevArrow ? (
        <PrevArrowOverlay width={ARROW_OVERLAY_WIDTH}>
          <Button circle icon="arrow-left" onClick={handleClickPrev} />
        </PrevArrowOverlay>
      ) : null}
      <List ref={listRef} gap={gap}>
        {children}
      </List>
      {nextArrow ? (
        <NextArrowOverlay width={ARROW_OVERLAY_WIDTH}>
          <Button circle icon="arrow-right" onClick={handleClickNext} />
        </NextArrowOverlay>
      ) : null}
    </Root>
  );
};
