import { useSong } from '@common/hooks';
import { get } from 'lodash';
import { DTO } from '@spintel/network/app';
import { Table, useTableConfig } from '@spintel/uikit';
import { useTranslation } from '@spintel/localization';
import { FC, useRef } from 'react';
import { DEFAULT_SORTING } from '../StationPossibleSpace.const';
import { TableStyles } from '../StationPossibleSpace.types';
import { StationPossibleSpaceTableActions } from './StationPossibleSpaceTableActions/StationPossibleSpaceTableActions';
import { Root } from './StationPossibleSpaceTable.styles';

interface StationPossibleSpaceTableProps {
  data: DTO.IStationPossibleSpaceResponse[];
  loading: boolean;
  sorting: DTO.IPagedSort;
  onSort?: (v: DTO.IPagedSort) => void;
  styles?: TableStyles;
}

export const StationPossibleSpaceTable: FC<StationPossibleSpaceTableProps> = ({
  data,
  loading,
  sorting,
  onSort,
  styles,
}) => {
  const { t } = useTranslation();
  const [{ song }] = useSong();
  const container = useRef<HTMLDivElement>();
  const { selected } = useTableConfig<DTO.IStationPossibleSpaceResponse>();
  return (
    <Root ref={container} height={get(styles, 'height')}>
      <Table<DTO.IStationPossibleSpaceResponse, DTO.IPagedSort>
        loading={loading}
        columns={selected}
        data={data}
        sorting={sorting}
        defaultSorting={DEFAULT_SORTING}
        scroll={{ x: 0 }}
        rowKey={({ songId }) => songId}
        stretchTo={() => container.current}
        onChange={({ sort }) => onSort(sort)}
        gutter={get(styles, 'outerSpace', [0, 0, 0, 0])}
        row={{
          highlighted: ({ item }) =>
            song.songId === item.songId ? 'bold' : null,
        }}
        actions={StationPossibleSpaceTableActions}
        empty={{
          icon: 'song',
          background: get(styles, 'empty'),
          title: t('empty.noSongsToDisplay'),
        }}
      />
    </Root>
  );
};
