import { useContext } from 'react';
import { useToggleComparison } from '@common/hooks';
import { DTO } from '@spintel/network/app';
import { Button, Dropdown, TableActions } from '@spintel/uikit';
import { StationMusicStackContext } from '../../StationMusicStack.context';
import { Root } from './StationMusicStackTableActions.style';

export const StationMusicStackTableActions: TableActions<
  DTO.IStationMusicStackResponse
> = ({ item }) => {
  const { stationId } = useContext(StationMusicStackContext);
  const comparisonMenuItem = useToggleComparison({
    stationId,
    songId: item.songId,
    artistName: item.artistName,
    songName: item.songName,
  });

  const menuItems = [...(comparisonMenuItem ? [comparisonMenuItem] : [])];

  if (!menuItems.length) return null;

  return (
    <Root>
      <Dropdown placement="bottomRight">
        <Dropdown.Toggle chevron={false}>
          <Button circle size="sm" icon="more" />
        </Dropdown.Toggle>
        <Dropdown.Menu items={menuItems} />
      </Dropdown>
    </Root>
  );
};

StationMusicStackTableActions.width = 45;
