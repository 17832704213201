import {
  useRef,
  ReactNode,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { flatten } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import AntdTreeSelect from 'antd/lib/tree-select';
import AntdForm from 'antd/lib/form';
import { Form } from '../../../Form/Form';
import { useForm } from '../../../Form/Form.hooks';
import { Icon } from '../../../Icon/Icon';
import {
  TableContext,
  TableContextProps,
} from '../../../../contexts/Table.context';
import {
  TableColumnSelection,
  TableColumnsSelectOption,
} from '../../Table.types';
import {
  TableColumnsSelectForm as TableColumnsSelectFormFields,
  TableColumnsSelectFormOnChangeParams,
} from '../TableColumnsSelect.types';
import { Content, Field, Cascader } from '../TableColumnsSelect.style';
import { TableColumnsSelectDropdown } from '../TableColumnsSelectDropdown/TableColumnsSelectDropdown';
import { getColumnSelectionDataIndexTree } from '../TableColumnsSelect.utils';
import { TableColumnsSelectFormRef } from '../TableColumnsSelect.types';
import { useTableColumnsSelectColumns } from '../TableColumnsSelect.hooks';
import { getColumnSelectOptions } from './TableColumnsSelectForm.utils';

interface TableColumnsSelectFormProps {
  onChange: (v: TableColumnsSelectFormOnChangeParams) => void;
}

const { useWatch } = AntdForm;

export const TableColumnsSelectForm = forwardRef<
  TableColumnsSelectFormRef,
  TableColumnsSelectFormProps
>(function TableColumnsSelectForm({ onChange }, ref) {
  const [form] = useForm<TableColumnsSelectFormFields>();
  const content = useRef<HTMLDivElement>();
  const formColumnSelection = useWatch('selection', form);

  const [{ columns }] = useTableColumnsSelectColumns();

  const tableColumnSelection = useContextSelector<
    TableContextProps,
    TableContextProps['selection']
  >(TableContext, ({ selection }) => selection);

  const options = useMemo((): TableColumnsSelectOption[] => {
    const formColumnFlatSelection = flatten(formColumnSelection);
    const formColumnSelectionDataIndexTree = getColumnSelectionDataIndexTree(
      columns,
      formColumnFlatSelection
    );
    const options = getColumnSelectOptions({
      columns,
      selectionTree: formColumnSelectionDataIndexTree,
    });
    return options;
  }, [columns, formColumnSelection]);

  const expandIcon = (
    <Icon name="arrow-triangular-right" size="sm" color="white" />
  );

  const dropdownRender = (menus: ReactNode) => {
    return <TableColumnsSelectDropdown>{menus}</TableColumnsSelectDropdown>;
  };

  const handleOnChange = (value: TableColumnSelection) => {
    onChange({ value, form });
  };

  useImperativeHandle(ref, () => ({
    values: { selection: formColumnSelection },
  }));

  return (
    <Form<TableColumnsSelectFormFields>
      form={form}
      initialValues={{ selection: tableColumnSelection }}
    >
      <Content ref={content}>
        <Field name="selection">
          <Cascader
            open
            multiple
            options={options}
            expandIcon={expandIcon}
            onChange={handleOnChange}
            dropdownRender={dropdownRender}
            getPopupContainer={() => content.current}
            showCheckedStrategy={AntdTreeSelect.SHOW_CHILD}
          />
        </Field>
      </Content>
    </Form>
  );
});
