import { DTO } from '@spintel/network/auth';

export const ALLOWED_ERRORS: DTO.IErrorCode[] = [
  DTO.IErrorCode.SigninFailed,
  DTO.IErrorCode.UserIsLocked,
  DTO.IErrorCode.UserNotFound,
  DTO.IErrorCode.UserNotAuthenticated,
  DTO.IErrorCode.PasswordResetFailed,
  DTO.IErrorCode.InvalidToken,
];
