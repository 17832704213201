/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IErrorResponse,
  IGetSongPlayIncreaseRankPlayComparisonResponse,
  IPlayIncreasePlaylistExportCreateParams,
  ISongPlayIncreaseAboveBelowResponse,
  ISongPlayIncreasePlaylistSizeResponse,
  IStationMaxPlayModel,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class SongPlayIncrease<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SongPlayIncrease
   * @name PlayIncreaseRankPlayComparisonDetail
   * @summary Get Song rank play comparison
   * @request GET:/song/play-increase/{songId}/rank-play-comparison
   * @secure
   */
  playIncreaseRankPlayComparisonDetail = (
    songId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetSongPlayIncreaseRankPlayComparisonResponse[],
      IErrorResponse
    >({
      path: `/song/play-increase/${songId}/rank-play-comparison`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongPlayIncrease
   * @name PlayIncreaseRankPlayComparisonExportCreate
   * @summary Export Song rank play comparison
   * @request POST:/song/play-increase/{songId}/rank-play-comparison/export
   * @secure
   */
  playIncreaseRankPlayComparisonExportCreate = (
    songId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/song/play-increase/${songId}/rank-play-comparison/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongPlayIncrease
   * @name PlayIncreaseMaxPlaysDetail
   * @summary Get song max play metrics.
   * @request GET:/song/play-increase/{songId}/max-plays
   * @secure
   */
  playIncreaseMaxPlaysDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<IStationMaxPlayModel[], IErrorResponse>({
      path: `/song/play-increase/${songId}/max-plays`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongPlayIncrease
   * @name PlayIncreaseMaxPlaysExportCreate
   * @summary Export song max play metrics
   * @request POST:/song/play-increase/{songId}/max-plays/export
   * @secure
   */
  playIncreaseMaxPlaysExportCreate = (
    songId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/song/play-increase/${songId}/max-plays/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongPlayIncrease
   * @name PlayIncreasePlaylistDetail
   * @summary Get Song rank play comparison playlist
   * @request GET:/song/play-increase/{songId}/playlist
   * @secure
   */
  playIncreasePlaylistDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<ISongPlayIncreasePlaylistSizeResponse[], IErrorResponse>({
      path: `/song/play-increase/${songId}/playlist`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongPlayIncrease
   * @name PlayIncreasePlaylistExportCreate
   * @summary Export Song rank play comparison playlist
   * @request POST:/song/play-increase/{songId}/playlist/export
   * @secure
   */
  playIncreasePlaylistExportCreate = (
    { songId, ...query }: IPlayIncreasePlaylistExportCreateParams,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/song/play-increase/${songId}/playlist/export`,
      method: 'POST',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongPlayIncrease
   * @name PlayIncreaseStationsAboveDetail
   * @summary Get PlayIncrease Above
   * @request GET:/song/play-increase/{songId}/stations/{stationId}/above
   * @secure
   */
  playIncreaseStationsAboveDetail = (
    songId: number,
    stationId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<ISongPlayIncreaseAboveBelowResponse[], IErrorResponse>({
      path: `/song/play-increase/${songId}/stations/${stationId}/above`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongPlayIncrease
   * @name PlayIncreaseStationsAboveExportCreate
   * @summary Export PlayIncrease Above
   * @request POST:/song/play-increase/{songId}/stations/{stationId}/above/export
   * @secure
   */
  playIncreaseStationsAboveExportCreate = (
    songId: number,
    stationId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/song/play-increase/${songId}/stations/${stationId}/above/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongPlayIncrease
   * @name PlayIncreaseStationsBelowDetail
   * @summary Get PlayIncrease Below
   * @request GET:/song/play-increase/{songId}/stations/{stationId}/below
   * @secure
   */
  playIncreaseStationsBelowDetail = (
    songId: number,
    stationId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<ISongPlayIncreaseAboveBelowResponse[], IErrorResponse>({
      path: `/song/play-increase/${songId}/stations/${stationId}/below`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongPlayIncrease
   * @name PlayIncreaseStationsBelowExportCreate
   * @summary Export PlayIncrease Below
   * @request POST:/song/play-increase/{songId}/stations/{stationId}/below/export
   * @secure
   */
  playIncreaseStationsBelowExportCreate = (
    songId: number,
    stationId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/song/play-increase/${songId}/stations/${stationId}/below/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
}
