import { FC } from 'react';
import { merge } from 'lodash';
import { useContext } from 'use-context-selector';
import { CatalogContext } from '../Catalog.context';
import { EmptyProps } from '../../Empty/Empty';
import { Root } from './CatalogSearch.style';

interface CatalogSearchProps {
  placeholder?: string;
  empty?: EmptyProps;
}

export const CatalogSearch: FC<CatalogSearchProps> = ({
  empty,
  placeholder,
}) => {
  const { setState, width, total } = useContext(CatalogContext);

  const handleOnChange = (q: string) => {
    setState(({ empty: prev }) => ({
      q,
      empty: merge({}, prev, empty),
    }));
  };

  return (
    <Root
      disabled={!total}
      width={width}
      placeholder={placeholder}
      onChange={handleOnChange}
    />
  );
};
