import { FC, useEffect } from 'react';
import { difference, noop } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import { usePrevious } from 'react-use';
import { Segmented } from '../../../Segmented/Segmented';
import { SegmentedOptions } from '../../../Segmented/Segmented.types';
import { GraphContext, GraphContextProps } from '../../Graph.context';
import { DEFAULT_GRAPH_ID } from '../../Graph.const';
import { GraphLegendItem } from '../../Graph.types';
import { GraphLegendProps } from '../GraphLegend';

export interface GraphLegendBadgeProps
  extends Pick<GraphLegendProps, 'id' | 'label' | 'disabled' | 'hint'> {
  onChange?: (data: string[]) => void;
}

export const GraphLegendBadge: FC<GraphLegendBadgeProps> = ({
  id = DEFAULT_GRAPH_ID,
  disabled = () => false,
  label = ({ item: { label } }) => label,
  hint = () => null,
  onChange = noop,
}) => {
  const chart = useContextSelector<
    GraphContextProps,
    GraphContextProps['graph']['current']['chart']['current']
  >(GraphContext, ({ graph }) => graph?.current?.chart?.current);

  const legend = useContextSelector<GraphContextProps, GraphLegendItem[]>(
    GraphContext,
    ({ legend }) => legend[id] || []
  );

  const options: SegmentedOptions = legend.map((item, index) => ({
    ...item,
    hint: hint({ item, index }),
    disabled: disabled({ item, index }),
    label: label({ item, index }),
  }));

  const value = chart?.data.datasets.reduce((res, { label }, index) => {
    return chart.getDatasetMeta(index).hidden ? res : [...res, label];
  }, []);

  const prevValue = usePrevious(value);

  const handleOnChange = (update: string[]) => {
    const [removed] = difference(value, update);
    const [added] = difference(update, value);
    const datasetIndex = chart?.data.datasets.findIndex(
      ({ label }) => label === added || label === removed
    );
    if (removed) chart?.hide(datasetIndex);
    if (added) chart?.show(datasetIndex);
  };

  useEffect(() => {
    prevValue !== value && onChange(value);
  }, [onChange, prevValue, value]);

  return (
    <Segmented<string[]>
      wrap
      multiple
      size="sm"
      opacity={1}
      value={value}
      variant="split"
      options={options}
      onChange={handleOnChange}
    />
  );
};
