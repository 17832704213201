import styled, { createGlobalStyle, css } from 'styled-components';
import AntdCascader from 'antd/lib/cascader';

const CascaderCheckboxInput = css`
  .ant-cascader-checkbox {
    &.ant-cascader-checkbox-indeterminate {
      .ant-cascader-checkbox-inner {
        &:after {
          width: 10px;
          height: 2px;
          background-color: ${({ theme }) => theme.palette.white};
        }
      }
    }
    .ant-cascader-checkbox-inner {
      box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.wolfGrey};
      background-color: ${({ theme }) => theme.palette.metropolisGrey};
    }
    + .ant-cascader-menu-item-content {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
    &.ant-cascader-checkbox-checked {
      .ant-cascader-checkbox-inner {
        box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.white};
      }
    }
    &.ant-cascader-checkbox-checked + .ant-cascader-menu-item-content {
      text-shadow: 0.7px 0 0 currentColor;
    }
    &.ant-cascader-checkbox-disabled {
      .ant-cascader-checkbox-inner {
        background-color: ${({ theme }) => theme.palette.metropolisGrey};
        box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.wolfGrey}40;
      }
      &.ant-cascader-checkbox-indeterminate {
        .ant-cascader-checkbox-inner {
          box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.white}40;
          &:after {
            background-color: ${({ theme }) => theme.palette.white}40;
          }
        }
      }
      &.ant-cascader-checkbox-checked {
        .ant-cascader-checkbox-inner {
          box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.white}40;
        }
        &:after {
          border-color: ${({ theme }) => theme.palette.transparent};
        }
      }
    }
    &:after {
      display: none;
    }

    &:not(.ant-cascader-checkbox-disabled):hover {
      .ant-cascader-checkbox-inner {
        box-shadow: 0px 0px 0px 1px
          ${({ theme }) => theme.palette.guacamoleGreen};
      }
    }
  }
  &.ant-cascader-checkbox-disabled {
    .ant-cascader-checkbox-inner {
      box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.wolfGrey};
    }
    + span {
      color: ${({ theme }) => theme.palette.white}40;
    }
  }
`;

export const Global = createGlobalStyle`
    .ant-cascader-dropdown {
        .ant-cascader-menus {
            .ant-cascader-menu {
                min-width: ${({ theme: { spacing } }) => spacing(60)};
                max-height: ${({ theme: { spacing } }) => spacing(68)};
                height: auto;
                border-right: none;
                .ant-cascader-menu-item {
                    ${CascaderCheckboxInput}
                    .ant-cascader-checkbox {
                        top: 0;
                    }
                    .ant-cascader-menu-item-expand-icon {
                        display: flex;
                        align-items: center;
                    }
                }
                .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
                .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
                  background-color: ${({ theme }) => theme.palette.wolfGrey};
                }

                & + .ant-cascader-menu{
                    border-left: 1px solid ${({ theme }) =>
                      theme.palette.asphaltGrey};
                }
            }
        }
    }
`;

export const Root = styled(AntdCascader)``;
