import styled from 'styled-components';
import { CSSProperties } from 'react';

interface RootProps {
  align: CSSProperties['alignItems'];
  justify: CSSProperties['justifyContent'];
}

export const Root = styled('div')<RootProps>`
  display: flex;
  flex: 0 0 auto;
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  padding-top: ${({ theme: { spacing } }) => spacing(5)};
`;
