import { useMemo } from 'react';
import { castArray } from 'lodash';
import { AxiosResponse, AxiosError } from 'axios';
import { useMutation as useReactQueryMutation } from 'react-query';
import { UseMutation } from '../../types/hooks.types';

type CallbackReturn = (...args: any) => any;

export function useCommonMutation<
  T,
  Req extends any[] = never[],
  Res = unknown,
  Err extends object = {}
>(
  context: T,
  callback: (context: T) => CallbackReturn
): UseMutation<Req, Res, Err> {
  const { data, isLoading, error, mutate, reset } = useReactQueryMutation<
    AxiosResponse<Res>,
    AxiosError<Err>,
    Req
  >((req) => {
    return callback(context)(...castArray(req));
  }, {});

  return useMemo(
    (): UseMutation<Req, Res, Err> => [
      {
        data: data?.data,
        error: error?.response?.data,
        loading: isLoading,
      },
      mutate,
      reset,
    ],
    [data, isLoading, error, mutate, reset]
  );
}
