import { ReactElement } from 'react';
import { get } from 'lodash';
import { RootRadio as Radio } from '../../Input/Input.style';
import { FormItem, FormItemProps } from '../FormItem/FormItem';
import { FormCheckboxGroupValidationRules } from '../FormItem/FormItem.types';
import { Help } from '../../Checkbox/Checkbox.style';
import {
  FormRadioGroupOption,
  FormRadioGroupDirection,
} from './FormRadioGroup.types';
import { DEFAULT_FIELD } from './FormRadioGroup.const';
import { Root } from './FormRadioGroup.style';

interface FormRadioGroupProps<T extends object = FormRadioGroupOption>
  extends FormItemProps<FormCheckboxGroupValidationRules> {
  options: T[];
  fields?: Partial<FormRadioGroupOption>;
  direction?: FormRadioGroupDirection;
}

export interface FormRadioGroupComponent {
  <T extends object>(props: FormRadioGroupProps<T>): ReactElement<
    any,
    any
  > | null;
}

export const FormRadioGroup: FormRadioGroupComponent = ({
  options,
  fields,
  direction = 'column',
  ...props
}) => {
  return (
    <FormItem hasFeedback {...props}>
      <Root direction={direction}>
        {options.map((item) => {
          const help = get(item, fields?.help || DEFAULT_FIELD.help);
          return (
            <Radio
              key={get(item, fields?.label || DEFAULT_FIELD.label)}
              value={get(item, fields?.value || DEFAULT_FIELD.value)}
            >
              {get(item, fields?.label || DEFAULT_FIELD.label)}
              {help && <Help>{help}</Help>}
            </Radio>
          );
        })}
      </Root>
    </FormItem>
  );
};
