import { FC } from 'react';
import { noop, debounce } from 'lodash';
import { Icon } from '../Icon/Icon';
import { SearchSize } from './Search.type';
import { SEARCH_SIZE_MAP, ICON_SIZE_MAP } from './Search.const';
import { Root, Spinner, ClearIcon } from './Search.style';

export interface SearchProps {
  value?: string;
  size?: SearchSize;
  loading?: boolean;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  debounce?: number;
  className?: string;
}

export const Search: FC<SearchProps> = ({
  value,
  loading,
  disabled,
  className,
  size = 'md',
  placeholder,
  onChange = noop,
  debounce: debounceValue = 0,
}) => {
  const prefix = (() => {
    if (loading) {
      return <Spinner size={size} />;
    }
    return <Icon size={ICON_SIZE_MAP[size]} name="search" />;
  })();

  const handleOnChange = debounce(onChange, debounceValue);

  return (
    <Root
      value={value}
      prefix={prefix}
      disabled={disabled}
      className={className}
      size={SEARCH_SIZE_MAP[size]}
      placeholder={placeholder}
      onChange={({ target: { value } }) => handleOnChange(value)}
      allowClear={{
        clearIcon: <ClearIcon size={ICON_SIZE_MAP[size]} name="clear" />,
      }}
    />
  );
};
