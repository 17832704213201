import { FC, PropsWithChildren } from 'react';
import { UiKitColor } from '../../types/uikit';
import { THEME } from '../../constants/theme.const';
import { Typography } from '../Typography/Typography';
import { BadgeVariant } from './Badge.types';
import { Status } from './Badge.style';

type BadgeProps = PropsWithChildren<{
  variant?: BadgeVariant;
  color?: UiKitColor;
  opacity?: number;
  typography?: keyof typeof Typography;
  className?: string;
}>;

export const Badge: FC<BadgeProps> = ({
  children,
  className,
  opacity = 0.4,
  variant = 'default',
  color = 'anthraciteGrey',
}) => {
  if (variant === 'status') {
    return (
      <Status
        text={children}
        opacity={opacity}
        className={className}
        color={THEME.palette[color]}
      />
    );
  }
  return null;
};
