import React from 'react';
import { stringify } from 'qs';
import { generatePath } from 'react-router';
import { Navigate } from 'react-router-dom';

interface RedirectProps {
  exact?: boolean;
  from?: {
    path: string;
    params?: Record<string, string>;
  };
  to:
    | string
    | {
        path: string;
        params?: PlainObject;
        search?: PlainObject;
      };
}

export function Redirect({ to }: RedirectProps): React.ReactElement {
  const href = (() => {
    if (typeof to === 'object') {
      return {
        pathname: generatePath(to.path, to.params),
        search: stringify(to.search, { addQueryPrefix: true }),
      };
    }
    return to;
  })();

  return <Navigate to={href} />;
}
