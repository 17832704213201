import { ReactElement } from 'react';
import { ColumnProps as AntdColumnProps } from 'antd/lib/table';
import { ColumnTitleProps as AntdColumnTitleProps } from 'antd/lib/table/interface';
import { Root, Sorter, Icon } from './TableColumnTitle.style';

export interface TableColumnTitleProps<T> extends AntdColumnTitleProps<T> {
  dataIndex: string;
  title: string;
  sorter?: AntdColumnProps<T>['sorter'];
  className?: string;
}

export interface TableColumnTitleComponent {
  <T extends object>(props: TableColumnTitleProps<T>): ReactElement<
    any,
    any
  > | null;
}

export const TableColumnTitle: TableColumnTitleComponent = ({
  dataIndex,
  title,
  sortColumns,
  sorter,
  filters,
  className,
}) => {
  const { order } =
    sortColumns.find(
      ({ column: { dataIndex: index } }) => index === dataIndex
    ) || {};

  return (
    <Root active={!!order || !!filters.length} className={className}>
      {title}
      {!!sorter && (
        <Sorter down={order === 'descend'}>
          <Icon active={!!order} name="sort-ascending" size="xs" />
        </Sorter>
      )}
    </Root>
  );
};
