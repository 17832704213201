import styled from 'styled-components';
import { THEME } from '../../../constants/theme.const';
import { Typography } from '../../Typography/Typography';

export const Root = styled('div')`
  position: relative;
  display: flex;
  min-height: ${({ theme: { spacing } }) => spacing(16)};
  color: ${({ theme }) => theme.palette.pearlGrey};
  align-items: flex-end;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 1px;
    width: ${({ theme: { spacing } }) => spacing(3)};
    z-index: 2;
    background: linear-gradient(
      100.33deg,
      rgba(27, 27, 27, 0) 8.91%,
      ${({ theme }) => theme.palette.anthraciteGrey} 93.5%
    );
  }

  &:hover {
    color: ${({ theme }) => theme.palette.stardustGrey};
  }

  &:active {
    color: ${({ theme }) => theme.palette.stardustGrey}40;
  }
`;

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  overflow: hidden;
`;

export const Header = styled('div')<{ active: boolean }>`
  overflow: hidden;
  * {
    color: ${({ theme, active }) => (active ? theme.palette.white : 'inherit')};
    overflow: hidden;
  }
`;

export const Body = styled('span')`
  display: flex;
  align-items: flex-end;
  gap: ${({ theme: { spacing } }) => spacing(2)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(3)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Value = styled(Typography.Heading3)<{ active: boolean }>`
  color: ${({ theme, active }) => (active ? theme.palette.white : 'inherit')};
  flex: 0 0 auto;
`;

export const ValueDiff = styled(Typography.Body3)`
  flex: 1 1 auto;
`;

export const Bar = styled('div')<{
  color: keyof typeof THEME.palette;
  total: number;
  value: number;
}>`
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ color, theme: { palette } }) => palette[color]}40;
  height: ${({ total, value }) => (100 / total) * value}%;
`;
