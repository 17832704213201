import { useContext, forwardRef, useMemo } from 'react';
import { isEqual } from 'lodash';
import { TableFilterValue, TableColumnsType } from '../Table.types';
import { UiKitLocalizationContext } from '../../../contexts/UiKitLocalization.context';
import { getFlattenColumns } from '../Table.utils';
import { Root, Label, Content, Clear } from './TableFiltersHeader.style';
import { TableFiltersHeaderTag } from './TableFiltersHeaderTag/TableFiltersHeaderTag';

interface TableFiltersHeaderProps {
  onClearAll: () => void;
  columns?: TableColumnsType<object>;
  onRemoveFilter: (key: string) => void;
  filters: Record<string, TableFilterValue>;
}

export const TableFiltersHeader = forwardRef<
  HTMLDivElement,
  TableFiltersHeaderProps
>(function TableFiltersHeader(
  { filters, onRemoveFilter, onClearAll, columns },
  ref
) {
  const { table } = useContext(UiKitLocalizationContext);

  const data = useMemo(() => {
    const data = Object.entries(filters).filter(([, value]) => !!value.length);
    if (columns) {
      const flat = getFlattenColumns(columns);
      return data.filter(([name]) => {
        return flat.some(({ dataIndex }) => isEqual(dataIndex, name));
      });
    }
    return data;
  }, [filters, columns]);

  return !!data.length ? (
    <Root ref={ref}>
      <Content>
        <Label>{table.filtersAppliedText}</Label>
        {data.map(([key, filter]) => {
          return (
            <TableFiltersHeaderTag
              key={key}
              dataIndex={key}
              filter={filter}
              onRemove={() => onRemoveFilter(key)}
            ></TableFiltersHeaderTag>
          );
        })}
      </Content>
      <Clear variant="link" onClick={onClearAll}>
        {table.filtersClearAllBtn}
      </Clear>
    </Root>
  ) : (
    <div ref={ref}></div>
  );
});
