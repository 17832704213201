import { FC } from 'react';
import { useContextSelector } from 'use-context-selector';
import { Navigate } from 'react-router-dom';
import {
  NavigationContext,
  NavigationContextProps,
} from '../../contexts/Navigation.context';

export const DefaultRedirect: FC = () => {
  const defaultUrl = useContextSelector<NavigationContextProps, string>(
    NavigationContext,
    ({ defaultUrl }) => defaultUrl
  );

  return <Navigate to={defaultUrl} />;
};
