import styled, { css } from 'styled-components';
import Drawer from 'antd/lib/drawer';
import { Root as Icon } from '../Icon/Icon.style';
import { Typography } from '../Typography/Typography';

const { Body3 } = Typography;

const RootTransparentMixin = css`
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const RootInlineMixin = css`
  z-index: 500;
`;

export const Root = styled(Drawer).withConfig({
  shouldForwardProp: (prop) => !['transparent'].includes(prop),
})<{ transparent: boolean }>`
  .ant-drawer-header {
    padding: ${({ theme: { spacing } }) => spacing(4)};
    padding-left: ${({ theme: { spacing } }) => spacing(6)};
    flex: 0 0 auto;

    .ant-drawer-header-title {
      display: flex;
      align-items: center;
      gap: ${({ theme: { spacing } }) => spacing(3)};
      .ant-drawer-title {
        order: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 0 auto;
      }
      .ant-drawer-close {
        order: 2;
        margin: 0;
        width: ${({ theme: { spacing } }) => spacing(10)};
        height: ${({ theme: { spacing } }) => spacing(10)};
        flex: 0 0 auto;
      }
    }
    &:not(.ant-drawer-header-close-only) {
      border-bottom: 1px solid ${({ theme }) => theme.palette.asphaltGrey};
    }
    &.ant-drawer-header-close-only {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .ant-drawer-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
  }
  .ant-form {
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  .ant-table thead > tr > th {
    background-color: ${({ theme }) => theme.palette.anthraciteGrey};
  }
  .ant-table tbody {
    > tr {
      > td {
        background-color: ${({ theme }) => theme.palette.anthraciteGrey};
        &.ant-table-cell-fix-left {
          background-color: ${({ theme }) => theme.palette.anthraciteGrey};
        }
      }
      &.ant-table-row:hover,
      &:hover {
        > td {
          background: ${({ theme }) => theme.palette.anthraciteGrey};
          background-color: ${({ theme }) => theme.palette.anthraciteGrey};
          &.ant-table-cell-fix-left {
            background-color: ${({ theme }) => theme.palette.anthraciteGrey};
          }
        }
      }
    }
  }
  .ant-card,
  .ant-table {
    background-color: ${({ theme }) => theme.palette.anthraciteGrey};
  }
  ${({ transparent }) => transparent && RootTransparentMixin}
  ${({ getContainer }) => getContainer && RootInlineMixin}
`;

export const Header = styled('div')``;

export const TitleLine = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  gap: ${({ theme: { spacing } }) => spacing(2)};
  align-items: center;
  > span {
    display: inline-flex;
  }
  ${Icon} {
    cursor: pointer;
  }
`;

export const Subtitle = styled(Body3)`
  color: ${({ theme }) => theme.palette.stardustGrey};
`;
