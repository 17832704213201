/* eslint-disable import/no-anonymous-default-export */
import { merge } from 'lodash';
import { LOCALE } from '@spintel/shared';
import actions from './actions';
import confirmations from './confirmations';
import forms from './forms';
import mails from './mails';
import navigation from './navigation';
import validation from './validation';
import mfa from './mfa';
import notifications from './notifications';
import placeholders from './placeholders';
import help from './help';
import httpErrorCode from './httpErrorCode';
import options from './options';
import httpValidationErrorCode from './httpValidationErrorCode';
import empty from './empty';
import hints from './hints';

export default merge(
  {
    actions,
    confirmations,
    empty,
    forms,
    help,
    hints,
    httpErrorCode,
    httpValidationErrorCode,
    mails,
    mfa,
    navigation,
    notifications,
    options,
    placeholders,
    validation,
  },
  LOCALE.en
);
