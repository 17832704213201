import styled from 'styled-components';
import AntdForm from 'antd/lib/form';

export const Root = styled(AntdForm.Item).withConfig({
  shouldForwardProp: (prop) => !['hideLabel'].includes(prop),
})<{ hideLabel?: boolean }>`
  &.ant-form-item-with-help {
    margin-bottom: ${({ theme: { spacing } }) => spacing(6)};
    .ant-form-item-explain {
      min-height: 0px !important;
    }
  }
  .ant-form-item-label {
    ${({ hideLabel }) => (hideLabel ? 'display: none;' : '')}
    label {
      height: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 16px;
      font-weight: 600;
      &.ant-form-item-required-mark-optional {
        .ant-form-item-optional {
          color: ${({ theme: { palette } }) => palette.white};
        }
      }
    }
  }
  .ant-form-item-extra,
  .ant-form-item-explain {
    font-size: 12px;
    line-height: 16px;
    margin-top: ${({ theme: { spacing } }) => spacing(1)};
    color: ${({ theme: { palette } }) => palette.pearlGrey};
    .ant-form-item-explain-error {
      font-weight: 600;
    }
  }
  &.ant-form-item-has-success {
    .ant-form-item-explain {
      display: none !important;
    }
  }

  &.ant-form-item-has-error {
    .ant-form-item-extra {
      display: none;
    }
  }
`;
