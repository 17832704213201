import { castArray } from 'lodash';
import {
  TableGlobalColumnsType,
  TableColumnSelection,
  TableGlobalColumnsExtendedType,
} from '../Table.types';
import { getTreeDataValue } from '../Table.utils';

interface GetColumnIdParams {
  title?: string;
  dataIndex?: string | string[];
}

export const getColumnId = ({
  title,
  dataIndex,
}: GetColumnIdParams): string[] => {
  if (dataIndex) return castArray(dataIndex);
  return [title];
};

export const decorateColumnsWithId = <T extends object>(
  columns: TableGlobalColumnsType<T>,
  parent: string[] = []
): TableGlobalColumnsExtendedType<T> => {
  return columns.map((props) => {
    switch (props.type) {
      case 'group':
        return {
          ...props,
          id: getTreeDataValue([
            ...parent,
            ...getColumnId({ title: props.title }),
          ]),
          children: decorateColumnsWithId(props.children, [
            ...parent,
            ...getColumnId({ title: props.title }),
          ]),
        };
      case 'column':
        return {
          ...props,
          id: getTreeDataValue([
            ...parent,
            ...getColumnId({
              title: props.title,
              dataIndex: props.dataIndex,
            }),
          ]),
        };
      default:
        return props;
    }
  });
};

export const getDefaultColumnSelection = <T extends object>(
  columns: TableGlobalColumnsExtendedType<T>,
  parents: string[] = []
): TableColumnSelection => {
  return columns.reduce((res, props) => {
    switch (props.type) {
      case 'group':
        return props.showHeader !== false && props.title
          ? [
              ...res,
              ...getDefaultColumnSelection(props.children, [
                ...parents,
                props.id,
              ]),
            ]
          : [...res, ...getDefaultColumnSelection(props.children, parents)];
      case 'column':
        return props?.select?.default ? [...res, [...parents, props.id]] : res;
      default:
        return res;
    }
  }, [] as TableColumnSelection);
};

export const getTotalSelectionNodes = <T extends object>(
  columns: TableGlobalColumnsExtendedType<T>,
  total: number = 0
): number => {
  return columns.reduce((res, props) => {
    switch (props.type) {
      case 'group':
        return getTotalSelectionNodes(props.children, res);
      case 'column':
        return props?.select ? res + 1 : res;
      default:
        return res;
    }
  }, total);
};
