import { useOptionsByRole, useUser } from '@spintel/network/auth';
import { useMutation } from '@spintel/network/app';
import { useModal, MenuItem } from '@spintel/uikit';
import { useRoute } from '@spintel/navigation';
import { useTranslation } from '@spintel/localization';
import { AuthRoutePath, MODALS } from '@spintel/shared';
import { RoutePath, USER_ROLES, UserRole } from '@common/constants';
import { usePanel } from '../../../../hooks/usePanel/usePanel.hook';
import { UserMenuContent } from './User.style';

export const useUserMenu = () => {
  const [
    {
      loading,
      user: { teams: userTeams, selectedTeamId, firstName, lastName, role },
    },
    refetchUser,
  ] = useUser();
  const [, refetchPanels] = usePanel();
  const [, { push }] = useRoute();
  const { t } = useTranslation();
  const [, toggle] = useModal(MODALS.CHANGE_PASSWORD);

  const [{ loading: updating }, update] = useMutation<[number], void>(
    ({ User }) => User.teamsCreate
  );

  const handleOnClick = (id: number) => {
    update([id], {
      onSuccess: () => {
        refetchUser();
        refetchPanels();
      },
    });
  };

  const teamsOptions = ((): MenuItem[] => {
    const options: MenuItem[] = userTeams.map(
      ({ name, id }): MenuItem => ({
        key: id,
        type: null,
        label: name,
        onClick: () => handleOnClick(id),
        icon: id === selectedTeamId ? 'check' : 'placeholder',
      })
    );
    const divider: MenuItem = {
      type: 'divider',
    };
    if (!userTeams.length) {
      return [];
    }
    if (userTeams.length > 5) {
      return [
        {
          type: null,
          children: options,
          label: t('options.teams'),
        },
        divider,
      ];
    }
    return [...options, divider];
  })();

  const { options: menuOptions } = useOptionsByRole<MenuItem>({
    options: [
      {
        key: 'team-management',
        label: t('actions.teamManagement'),
        onClick: () =>
          push({
            to: RoutePath.MANAGEMENT_TEAMS,
          }),
        role: [UserRole.TeamManager],
      },
      {
        key: 'account-management',
        label: t('actions.accountManagement'),
        onClick: () =>
          push({
            to: RoutePath.MANAGEMENT,
          }),
        role: [UserRole.AccountManager, UserRole.SuperAdmin],
      },
      {
        type: 'divider',
        role: [
          UserRole.TeamManager,
          UserRole.AccountManager,
          UserRole.SuperAdmin,
        ],
      },
      {
        key: 'resetPassword',
        label: t('actions.resetPassword'),
        onClick: () => toggle(true),
        role: USER_ROLES,
      },
      {
        key: 'logout',
        label: t('actions.logout'),
        onClick: () =>
          push({
            to: AuthRoutePath.LOGOUT,
          }),
        role: USER_ROLES,
      },
    ],
  });

  const menu: MenuItem[] = [
    {
      type: 'group',
      label: (
        <UserMenuContent
          size="md"
          subtitle={role}
          title={t('forms.fullName', { firstName, lastName })}
        />
      ),
    },
    {
      type: 'divider',
    },
    ...teamsOptions,
    ...menuOptions,
  ];

  return [{ menu, updating: updating || loading }];
};
