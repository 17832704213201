import { useContext, useRef, FC, PropsWithChildren } from 'react';
import { get } from 'lodash';
import { Table, useTableConfig } from '@spintel/uikit';
import { DTO } from '@spintel/network/app';
import { isHighlightedRow } from '@common/utils';
import { useSong } from '@common/hooks';
import { useSongsComparison } from '@common/hooks';
import { useTranslation } from '@spintel/localization';
import { DEFAULT_SORTING } from '../StationMusicStack.const';
import { TableStyles } from '../StationMusicStack.types';
import { StationMusicStackContext } from '../StationMusicStack.context';
import { Root } from './StationMusicStackTable.style';
import { StationMusicStackTableActions } from './StationMusicStackTableActions/StationMusicStackTableActions';

type StationMusicStackTableProps = PropsWithChildren<{
  loading: boolean;
  styles?: TableStyles;
  sorting: DTO.IPagedSort;
  onSort?: (v: DTO.IPagedSort) => void;
  data: DTO.IStationMusicStackResponse[];
}>;

export const StationMusicStackTable: FC<StationMusicStackTableProps> = ({
  data,
  onSort,
  styles,
  sorting,
  loading,
  children,
}) => {
  const { t } = useTranslation();
  const [{ song }] = useSong();
  const { stationId } = useContext(StationMusicStackContext);
  const [{ songIds }] = useSongsComparison({ stationId });
  const container = useRef<HTMLDivElement>();

  const { selected } = useTableConfig<DTO.IStationMusicStackResponse>();

  return (
    <Root ref={container} height={get(styles, 'height', 'calc(100vh - 220px)')}>
      <Table<DTO.IStationMusicStackResponse, DTO.IPagedSort>
        virtual
        data={data}
        scroll={{ x: 0 }}
        sorting={sorting}
        loading={loading}
        columns={selected}
        defaultSorting={DEFAULT_SORTING}
        rowKey={({ songId }) => songId}
        stretchTo={() => container.current}
        onChange={({ sort }) => onSort(sort)}
        gutter={get(styles, 'outerSpace', [0, 0, 0, 0])}
        row={{
          highlighted: ({ item }) =>
            isHighlightedRow({
              songId: song.songId,
              currentSongId: item.songId,
              songIds,
            }),
        }}
        actions={StationMusicStackTableActions}
        empty={{
          icon: 'song',
          background: get(styles, 'empty'),
          title: t('empty.noSongsToDisplay'),
        }}
      >
        {children}
      </Table>
    </Root>
  );
};
