import { FC, PropsWithChildren } from 'react';
import { Root } from './CardHeader.style';

type CardHeaderProps = PropsWithChildren<{
  className?: string;
}>;

export const CardHeader: FC<CardHeaderProps> = ({ children, className }) => {
  return <Root className={className}>{children}</Root>;
};
