import styled, { createGlobalStyle, css } from 'styled-components';
import AntdMenu from 'antd/lib/menu';
import { THEME } from '../../constants/theme.const';

export const Root = styled(AntdMenu)<{ width?: number }>`
  &.ant-dropdown-menu {
    width: ${({ width }) => width && `${width}px`};
    box-shadow: none;
    border-radius: 0;
    .ant-dropdown-menu-item-divider {
      margin-left: ${({ theme: { spacing } }) => spacing(4)};
      margin-right: ${({ theme: { spacing } }) => spacing(4)};
    }
    .ant-dropdown-menu-submenu {
      .ant-dropdown-menu-submenu-title {
        padding-left: ${({ theme: { spacing } }) => spacing(4)};
        padding-right: ${({ theme: { spacing } }) => spacing(4)};
        color: ${({ theme: { palette } }) => palette.white};
        .ant-dropdown-menu-submenu-expand-icon {
          right: ${({ theme: { spacing } }) => spacing(4)};
        }
      }
    }
    .ant-dropdown-menu-item {
      padding-left: ${({ theme: { spacing } }) => spacing(4)};
      padding-right: ${({ theme: { spacing } }) => spacing(4)};
      color: ${({ theme: { palette } }) => palette.white};
      &:hover {
        background-color: ${({ theme: { palette } }) => palette.wolfGrey};
      }
      &.ant-dropdown-menu-item-selected {
        &:hover {
          background-color: ${({ theme: { palette } }) => palette.white};
          color: ${({ theme: { palette } }) => palette.metropolisGrey};
        }
      }
      &.ant-dropdown-menu-item-selected {
        color: ${({ theme: { palette } }) => palette.metropolisGrey};
      }
      .ant-dropdown-menu-title-content {
        max-width: 100%;
        a {
          text-decoration: none;
        }
      }
      &.with-status {
        display: flex;
        align-items: center;
        gap: ${({ theme: { spacing } }) => spacing(2)};
        &::before {
          content: '';
          display: block;
          width: ${({ theme: { spacing } }) => spacing(4)};
          height: ${({ theme: { spacing } }) => spacing(4)};
          border-radius: 50%;
          opacity: 0.4;
        }
      }

      &.truncated-item {
        .ant-dropdown-menu-title-content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      ${Object.entries(THEME.palette).map(
        ([key, color]) => css`
          &.with-status-${key} {
            &::before {
              background-color: ${color};
            }
          }
        `
      )}
    }
  }
`;

export const Global = createGlobalStyle`
  .ant-dropdown-menu-submenu {
    .ant-dropdown-menu {
      background: ${({ theme: { palette } }) => palette.icarusGrey};
      max-height: ${({ theme }) => theme.spacing(68)};
      overflow: auto;
    }
    .ant-dropdown-menu-item {
      &.ant-dropdown-menu-item-selected {
        &:hover {
          background-color: ${({ theme: { palette } }) => palette.white};
          color: ${({ theme: { palette } }) => palette.metropolisGrey};
        }
      }
      &.truncated-item {
        .ant-dropdown-menu-title-content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
`;
