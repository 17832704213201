import { createContext } from 'react';
import { noop } from 'lodash';
import { TableColumnsSelectDropdownGroups } from './TableColumnsSelectDropdown.types';

interface TableColumnsSelectDropdownContextProps {
  groups: TableColumnsSelectDropdownGroups;
  setGroup: (parent: string, group: string, area: string) => void;
  resetGroup: (parent: string) => void;
}

export const TableColumnsSelectDropdownContext =
  createContext<TableColumnsSelectDropdownContextProps>({
    groups: {},
    setGroup: noop,
    resetGroup: noop,
  });
