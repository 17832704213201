import { FC } from 'react';
import { useMount } from 'react-use';
import { Redirect, useRoute } from '@spintel/navigation';
import { Form, Button, Prompt } from '@spintel/uikit';
import { DTO, useLogin, useAuth } from '@spintel/network/auth';
import { AuthRoutePath } from '@common/constants';
import { useTranslation } from '@spintel/localization';
import { AuthPage } from '../../partitions/AuthPage/AuthPage';

export const Login: FC = () => {
  const [, { handleLoginCallback }] = useAuth();
  const { t } = useTranslation();
  const [, { push }] = useRoute();
  const [{ data, error, loading }, login] = useLogin();

  const handleOnSubmit = ({ password, email }: DTO.ILoginUserDto) => {
    login({ password, email: email.trim() });
  };

  useMount(() => handleLoginCallback({}));

  if (data?.isMfaRequired && !data?.isMfaConfigured) {
    return (
      <Redirect
        to={{ path: AuthRoutePath.MFA_SETUP, search: { token: data.token } }}
      />
    );
  }

  if (data?.isMfaRequired && data?.isMfaConfigured) {
    return (
      <Redirect
        to={{ path: AuthRoutePath.MFA_CONFIRM, search: { token: data.token } }}
      />
    );
  }

  if (data) {
    return (
      <Redirect
        to={{ path: AuthRoutePath.CALLBACK, search: { token: data.token } }}
      />
    );
  }

  return (
    <AuthPage errorCode={error?.errorCode}>
      <Prompt title={t('forms.logIn')}>
        <Form
          initialValues={{ email: '', password: '' }}
          onSubmit={handleOnSubmit}
        >
          <Prompt.Content>
            <Form.Input
              name="email"
              label={t('forms.email')}
              placeholder={t('placeholders.enterYourEmail')}
              autofocus
              validation={{
                required: true,
                email: true,
                whitespace: true,
              }}
            />
            <Form.Password
              name="password"
              label={t('forms.password')}
              placeholder={t('placeholders.enterYourPassword')}
              validation={{
                required: true,
              }}
            />
          </Prompt.Content>
          <Prompt.Actions justify="space-between">
            <Button
              size="lg"
              variant="link"
              onClick={() => push({ to: AuthRoutePath.FORGOT_PASSWORD })}
            >
              {t('actions.forgotPassword')}
            </Button>
            <Form.Submit
              loading={loading}
              size="lg"
              variant="primary"
              disabled={error?.errorCode === DTO.IErrorCode.UserIsLocked}
            >
              {t('actions.login')}
            </Form.Submit>
          </Prompt.Actions>
        </Form>
      </Prompt>
    </AuthPage>
  );
};
