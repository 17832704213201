import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Root = styled('div')<{
  justify: CSSProperties['justifyContent'];
}>(({ justify, theme: { palette, spacing } }) => ({
  display: 'flex',
  justifyContent: justify,
  gap: spacing(3),
  padding: spacing(6),
  borderTop: `1px solid ${palette.asphaltGrey}`,
}));
