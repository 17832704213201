import { FC } from 'react';
import { useTranslation } from '@spintel/localization';
import { StationDetailsContactsItem } from '../StationContacts.types';
import {
  ContactsItem,
  ContactsItemGroup,
  ContactsItemLabel,
  ContactsItemValue,
} from './StationContactsItem.styles';

interface StationContactsItemProps extends StationDetailsContactsItem {
  isBlock?: boolean;
}

export const StationContactsItem: FC<StationContactsItemProps> = ({
  icon,
  label,
  value,
  isBlock = false,
}) => {
  const { t } = useTranslation();

  return (
    <ContactsItem isBlock={isBlock}>
      <ContactsItemGroup isBlock={isBlock}>
        {icon}
        <ContactsItemLabel>{t(label)}</ContactsItemLabel>
      </ContactsItemGroup>
      <ContactsItemValue>{value}</ContactsItemValue>
    </ContactsItem>
  );
};
