import styled from 'styled-components';

export const Root = styled('div')<{ gutter: number[] }>`
  flex: 1 1 auto;
  overflow: auto;
  padding-top: ${({ gutter: [top], theme: { spacing } }) => spacing(top)};
  padding-right: ${({ gutter: [, right], theme: { spacing } }) =>
    spacing(right)};
  padding-bottom: ${({ gutter: [, , bottom], theme: { spacing } }) =>
    spacing(bottom)};
  padding-left: ${({ gutter: [, , , left], theme: { spacing } }) =>
    spacing(left)};
`;
