import { forwardRef } from 'react';
import { InputRef, InputProps as AntdInputProps } from 'antd/lib/input';
import { RootInput } from './Input.style';

interface InputProps extends AntdInputProps {}

export const Input = forwardRef<InputRef, InputProps>(function Input(
  { ...props },
  ref
) {
  return <RootInput {...props} ref={ref} />;
});
