import {
  Children,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isBoolean, noop } from 'lodash';
import { MapRef, MapSourceDataEvent, useMap } from 'react-map-gl';
import { MapLayer } from './Map.types';
import { MapGeo } from './MapGeo/MapGeo';
import { getActiveLayerIds } from './Map.utils';

interface UseOnClickParams {
  id: string;
  onClick?: () => void;
}

export const useOnClick = ({ id, onClick }: UseOnClickParams) => {
  const { current: map } = useMap();

  useEffect(() => {
    if (map && onClick) {
      const onMouseenter = () => {
        map.getCanvas().style.cursor = 'pointer';
      };
      const onMouseleave = () => {
        map.getCanvas().style.cursor = 'default';
      };
      map.on('click', id, onClick);
      map.on('mouseenter', id, onMouseenter);
      map.on('mouseleave', id, onMouseleave);
      return () => {
        map.off('click', id, onClick);
        map.off('mouseenter', id, onMouseenter);
        map.off('mouseleave', id, onMouseleave);
      };
    }
    return noop;
  }, [id, onClick, map]);
};

interface MapLoadingParams {
  sourceIds: string[];
  onSourceLoaded: () => void;
  map: MapRef | undefined;
  loadingData?: boolean;
}

export const useMapLoading = ({
  loadingData,
  sourceIds,
  onSourceLoaded,
  map,
}: MapLoadingParams) => {
  const hasSource = isBoolean(loadingData);
  const [loadedSourceIds, setLoadedSourceIds] = useState<string[]>([]);
  const [sourceLoaded, setSourceLoaded] = useState(false);

  const onSourceData = useCallback(
    (data: MapSourceDataEvent) =>
      setTimeout(() => {
        if (!sourceIds.length) return;
        const { sourceId, isSourceLoaded } = data;
        const match = sourceIds.includes(sourceId);
        if (isSourceLoaded && match) {
          setLoadedSourceIds((prev) => {
            const m = prev.includes(sourceId);
            return m ? prev : [...prev, sourceId];
          });
        }
      }),
    [sourceIds]
  );

  useEffect(() => {
    const onLoad = () => {
      onSourceLoaded();
      setSourceLoaded(true);
    };

    if (!hasSource) {
      onLoad();
    }

    if (loadedSourceIds.length === sourceIds.length) {
      onLoad();
    }
  }, [hasSource, loadedSourceIds.length, onSourceLoaded, sourceIds.length]);

  useEffect(() => {
    if (!hasSource) return;

    if (map) {
      map.on('sourcedata', onSourceData);
    }

    return () => {
      if (map) {
        map.off('sourcedata', onSourceData);
      }
    };
  }, [hasSource, map, onSourceData]);

  return useMemo(
    () => ({ loading: !sourceLoaded || loadingData }),
    [sourceLoaded, loadingData]
  );
};

export const useMapLayers = ({
  children,
  circlesId,
  polygonsId,
}: {
  children: ReactNode;
  circlesId: string;
  polygonsId: string;
}) => {
  const [layers, setLayers] = useState<MapLayer[]>([]);

  useEffect(() => {
    const layers: MapLayer[] = [];

    Children.forEach(children as any, (child) => {
      const childName = child?.type?.name;

      if (childName) {
        const childGeo = childName === MapGeo.name;
        if (childGeo) {
          const circles = child?.props?.circles;
          const polygons = child?.props?.polygons;

          if (circles && circles.length) {
            layers.push({
              id: circlesId,
              isClickable: true,
            });
          }
          if (polygons && polygons.length) {
            layers.push({
              id: polygonsId,
              isClickable: false,
            });
          }
        }
      }

      setLayers(layers);
    });
  }, [children, circlesId, polygonsId]);

  const interactiveLayerIds = useMemo(
    () => getActiveLayerIds(layers),
    [layers]
  );
  const sourceIds = useMemo(() => layers.map(({ id }) => id), [layers]);

  return useMemo(
    () => ({ sourceIds, interactiveLayerIds }),
    [interactiveLayerIds, sourceIds]
  );
};
