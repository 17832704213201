import { FC, PropsWithChildren, useContext, useRef, useEffect } from 'react';
import { noop } from 'lodash';
import {
  IRefreshTokenDto,
  IJwtTokenResponseDto,
} from '../../../../__generated__/data-contracts';
import { RequestParams } from '../../../../__generated__/http-client';
import { useMutation } from '../../../../hooks/useMutation/useMutation.hook';
import { HttpProviderContext } from '../../../../contexts/HttpProvider.context';
import { useAuthToken } from '../../../../hooks/useAuthToken/useAuthToken.hook';
import { useAuth } from '../../../../hooks/useAuth/useAuth.hook';
import { AuthEvent } from '../../../../constants/authEvent.const';

type SessionManagerProps = PropsWithChildren<{
  logoutUrl: string;
}>;

export const SessionManager: FC<SessionManagerProps> = ({
  children,
  logoutUrl,
}) => {
  const [{ refreshToken, refreshTokenInMs, inactivityTimeout }, setToken] =
    useAuthToken();
  const [user] = useAuth();
  const sessionTimeout = useRef(setTimeout(noop, 0));
  const { httpClient } = useContext(HttpProviderContext);

  const [, refresh] = useMutation<
    [IRefreshTokenDto, RequestParams],
    IJwtTokenResponseDto
  >(({ Auth }) => Auth.refreshTokenCreate);

  useEffect(() => {
    const interceptor = httpClient.instance.interceptors.request.use(
      (config) => {
        if (config.headers.Intercept !== false) {
          clearTimeout(sessionTimeout.current);
          sessionTimeout.current = setTimeout(() => {
            if (inactivityTimeout && user) {
              window.open(
                `${logoutUrl}?reason=${AuthEvent.sessionTimeout}`,
                '_self'
              );
            }
          }, inactivityTimeout);
        }
        return config;
      }
    );

    return () => {
      clearTimeout(sessionTimeout.current);
      httpClient.instance.interceptors.request.eject(interceptor);
    };
  }, [inactivityTimeout, httpClient, logoutUrl, user]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (user?.id && refreshToken) {
        refresh(
          [
            { refreshToken },
            {
              headers: {
                Intercept: false,
              },
            },
          ],
          {
            onSuccess: ({
              data: { token: newToken, refreshToken: newRefreshToken },
            }) => {
              setToken({ token: newToken, refreshToken: newRefreshToken });
            },
          }
        );
      }
    }, refreshTokenInMs);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, refreshToken]);

  return <>{children}</>;
};
