import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import {
  Root,
  Logo,
  Title,
  Subtitle,
  Container,
  Content,
  Actions,
} from './Prompt.style';
import LOGO from './assets/logo.png';

type PromptProps = PropsWithChildren<{
  title?: ReactNode;
  subtitle?: ReactNode;
}>;

interface PromptComponent {
  (props: PromptProps): ReactElement<any, any> | null;
  Content: typeof Content;
  Actions: typeof Actions;
}

export const Prompt: PromptComponent = ({ children, title, subtitle }) => {
  return (
    <Root>
      <Logo src={LOGO} alt="Spintel" />
      <Container>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {children}
      </Container>
    </Root>
  );
};

Prompt.Actions = Actions;
Prompt.Content = Content;
