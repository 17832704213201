import { FC, PropsWithChildren } from 'react';
import { Root } from './TabCardContent.style';

type CardFooterProps = PropsWithChildren<{
  className?: string;
}>;

export const CardContent: FC<CardFooterProps> = ({ children, className }) => {
  return <Root className={className}>{children}</Root>;
};
