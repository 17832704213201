import { merge, set, get } from 'lodash';
import {
  TableGlobalColumnsExtendedType,
  TableColumnSelection,
  TableColumnSelectOnChange,
  TableColumnSelectionTree,
} from '../Table.types';

export const getColumnSelectionDataIndexTree = <T extends object>(
  columns: TableGlobalColumnsExtendedType<T>,
  selection: TableColumnSelection['0']
): TableColumnSelectionTree => {
  return columns.reduce((res, props) => {
    switch (props.type) {
      case 'group':
        return merge(
          {},
          res,
          getColumnSelectionDataIndexTree(props.children, selection)
        );
      case 'column':
        return set(res, props.dataIndex, selection.includes(props.id));
      default:
        return res;
    }
  }, {} as TableColumnSelectionTree);
};

export const getSelectionOnChangeHandlers = <T extends object>(
  columns: TableGlobalColumnsExtendedType<T>,
  selection: TableColumnSelection['0']
): TableColumnSelectOnChange[] => {
  return columns.reduce((res, props) => {
    switch (props.type) {
      case 'group':
        return [
          ...res,
          ...getSelectionOnChangeHandlers(props.children, selection),
        ];
      case 'column':
        return props?.select?.onChange && selection.includes(props.id)
          ? [...res, props.select.onChange]
          : res;
      default:
        return res;
    }
  }, []);
};

export const getSelectionFromDataIndexTree = <T extends object>(
  columns: TableGlobalColumnsExtendedType<T>,
  tree: TableColumnSelectionTree,
  parents: string[] = []
): TableColumnSelection => {
  return columns.reduce((res, props) => {
    switch (props.type) {
      case 'group':
        return props.showHeader !== false && props.title
          ? [
              ...res,
              ...getSelectionFromDataIndexTree(props.children, tree, [
                ...parents,
                props.id,
              ]),
            ]
          : [
              ...res,
              ...getSelectionFromDataIndexTree(props.children, tree, parents),
            ];
      case 'column':
        return (() => {
          const selected = get(tree, props.dataIndex, false);
          return selected ? [...res, [...parents, props.id]] : res;
        })();
      default:
        return res;
    }
  }, [] as TableColumnSelection);
};
