import { FC, PropsWithChildren, useState, useCallback } from 'react';
import { get, merge, omit } from 'lodash';
import { TableColumnsSelectDropdownContext } from './TableColumnsSelectDropdown.context';
import { TableColumnsSelectDropdownGroups } from './TableColumnsSelectDropdown.types';

type TableColumnsSelectDropdownProps = PropsWithChildren<unknown>;

export const TableColumnsSelectDropdown: FC<
  TableColumnsSelectDropdownProps
> = ({ children }) => {
  const [groups, setGroups] = useState<TableColumnsSelectDropdownGroups>({});

  const setGroup = useCallback(
    (parent: string, group: string, area: string) => {
      setGroups((prev) => {
        const areas = get(prev, [parent, group], []);
        const update = [...areas, area];
        return merge({}, prev, {
          [parent]: {
            [group]: update,
          },
        });
      });
    },
    []
  );

  const resetGroup = useCallback((parent: string) => {
    setGroups((prev) => omit(prev, parent));
  }, []);

  return (
    <TableColumnsSelectDropdownContext.Provider
      value={{ groups, setGroup, resetGroup }}
    >
      {children}
    </TableColumnsSelectDropdownContext.Provider>
  );
};
