import { FC } from 'react';
import { useTranslation } from '@spintel/localization';
import { Form, Prompt } from '@spintel/uikit';
import { DTO, useMutation } from '@spintel/network/app';
import { useUser } from '@spintel/network/auth';
import { usePanel } from '../../../../hooks/usePanel/usePanel.hook';

export const PanelPrompt: FC = () => {
  const { t } = useTranslation();
  const [{ options }, refetchPanels] = usePanel();
  const [
    {
      user: { selectedTeamId },
    },
    refetchUsers,
  ] = useUser();

  const [{ loading }, update] = useMutation<
    [DTO.IUpdateUserPanelRequestDto],
    void
  >(({ User }) => User.panelUpdate);

  const handleOnSubmit = ({ panelId }: { panelId: number }) => {
    update([{ panelId, teamId: selectedTeamId }], {
      onSuccess: () => {
        refetchPanels();
        refetchUsers();
      },
    });
  };

  return (
    <Prompt
      title={t('empty.panelRemoved.title')}
      subtitle={t('empty.panelRemoved.subtitle')}
    >
      <Form onSubmit={handleOnSubmit}>
        <Prompt.Content>
          <Form.Select
            name="panelId"
            options={options}
            label={t('forms.panel')}
            validation={{ required: true }}
          />
        </Prompt.Content>
        <Prompt.Actions>
          <Form.Submit size="lg" variant="primary" loading={loading}>
            {t('actions.continue')}
          </Form.Submit>
        </Prompt.Actions>
      </Form>
    </Prompt>
  );
};
