/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IEmailVerificationDto,
  IEmailVerificationResponseDto,
  IErrorResponse,
  IForgotPasswordDto,
  IJwtTokenResponseDto,
  ILoginUserDto,
  ILoginUserResponseDto,
  IMfaResponseDto,
  IMfaSetupResponseDto,
  IRefreshTokenDto,
  IResetPasswordDto,
  ISignUpUserRequestDto,
  ISignUpUserResponseDto,
  IUpdatePasswordDto,
  IValidateTokenDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Auth<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Auth
   * @name EmailVerificationCreate
   * @summary Email verification
   * @request POST:/auth/email-verification
   * @secure
   */
  emailVerificationCreate = (
    data: IEmailVerificationDto,
    params: RequestParams = {}
  ) =>
    this.http.request<IEmailVerificationResponseDto, IErrorResponse>({
      path: `/auth/email-verification`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name SignupCreate
   * @summary SignUp user
   * @request POST:/auth/signup
   * @secure
   */
  signupCreate = (data: ISignUpUserRequestDto, params: RequestParams = {}) =>
    this.http.request<ISignUpUserResponseDto, IErrorResponse>({
      path: `/auth/signup`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name LoginCreate
   * @summary LogIn user
   * @request POST:/auth/login
   * @secure
   */
  loginCreate = (data: ILoginUserDto, params: RequestParams = {}) =>
    this.http.request<ILoginUserResponseDto, IErrorResponse>({
      path: `/auth/login`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name MfaSetupList
   * @summary Get MFA setup data
   * @request GET:/auth/mfa-setup
   * @secure
   */
  mfaSetupList = (params: RequestParams = {}) =>
    this.http.request<IMfaSetupResponseDto, IErrorResponse>({
      path: `/auth/mfa-setup`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name MfaValidateCreate
   * @summary Validate MFA TOTP code
   * @request POST:/auth/mfa-validate
   * @secure
   */
  mfaValidateCreate = (data: IMfaResponseDto, params: RequestParams = {}) =>
    this.http.request<IJwtTokenResponseDto, IErrorResponse>({
      path: `/auth/mfa-validate`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name RefreshTokenCreate
   * @summary Refresh Token
   * @request POST:/auth/refresh-token
   * @secure
   */
  refreshTokenCreate = (data: IRefreshTokenDto, params: RequestParams = {}) =>
    this.http.request<IJwtTokenResponseDto, IErrorResponse>({
      path: `/auth/refresh-token`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name ValidateResetTokenCreate
   * @summary Validate token
   * @request POST:/auth/validate-reset-token
   * @secure
   */
  validateResetTokenCreate = (
    data: IValidateTokenDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/auth/validate-reset-token`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name ForgotPasswordCreate
   * @summary Forgot password
   * @request POST:/auth/forgot-password
   * @secure
   */
  forgotPasswordCreate = (
    data: IForgotPasswordDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/auth/forgot-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name ResetPasswordCreate
   * @summary Reset password
   * @request POST:/auth/reset-password
   * @secure
   */
  resetPasswordCreate = (data: IResetPasswordDto, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/auth/reset-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name UpdatePasswordCreate
   * @summary Update password
   * @request POST:/auth/update-password
   * @secure
   */
  updatePasswordCreate = (
    data: IUpdatePasswordDto,
    params: RequestParams = {}
  ) =>
    this.http.request<IJwtTokenResponseDto, IErrorResponse>({
      path: `/auth/update-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name LogoutCreate
   * @summary LogOut user
   * @request POST:/auth/logout
   * @secure
   */
  logoutCreate = (params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/auth/logout`,
      method: 'POST',
      secure: true,
      ...params,
    });
}
