import { FC, memo } from 'react';
import { Source, Layer } from 'react-map-gl';
import type { Polygon } from 'geojson';
import { useContext } from 'use-context-selector';
import { MapPolygonsProps } from '../Map.types';
import { THEME } from '../../../constants/theme.const';
import { getFeaturesCollection, getLayerId } from '../Map.utils';
import { MapContext } from '../Map.context';
import { DEFAULT_MAP_TRANSITION } from '../Map.const';

const { delay, duration } = DEFAULT_MAP_TRANSITION;

export const MapPolygons: FC<MapPolygonsProps> = memo(
  ({ data, tolerance = 0, styles: { opacity = 1, color = 'pearlGrey' } }) => {
    const { polygonsId } = useContext(MapContext);
    const collection = getFeaturesCollection<Polygon>({
      data,
      defaultColor: color,
    });

    return (
      <Source
        id={polygonsId}
        tolerance={tolerance}
        type="geojson"
        data={collection}
      >
        <Layer
          id={getLayerId(polygonsId)}
          type="fill"
          paint={{
            'fill-color': ['get', 'color'],
            'fill-opacity': opacity,
            'fill-opacity-transition': {
              delay,
              duration,
            },
            'fill-outline-color': THEME.palette.transparent,
          }}
        />
      </Source>
    );
  }
);
