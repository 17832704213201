import { Dispatch, SetStateAction, createContext } from 'react';

type ModalsState<S> = [S, Dispatch<SetStateAction<S>>];

export type Modals = Record<
  string,
  {
    isOpen?: boolean;
    params: unknown;
  }
>;

export const ModalsContext = createContext<ModalsState<Modals>>(null);
