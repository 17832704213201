import { Typography } from '@spintel/uikit';
import styled from 'styled-components';

const { Heading6, Body3 } = Typography;

export const Title = styled(Heading6)`
  margin-bottom: ${({ theme: { spacing } }) => spacing(4)};
`;

export const Id = styled(Body3)`
  color: ${({ theme }) => theme.palette.pearlGrey};
`;

export const Content = styled('div')`
  display: flex;
  gap: ${({ theme: { spacing } }) => spacing(2)};
  justify-content: center;
  align-items: center;
`;
