import {
  Key,
  useState,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { uniqBy, get, sortBy, isEqual } from 'lodash';
import AntdForm from 'antd/lib/form';
import { UiKitLocalizationContext } from '../../../../contexts/UiKitLocalization.context';
import { Form } from '../../../Form/Form';
import { Button } from '../../../Button/Button';
import { Search } from '../../../Search/Search';
import { Empty } from '../../../Empty/Empty';
import { TableFilterComponent } from '../TableFilters.types';
import {
  Root,
  Header,
  Content,
  Options,
  Group,
  Toggle,
  Footer,
  NoData,
} from '../TableFilters.style';
import { TableFilterSearchState } from './TableFilterSearch.types';

const { useForm, useWatch } = AntdForm;

export const TableFilterSearch: TableFilterComponent = ({
  data,
  filter: { placeholder } = {},
  confirm,
  dataIndex,
  selectedKeys,
  setSelectedKeys,
  visible,
}) => {
  const root = useRef<HTMLDivElement>();
  const content = useRef<HTMLDivElement>();
  const [form] = useForm<{ selectedKeys: Key[] }>();
  const selected = useWatch('selectedKeys', form) || [];
  const [all, toggle] = useState(false);
  const { table } = useContext(UiKitLocalizationContext);
  const [{ search, width, height }, setState] =
    useState<TableFilterSearchState>({
      search: '',
    });

  const uniq = uniqBy(data, (item) => get(item, dataIndex)).filter((item) =>
    get(item, dataIndex)
  );

  const options = uniq.map((item) => ({
    value: get(item, dataIndex),
    label: get(item, dataIndex),
  }));

  const filtered = sortBy(options, ({ label }) =>
    label.toLocaleLowerCase()
  ).filter(({ label }) =>
    search
      ? label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      : true
  );

  const indeterminate = (() => {
    if (selected.length) {
      return selected.length !== options.length;
    }
    return false;
  })();

  const hasOptions = !!filtered.length;

  const handleOnSubmit = ({ selectedKeys }: { selectedKeys: Key[] }) => {
    setSelectedKeys(selectedKeys);
    confirm({ closeDropdown: true });
  };

  const handleOnToggle = (v: boolean) => {
    form.setFields([
      {
        name: 'selectedKeys',
        value: v && !indeterminate ? options.map(({ value }) => value) : [],
      },
    ]);
    toggle(v && !indeterminate);
  };

  useEffect(() => {
    if (visible) {
      setState((prev) => ({ ...prev, search: '' }));
      form.setFieldsValue({ selectedKeys });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    toggle(!!selected.length);
  }, [selected.length]);

  useLayoutEffect(() => {
    setState((prev) => ({
      ...prev,
      width: root.current.offsetWidth,
      height: content.current.offsetHeight,
    }));
  }, []);

  return (
    <Root ref={root} width={width}>
      <Form
        form={form}
        initialValues={{ selectedKeys }}
        onSubmit={handleOnSubmit}
      >
        <Header>
          <Search
            value={search}
            placeholder={placeholder}
            onChange={(search) => setState((prev) => ({ ...prev, search }))}
          />
        </Header>
        <Content ref={content} empty={!hasOptions} height={height}>
          {hasOptions && (
            <Toggle
              value={all}
              indeterminate={indeterminate}
              onChange={handleOnToggle}
            >
              {table.dropdownFilterToggleAll}
            </Toggle>
          )}
          {!hasOptions && (
            <NoData>
              <Empty title={table.dropdownFilterEmpty} size="sm" />
            </NoData>
          )}
          {hasOptions && (
            <Options>
              <Group preserve name="selectedKeys" options={filtered} />
            </Options>
          )}
        </Content>
        <Footer>
          <Button onClick={() => confirm({ closeDropdown: true })}>
            {table.dropdownFilterCancel}
          </Button>
          <Form.Submit
            variant="primary"
            disabled={isEqual(selectedKeys, selected)}
          >
            {table.dropdownFilterConfirm}
          </Form.Submit>
        </Footer>
      </Form>
    </Root>
  );
};
