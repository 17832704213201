import styled from 'styled-components';
import { RootNumber } from '../../Input/Input.style';

export const Number = styled(RootNumber)`
  .ant-input-number-handler-wrap {
    opacity: 1;
    border-radius: 0;
    width: ${({ theme }) => theme.spacing(8)};
    right: ${({ theme }) => theme.spacing(-4)};
    background: none;

    &:hover {
      .ant-input-number-handler {
        height: 50% !important;
      }
    }

    .ant-input-number-handler {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }

      &:focus,
      &:active {
        background: none;
      }

      &.ant-input-number-handler-up-disabled,
      &.ant-input-number-handler-down-disabled {
        opacity: 0.4;
      }

      & > span {
        left: auto;
        right: auto;
        color: ${({ theme }) => theme.palette.white};
      }
    }
  }
`;
