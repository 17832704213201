import styled, { createGlobalStyle } from 'styled-components';
import { Typography } from '../../Typography/Typography';

const { Body2 } = Typography;

export const Global = createGlobalStyle`
    .ant-notification {
        &.ant-notification-bottomLeft {
            margin-left: ${({ theme: { spacing } }) => spacing(3)};
        }
        .ant-notification-notice {
            display: grid;
            grid-template-columns: 1fr min-content;
            grid-template-rows: auto;
            border-radius: 0;
            padding: 0;
            background-color: ${({ theme }) => theme.palette.metropolisGrey};
            width: ${({ theme: { spacing } }) => spacing(92.5)};
            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: ${({ theme }) => theme.palette.londonGrey}50;
            }
            .ant-notification-notice-content,
            .ant-notification-notice-close {
              position: relative;
              z-index: 1;
            }
            .ant-notification-notice-content {
                border-right: 1px solid ${({ theme }) =>
                  theme.palette.londonGrey};
                  padding-left: ${({ theme: { spacing } }) => spacing(6)};
                  padding-right: ${({ theme: { spacing } }) => spacing(6)};
                  padding-top: ${({ theme: { spacing } }) => spacing(3.5)};
                  padding-bottom: ${({ theme: { spacing } }) => spacing(4.5)};
                  .ant-notification-notice-message {
                    margin: 0;
                    padding-right: 0;
                  }
            }
            .ant-notification-notice-close {
                display: inherit;
                position: static;
                text-decoration: none;
                color: ${({ theme }) => theme.palette.white};
                margin: 0 ${({ theme: { spacing } }) => spacing(3)};
                align-items: center;
            }
        }
    }
`;

export const Content = styled('div')``;

export const Message = styled(Body2)`
  max-width: ${({ theme }) => theme.spacing(70)};
`;

export const Actions = styled('div')`
  display: flex;
  gap: ${({ theme: { spacing } }) => spacing(2)};
  margin-top: ${({ theme: { spacing } }) => spacing(4)};
`;
