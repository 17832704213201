import { Icon, Section } from '@spintel/uikit';
import { FC, useContext, useMemo } from 'react';
import { useTranslation } from '@spintel/localization';
import { StationDetailsContext } from '../StationDetails.context';
import { getStationContacts, sortSocials } from './StationContacts.utils';
import { StationContactsItem } from './StationContactsItem/StationContactsItem';
import { Root, ContactsColumn, EmptyMessage } from './StationContacts.styles';
interface StationContactsProps {
  border?: boolean;
}
export const StationContacts: FC<StationContactsProps> = ({
  border = false,
}) => {
  const { data } = useContext(StationDetailsContext);

  const { t } = useTranslation();

  const { address, contacts, socials } = useMemo(
    () => ({
      address: data?.officeAddress || '',
      contacts: getStationContacts(data?.contacts || []),
      socials: getStationContacts((data?.socials || []).sort(sortSocials)),
    }),
    [data]
  );

  const isEmpty = !contacts.length && !socials.length && !address.length;

  return (
    <Section stretch>
      <Section.Content>
        <Root border={border}>
          {isEmpty && <EmptyMessage>{t('empty.noContacts')}</EmptyMessage>}

          {contacts.length > 0 && (
            <ContactsColumn>
              {contacts.map((contact, i) => (
                <StationContactsItem
                  key={`contacts-${contact.label}-${i}`}
                  {...contact}
                />
              ))}
            </ContactsColumn>
          )}

          {socials.length > 0 && (
            <ContactsColumn>
              {socials.map((contact, i) => (
                <StationContactsItem
                  key={`socials-${contact.label}-${i}`}
                  {...contact}
                />
              ))}
            </ContactsColumn>
          )}

          {address && (
            <ContactsColumn>
              <StationContactsItem
                label="forms.officeAddress"
                value={address}
                icon={<Icon name="address" size="sm" color="stardustGrey" />}
                isBlock
              />
            </ContactsColumn>
          )}
        </Root>
      </Section.Content>
    </Section>
  );
};
