import { ReactElement } from 'react';
import AntdTabs, { TabsProps as AntdTabProps } from 'antd/lib/tabs';
import { TabsBarTabPane } from './TabsBarTabPane/TabsBarTabPane';
import { Root } from './Tabs.style';
import { TABS_SIZE_MAP } from './Tabs.conts';
import { TabSize } from './Tabs.types';

interface TabsProps extends Omit<AntdTabProps, 'size'> {
  size?: TabSize;
  border?: boolean;
}

interface TabsComponent {
  (props: TabsProps): ReactElement<any, any> | null;
  TabPane: typeof AntdTabs.TabPane;
  BarTabPane: typeof TabsBarTabPane;
}

export const Tabs: TabsComponent = ({
  children,
  size = 'md',
  border = true,
  ...props
}) => {
  return (
    <Root size={TABS_SIZE_MAP[size]} border={border} {...props}>
      {children}
    </Root>
  );
};

Tabs.TabPane = AntdTabs.TabPane;
Tabs.BarTabPane = TabsBarTabPane;
