import { createContext } from 'use-context-selector';

interface MapContextParams {
  circlesId: string;
  polygonsId: string;
  loading: boolean;
}

export const MapContext = createContext<MapContextParams>({
  circlesId: '',
  polygonsId: '',
  loading: true,
});
