import styled, { css } from 'styled-components';
import AntdDescription from 'antd/lib/descriptions';

const RootEllipsisMixin = css`
  .ant-descriptions-item-content {
    overflow: hidden;
  }
`;

export const Root = styled(AntdDescription)<{ ellipsis: boolean }>`
  .ant-descriptions-row {
    > th {
      padding-bottom: 0;
    }
  }
  ${({ ellipsis }) => ellipsis && RootEllipsisMixin}
`;
