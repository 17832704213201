import { FC, PropsWithChildren } from 'react';
import { Tooltip as UIKitTooltip } from '@spintel/uikit';
import { ButtonContainer } from './Tooltip.styles';

type TooltipProps = PropsWithChildren<{
  children: React.ReactNode;
  disabled?: boolean;
  content?: Parameters<typeof UIKitTooltip>[0]['content'];
  placement?: Parameters<typeof UIKitTooltip>[0]['placement'];
}>;

export const Tooltip: FC<TooltipProps> = ({
  disabled,
  content,
  placement = 'bottom',
  children,
}) => {
  return (
    <UIKitTooltip content={content} placement={placement}>
      <ButtonContainer disabled={disabled}>{children}</ButtonContainer>
    </UIKitTooltip>
  );
};
