import { FC, useRef, useState, useEffect } from 'react';
import { Alert, AlertRefType } from '../Alert';
import { Root } from './AlertSnackbar.style';
import { CloseTimer } from './AlertSnackbar.types';
import {
  getNewAlertsWithTimeout,
  getActualTimers,
} from './AlertSnackbar.utils';
import SnackbarObservable, { AlertItem } from './AlertSnackbar.services';

export interface SnackbarProps {
  className?: string;
}

export const Snackbar: FC<SnackbarProps> = ({ className }) => {
  const [alerts, setAlerts] = useState<AlertItem[]>([]);
  const timers = useRef<CloseTimer[]>([]);

  const newAlertsWithTimeout = getNewAlertsWithTimeout(timers.current, alerts);

  useEffect(() => {
    const observer = (alerts: AlertItem[]) => setAlerts(alerts);

    SnackbarObservable.subscribe(observer);

    return () => {
      SnackbarObservable.unsubscribe(observer);
      SnackbarObservable.reset();

      // eslint-disable-next-line react-hooks/exhaustive-deps
      for (let timer of timers.current) {
        clearTimeout(timer.timeoutId);
      }

      timers.current = [];
    };
  }, []);

  useEffect(() => {
    timers.current = getActualTimers(timers.current, alerts);
  }, [alerts]);

  const addTimer = (timer: CloseTimer) => {
    timers.current.push(timer);
  };

  return (
    <Root className={className}>
      {alerts.map((alert) => (
        <SnackbarItem
          key={alert.id}
          alert={alert}
          hasNewTimer={newAlertsWithTimeout.includes(alert)}
          addTimer={addTimer}
        />
      ))}
    </Root>
  );
};

interface SnackbarItemProps {
  alert: AlertItem;
  hasNewTimer: boolean;
  addTimer: (timer: CloseTimer) => void;
}

const SnackbarItem: FC<SnackbarItemProps> = ({
  alert,
  addTimer,
  hasNewTimer,
}) => {
  const alertRef = useRef<AlertRefType>();

  if (hasNewTimer) {
    const timeoutId = setTimeout(() => {
      if (alertRef.current) {
        alertRef.current.close();
      }
    }, alert.timeout);

    addTimer({
      alertId: alert.id,
      timeoutId,
    });
  }

  return (
    <Alert
      {...alert}
      ref={alertRef}
      afterClose={() => SnackbarObservable.remove(alert.id)}
    />
  );
};
