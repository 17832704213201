import { useTranslation } from '@spintel/localization';
import { useState } from 'react';
import { StationDetailsSongComparisonType } from './useSongComparisonType.types';

export const useSongComparisonOptions = (
  initial: StationDetailsSongComparisonType = StationDetailsSongComparisonType.STANDARD
) => {
  const { t } = useTranslation();
  const [type, setType] = useState<StationDetailsSongComparisonType>(initial);

  const typeOptions = [
    {
      value: StationDetailsSongComparisonType.STANDARD,
      label: t('options.standard'),
    },
    {
      value: StationDetailsSongComparisonType.RELATIVE,
      label: t('options.relative'),
    },
  ];

  return [{ type, typeOptions }, { setType }];
};
