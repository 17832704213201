import styled from 'styled-components';
import { Form } from '../../Form/Form';
import { Checkbox } from '../../Checkbox/Checkbox';

export const Root = styled('div')<{ width?: number }>(
  ({ width, theme: { spacing } }) => ({
    width: width || 'min-content',
    maxWidth: spacing(100),
  })
);

export const Header = styled('div')(({ theme: { spacing, palette } }) => ({
  padding: spacing(4),
  borderBottom: `1px solid ${palette.wolfGrey}`,
}));

export const Content = styled('div')<{ empty: boolean; height?: number }>(
  ({ empty, height, theme: { spacing } }) => ({
    padding: spacing(empty ? 7 : 4),
    height: height ? `${height}px` : '',
  })
);

export const Options = styled('div')`
  overflow: auto;
  max-height: ${({ theme: { spacing } }) => spacing(51)};
  padding-left: ${({ theme: { spacing } }) => spacing(0.5)};
`;

export const Toggle = styled(Checkbox)(({ theme: { spacing } }) => ({
  marginBottom: spacing(2),
  paddingLeft: spacing(0.5),
}));

export const Group = styled(Form.CheckboxGroup)`
  margin-bottom: 0;
`;

export const Footer = styled('div')(({ theme: { spacing, palette } }) => ({
  padding: spacing(4),
  display: 'flex',
  justifyContent: 'flex-end',
  gap: spacing(2),
  borderTop: `1px solid ${palette.wolfGrey}`,
}));

export const NoData = styled('div')`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
