export enum RoutePath {
  CHART_MONITOR = '/chart-monitor',
  MANAGEMENT = '/management',
  MANAGEMENT_PANELS = '/management/panels',
  MANAGEMENT_TEAMS = '/management/teams',
  MY_SONGS = '/my-songs',
  OWNERS = '/owners',
  PANEL_CHARTS = '/panel-charts',
  PANEL_CHARTS_MOST_ADDED_CURRENTS = '/panel-charts/most-added-currents',
  PANELS = '/panels',
  ROOT = '/',
  ROTATION_MAP = '/rotation-map',
  SONG = '/song',
  SONG_ADD_OPPORTUNITIES = '/song/add-opportunities',
  SONG_COMPETITION = '/song/competition',
  SONG_OWNERSHIP = '/song/ownership',
  SONG_PLAY_INCREASE = '/song/play-increase',
  SONG_SONG_COMPARISON = '/song/song-comparison',
  SONG_STATION_AIRPLAY = '/song/station-airplay',
  STATION = '/stations/:stationId',
  STATION_AVERAGE_ADDS = '/station-average-adds',
  STATION_OVERVIEW = '/stations/:stationId/overview',
  STATION_DETAILS = '/stations/:stationId/details',
  STATION_MUSIC_STACK = '/stations/:stationId/music-stack',
  STATION_MY_SONGS = '/stations/:stationId/my-songs',
  STATION_PLAYLIST = '/stations/:stationId/playlist',
  STATION_POSSIBLE_SPACE = '/stations/:stationId/possible-space',
  STATIONS = '/stations',
}
