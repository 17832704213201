import styled, { css } from 'styled-components';
import Radio from 'antd/lib/radio';
import { SegmentedSize } from '../Segmented.types';
import { SEGMENTED_HEIGHT_MAP } from './SegmentedDefault.const';

interface OptionProps {
  hasIcon: boolean;
  size: SegmentedSize;
}

const SmallOptionMixin = css<OptionProps>`
  &.ant-radio-button-wrapper {
    padding-left: ${({ hasIcon, theme: { spacing } }) =>
      hasIcon ? '7px' : spacing(2.25)};
    padding-right: ${({ hasIcon, theme: { spacing } }) =>
      hasIcon ? '7px' : spacing(2.25)};
  }
`;

export const Root = styled(Radio.Group)``;

export const Option = styled(Radio.Button)<OptionProps>`
  background: ${({ theme }) => theme.palette.transparent};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme: { spacing } }) => spacing(3)};
  line-height: ${({ theme: { spacing } }) => spacing(4)};
  vertical-align: middle;
  padding-left: ${({ hasIcon, theme: { spacing } }) =>
    hasIcon ? '9px' : spacing(3.5)};
  padding-right: ${({ hasIcon, theme: { spacing } }) =>
    hasIcon ? '9px' : spacing(3.5)};
  ${({ size }) => size === 'sm' && SmallOptionMixin}
  &:focus-within {
    box-shadow: none !important;
  }
  &:first-child {
    padding-left: ${({ hasIcon, size, theme: { spacing } }) =>
      hasIcon ? '11px' : spacing(size === 'sm' ? 3 : 4)};
    border-top-left-radius: ${({ size }) => SEGMENTED_HEIGHT_MAP[size] / 2}px;
    border-bottom-left-radius: ${({ size }) =>
      SEGMENTED_HEIGHT_MAP[size] / 2}px;
  }
  &:last-child {
    padding-right: ${({ hasIcon, size, theme: { spacing } }) =>
      hasIcon ? '11px' : spacing(size === 'sm' ? 3 : 4)};
    border-top-right-radius: ${({ size }) => SEGMENTED_HEIGHT_MAP[size] / 2}px;
    border-bottom-right-radius: ${({ size }) =>
      SEGMENTED_HEIGHT_MAP[size] / 2}px;
  }
  &.ant-radio-button-wrapper-checked {
    &:not([class*='ant-radio-button-wrapper-disabled']) {
      &.ant-radio-button-wrapper {
        &:first-child {
          border-right-color: ${({ theme }) => theme.palette.white};
        }
      }
    }
  }
  &.ant-radio-button-wrapper-disabled {
    opacity: 0.4;
    color: ${({ theme }) => theme.palette.white};
    &:not(:first-child) {
      &:before {
        opacity: 0.4;
        background-color: ${({ theme }) => theme.palette.wolfGrey};
      }
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${({ theme }) => theme.palette.white}40;
    }
  }
  &:not(.ant-radio-button-wrapper-disabled) {
    color: ${({ theme }) => theme.palette.white};
    &.ant-radio-button-wrapper-checked {
      color: ${({ theme }) => theme.palette.metropolisGrey};
      &:hover {
        &:before {
          background: ${({ theme }) => theme.palette.white};
        }
      }
      &:active {
        border-color: ${({ theme }) => theme.palette.white};
      }
    }
    &:active {
      .ant-radio-button {
        + span {
          opacity: 0.4;
        }
      }
    }
    &:hover {
      border-color: ${({ theme }) => theme.palette.white};
      &:not(:first-child) {
        &:before {
          background: ${({ theme }) => theme.palette.white};
        }
      }
      + .ant-radio-button-wrapper {
        &:before {
          background: ${({ theme }) => theme.palette.white};
        }
      }
    }
  }
  .ant-radio-button {
    + span {
      display: flex;
      font-weight: ${({ hasIcon }) => (hasIcon ? 400 : 600)};
    }
  }
`;
