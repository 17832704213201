import styled from 'styled-components';
import { RootInput } from '../Input/Input.style';
import { rotateAnimation } from '../../styles/Rotate.style';
import { Icon } from '../Icon/Icon';
import { SearchSize } from './Search.type';

export const Root = styled(RootInput)`
  &.ant-input-affix-wrapper {
    border-radius: ${({ theme: { spacing } }) => spacing(4)};
    &.ant-input-affix-wrapper-lg {
      border-radius: ${({ theme: { spacing } }) => spacing(5)};
      .ant-input-prefix {
        margin-left: -${({ theme: { spacing } }) => spacing(1.5)};
      }
      .ant-input-suffix {
        .ant-input-clear-icon {
          width: ${({ theme: { spacing } }) => spacing(6)};
          height: ${({ theme: { spacing } }) => spacing(6)};
        }
      }
    }
    .ant-input-prefix {
      margin-left: -${({ theme: { spacing } }) => spacing(0.5)};
      margin-right: ${({ theme: { spacing } }) => spacing(2)};
    }
    .ant-input-suffix {
      .ant-input-clear-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${({ theme: { spacing } }) => spacing(4)};
        height: ${({ theme: { spacing } }) => spacing(4)};
      }
    }
  }
`;

export const ClearIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.pearlGrey};
  &:hover {
    color: ${({ theme }) => theme.palette.white};
  }
`;

export const Spinner = styled('span')<{ size: SearchSize }>`
  width: ${({ size, theme: { spacing } }) =>
    size === 'lg' ? spacing(6) : spacing(4)};
  height: ${({ size, theme: { spacing } }) =>
    size === 'lg' ? spacing(6) : spacing(4)};
  border: 3px solid ${({ theme }) => theme.palette.wolfGrey};
  border-bottom-color: ${({ theme }) => theme.palette.white};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotateAnimation} 1s linear infinite;
`;
