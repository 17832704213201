import styled from 'styled-components';
import AntdSkeleton from 'antd/lib/skeleton';

export const Root = styled(AntdSkeleton)``;

export const Title = styled(AntdSkeleton)`
  .ant-skeleton-title {
    width: 100%;
    border-radius: 0;
    height: ${({ theme: { spacing } }) => spacing(8)};
    max-width: ${({ theme: { spacing } }) => spacing(100)};
  }
`;

export const Subtitle = styled(AntdSkeleton)`
  .ant-skeleton-title {
    width: 80%;
    border-radius: 0;
    height: ${({ theme: { spacing } }) => spacing(3.5)};
    max-width: ${({ theme: { spacing } }) => spacing(70)};
  }
`;

export const Head = styled(AntdSkeleton)`
  .ant-skeleton-title {
    border-radius: 0;
    height: ${({ theme: { spacing } }) => spacing(12)};
  }
`;
