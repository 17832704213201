import {
  PropsWithChildren,
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import { TooltipContainerRefType } from '../Chart.types';
import { Root } from './TooltipContainer.style';

type TooltipContainerProps = PropsWithChildren<{}>;

export const TooltipContainer = forwardRef<
  TooltipContainerRefType,
  TooltipContainerProps
>(function TooltipContainer(_, ref) {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [tooltip, setTooltip] = useState<{ x: number; y: number }>();

  useImperativeHandle(ref, () => ({
    node: tooltipRef.current,
    setVisible,
    setTooltip,
  }));

  return (
    <Root visible={visible} x={tooltip?.x} y={tooltip?.y} ref={tooltipRef} />
  );
});
