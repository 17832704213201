/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IComparisonTypeRequestDto,
  IDateRangeRequestDto,
  IErrorResponse,
  IGetSongComparisonHistoricalRelativeGraphResponse,
  IGetSongComparisonHistoricalRelativeGridResponse,
  IGetSongComparisonHistoricalStandardGraphResponse,
  IGetSongComparisonHistoricalStandardGridResponse,
  IGetSongComparisonPanelGridResponse,
  IGetSongComparisonStationsGridResponse,
  ISearchSongComparisonModel,
  ISongComparisonHistoricalRelativeRequestDto,
  ISongComparisonHistoricalStandardRequestDto,
  ISongComparisonHistoricalStationPlayComparisonGridResponse,
  ISongComparisonModel,
  ISongComparisonsRequestDto,
  ISongComparisonStatisticsModel,
  IStringPagedRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SongComparison<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SongComparison
   * @name SearchCreate
   * @summary Search for songs comparison
   * @request POST:/song-comparison/{songId}/search
   * @secure
   */
  searchCreate = (
    songId: number,
    data: IStringPagedRequest,
    params: RequestParams = {}
  ) =>
    this.http.request<ISearchSongComparisonModel[], IErrorResponse>({
      path: `/song-comparison/${songId}/search`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name StationDetail
   * @summary Get songs comparison
   * @request GET:/song-comparison/{songId}/station/{stationId}
   * @secure
   */
  stationDetail = (
    songId: number,
    stationId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<ISongComparisonStatisticsModel[], IErrorResponse>({
      path: `/song-comparison/${songId}/station/${stationId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name SongComparisonDetail
   * @summary Get songs comparison list
   * @request GET:/song-comparison/{songId}
   * @secure
   */
  songComparisonDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<ISongComparisonModel[], IErrorResponse>({
      path: `/song-comparison/${songId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name StationsDetail
   * @summary Get station songs comparison
   * @request GET:/song-comparison/{songId}/stations
   * @secure
   */
  stationsDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<IGetSongComparisonStationsGridResponse[], IErrorResponse>(
      {
        path: `/song-comparison/${songId}/stations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }
    );
  /**
   * No description
   *
   * @tags SongComparison
   * @name StationsExportCreate
   * @summary Export songs comparison
   * @request POST:/song-comparison/{songId}/stations-export
   * @secure
   */
  stationsExportCreate = (
    songId: number,
    data: IComparisonTypeRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/song-comparison/${songId}/stations-export`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name HistoricalStandardGraphCreate
   * @summary Get songs comparison history standard graph
   * @request POST:/song-comparison/{songId}/historical-standard-graph
   * @secure
   */
  historicalStandardGraphCreate = (
    songId: number,
    data: IDateRangeRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetSongComparisonHistoricalStandardGraphResponse[],
      IErrorResponse
    >({
      path: `/song-comparison/${songId}/historical-standard-graph`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name HistoricalRelativeStationPlayComparisonCreate
   * @summary Get songs comparison historical relative - All / Exclusive / Not On
   * @request POST:/song-comparison/{songId}/historical-relative-station-play-comparison
   * @secure
   */
  historicalRelativeStationPlayComparisonCreate = (
    songId: number,
    data: ISongComparisonHistoricalRelativeRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ISongComparisonHistoricalStationPlayComparisonGridResponse[],
      IErrorResponse
    >({
      path: `/song-comparison/${songId}/historical-relative-station-play-comparison`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name HistoricalStandardGridDetail
   * @summary Get songs comparison history standard grid by week
   * @request GET:/song-comparison/{songId}/historical-standard-grid/{week}
   * @secure
   */
  historicalStandardGridDetail = (
    songId: number,
    week: string,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetSongComparisonHistoricalStandardGridResponse[],
      IErrorResponse
    >({
      path: `/song-comparison/${songId}/historical-standard-grid/${week}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name HistoricalStandardGridExportDetail
   * @summary Export songs comparison standard history grid by week
   * @request GET:/song-comparison/{songId}/historical-standard-grid/{week}/export
   * @secure
   */
  historicalStandardGridExportDetail = (
    songId: number,
    week: string,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/song-comparison/${songId}/historical-standard-grid/${week}/export`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name HistoricalStandardStationPlayComparisonCreate
   * @summary Get songs comparison historical standard - All / Exclusive / Not On
   * @request POST:/song-comparison/{songId}/historical-standard-station-play-comparison
   * @secure
   */
  historicalStandardStationPlayComparisonCreate = (
    songId: number,
    data: ISongComparisonHistoricalStandardRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<
      ISongComparisonHistoricalStationPlayComparisonGridResponse[],
      IErrorResponse
    >({
      path: `/song-comparison/${songId}/historical-standard-station-play-comparison`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name HistoricalRelativeGraphCreate
   * @summary Get songs comparison history relative graph
   * @request POST:/song-comparison/{songId}/historical-relative-graph
   * @secure
   */
  historicalRelativeGraphCreate = (
    songId: number,
    data: IDateRangeRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetSongComparisonHistoricalRelativeGraphResponse[],
      IErrorResponse
    >({
      path: `/song-comparison/${songId}/historical-relative-graph`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name HistoricalRelativeGridDetail
   * @summary Get songs comparison history relative grid by week
   * @request GET:/song-comparison/{songId}/historical-relative-grid/{week}
   * @secure
   */
  historicalRelativeGridDetail = (
    songId: number,
    week: number,
    params: RequestParams = {}
  ) =>
    this.http.request<
      IGetSongComparisonHistoricalRelativeGridResponse[],
      IErrorResponse
    >({
      path: `/song-comparison/${songId}/historical-relative-grid/${week}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name HistoricalRelativeGridExportDetail
   * @summary Export songs comparison relative history grid by week
   * @request GET:/song-comparison/{songId}/historical-relative-grid/{week}/export
   * @secure
   */
  historicalRelativeGridExportDetail = (
    songId: number,
    week: number,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/song-comparison/${songId}/historical-relative-grid/${week}/export`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name PostSongComparison
   * @summary Add new songs comparison
   * @request POST:/song-comparison/{songId}/add
   * @secure
   */
  postSongComparison = (
    songId: number,
    data: ISongComparisonsRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/song-comparison/${songId}/add`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name RemoveDelete
   * @summary Remove songs from comparison
   * @request DELETE:/song-comparison/{songId}/remove
   * @secure
   */
  removeDelete = (
    songId: number,
    data: ISongComparisonsRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/song-comparison/${songId}/remove`,
      method: 'DELETE',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name PanelDetail
   * @summary Get songs comparison
   * @request GET:/song-comparison/{songId}/panel
   * @secure
   */
  panelDetail = (songId: number, params: RequestParams = {}) =>
    this.http.request<IGetSongComparisonPanelGridResponse[], IErrorResponse>({
      path: `/song-comparison/${songId}/panel`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags SongComparison
   * @name PanelExportCreate
   * @summary Export songs comparison
   * @request POST:/song-comparison/{songId}/panel/export
   * @secure
   */
  panelExportCreate = (songId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/song-comparison/${songId}/panel/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
}
