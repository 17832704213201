import { FC } from 'react';
import { DTO, useMutation } from '@spintel/network/app';
import { useUser } from '@spintel/network/auth';
import { usePanel } from '../../../../hooks/usePanel/usePanel.hook';
import { Root } from './Panels.style';

export const Panels: FC = () => {
  const [{ options }] = usePanel();
  const [
    {
      user: { selectedTeamId, selectedPanelId },
    },
    refetchUser,
  ] = useUser();

  const [{ loading }, update] = useMutation<
    [DTO.IUpdateUserPanelRequestDto],
    void
  >(({ User }) => User.panelUpdate);

  const handleOnChange = (panelId: number) => {
    update([{ panelId, teamId: selectedTeamId }], {
      onSuccess: () => refetchUser(),
    });
  };

  return (
    <Root
      size="lg"
      value={selectedPanelId}
      variant="round"
      loading={loading}
      options={options}
      onChange={(v) => handleOnChange(v as number)}
    />
  );
};
