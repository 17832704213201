import { FC, Key } from 'react';
import { useUpdateEffect } from 'react-use';
import { useTranslation } from '@spintel/localization';
import { AuthEvent, DTO, useUpdatePassword } from '@spintel/network/auth';
import { Redirect } from '@spintel/navigation';
import { AuthRoutePath, MODALS } from '@common/constants';
import {
  Modal,
  Form,
  useModal,
  AlertProps,
  Alert,
  Notification,
} from '@spintel/uikit';
import { ALLOWED_ERRORS } from './ChangePasswordModal.const';

export const ChangePasswordModal: FC = () => {
  const { t } = useTranslation();
  const [{ isOpen }, toggleModal] = useModal(MODALS.CHANGE_PASSWORD);
  const [{ loading, error }, updatePassword, reset] = useUpdatePassword();

  const handleSubmit = (form: DTO.IUpdatePasswordDto) => {
    updatePassword(form, () => {
      Notification.show({
        message: t('notifications.passwordSuccessfullyUpdated'),
      });
      toggleModal(false);
    });
  };

  useUpdateEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  if (error?.errorCode === DTO.IErrorCode.UserIsLocked) {
    Alert.error(
      {
        message: t(`httpErrorCode.${error?.errorCode}`),
        closable: true,
      },
      400
    );

    return (
      <Redirect
        to={{
          path: AuthRoutePath.LOGOUT,
          search: { reason: AuthEvent.userIsLocked },
        }}
      />
    );
  }

  const alert: AlertProps = ALLOWED_ERRORS.includes(error?.errorCode)
    ? {
        message: t(`httpErrorCode.${error.errorCode}`),
        variant: 'error',
      }
    : null;

  let formErrors: Record<Key, string[]> =
    error?.errorCode === DTO.IErrorCode.PasswordInvalid
      ? { currentPassword: [''] }
      : null;

  return (
    <Modal
      title={t('forms.resetYourPassword')}
      alert={alert}
      width={400}
      name={MODALS.CHANGE_PASSWORD}
    >
      <Form initialValues={{}} errors={formErrors} onSubmit={handleSubmit}>
        <Modal.Content>
          <Form.Password
            label={t('forms.currentPassword')}
            name="currentPassword"
            validation={{
              required: true,
            }}
          />
          <Form.Password
            label={t('forms.newPassword')}
            name="newPassword"
            validation={{
              password: true,
              required: true,
            }}
            errors={{
              password: t('validation.passwordPattern'),
            }}
            help={t('help.password')}
          />
          <Form.Password<{ newPassword: string }>
            label={t('forms.repeatNewPassword')}
            name="passwordConfirmation"
            dependencies={['newPassword']}
            validation={{
              equal: ({ newPassword }) => newPassword,
              required: true,
            }}
            errors={{
              equal: t('validation.passwordsResetMatch'),
            }}
          />
        </Modal.Content>
        <Modal.Footer>
          <Modal.Close size="lg">{t('actions.cancel')}</Modal.Close>
          <Form.Submit size="lg" loading={loading} variant="primary">
            {t('actions.reset')}
          </Form.Submit>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
