/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface IAddEditPanelModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  stations?: number[] | null;
}

export interface IAddUserRequestDto {
  email?: string | null;
  userRoleType?: IUserRoleType;
  sendInvite?: boolean;
  teamIds?: number[] | null;
}

export interface IArtistModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface IClientInfo {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

/**
 * Song comparison type
 */
export interface IComparisonTypeRequestDto {
  comparisonType?: ISongComparisonType;
}

export interface ICurrentUserResponse {
  /** @format int32 */
  id?: number;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  client?: IClientInfo;
  teams?: ITeamModel[] | null;
  role?: string | null;

  /** @format int32 */
  selectedPanelId?: number | null;

  /** @format int32 */
  selectedTeamId?: number | null;
}

export interface IDateRangeRequestDto {
  dateRange?: IDateRangeType;
}

export enum IDateRangeType {
  All = 'All',
  Type1M = '1M',
  Type3M = '3M',
  Type6M = '6M',
  Type1Y = '1Y',
}

export interface IDefaultQueryModel {
  weekType?: IWeekType;
}

export interface IDefaultQueryModelPagedRequest {
  query?: IDefaultQueryModel;

  /** @format int32 */
  pageNumber?: number;

  /** @format int32 */
  pageSize?: number;
  sort?: IPagedSort;
}

export interface IEmailVerificationDto {
  token?: string | null;
}

export interface IEmailVerificationResponseDto {
  email?: string | null;
}

export type IEmptyDto = object;

export interface IEmptyDtoPagedRequest {
  query?: IEmptyDto;

  /** @format int32 */
  pageNumber?: number;

  /** @format int32 */
  pageSize?: number;
  sort?: IPagedSort;
}

export type IEmptyQueryModel = object;

export interface IEmptyQueryModelPagedRequest {
  query?: IEmptyQueryModel;

  /** @format int32 */
  pageNumber?: number;

  /** @format int32 */
  pageSize?: number;
  sort?: IPagedSort;
}

export enum IErrorCode {
  ApiError = 'api_error',
  ValidationError = 'validation_error',
  DatabaseError = 'database_error',
  NotFound = 'not_found',
  SignupFailed = 'signup_failed',
  SigninFailed = 'signin_failed',
  InvalidToken = 'invalid_token',
  RoleUpdateFailed = 'role_update_failed',
  RoleAddFailed = 'role_add_failed',
  UserNotFound = 'user_not_found',
  UserNotAuthenticated = 'user_not_authenticated',
  UserNotActivated = 'user_not_activated',
  UserCreateFailed = 'user_create_failed',
  UserUpdateFailed = 'user_update_failed',
  UserIsLocked = 'user_is_locked',
  UserExists = 'user_exists',
  UserMfaAlreadySetup = 'user_mfa_already_setup',
  SameUserError = 'same_user_error',
  Forbidden = 'forbidden',
  UserSettingsUpdateFailed = 'user_settings_update_failed',
  EmailExists = 'email_exists',
  SendInviteEmailFailed = 'send_invite_email_failed',
  ResetPasswordEmailFailed = 'reset_password_email_failed',
  EmailNotConfirmed = 'email_not_confirmed',
  EmailWasConfirmed = 'email_was_confirmed',
  PasswordResetFailed = 'password_reset_failed',
  PasswordConfirmationFailed = 'password_confirmation_failed',
  PasswordInvalid = 'password_invalid',
  AddPanelFailed = 'add_panel_failed',
  ValidationTooSmall = 'validation_too_small',
  ValidationTooLarge = 'validation_too_large',
  ValidationExists = 'validation_exists',
  ValidationEmpty = 'validation_empty',
  ValidationRequired = 'validation_required',
  ValidationIvalidFormat = 'validation_ivalid_format',
  ValidationPasswordOneUppercase = 'validation_password_one_uppercase',
  ValidationPasswordOneLowercase = 'validation_password_one_lowercase',
  ValidationPasswordOneNumber = 'validation_password_one_number',
  ValidationPasswordOneSpecialCharacter = 'validation_password_one_special_character',
  ValidationTotpInvalid = 'validation_totp_invalid',
  ClientNotFound = 'client_not_found',
}

export interface IErrorResponse {
  errorCode?: IErrorCode;
  message?: string | null;
  errors?: Record<string, string[] | null>;
  traceId?: string | null;
}

export enum IExportNameType {
  Stations = 'Stations',
  AddOpportunities = 'AddOpportunities',
}

export interface IExportSongHistoryGridRequestDto {
  /** @format date-time */
  dateTo?: string;
  songHistoryTabType?: ISongHistoryTabType;
}

export interface IForgotPasswordDto {
  email?: string | null;
}

export interface IFrequencyModel {
  type?: IFrequencyTypeValue;

  /** @format double */
  value?: number | null;
}

export enum IFrequencyTypeValue {
  Am = 'am',
  Fm = 'fm',
  Hd = 'hd',
  Hd2 = 'hd2',
}

export interface IGetSongAddOpportunitiesComparisonRelativeGraphResponse {
  /** @format int32 */
  week?: number;
  songs?: IGetSongAddOpportunitiesRelativeGraphModel[] | null;
}

export interface IGetSongAddOpportunitiesComparisonRelativeGridResponse {
  /** @format int32 */
  songId?: number;
  isNeverRanged?: boolean;
  songName?: string | null;
  artistName?: string | null;

  /** @format int32 */
  stationTwRank?: number | null;

  /** @format int32 */
  stationTwRankChange?: number | null;

  /** @format double */
  stationTwPoints?: number;

  /** @format double */
  stationTwPointsChange?: number;

  /** @format int32 */
  stationTwPlays?: number;

  /** @format int32 */
  stationTwPlaysChange?: number;

  /** @format int32 */
  stationAllTimePlays?: number;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  twRankNationalChange?: number | null;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  allTimePlays?: number;

  /** @format double */
  twPoints?: number;

  /** @format double */
  twPointsChange?: number;

  /** @format double */
  allTimePoints?: number;
}

export interface IGetSongAddOpportunitiesComparisonStandardGraphResponse {
  /** @format int32 */
  songId?: number;
  metrics?: Record<string, ISongAddOpportunitiesStandardGraphModel>;
}

export interface IGetSongAddOpportunitiesRelativeGraphModel {
  /** @format int32 */
  songId?: number;

  /** @format int32 */
  plays?: number;

  /** @format int32 */
  playsChange?: number;

  /** @format int32 */
  playsDay?: number;

  /** @format double */
  playsDayPercent?: number;

  /** @format int32 */
  playsNight?: number;

  /** @format int32 */
  playsOvn?: number;

  /** @format double */
  playsOvnPercent?: number;

  /** @format int32 */
  playsAt?: number;

  /** @format double */
  points?: number;

  /** @format double */
  pointsChange?: number;

  /** @format int32 */
  rank?: number | null;

  /** @format int32 */
  rankChange?: number | null;
}

export interface IGetSongAddOpportunitiesResponse {
  callsign?: string | null;

  /** @format int32 */
  stationId?: number;
  owner?: string | null;
  city?: string | null;
  state?: string | null;

  /** @format int32 */
  outlookTypeId?: number | null;

  /** @format int32 */
  high?: number | null;

  /** @format int32 */
  low?: number | null;

  /** @format int32 */
  playlistSize?: number;

  /** @format int32 */
  currents?: number;

  /** @format int32 */
  dayparts?: number;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  allTimePlays?: number;
}

export interface IGetSongAirplayResponse {
  callsign?: string | null;
  owner?: string | null;

  /** @format int32 */
  stationId?: number;
  city?: string | null;
  state?: string | null;

  /** @format int32 */
  rotationPlaysTypeId?: number;

  /** @format int32 */
  rotationRanksTypeId?: number;

  /** @format int32 */
  outlookTypeId?: number | null;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  twRankNationalChange?: number | null;

  /** @format double */
  cwPoints?: number;

  /** @format double */
  cwPointsChange?: number | null;

  /** @format double */
  twPoints?: number;

  /** @format double */
  twPointsChange?: number | null;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number | null;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number | null;

  /** @format int32 */
  twPlaysDay?: number;

  /** @format int32 */
  twPlaysDayChange?: number | null;

  /** @format int32 */
  twPlaysNight?: number;

  /** @format int32 */
  twPlaysNightChange?: number | null;

  /** @format int32 */
  twPlaysAm?: number;

  /** @format int32 */
  twPlaysMid?: number;

  /** @format int32 */
  twPlaysPm?: number;

  /** @format int32 */
  twPlaysEve?: number;

  /** @format int32 */
  twPlaysOvn?: number;

  /** @format int32 */
  allTimePlays?: number;

  /** @format int32 */
  headroom?: number;

  /** @format int32 */
  high?: number | null;

  /** @format int32 */
  low?: number | null;

  /** @format int32 */
  above?: number | null;

  /** @format int32 */
  below?: number | null;

  /** @format int32 */
  rankComparison?: number | null;

  /** @format double */
  avgPlayComparison?: number;

  /** @format int32 */
  maxPlays?: number;

  /** @format int32 */
  possibleSpaces?: number;

  /** @format int32 */
  playlistSize?: number;

  /** @format int32 */
  dayparts?: number;

  /** @format int32 */
  currents?: number;
}

export interface IGetSongComparisonHistoricalRelativeGraphModel {
  /** @format int32 */
  songId?: number;

  /** @format int32 */
  plays?: number;
}

export interface IGetSongComparisonHistoricalRelativeGraphResponse {
  /** @format int32 */
  week?: number;
  songs?: IGetSongComparisonHistoricalRelativeGraphModel[] | null;
}

export interface IGetSongComparisonHistoricalRelativeGridResponse {
  /** @format int32 */
  songId?: number;
  isNeverRanged?: boolean;
  songName?: string | null;
  artistName?: string | null;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  allTimePlays?: number;

  /** @format double */
  twPoints?: number;

  /** @format double */
  twPointsChange?: number;
}

export interface IGetSongComparisonHistoricalStandardGraphResponse {
  /** @format int32 */
  songId?: number;
  weeks?: Record<string, number>;
}

export interface IGetSongComparisonHistoricalStandardGridResponse {
  /** @format int32 */
  songId?: number;
  songName?: string | null;
  artistName?: string | null;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format double */
  twPoints?: number;

  /** @format double */
  twPointsChange?: number;

  /** @format int32 */
  allTimePlays?: number;

  /** @format int32 */
  cwRank?: number | null;

  /** @format int32 */
  cwRankChange?: number | null;

  /** @format int32 */
  cwPlays?: number | null;

  /** @format int32 */
  cwPlaysChange?: number | null;

  /** @format double */
  cwPoints?: number | null;

  /** @format double */
  cwPointsChange?: number | null;
}

export interface IGetSongComparisonPanelGridResponse {
  /** @format int32 */
  songId?: number;
  songName?: string | null;
  artistName?: string | null;

  /** @format int32 */
  stationsOn?: number;

  /** @format int32 */
  twRank?: number | null;

  /** @format double */
  cwPoints?: number;

  /** @format double */
  twPoints?: number;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  historyOrder?: number;
}

export interface IGetSongComparisonStationsGridResponse {
  /** @format int32 */
  stationId?: number;
  callsign?: string | null;

  /** @format int32 */
  ownerId?: number | null;
  ownerName?: string | null;
  songs?: Record<string, ISongMetricsModel>;
}

export interface IGetSongCompetitionResponse {
  competition?: IStationCompetitionModel[] | null;
  songsInfo?: ISongCompetitionInfoModel[] | null;
}

export interface IGetSongOverviewHeaderTileResponse {
  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  twPlays?: number | null;

  /** @format int32 */
  twPlaysChange?: number | null;

  /** @format int32 */
  cwPlays?: number | null;

  /** @format int32 */
  cwPlaysChange?: number | null;
}

export interface IGetSongOverviewHistoryGridResponse {
  /** @format int32 */
  stationId?: number;

  /** @format int32 */
  stationRank?: number | null;

  /** @format int32 */
  stationRankChange?: number | null;
  callsign?: string | null;

  /** @format int64 */
  cwPlays?: number | null;

  /** @format int64 */
  cwPlaysChange?: number | null;

  /** @format int64 */
  twPlays?: number | null;

  /** @format int64 */
  twPlaysChange?: number | null;

  /** @format int64 */
  lwPlays?: number | null;

  /** @format int64 */
  allTimePlays?: number | null;
  isStationsOn?: boolean;
  isNewAdds?: boolean;
  isDrops?: boolean;
}

export interface IGetSongOverviewMapStationHistoryGraphResponse {
  /** @format date-time */
  week?: string;

  /** @format int32 */
  plays?: number;
}

export interface IGetSongOverviewPlaysTileResponse {
  /** @format int32 */
  cwPlays?: number | null;

  /** @format int32 */
  cwPlaysChange?: number | null;

  /** @format int32 */
  twPlays?: number | null;

  /** @format int32 */
  twPlaysChange?: number | null;

  /** @format int64 */
  atPlays?: number | null;
}

export interface IGetSongOverviewPointsTileResponse {
  /** @format double */
  cwPoints?: number | null;

  /** @format double */
  cwPointsChange?: number | null;

  /** @format double */
  twPoints?: number | null;

  /** @format double */
  twPointsChange?: number | null;
}

export interface IGetSongOverviewRanksComparisonResponse {
  /** @format int32 */
  negative?: number | null;

  /** @format int32 */
  positive?: number | null;

  /** @format int32 */
  total?: number | null;
}

export interface IGetSongOverviewRanksTileResponse {
  /** @format int32 */
  cwRank?: number | null;

  /** @format int32 */
  cwRankChange?: number | null;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;
}

export interface IGetSongOverviewRotationGridResponse {
  tabs?: IItemModel[] | null;
  items?: ISongRotationItemResponse[] | null;
}

export interface IGetSongOverviewStationsAddsTileResponse {
  /** @format int32 */
  twStationsAdds?: number | null;

  /** @format int32 */
  cwStationsAdds?: number | null;
}

export interface IGetSongOverviewStationsAverageTileResponse {
  /** @format double */
  averageStationPlays?: number | null;
}

export interface IGetSongOverviewStationsDropsTileResponse {
  /** @format int32 */
  twStationsDrops?: number | null;

  /** @format int32 */
  cwStationsDrops?: number | null;
}

export interface IGetSongOverviewStationsOnTileResponse {
  /** @format int32 */
  cwStationsOn?: number | null;

  /** @format int32 */
  cwStationsTotal?: number | null;

  /** @format int32 */
  twStationsOn?: number | null;

  /** @format int32 */
  twStationsOnChange?: number | null;

  /** @format int32 */
  twStationsTotal?: number | null;
}

export interface IGetSongPlayIncreaseRankPlayComparisonResponse {
  /** @format int32 */
  stationId?: number;
  callsign?: string | null;

  /** @format int32 */
  above?: number | null;

  /** @format int32 */
  below?: number | null;

  /** @format int32 */
  rankPlayComparison?: number | null;

  /** @format double */
  avgPlayComparison?: number;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  allTimePlays?: number;
}

export interface IGetSongSelectorResponse {
  /** @format int32 */
  songId?: number;
  songName?: string | null;
  artistName?: string | null;

  /** @format int32 */
  rank?: number | null;
}

export interface IGetStationOverviewSongRecentlyAddedDroppedResponse {
  weekType?: IWeekType;

  /** @format int32 */
  songId?: number;
  songName?: string | null;

  /** @format int32 */
  artistId?: number;
  artistName?: string | null;

  /** @format int32 */
  stationOn?: number;

  /** @format int32 */
  stationAdded?: number;

  /** @format int32 */
  stationRank?: number | null;

  /** @format double */
  stationCwPoints?: number;

  /** @format double */
  stationCwPointsChange?: number;

  /** @format double */
  stationTwPoints?: number;

  /** @format double */
  stationTwPointsChange?: number;
}

export interface IGetStationOverviewSongRecentlyAddedDroppedResponsePagedResult {
  items?: IGetStationOverviewSongRecentlyAddedDroppedResponse[] | null;

  /** @format int32 */
  pageNumber?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int64 */
  totalCount?: number;
}

export interface IGetStationOverviewSongsDecreasesResponse {
  /** @format int32 */
  songId?: number;
  songName?: string | null;
  label?: string | null;

  /** @format int32 */
  artistId?: number;
  artistName?: string | null;

  /** @format int32 */
  stationTwPlays?: number;

  /** @format int32 */
  stationTwPlaysChange?: number;

  /** @format int32 */
  stationTwRank?: number | null;

  /** @format int32 */
  stationTwRankChange?: number | null;
}

export interface IGetStationOverviewSongsDecreasesResponsePagedResult {
  items?: IGetStationOverviewSongsDecreasesResponse[] | null;

  /** @format int32 */
  pageNumber?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int64 */
  totalCount?: number;
}

export interface IGetStationOverviewSongsIncreasesResponse {
  /** @format int32 */
  songId?: number;
  songName?: string | null;
  label?: string | null;

  /** @format int32 */
  artistId?: number;
  artistName?: string | null;

  /** @format int32 */
  stationTwPlays?: number;

  /** @format int32 */
  stationTwPlaysChange?: number;

  /** @format int32 */
  stationTwRank?: number | null;

  /** @format int32 */
  stationTwRankChange?: number | null;
}

export interface IGetStationOverviewSongsIncreasesResponsePagedResult {
  items?: IGetStationOverviewSongsIncreasesResponse[] | null;

  /** @format int32 */
  pageNumber?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int64 */
  totalCount?: number;
}

export interface IGetStationsAirplayResponse {
  /** @format int32 */
  stationId?: number;
  callsign?: string | null;
  owner?: string | null;
  city?: string | null;
  state?: string | null;

  /** @format int32 */
  outlookTypeId?: number | null;

  /** @format int32 */
  rotationRanksTypeId?: number;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  twRankNationalChange?: number | null;

  /** @format double */
  cwPoints?: number;

  /** @format double */
  cwPointsChange?: number;

  /** @format double */
  twPoints?: number;

  /** @format double */
  twPointsChange?: number;

  /** @format int32 */
  rotationPlaysTypeId?: number;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  twPlaysDay?: number;

  /** @format int32 */
  twPlaysDayChange?: number;

  /** @format int32 */
  twPlaysNight?: number;

  /** @format int32 */
  twPlaysNightChange?: number;

  /** @format int32 */
  twPlaysAm?: number;

  /** @format int32 */
  twPlaysMid?: number;

  /** @format int32 */
  twPlaysPm?: number;

  /** @format int32 */
  twPlaysEve?: number;

  /** @format int32 */
  twPlaysOvn?: number;

  /** @format int32 */
  allTimePlays?: number;

  /** @format int32 */
  high?: number | null;

  /** @format int32 */
  low?: number | null;

  /** @format int32 */
  above?: number | null;

  /** @format int32 */
  below?: number | null;

  /** @format int32 */
  rankComparison?: number | null;

  /** @format double */
  avgPlayComparison?: number;

  /** @format int32 */
  headroom?: number;

  /** @format int32 */
  maxPlays?: number;

  /** @format int32 */
  possibleSpaces?: number;

  /** @format int32 */
  playlistSize?: number;

  /** @format int32 */
  dayparts?: number;

  /** @format int32 */
  currents?: number;
}

export interface IHealthCheckModel {
  postgres?: string | null;
  postgresAdmin?: string | null;
  redshift?: string | null;
  dataUpdates?: string | null;
}

export interface IIncDecQueryDto {
  weekType?: IWeekType;
}

export interface IIncDecQueryDtoPagedRequest {
  query?: IIncDecQueryDto;

  /** @format int32 */
  pageNumber?: number;

  /** @format int32 */
  pageSize?: number;
  sort?: IPagedSort;
}

export interface IItemModel {
  /** @format int32 */
  id?: number | null;
  name?: string | null;
}

export interface IJwtTokenResponseDto {
  token?: string | null;
  refreshToken?: string | null;
}

export interface IListIdsRequestDto {
  ids?: number[] | null;
}

export interface ILoginUserDto {
  email?: string | null;
  password?: string | null;
}

export interface ILoginUserResponseDto {
  isMfaRequired?: boolean;
  isMfaConfigured?: boolean;
  token?: string | null;
  refreshToken?: string | null;
}

export interface IMemberModel {
  /** @format int32 */
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  resendInvitationRequired?: boolean;
  invitationRequired?: boolean;
  isActive?: boolean;
  teams?: ITeamModel[] | null;
  role?: string | null;

  /** @format date-time */
  registrationDate?: string | null;
}

export interface IMfaResponseDto {
  totpCode?: string | null;
}

export interface IMfaSetupResponseDto {
  email?: string | null;
  url?: string | null;
  textCode?: string | null;
  qrCode?: string | null;
}

export interface IPagedSort {
  column?: string | null;
  order?: IPagedSortOrder;
}

export enum IPagedSortOrder {
  Ascend = 'ascend',
  Descend = 'descend',
}

export interface IPanelChartDatesModel {
  /** @format date-time */
  startDate?: string;

  /** @format date-time */
  cwStartDate?: string;

  /** @format date-time */
  currentDate?: string;
}

export interface IPanelChartRequest {
  /** @format date-time */
  twStartDate?: string;
}

export interface IPanelChartRequestPagedRequest {
  query?: IPanelChartRequest;

  /** @format int32 */
  pageNumber?: number;

  /** @format int32 */
  pageSize?: number;
  sort?: IPagedSort;
}

export interface IPanelChartStatisticsResponse {
  /** @format int32 */
  songId?: number;
  songName?: string | null;
  artistName?: string | null;

  /** @format int64 */
  allTimePlays?: number | null;

  /** @format int64 */
  cwRank?: number | null;

  /** @format int64 */
  cwRankChange?: number | null;

  /** @format double */
  cwPoints?: number | null;

  /** @format double */
  cwPointsChange?: number | null;

  /** @format int64 */
  cwPlays?: number | null;

  /** @format int64 */
  cwPlaysChange?: number | null;

  /** @format double */
  cwPointsDay?: number | null;

  /** @format double */
  cwPointsDayChange?: number | null;

  /** @format int64 */
  cwPlaysDay?: number | null;

  /** @format int64 */
  cwPlaysDayChange?: number | null;

  /** @format double */
  cwPointsNight?: number | null;

  /** @format double */
  cwPointsNightChange?: number | null;

  /** @format int64 */
  cwPlaysNight?: number | null;

  /** @format int64 */
  cwPlaysNightChange?: number | null;

  /** @format double */
  cwPointsAm?: number | null;

  /** @format double */
  cwPointsAmChange?: number | null;

  /** @format int64 */
  cwPlaysAm?: number | null;

  /** @format int64 */
  cwPlaysAmChange?: number | null;

  /** @format double */
  cwPointsMid?: number | null;

  /** @format double */
  cwPointsMidChange?: number | null;

  /** @format int64 */
  cwPlaysMid?: number | null;

  /** @format int64 */
  cwPlaysMidChange?: number | null;

  /** @format double */
  cwPointsPm?: number | null;

  /** @format double */
  cwPointsPmChange?: number | null;

  /** @format int64 */
  cwPlaysPm?: number | null;

  /** @format int64 */
  cwPlaysPmChange?: number | null;

  /** @format int64 */
  cwPlaysEve?: number | null;

  /** @format int64 */
  cwPlaysEveChange?: number | null;

  /** @format double */
  cwPointsEve?: number | null;

  /** @format double */
  cwPointsEveChange?: number | null;

  /** @format int64 */
  cwPlaysOvn?: number | null;

  /** @format int64 */
  cwPlaysOvnChange?: number | null;

  /** @format double */
  cwPointsOvn?: number | null;

  /** @format double */
  cwPointsOvnChange?: number | null;

  /** @format int64 */
  twRank?: number | null;

  /** @format int64 */
  twRankChange?: number | null;

  /** @format double */
  twPoints?: number | null;

  /** @format double */
  twPointsChange?: number | null;

  /** @format int64 */
  twPlays?: number | null;

  /** @format int64 */
  twPlaysChange?: number | null;

  /** @format double */
  twPointsDay?: number | null;

  /** @format double */
  twPointsDayChange?: number | null;

  /** @format int64 */
  twPlaysDay?: number | null;

  /** @format int64 */
  twPlaysDayChange?: number | null;

  /** @format double */
  twPointsNight?: number | null;

  /** @format double */
  twPointsNightChange?: number | null;

  /** @format int64 */
  twPlaysNight?: number | null;

  /** @format int64 */
  twPlaysNightChange?: number | null;

  /** @format double */
  twPointsAm?: number | null;

  /** @format double */
  twPointsAmChange?: number | null;

  /** @format int64 */
  twPlaysAm?: number | null;

  /** @format int64 */
  twPlaysAmChange?: number | null;

  /** @format double */
  twPointsMid?: number | null;

  /** @format double */
  twPointsMidChange?: number | null;

  /** @format int64 */
  twPlaysMid?: number | null;

  /** @format int64 */
  twPlaysMidChange?: number | null;

  /** @format double */
  twPointsPm?: number | null;

  /** @format double */
  twPointsPmChange?: number | null;

  /** @format int64 */
  twPlaysPm?: number | null;

  /** @format int64 */
  twPlaysPmChange?: number | null;

  /** @format double */
  twPointsEve?: number | null;

  /** @format double */
  twPointsEveChange?: number | null;

  /** @format int64 */
  twPlaysEve?: number | null;

  /** @format int64 */
  twPlaysEveChange?: number | null;

  /** @format double */
  twPointsOvn?: number | null;

  /** @format double */
  twPointsOvnChange?: number | null;

  /** @format int64 */
  twPlaysOvn?: number | null;

  /** @format int64 */
  twPlaysOvnChange?: number | null;

  /** @format double */
  lwPoints?: number | null;

  /** @format double */
  lwPointsChange?: number | null;

  /** @format int64 */
  lwPlays?: number | null;

  /** @format int64 */
  lwPlaysChange?: number | null;

  /** @format double */
  lwPointsDay?: number | null;

  /** @format double */
  lwPointsDayChange?: number | null;

  /** @format int64 */
  lwPlaysDay?: number | null;

  /** @format int64 */
  lwPlaysDayChange?: number | null;

  /** @format double */
  lwPointsNight?: number | null;

  /** @format double */
  lwPointsNightChange?: number | null;

  /** @format int64 */
  lwPlaysNight?: number | null;

  /** @format int64 */
  lwPlaysNightChange?: number | null;

  /** @format double */
  lwPointsAm?: number | null;

  /** @format double */
  lwPointsAmChange?: number | null;

  /** @format int64 */
  lwPlaysAm?: number | null;

  /** @format int64 */
  lwPlaysAmChange?: number | null;

  /** @format double */
  lwPointsMid?: number | null;

  /** @format double */
  lwPointsMidChange?: number | null;

  /** @format int64 */
  lwPlaysMid?: number | null;

  /** @format int64 */
  lwPlaysMidChange?: number | null;

  /** @format double */
  lwPointsPm?: number | null;

  /** @format double */
  lwPointsPmChange?: number | null;

  /** @format int64 */
  lwPlaysPm?: number | null;

  /** @format int64 */
  lwPlaysPmChange?: number | null;

  /** @format double */
  lwPointsEve?: number | null;

  /** @format double */
  lwPointsEveChange?: number | null;

  /** @format int64 */
  lwPlaysEve?: number | null;

  /** @format int64 */
  lwPlaysEveChange?: number | null;

  /** @format double */
  lwPointsOvn?: number | null;

  /** @format double */
  lwPointsOvnChange?: number | null;

  /** @format int64 */
  lwPlaysOvn?: number | null;

  /** @format int64 */
  lwPlaysOvnChange?: number | null;
}

export interface IPanelChartStatisticsResponsePagedResult {
  items?: IPanelChartStatisticsResponse[] | null;

  /** @format int32 */
  pageNumber?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int64 */
  totalCount?: number;
}

export interface IPanelFilterDto {
  /** @format int32 */
  teamId?: number | null;
  scopeType?: IPanelScopeType;
}

export interface IPanelInfoModel {
  /** @format int32 */
  id?: number;
  name?: string | null;

  /** @format int32 */
  stationCount?: number;
  isSystem?: boolean;
  isActive?: boolean;

  /** @format date-time */
  createdAt?: string;
  panelScopeType?: IPanelScopeType;
}

export interface IPanelModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  stations?: IStationInfoModel[] | null;
}

export enum IPanelScopeType {
  National = 'National',
  Spintel = 'Spintel',
  Team = 'Team',
  Personal = 'Personal',
}

export enum IRankAwayType {
  All = 'All',
  OnDeck = 'OnDeck',
  InRange = 'InRange',
  Behind = 'Behind',
}

export interface IRefreshTokenDto {
  refreshToken?: string | null;
}

export interface IRelativeGridRequestDto {
  /** @format int32 */
  week?: number;
}

export interface IResetPasswordDto {
  resetPasswordToken?: string | null;
  newPassword?: string | null;
  passwordConfirmation?: string | null;
}

export enum IRotationTabType {
  Plays = 'Plays',
  Rank = 'Rank',
}

export interface ISearchSongComparisonModel {
  songName?: string | null;

  /** @format int32 */
  songId?: number;
  artistName?: string | null;

  /** @format int32 */
  rank?: number | null;
  pinned?: boolean;
}

export interface ISignUpUserRequestDto {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  password?: string | null;
  passwordConfirmation?: string | null;
}

export interface ISignUpUserResponseDto {
  isMfaRequired?: boolean;
  token?: string | null;
  refreshToken?: string | null;
}

export interface ISocialModel {
  /** @format int32 */
  id?: number;
  type?: ISocialTypeValue;
  value?: string | null;
}

export enum ISocialTypeValue {
  Facebook = 'facebook',
  Twitter = 'twitter',
  Instagram = 'instagram',
  Youtube = 'youtube',
}

export interface ISongAddOpportunitiesComparisonStandardGridResponse {
  /** @format int32 */
  songId?: number;
  songName?: string | null;
  artistName?: string | null;

  /** @format int32 */
  stationTwRank?: number | null;

  /** @format int32 */
  stationTwRankChange?: number | null;

  /** @format double */
  stationCwPoints?: number;

  /** @format double */
  stationCwPointsChange?: number;

  /** @format double */
  stationTwPoints?: number;

  /** @format double */
  stationTwPointsChange?: number;

  /** @format int32 */
  stationCwPlays?: number;

  /** @format int32 */
  stationCwPlaysChange?: number;

  /** @format int32 */
  stationTwPlays?: number;

  /** @format int32 */
  stationTwPlaysChange?: number;

  /** @format int32 */
  stationAllTimePlays?: number;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  twRankNationalChange?: number | null;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  allTimePlays?: number;

  /** @format double */
  cwPoints?: number;

  /** @format double */
  cwPointsChange?: number;

  /** @format double */
  twPoints?: number;

  /** @format double */
  twPointsChange?: number;

  /** @format double */
  allTimePoints?: number;
}

export interface ISongAddOpportunitiesHighLowResponse {
  /** @format int32 */
  songId?: number;
  songName?: string | null;

  /** @format int32 */
  artistId?: number;
  artistName?: string | null;

  /** @format int32 */
  stationTwRank?: number | null;

  /** @format int32 */
  stationTwRankChange?: number | null;

  /** @format int32 */
  stationCwPlays?: number;

  /** @format int32 */
  stationCwPlaysChange?: number;

  /** @format int32 */
  stationTwPlays?: number;

  /** @format int32 */
  stationTwPlaysChange?: number;

  /** @format int32 */
  stationAllTimePlays?: number;

  /** @format double */
  stationCwPoints?: number;

  /** @format double */
  stationCwPointsChange?: number;

  /** @format double */
  stationTwPoints?: number;

  /** @format double */
  stationTwPointsChange?: number;

  /** @format double */
  stationAllTimePoints?: number;

  /** @format int32 */
  cwRank?: number | null;

  /** @format int32 */
  cwRankChange?: number | null;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format double */
  cwPoints?: number;

  /** @format double */
  cwPointsChange?: number;

  /** @format double */
  twPoints?: number;

  /** @format double */
  twPointsChange?: number;

  /** @format double */
  allTimePoints?: number;
}

export interface ISongAddOpportunitiesStandardGraphModel {
  /** @format int32 */
  plays?: number;

  /** @format int32 */
  playsChange?: number;

  /** @format int32 */
  playsDay?: number;

  /** @format double */
  playsDayPercent?: number;

  /** @format int32 */
  playsNight?: number;

  /** @format int32 */
  playsOvn?: number;

  /** @format double */
  playsOvnPercent?: number;

  /** @format int32 */
  playsAt?: number;

  /** @format double */
  points?: number;

  /** @format double */
  pointsChange?: number;

  /** @format int32 */
  rank?: number | null;

  /** @format int32 */
  rankChange?: number | null;
}

export interface ISongAddsCwResponseDto {
  /** @format int64 */
  totalCwPlays?: number;
  pagedResult?: ISongStationPlaysDtoPagedResult;
}

export interface ISongAddsTwResponseDto {
  /** @format int64 */
  totalTwPlays?: number;
  pagedResult?: ISongStationPlaysDtoPagedResult;
}

export interface ISongComparisonHistoricalRelativeRequestDto {
  /** @format int32 */
  week?: number;

  /** @format int32 */
  comparedSongId?: number;
}

export interface ISongComparisonHistoricalStandardRequestDto {
  /** @format date-time */
  dateTo?: string;

  /** @format int32 */
  comparedSongId?: number;
}

export interface ISongComparisonHistoricalStationPlayComparisonGridResponse {
  /** @format int32 */
  stationId?: number;
  callsign?: string | null;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  winLoses?: number;
  isAll?: boolean;
  isExclusive?: boolean;
  isNotOn?: boolean;
}

export interface ISongComparisonModel {
  /** @format int32 */
  songId?: number;
  name?: string | null;
  artistModels?: IArtistModel[] | null;

  /** @format int32 */
  nationalRank?: number | null;

  /** @format int32 */
  historyOrder?: number;
}

export interface ISongComparisonStatisticsModel {
  /** @format int32 */
  songId?: number | null;
}

export enum ISongComparisonType {
  PlaysTw = 'plays-tw',
  PlaysTwDay = 'plays-tw-day',
  PlaysTwNight = 'plays-tw-night',
  PlaysTwOvn = 'plays-tw-ovn',
  PlaysCw = 'plays-cw',
  PlaysAt = 'plays-at',
  RankTw = 'rank-tw',
  RankCw = 'rank-cw',
  PointsTw = 'points-tw',
  PointsCw = 'points-cw',
}

export interface ISongComparisonsRequestDto {
  songComparisons?: number[] | null;
}

export interface ISongCompetitionInfoModel {
  /** @format int32 */
  songId?: number;
  songName?: string | null;
  artistName?: string | null;

  /** @format int32 */
  panelRank?: number | null;
}

export interface ISongCompetitionModel {
  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  cwRankChange?: number | null;

  /** @format double */
  twPointsChange?: number;

  /** @format double */
  cwPointsChange?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  twDayPlaysChange?: number;

  /** @format int32 */
  twNightPlaysChange?: number;

  /** @format int32 */
  twOvnPlaysChange?: number;

  /** @format int32 */
  allTimePlaysChange?: number;
}

export interface ISongDecreasesResponseDto {
  /** @format int64 */
  totalTwPlaysChange?: number | null;

  /** @format int64 */
  totalTwPlaysChangeDiff?: number | null;

  /** @format int64 */
  totalCwPlaysChange?: number | null;

  /** @format int64 */
  totalCwPlaysChangeDiff?: number | null;
  pagedResult?: ISongStationPlaysDtoPagedResult;
}

export interface ISongDropsResponseDto {
  /** @format int64 */
  lostPlays?: number | null;
  pagedResult?: ISongStationPlaysDtoPagedResult;
}

export interface ISongHistoryGridRequestDto {
  /** @format date-time */
  dateTo?: string;
}

export enum ISongHistoryTabType {
  Plays = 'Plays',
  PlaysChange = 'PlaysChange',
  Rank = 'Rank',
  RankChange = 'RankChange',
  StationsOn = 'StationsOn',
  StationsAdds = 'StationsAdds',
  StationsDrops = 'StationsDrops',
}

export interface ISongIncreasesResponseDto {
  /** @format int64 */
  totalTwPlaysChange?: number | null;

  /** @format int64 */
  totalTwPlaysChangeDiff?: number | null;

  /** @format int64 */
  totalCwPlaysChange?: number | null;

  /** @format int64 */
  totalCwPlaysChangeDiff?: number | null;
  pagedResult?: ISongStationPlaysDtoPagedResult;
}

export interface ISongMetricsModel {
  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  cwRankChange?: number | null;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  allTimePlaysChange?: number;

  /** @format double */
  twPointsChange?: number;

  /** @format double */
  cwPointsChange?: number;

  /** @format int32 */
  twDayPlaysChange?: number;

  /** @format int32 */
  twNightPlaysChange?: number;

  /** @format int32 */
  twOvnPlaysChange?: number;
}

export interface ISongNoChangesResponseDto {
  /** @format int64 */
  totalTwPlays?: number | null;

  /** @format int64 */
  totalCwPlays?: number | null;
  pagedResult?: ISongStationPlaysDtoPagedResult;
}

export interface ISongOutlookItemResponse {
  /** @format int32 */
  stationId?: number;
  callsign?: string | null;

  /** @format int64 */
  cwPlays?: number;

  /** @format int64 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  allTimePlays?: number;

  /** @format int32 */
  playlistSize?: number | null;

  /** @format int32 */
  outlookTypeId?: number | null;
}

export interface ISongOverviewOutlookGridResponse {
  tabs?: IItemModel[] | null;
  items?: ISongOutlookItemResponse[] | null;
}

export interface ISongOwnershipGridResponse {
  /** @format int32 */
  ownerId?: number;
  ownerName?: string | null;

  /** @format int32 */
  stations?: number;

  /** @format int32 */
  stationsOn?: number;

  /** @format double */
  stationsPerc?: number;

  /** @format int32 */
  ownerTwRank?: number | null;

  /** @format double */
  offsetPanelPerc?: number;

  /** @format double */
  offsetOwnerPerc?: number;

  /** @format double */
  offsetChangePerc?: number;

  /** @format int32 */
  offsetAvg?: number;

  /** @format int32 */
  offsetPsAvg?: number;

  /** @format double */
  cwPoints?: number;

  /** @format double */
  cwPointsChange?: number;

  /** @format double */
  twPoints?: number;

  /** @format double */
  twPointsChange?: number;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  twPlaysAvg?: number;

  /** @format int32 */
  twPlaysDay?: number;

  /** @format int32 */
  twPlaysDayChange?: number;

  /** @format double */
  twPlaysDayPerc?: number;

  /** @format int32 */
  twPlaysNight?: number;

  /** @format int32 */
  twPlaysNightChange?: number;

  /** @format double */
  twPlaysNightPerc?: number;

  /** @format int32 */
  twPlaysAm?: number;

  /** @format int32 */
  twPlaysAmChange?: number;

  /** @format double */
  twPlaysAmPerc?: number;

  /** @format int32 */
  twPlaysMid?: number;

  /** @format int32 */
  twPlaysMidChange?: number;

  /** @format double */
  twPlaysMidPerc?: number;

  /** @format int32 */
  twPlaysPm?: number;

  /** @format int32 */
  twPlaysPmChange?: number;

  /** @format double */
  twPlaysPmPerc?: number;

  /** @format int32 */
  twPlaysEve?: number;

  /** @format int32 */
  twPlaysEveChange?: number;

  /** @format double */
  twPlaysEvePerc?: number;

  /** @format int32 */
  twPlaysOvn?: number;

  /** @format int32 */
  twPlaysOvnChange?: number;

  /** @format double */
  twPlaysOvnPerc?: number;

  /** @format int32 */
  atPlays?: number;
}

export interface ISongOwnershipMapResponse {
  /** @format int32 */
  stationId?: number;
  callsign?: string | null;

  /** @format int32 */
  ownerId?: number | null;
  ownerName?: string | null;
  city?: string | null;
  state?: string | null;

  /** @format int32 */
  rotationPlaysTypeId?: number;

  /** @format int32 */
  outlookTypeId?: number | null;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  allTimePlays?: number;
}

export interface ISongOwnershipPieChartResponse {
  /** @format int32 */
  ownerId?: number;
  ownerName?: string | null;

  /** @format int32 */
  allTimePlays?: number;

  /** @format int32 */
  stationsQty?: number;
}

export interface ISongPlayIncreasePlaylistSizeResponse {
  /** @format int32 */
  stationId?: number;
  callsign?: string | null;

  /** @format int32 */
  playlistSize?: number;

  /** @format int32 */
  currents?: number;

  /** @format int32 */
  dayparts?: number;

  /** @format int32 */
  rankAway?: number | null;

  /** @format int32 */
  rankAwayTypeId?: number | null;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  allTimePlays?: number;
}

export interface ISongResponse {
  /** @format int32 */
  id?: number;
  name?: string | null;
  artistName?: string | null;
}

export interface ISongRotationItemResponse {
  /** @format int32 */
  stationId?: number;
  callsign?: string | null;
  owner?: string | null;
  city?: string | null;
  state?: string | null;

  /** @format int32 */
  stationRank?: number | null;

  /** @format int32 */
  stationRankChange?: number | null;

  /** @format int64 */
  cwPlays?: number;

  /** @format int64 */
  cwPlaysChange?: number;

  /** @format int64 */
  twPlays?: number;

  /** @format int64 */
  twPlaysChange?: number;

  /** @format int64 */
  allTimePlays?: number;

  /** @format int32 */
  playsRotationTypeId?: number;

  /** @format int32 */
  rankRotationTypeId?: number;
}

export interface ISongSelectorModel {
  /** @format int32 */
  songId?: number;
  songName?: string | null;
  artistName?: string | null;

  /** @format int32 */
  rank?: number | null;
}

export interface ISongStationPlaysDto {
  /** @format int32 */
  stationId?: number;
  callsign?: string | null;

  /** @format int64 */
  cwPlays?: number | null;

  /** @format int64 */
  cwPlaysChange?: number | null;

  /** @format int64 */
  twPlays?: number | null;

  /** @format int64 */
  twPlaysChange?: number | null;

  /** @format int64 */
  allTimePlays?: number | null;
}

export interface ISongStationPlaysDtoPagedResult {
  items?: ISongStationPlaysDto[] | null;

  /** @format int32 */
  pageNumber?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int64 */
  totalCount?: number;
}

export interface IStationCompetitionModel {
  /** @format int32 */
  stationId?: number;
  callsign?: string | null;

  /** @format int32 */
  ownerId?: number | null;
  ownerName?: string | null;
  songs?: Record<string, ISongCompetitionModel>;
}

export interface IStationContactModel {
  /** @format int32 */
  id?: number;
  type?: IStationContactTypeValue;
  value?: string | null;
}

export enum IStationContactTypeValue {
  StudioLine = 'studio_line',
  BusinessLine = 'business_line',
  Website = 'website',
  Email = 'email',
}

export interface IStationDetailsModel {
  /** @format int32 */
  id?: number;
  callsign?: string | null;
  name?: string | null;

  /** @format int32 */
  stateId?: number | null;
  stateName?: string | null;
  city?: string | null;
  owner?: string | null;
  officeAddress?: string | null;
  frequency?: IFrequencyModel;
  contacts?: IStationContactModel[] | null;
  socials?: ISocialModel[] | null;
}

export interface IStationInfoModel {
  /** @format int32 */
  id?: number;
  callsign?: string | null;
  name?: string | null;
  city?: string | null;
  stateName?: string | null;
  ownerName?: string | null;

  /** @format double */
  weight?: number;
}

export interface IStationMaxPlayModel {
  /** @format int32 */
  stationId?: number;
  callsign?: string | null;

  /** @format int32 */
  maxPlays?: number;

  /** @format int32 */
  headRoom?: number;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  allTimePlays?: number;
}

export interface IStationModel {
  /** @format int32 */
  id?: number;
  callsign?: string | null;
  name?: string | null;
  band?: string | null;

  /** @format double */
  lng?: number | null;

  /** @format double */
  lat?: number | null;
  contours?: number[][] | null;
}

export interface IStationMusicStackResponse {
  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format double */
  cwPoints?: number;

  /** @format double */
  cwPointsChange?: number;

  /** @format double */
  twPoints?: number;

  /** @format double */
  twPointsChange?: number;

  /** @format double */
  allTimePoints?: number;

  /** @format int32 */
  songId?: number;
  songName?: string | null;

  /** @format int32 */
  artistId?: number;
  artistName?: string | null;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  allTimePlays?: number;
}

export interface IStationMySongsResponse {
  /** @format int32 */
  songId?: number;
  songName?: string | null;

  /** @format int32 */
  artistId?: number;
  artistName?: string | null;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  high?: number | null;

  /** @format int32 */
  low?: number | null;

  /** @format int32 */
  above?: number | null;

  /** @format int32 */
  below?: number | null;

  /** @format int32 */
  rankPlayComparison?: number | null;

  /** @format double */
  avgPlayComparison?: number;
}

export interface IStationPlaylistResponse {
  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  songId?: number;
  songName?: string | null;

  /** @format int32 */
  artistId?: number;
  artistName?: string | null;

  /** @format double */
  cwPoints?: number;

  /** @format double */
  cwPointsChange?: number;

  /** @format double */
  twPoints?: number;

  /** @format double */
  twPointsChange?: number;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int32 */
  twPlaysDay?: number;

  /** @format int32 */
  twPlaysDayChange?: number;

  /** @format int32 */
  twPlaysNight?: number;

  /** @format int32 */
  twPlaysNightChange?: number;

  /** @format int32 */
  allTimePlays?: number;
}

export interface IStationPossibleSpaceResponse {
  /** @format int32 */
  songId?: number;
  songName?: string | null;

  /** @format int32 */
  artistId?: number;
  artistName?: string | null;

  /** @format int32 */
  stationTwRank?: number | null;

  /** @format int32 */
  stationTwRankChange?: number | null;

  /** @format int32 */
  stationCwPlays?: number;

  /** @format int32 */
  stationCwPlaysChange?: number;

  /** @format int32 */
  stationTwPlays?: number;

  /** @format int32 */
  stationTwPlaysChange?: number;

  /** @format int32 */
  stationAllTimePlays?: number;

  /** @format double */
  stationCwPoints?: number;

  /** @format double */
  stationCwPointsChange?: number;

  /** @format double */
  stationTwPoints?: number;

  /** @format double */
  stationTwPointsChange?: number;

  /** @format double */
  stationAllTimePoints?: number;

  /** @format int32 */
  cwRank?: number | null;

  /** @format int32 */
  cwRankChange?: number | null;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format double */
  cwPoints?: number;

  /** @format double */
  cwPointsChange?: number;

  /** @format double */
  twPoints?: number;

  /** @format double */
  twPointsChange?: number;

  /** @format double */
  allTimePoints?: number;
}

export interface IStringPagedRequest {
  query?: string | null;

  /** @format int32 */
  pageNumber?: number;

  /** @format int32 */
  pageSize?: number;
  sort?: IPagedSort;
}

export interface ITeamDetailsResponse {
  /** @format int32 */
  id?: number;
  name?: string | null;
  teamMembers?: ITeamMemberModel[] | null;
  teamSongs?: ITeamSongModel[] | null;
  teamPanels?: ITeamPanelModel[] | null;

  /** @format int32 */
  songAt?: number;
}

export interface ITeamMemberModel {
  /** @format int32 */
  id?: number;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  roleName?: string | null;
}

export interface ITeamModel {
  /** @format int32 */
  id?: number;
  name?: string | null;

  /** @format date-time */
  createdAt?: string;
  songIds?: number[] | null;
}

export interface ITeamPanelModel {
  /** @format int32 */
  panelId?: number;
  panelName?: string | null;

  /** @format int32 */
  stationCount?: number;
  isSystem?: boolean;

  /** @format date-time */
  createAt?: string;
}

export interface ITeamRequestDto {
  name?: string | null;
}

export interface ITeamResponse {
  /** @format int32 */
  id?: number;
  name?: string | null;

  /** @format date-time */
  createdAt?: string;

  /** @format int32 */
  memberCount?: number;

  /** @format int32 */
  songCount?: number;

  /** @format int32 */
  panelCount?: number;
}

export interface ITeamSongGridResponse {
  /** @format int32 */
  songId?: number;
  songName?: string | null;
  artistName?: string | null;

  /** @format int32 */
  twRank?: number | null;

  /** @format int32 */
  twRankChange?: number | null;

  /** @format double */
  cwPoints?: number;

  /** @format double */
  cwPointsChange?: number;

  /** @format double */
  twPoints?: number;

  /** @format double */
  twPointsChange?: number;

  /** @format int32 */
  cwPlays?: number;

  /** @format int32 */
  cwPlaysChange?: number;

  /** @format int32 */
  twPlays?: number;

  /** @format int32 */
  twPlaysChange?: number;

  /** @format int64 */
  twPlaysDay?: number | null;

  /** @format int64 */
  twPlaysDayChange?: number | null;

  /** @format int64 */
  twPlaysNight?: number | null;

  /** @format int64 */
  twPlaysNightChange?: number | null;

  /** @format int32 */
  allTimePlays?: number;
}

export interface ITeamSongModel {
  /** @format int32 */
  songId?: number;
  songName?: string | null;
  artistName?: string | null;

  /** @format int32 */
  nationalTwRank?: number | null;
}

export interface IUpdatePasswordDto {
  currentPassword?: string | null;
  newPassword?: string | null;
  passwordConfirmation?: string | null;
}

export interface IUpdateRoleRequestDto {
  role?: IUserRoleType;
}

export interface IUpdateUserPanelRequestDto {
  /** @format int32 */
  panelId?: number;

  /** @format int32 */
  teamId?: number | null;
}

export enum IUserRoleType {
  AccountManager = 'Account manager',
  TeamManager = 'Team manager',
  Member = 'Member',
}

export interface IValidateTokenDto {
  token?: string | null;
}

export interface IWeekPointMetricsModel {
  /** @format int64 */
  plays?: number;

  /** @format int64 */
  playsChange?: number;

  /** @format int32 */
  rank?: number | null;

  /** @format int32 */
  rankChange?: number | null;

  /** @format int32 */
  stationsOn?: number;

  /** @format int32 */
  stationsAdds?: number;

  /** @format int32 */
  stationsDrops?: number;
}

export enum IWeekType {
  Cw = 'cw',
  Tw = 'tw',
}

export interface ICurrentsExportListParams {
  /** @format date-time */
  startDate?: string;
}

export interface IPossibleSpaceExportListParams {
  /** @format date-time */
  startDate?: string;
}

export interface IRotationExportDetailParams {
  type?: IRotationTabType;

  /** @format int32 */
  songId: number;
}

export interface IOutlookMovetoSectionCreateParams {
  /** @format int32 */
  stationId?: number;

  /** @format int32 */
  outlookTypeId?: number;

  /** @format int32 */
  songId: number;
}

export interface IPlayIncreasePlaylistExportCreateParams {
  /** rank away tab type */
  type?: IRankAwayType;

  /**
   * Song identifier
   * @format int32
   */
  songId: number;
}

export interface IPossibleSpaceExportCreateParams {
  /** Export name type */
  type?: IExportNameType;

  /**
   * Song identifier
   * @format int32
   */
  songId: number;

  /**
   * Station identifier
   * @format int32
   */
  stationId: number;
}

export interface IPlaylistExportCreateParams {
  /** Export name type */
  type?: IExportNameType;

  /**
   * Song identifier
   * @format int32
   */
  songId: number;

  /**
   * Station identifier
   * @format int32
   */
  stationId: number;
}

export interface IMusicStackExportCreateParams {
  /** Export name type */
  type?: IExportNameType;

  /**
   * Song identifier
   * @format int32
   */
  songId: number;

  /**
   * Station identifier
   * @format int32
   */
  stationId: number;
}
