module.exports = {
  addsCW: 'Displays stations that have added your song in the current week.',
  addsTW: 'Displays stations that have added your song this week.',
  dropsTW: 'Displays stations that have dropped your song this week.',
  maximumCountOfPanels_one: 'Maximum of {{count}} panel',
  maximumCountOfPanels_other: 'Maximum of {{count}} panels',
  maximumCountOfSongs_one: 'Maximum of {{count}} song',
  maximumCountOfSongs_other: 'Maximum of {{count}} songs',
  noChanges: 'Displays stations that have no change in plays.',
  noComparedSongs:
    'All songs are turned off for display. To add songs for comparison, turn on song tags below the graph.',
  onTheMap: 'Displays stations on the map by rotation or outlook',
  outlook:
    'Displays the station add potentials on the selected panel for the next week.',
  recentlyAdded: 'Displays songs that were recently added to the station',
  recentlyDropped: 'Displays songs that were recently dropped from the station',
  rotation:
    'Displays your songs rotation level at the station level for the selected panel.',
  songComparisonLimit: 'The limit of {{count}} comparison songs is reached',
  songHistory:
    'Displays your song’s station-specific details for the selected week.',
  songsDecreases: 'Displays songs that have decreased in plays.',
  songsIncreases: 'Displays songs that have increased in plays.',
  stationsDecreases: 'Displays stations that have decreased in plays.',
  stationsIncreases: 'Displays stations that have increased in plays.',
  stationsInfo: '(0-1)[{{count}} station];(2-inf)[{{count}} stations];',
  teamInfo: '{{members}} <separator /> {{songs}} <separator /> {{panels}}',
};
