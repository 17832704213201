import formValidateMessages from './formValidateMessages';
import forms from './forms';
import placeholders from './placeholders';
import notifications from './notifications';
import empty from './empty';
import httpErrorCode from './httpErrorCode';
import httpValidationErrorCode from './httpValidationErrorCode';
import prompt from './prompt';

export const en = {
  empty,
  httpErrorCode,
  httpValidationErrorCode,
  formValidateMessages,
  forms,
  placeholders,
  notifications,
  prompt,
};
