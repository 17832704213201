import { ChartDataset, ScatterDataPoint, ChartOptions, Tick } from 'chart.js';
import { THEME } from '../../../constants/theme.const';

export const TOOLTIP_OFFSET = 24;
export const LABEL_BORDER_WIDTH = 4;

export const defaultDataset: Omit<
  ChartDataset<'line', ScatterDataPoint[]>,
  'data'
> = {
  fill: true,
  borderWidth: LABEL_BORDER_WIDTH,
  pointBackgroundColor: THEME.palette.transparent,
  pointBorderColor: THEME.palette.transparent,
};

export const defaultFont = {
  family: 'Montserrat',
  size: 10,
  lineHeight: '14px',
};

export const defaultOptions: ChartOptions<'line'> = {
  interaction: {
    mode: 'nearest',
    intersect: false,
    axis: 'x',
    includeInvisible: false,
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        maxRotation: 0,
        maxTicksLimit: 5,
        font: defaultFont,
        autoSkip: false,
        align: 'inner',
      },
      afterFit: (axis: any) => {
        // (sm) Fir for https://github.com/chartjs/Chart.js/issues/6154
        const { options, _labelSizes: labelSizes } = axis;
        const ticks = axis.getTicks();

        const widest = labelSizes.widest.width;
        const chartWidth = axis.width;
        const { autoSkipPadding, maxTicksLimit } = options.ticks;

        const maxFit = Math.trunc(chartWidth / (widest + autoSkipPadding));
        const willFit = Math.min(maxFit, maxTicksLimit);

        const validLabelIndices: number[] = [];
        validLabelIndices.push(0, ticks.length - 1);

        const numLabels = ticks.length % 2 === 0 ? willFit : willFit - 1;

        const interval = ticks.length / (numLabels - 1);
        for (let i = 1; i < willFit - 1; i += 1) {
          validLabelIndices.push(Math.floor(interval * i));
        }

        ticks.forEach((tick: Tick, index: number) => {
          Object.assign(tick, {
            label: validLabelIndices.includes(index) ? tick.label : null,
          });
        });
        axis.ticks = ticks.filter((i: Tick) => i.label);
      },
    },
    y: {
      offset: true,
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        maxRotation: 0,
        font: defaultFont,
        precision: 100,
      },
    },
  },
  plugins: {
    datalabels: {
      color: THEME.palette.black,
      labels: {
        title: {
          clip: true,
          padding: {
            top: 2,
            bottom: 1,
            left: 6,
            right: 6,
          },
          font: {
            ...defaultFont,
            weight: 400,
          },
          offset: 0,
          align: (context) => {
            const ticks = context.chart.getDatasetMeta(0).xScale.ticks;
            switch (context.dataIndex) {
              case ticks[0].value:
                return 'right';
              case ticks[ticks.length - 1].value:
                return 'left';
              default:
                return 'center';
            }
          },
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  },
};
