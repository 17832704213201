import { FC, Key, PropsWithChildren, useEffect } from 'react';
import { useContextSelector } from 'use-context-selector';
import { SwitchContext, SwitchContextProps } from '../Switch.context';

type SwitchCaseProps = PropsWithChildren<{
  value: Key;
}>;

export const SwitchCase: FC<SwitchCaseProps> = ({ children, value }) => {
  const condition = useContextSelector<
    SwitchContextProps,
    SwitchContextProps['condition']
  >(SwitchContext, ({ condition }) => condition);

  const add = useContextSelector<SwitchContextProps, SwitchContextProps['add']>(
    SwitchContext,
    ({ add }) => add
  );

  useEffect(() => add(value), [add, value]);

  if (condition === value) {
    return <>{children}</>;
  }

  return null;
};
