import { ChartData, ChartDataset, ChartType, ScatterDataPoint } from 'chart.js';
import { cloneDeep, isNaN, merge } from 'lodash';
import { FC, PropsWithChildren, useContext, useState, useMemo } from 'react';
import { Line as ChartJSLine } from 'react-chartjs-2';
import { THEME } from '../../../constants/theme.const';
import { ChartContext } from '../Chart.context';
import { defaultDataset, defaultOptions } from './Range.const';
import { RangeContainer, Root, StyledSlider } from './Range.style';

type RangeProps = PropsWithChildren<{
  type: ChartType;
  className?: string;
}>;

export const Range: FC<RangeProps> = ({ type, className }) => {
  const { data, setRange, color, instance, reverse } = useContext(ChartContext);
  const [range, setRangeState] = useState<[number, number]>([0, data.length]);

  const options = useMemo(
    () =>
      merge(
        {
          scales: {
            y: {
              reverse,
            },
          },
        },
        defaultOptions
      ),
    [reverse]
  );

  const chartDataset = merge<
    ChartDataset<'line', ScatterDataPoint[]>,
    Omit<ChartDataset<'line', ScatterDataPoint[]>, 'data'>
  >(
    {
      borderColor: THEME.palette[color],
      data,
    },
    cloneDeep(defaultDataset)
  );

  const defaultChartData: ChartData<'line'> = {
    datasets: [chartDataset],
    labels: data?.map(({ x }) => x),
  };

  const chartData = defaultChartData;

  const handleOnChange = (value: [number, number]) => {
    setRange({ x: value });
    setRangeState(value);
  };

  const rangeWidth = instance?.chartArea?.right - instance?.chartArea?.left;

  return (
    <Root className={className}>
      <RangeContainer width={isNaN(rangeWidth) ? 0 : rangeWidth}>
        <StyledSlider
          range={{ draggableTrack: true }}
          tooltipVisible={false}
          onChange={handleOnChange}
          min={0}
          max={data.length - 1}
          value={range}
        />
        {type === 'line' && <ChartJSLine data={chartData} options={options} />}
      </RangeContainer>
    </Root>
  );
};
