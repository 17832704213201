import { css } from 'styled-components';

export const DayNightGradient = css`
  background: linear-gradient(
    270deg,
    #012b55 0%,
    #7b9fb9 25%,
    #fbbc90 50%,
    #ff9b57 75%,
    #db6862 100%
  );
`;

export const PistachioGreenGradient = css`
  background: linear-gradient(180deg, #82b955 0%, rgba(130, 185, 85, 0) 100%);
`;

export const AnthraciteGreyGradient = css`
  background: linear-gradient(
    0deg,
    ${({ theme }) => theme.palette.anthraciteGrey} 0%,
    rgba(27, 27, 27, 0) 100%
  );
`;

export const MetropolisGreyRightToLeft = css`
  background: linear-gradient(
    -88.63deg,
    rgba(17, 17, 17, 0) 0.95%,
    ${({ theme }) => theme.palette.metropolisGrey} 55.4%
  );
`;

export const MetropolisGreyLeftToRight = css`
  background: linear-gradient(
    88.63deg,
    rgba(17, 17, 17, 0) 0.95%,
    ${({ theme }) => theme.palette.metropolisGrey} 55.4%
  );
`;
