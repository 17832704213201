import { DTO } from '@spintel/network/app';
import { useNumber, useTranslation } from '@spintel/localization';
import { numberSorter, stringSorter } from '@common/utils';
import { get, set } from 'lodash';
import { useMemo, useState } from 'react';
import { TableGlobalColumnsType, Diff } from '@spintel/uikit';
import { COLUMN_SIZES } from '@common/constants';
import { DEFAULT_SORTING } from './StationPossibleSpace.const';

interface UseColumnsParams {
  order: DTO.IPagedSortOrder;
}

export const useColumns = ({ order }: UseColumnsParams) => {
  const { t } = useTranslation();
  const { f } = useNumber();

  const columns: TableGlobalColumnsType<DTO.IStationPossibleSpaceResponse> = [
    {
      children: [
        {
          dataIndex: 'songName',
          fixed: 'left',
          title: t('forms.song'),
          type: 'column',
          filter: {
            type: 'search',
            placeholder: t('placeholders.song'),
          },
          sorter: stringSorter('songName'),
          width: COLUMN_SIZES.songName,
          ellipsis: true,
        },
      ],
      type: 'group',
      showHeader: false,
    },

    {
      children: [
        {
          dataIndex: 'artistName',
          title: t('forms.artist'),
          type: 'column',
          filter: {
            type: 'search',
            placeholder: t('placeholders.artist'),
          },
          sorter: stringSorter('artistName'),
          width: COLUMN_SIZES.artistName,
          ellipsis: true,
          select: { default: true },
        },
      ],
      type: 'group',
      showHeader: false,
    },
    {
      children: [
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'stationTwRank',
                  title: t('forms.tw'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('stationTwRank', order),
                  width: COLUMN_SIZES.tw,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: {
                    default: true,
                    group: 'stationTwRank',
                    onChange: ({ selection }) => {
                      return set(
                        selection,
                        'stationTwRankChange',
                        get(selection, 'stationTwRank', true)
                      );
                    },
                  },
                },
                {
                  dataIndex: 'stationTwRankChange',
                  title: '+/-',
                  type: 'column',
                  filter: { type: 'range', label: t('forms.twChange') },
                  sorter: numberSorter('stationTwRankChange', order),
                  width: COLUMN_SIZES.changes,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => (
                    <Diff value={value} formatter={(val) => f('f1', val)} />
                  ),
                  select: {
                    default: true,
                    group: 'stationTwRank',
                    disabled: ({ selection }) =>
                      !get(selection, 'stationTwRank'),
                  },
                },
              ],
            },
          ],
        },
      ],
      title: t('forms.stationRank'),
      type: 'group',
      minWidth: COLUMN_SIZES.stationRank,
    },
    {
      children: [
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'stationCwPoints',
                  title: t('forms.cw'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('stationCwPoints', order),
                  width: COLUMN_SIZES.cw,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: {
                    default: true,
                    group: 'stationCwPoints',
                    onChange: ({ selection }) => {
                      return set(
                        selection,
                        'stationCwPointsChange',
                        get(selection, 'stationCwPoints', true)
                      );
                    },
                  },
                },
                {
                  dataIndex: 'stationCwPointsChange',
                  render: (value) => (
                    <Diff value={value} formatter={(val) => f('f1', val)} />
                  ),
                  title: '+/-',
                  type: 'column',
                  filter: { type: 'range', label: t('forms.cwChange') },
                  sorter: numberSorter('stationCwPointsChange', order),
                  width: COLUMN_SIZES.changes,
                  empty: {
                    null: 'dash',
                  },
                  select: {
                    default: true,
                    group: 'stationCwPoints',
                    disabled: ({ selection }) =>
                      !get(selection, 'stationCwPoints'),
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'stationTwPoints',
                  title: t('forms.tw'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('stationTwPoints', order),
                  width: COLUMN_SIZES.tw,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: {
                    default: true,
                    group: 'stationTwPoints',
                    onChange: ({ selection }) => {
                      return set(
                        selection,
                        'stationTwPointsChange',
                        get(selection, 'stationTwPoints', true)
                      );
                    },
                  },
                },
                {
                  dataIndex: 'stationTwPointsChange',
                  render: (value) => (
                    <Diff value={value} formatter={(val) => f('f1', val)} />
                  ),
                  title: '+/-',
                  type: 'column',
                  filter: { type: 'range', label: t('forms.twChange') },
                  sorter: numberSorter('stationTwPointsChange', order),
                  width: COLUMN_SIZES.changes,
                  empty: {
                    null: 'dash',
                  },
                  select: {
                    default: true,
                    group: 'stationTwPoints',
                    disabled: ({ selection }) =>
                      !get(selection, 'stationTwPoints'),
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'stationAllTimePoints',
                  title: t('forms.at'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('stationAllTimePoints', order),
                  width: COLUMN_SIZES.at,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: { default: true },
                },
              ],
            },
          ],
        },
      ],
      title: t('forms.stationPoints'),
      type: 'group',
      minWidth: COLUMN_SIZES.stationPoints,
    },
    {
      children: [
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'stationCwPlays',
                  title: t('forms.cw'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('stationCwPlays', order),
                  width: COLUMN_SIZES.cw,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: {
                    default: true,
                    group: 'stationCwPlays',
                    onChange: ({ selection }) => {
                      return set(
                        selection,
                        'stationCwPlaysChange',
                        get(selection, 'stationCwPlays', true)
                      );
                    },
                  },
                },
                {
                  dataIndex: 'stationCwPlaysChange',
                  render: (value) => (
                    <Diff value={value} formatter={(val) => f('f1', val)} />
                  ),
                  title: '+/-',
                  type: 'column',
                  filter: { type: 'range', label: t('forms.cwChange') },
                  sorter: numberSorter('stationCwPlaysChange', order),
                  width: COLUMN_SIZES.changes,
                  empty: {
                    null: 'dash',
                  },
                  select: {
                    default: true,
                    group: 'stationCwPlays',
                    disabled: ({ selection }) =>
                      !get(selection, 'stationCwPlays'),
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'stationTwPlays',
                  title: t('forms.tw'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('stationTwPlays', order),
                  width: COLUMN_SIZES.tw,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: {
                    default: true,
                    group: 'stationTwPlays',
                    onChange: ({ selection }) => {
                      return set(
                        selection,
                        'stationTwPlaysChange',
                        get(selection, 'stationTwPlays', true)
                      );
                    },
                  },
                },
                {
                  dataIndex: 'stationTwPlaysChange',
                  render: (value) => (
                    <Diff value={value} formatter={(val) => f('f1', val)} />
                  ),
                  title: '+/-',
                  type: 'column',
                  filter: { type: 'range', label: t('forms.twChange') },
                  sorter: numberSorter('stationTwPlaysChange', order),
                  width: COLUMN_SIZES.changes,
                  empty: {
                    null: 'dash',
                  },
                  select: {
                    default: true,
                    group: 'stationTwPlays',
                    disabled: ({ selection }) =>
                      !get(selection, 'stationTwPlays'),
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'stationAllTimePlays',
                  title: t('forms.at'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('stationAllTimePlays', order),
                  width: COLUMN_SIZES.at,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: { default: true },
                },
              ],
            },
          ],
        },
      ],
      title: t('forms.stationPlays'),
      type: 'group',
      minWidth: COLUMN_SIZES.stationPlays,
    },
    {
      children: [
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'cwRank',
                  title: t('forms.cw'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('cwRank', order),
                  width: COLUMN_SIZES.cw,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: {
                    default: true,
                    group: 'cwRank',
                    onChange: ({ selection }) => {
                      return set(
                        selection,
                        'cwRankChange',
                        get(selection, 'cwRank', true)
                      );
                    },
                  },
                },
                {
                  dataIndex: 'cwRankChange',
                  render: (value) => (
                    <Diff value={value} formatter={(val) => f('f1', val)} />
                  ),
                  title: '+/-',
                  type: 'column',
                  filter: { type: 'range', label: t('forms.cwChange') },
                  sorter: numberSorter('cwRankChange', order),
                  width: COLUMN_SIZES.changes,
                  empty: {
                    null: 'dash',
                  },
                  select: {
                    default: true,
                    group: 'cwRank',
                    disabled: ({ selection }) => !get(selection, 'cwRank'),
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'twRank',
                  title: t('forms.tw'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('twRank', order),
                  width: COLUMN_SIZES.tw,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: {
                    default: true,
                    group: 'twRank',
                    onChange: ({ selection }) => {
                      return set(
                        selection,
                        'twRankChange',
                        get(selection, 'twRank', true)
                      );
                    },
                  },
                },
                {
                  dataIndex: 'twRankChange',
                  render: (value) => (
                    <Diff value={value} formatter={(val) => f('f1', val)} />
                  ),
                  title: '+/-',
                  type: 'column',
                  filter: { type: 'range', label: t('forms.twChange') },
                  sorter: numberSorter('twRankChange', order),
                  width: COLUMN_SIZES.changes,
                  empty: {
                    null: 'dash',
                  },
                  select: {
                    default: true,
                    group: 'twRank',
                    disabled: ({ selection }) => !get(selection, 'twRank'),
                  },
                },
              ],
            },
          ],
        },
      ],
      title: t('forms.panelRank'),
      type: 'group',
      minWidth: COLUMN_SIZES.panelRank,
    },
    {
      children: [
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'cwPoints',
                  title: t('forms.cw'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('cwPoints', order),
                  width: COLUMN_SIZES.cw,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: {
                    default: true,
                    group: 'cwPoints',
                    onChange: ({ selection }) => {
                      return set(
                        selection,
                        'cwPointsChange',
                        get(selection, 'cwPoints', true)
                      );
                    },
                  },
                },
                {
                  dataIndex: 'cwPointsChange',
                  render: (value) => (
                    <Diff value={value} formatter={(val) => f('f1', val)} />
                  ),
                  title: '+/-',
                  type: 'column',
                  filter: { type: 'range', label: t('forms.cwChange') },
                  sorter: numberSorter('cwPointsChange', order),
                  width: COLUMN_SIZES.changes,
                  empty: {
                    null: 'dash',
                  },
                  select: {
                    default: true,
                    group: 'cwPoints',
                    disabled: ({ selection }) => !get(selection, 'cwPoints'),
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'twPoints',
                  title: t('forms.tw'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('twPoints', order),
                  width: COLUMN_SIZES.tw,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: {
                    default: true,
                    group: 'twPoints',
                    onChange: ({ selection }) => {
                      return set(
                        selection,
                        'twPointsChange',
                        get(selection, 'twPoints', true)
                      );
                    },
                  },
                },
                {
                  dataIndex: 'twPointsChange',
                  render: (value) => (
                    <Diff value={value} formatter={(val) => f('f1', val)} />
                  ),
                  title: '+/-',
                  type: 'column',
                  filter: { type: 'range', label: t('forms.twChange') },
                  sorter: numberSorter('twPointsChange', order),
                  width: COLUMN_SIZES.changes,
                  empty: {
                    null: 'dash',
                  },
                  select: {
                    default: true,
                    group: 'twPoints',
                    disabled: ({ selection }) => !get(selection, 'twPoints'),
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'allTimePoints',
                  title: t('forms.at'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('allTimePoints', order),
                  width: COLUMN_SIZES.at,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: { default: true },
                },
              ],
            },
          ],
        },
      ],
      title: t('forms.panelPoints'),
      type: 'group',
      minWidth: COLUMN_SIZES.panelPoints,
    },
    {
      children: [
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'cwPlays',
                  title: t('forms.cw'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('cwPlays', order),
                  width: COLUMN_SIZES.cw,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: {
                    default: true,
                    group: 'cwPlays',
                    onChange: ({ selection }) => {
                      return set(
                        selection,
                        'cwPlaysChange',
                        get(selection, 'cwPlays', true)
                      );
                    },
                  },
                },
                {
                  dataIndex: 'cwPlaysChange',
                  render: (value) => (
                    <Diff value={value} formatter={(val) => f('f1', val)} />
                  ),
                  title: '+/-',
                  type: 'column',
                  filter: { type: 'range', label: t('forms.cwChange') },
                  sorter: numberSorter('cwPlaysChange', order),
                  width: COLUMN_SIZES.changes,
                  empty: {
                    null: 'dash',
                  },
                  select: {
                    default: true,
                    group: 'cwPlays',
                    disabled: ({ selection }) => !get(selection, 'cwPlays'),
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'group',
          showHeader: false,
          children: [
            {
              type: 'group',
              showHeader: false,
              children: [
                {
                  dataIndex: 'twPlays',
                  title: t('forms.tw'),
                  type: 'column',
                  filter: { type: 'range' },
                  sorter: numberSorter('twPlays', order),
                  width: COLUMN_SIZES.tw,
                  empty: {
                    null: 'dash',
                  },
                  render: (value) => f('f1', value),
                  select: {
                    default: true,
                    group: 'twPlays',
                    onChange: ({ selection }) => {
                      return set(
                        selection,
                        'twPlaysChange',
                        get(selection, 'twPlays', true)
                      );
                    },
                  },
                },
                {
                  dataIndex: 'twPlaysChange',
                  render: (value) => (
                    <Diff value={value} formatter={(val) => f('f1', val)} />
                  ),
                  title: '+/-',
                  type: 'column',
                  filter: { type: 'range', label: t('forms.twChange') },
                  sorter: numberSorter('twPlaysChange', order),
                  width: COLUMN_SIZES.changes,
                  empty: {
                    null: 'dash',
                  },
                  select: {
                    default: true,
                    group: 'twPlays',
                    disabled: ({ selection }) => !get(selection, 'twPlays'),
                  },
                },
              ],
            },
          ],
        },
      ],
      title: t('forms.panelPlays'),
      type: 'group',
      minWidth: COLUMN_SIZES.panelPlays,
    },
  ];

  return { columns };
};

export const useSorting = () => {
  const [sorting, setSorting] = useState<DTO.IPagedSort>();

  return useMemo(() => {
    return { sorting: sorting || DEFAULT_SORTING, setSorting };
  }, [sorting]);
};
