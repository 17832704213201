import React, { FC, PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  NavigationContext,
  NavigationContextProps,
} from '../../contexts/Navigation.context';

type NavigationProviderProps = PropsWithChildren<{
  defaultUrl: string;
  basename: string;
}> &
  NavigationContextProps;

export const NavigationProvider: FC<NavigationProviderProps> = ({
  role,
  children,
  basename,
  loginUrl,
  defaultUrl,
  protectedFallback = null,
}) => {
  return (
    <NavigationContext.Provider
      value={{
        role,
        loginUrl,
        defaultUrl,
        protectedFallback,
      }}
    >
      <BrowserRouter basename={basename}>{children}</BrowserRouter>
    </NavigationContext.Provider>
  );
};
