import { Key } from 'react';
import { noop } from 'lodash';
import { createContext } from 'use-context-selector';

export interface FormCheckboxSelectionContextProps {
  name: Key | Key[];
  value: Key[];
  onChange: (v: boolean, value: Key) => void;
  onToggle: (v: boolean, values: Key[]) => void;
}

export const FormCheckboxSelectionContext =
  createContext<FormCheckboxSelectionContextProps>({
    name: null,
    value: [],
    onChange: noop,
    onToggle: noop,
  });
