import { toNumber } from 'lodash';
import { UiKitDiffType } from '../../types/uikit';

export const getDiff = (
  value: number | null = null
): { diffValue: number | null; diffType: UiKitDiffType } => {
  const diffValue = (() => {
    if (value === null) return value;
    return toNumber(value.toString().replace(/-/g, ''));
  })();

  const diffType = (() => {
    if (value === null) return UiKitDiffType.neutral;
    if (value === 0) return UiKitDiffType.neutral;
    if (value.toString().includes('-')) return UiKitDiffType.negative;
    return UiKitDiffType.positive;
  })();

  return { diffValue, diffType };
};
