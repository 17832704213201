import {
  PropsWithChildren,
  ReactElement,
  useContext,
  useMemo,
  useRef,
} from 'react';
import MapGl, { MapRef } from 'react-map-gl';
import { noop } from 'lodash';
import { UiKitProviderContext } from '../../contexts/UiKitProvider.context';
import { Loader, Root } from './Map.style';
import { MapCircle } from './MapCircle/MapCircle';
import { MapPolygon } from './MapPolygon/MapPolygon';
import { MapZoom } from './MapZoom/MapZoom';
import { MapPosition } from './Map.types';
import {
  DEFAULT_MAP_CENTER,
  SOURCE_ID_PREFIX_CIRCLE,
  SOURCE_ID_PREFIX_POLYGON,
} from './Map.const';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MapCircles } from './MapCircles/MapCircles';
import { useMapLayers, useMapLoading } from './Map.hooks';
import { MapPolygons } from './MapPolygons/MapPolygons';
import { MapGeo } from './MapGeo/MapGeo';
import { MapContext } from './Map.context';

type MapProps = PropsWithChildren<{
  className?: string;
  center?: MapPosition;
  zoom?: number;
  zoomOnScroll?: boolean;
  onSourceLoaded?: () => void;
  loading?: boolean;
  id: string;
}>;

interface MapComponent {
  (props: MapProps): ReactElement<any, any> | null;
  Circle: typeof MapCircle;
  Circles: typeof MapCircles;
  Polygon: typeof MapPolygon;
  Polygons: typeof MapPolygons;
  Zoom: typeof MapZoom;
  Geo: typeof MapGeo;
}

export const Map: MapComponent = ({
  className,
  center: { lat, lng } = DEFAULT_MAP_CENTER,
  zoom,
  zoomOnScroll = false,
  children,
  id,
  loading: loadingData,
  onSourceLoaded = noop,
}) => {
  const map = useRef<MapRef>(null);
  const { mapbox = {} } = useContext(UiKitProviderContext);

  const mapStyle = (() => {
    const { styleId, tenantId, token } = mapbox;
    if (styleId && tenantId && token) {
      return `mapbox://styles/${tenantId}/${styleId}`;
    }
    return 'mapbox://styles/mapbox/light-v9';
  })();

  const circlesId = useMemo(() => `${SOURCE_ID_PREFIX_CIRCLE}-${id}`, [id]);
  const polygonsId = useMemo(() => `${SOURCE_ID_PREFIX_POLYGON}-${id}`, [id]);

  const { interactiveLayerIds, sourceIds } = useMapLayers({
    children,
    circlesId,
    polygonsId,
  });

  const { loading } = useMapLoading({
    loadingData,
    sourceIds,
    onSourceLoaded,
    map: map?.current,
  });

  return (
    <Loader spinning={loading}>
      <Root className={className}>
        <MapContext.Provider
          value={{
            circlesId,
            polygonsId,
            loading,
          }}
        >
          <MapGl
            ref={map}
            initialViewState={{
              latitude: lat,
              longitude: lng,
              zoom,
            }}
            mapboxAccessToken={mapbox?.token}
            mapStyle={mapStyle}
            attributionControl={false}
            scrollZoom={zoomOnScroll}
            cursor={'default'}
            interactiveLayerIds={interactiveLayerIds}
          >
            {children}
          </MapGl>
        </MapContext.Provider>
      </Root>
    </Loader>
  );
};

Map.Circle = MapCircle;
Map.Circles = MapCircles;
Map.Polygon = MapPolygon;
Map.Polygons = MapPolygons;
Map.Zoom = MapZoom;
Map.Geo = MapGeo;
