import { SelectProps as AntdSelectProps } from 'antd/lib/select';
import { IconSize } from '../Icon/Icon.types';
import { SelectOptionProps, SelectSize } from './Select.types';

type AntdSelectSize = AntdSelectProps['size'];

export const DEFAULT_SELECT_OPTION_PROPS: SelectOptionProps = {
  label: 'label',
  value: 'value',
  icon: 'icon',
  status: 'status',
};

export const SELECT_SIZE_MAP: Record<SelectSize, AntdSelectSize> = {
  sm: 'small',
  md: 'middle',
  lg: 'large',
};

export const SUFFIX_ICON_SIZE_MAP: Record<SelectSize, IconSize> = {
  sm: 'sm',
  md: 'sm',
  lg: 'md',
};
