import styled from 'styled-components';
import { Typography } from '../../Typography/Typography';
import { Button } from '../../Button/Button';

const { Body3 } = Typography;

export const Root = styled('div')(({ theme: { spacing, palette } }) => ({
  paddingTop: spacing(4),
  paddingBottom: spacing(4),
  paddingLeft: spacing(6),
  paddingRight: spacing(6),
  background: palette.icarusGrey,
  display: 'flex',
  gap: spacing(6),
  flexWrap: 'nowrap',
}));

export const Label = styled(Body3)`
  flex: 0 0 auto;
  line-height: ${({ theme: { spacing } }) => spacing(6)};
  margin-right: ${({ theme: { spacing } }) => spacing(2)};
`;

export const Content = styled('div')`
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme: { spacing } }) => spacing(3)};
`;

export const Clear = styled(Button)`
  margin-top: -${({ theme: { spacing } }) => spacing(1)};
`;
