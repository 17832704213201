import { FC, PropsWithChildren } from 'react';
import { noop } from 'lodash';
import { RootCheckbox } from '../Input/Input.style';
import { Help } from './Checkbox.style';

type CheckboxProps = PropsWithChildren<{
  value: boolean;
  indeterminate?: boolean;
  onChange?: (v: boolean) => void;
  className?: string;
  help?: string;
  disabled?: boolean;
}>;

export const Checkbox: FC<CheckboxProps> = ({
  value,
  indeterminate,
  children,
  className,
  help,
  disabled,
  onChange = noop,
}) => {
  return (
    <RootCheckbox
      checked={value}
      disabled={disabled}
      indeterminate={indeterminate}
      className={className}
      onChange={({ target: { checked } }) => onChange(checked)}
    >
      {children}
      {help && <Help>{help}</Help>}
    </RootCheckbox>
  );
};
