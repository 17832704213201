import { FC, PropsWithChildren, CSSProperties } from 'react';
import { Root } from './DrawerFooter.style';

type DrawerFooterProps = PropsWithChildren<{
  justify?: CSSProperties['justifyContent'];
}>;

export const DrawerFooter: FC<DrawerFooterProps> = ({
  children = null,
  justify = 'flex-end',
}) => {
  return <Root justify={justify}>{children}</Root>;
};
