import styled from 'styled-components';
import AntdCheckbox from 'antd/lib/checkbox';
import { RootCheckbox } from '../../Input/Input.style';
import { FormCheckboxGroupDirection } from './FormCheckboxGroup.types';

const { Group } = AntdCheckbox;

export const Option = styled(RootCheckbox)`
  .ant-checkbox + span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
`;

export const Root = styled(Group)<{
  direction: FormCheckboxGroupDirection;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  label {
    &.ant-checkbox-wrapper {
      margin-left: ${({ direction, theme: { spacing } }) =>
        direction === 'column' ? 0 : spacing(2)};
      margin-bottom: ${({ direction, theme: { spacing } }) =>
        direction === 'column' ? spacing(2) : 0};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
