import { useCallback, useContext } from 'react';
import { get } from 'lodash';
import moment, { MomentInput } from 'moment';
import { LocalizationContext } from '../../contexts/Localization.context';
import { UseDateLongDateFormatSpec } from './useDate.types';
import { USE_DATE_LONG_DATE_FORMAT } from './useDate.const';

export const useDate = () => {
  const { locale } = useContext(LocalizationContext);

  const format = useCallback(
    (value: MomentInput, pattern: keyof UseDateLongDateFormatSpec) => {
      return moment
        .utc(value)
        .format(get(USE_DATE_LONG_DATE_FORMAT, [locale, pattern], pattern));
    },
    [locale]
  );

  return { format };
};
