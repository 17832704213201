import { createGlobalStyle } from 'styled-components';

export const Global = createGlobalStyle`
  ::-webkit-scrollbar {
    width: ${({ theme: { spacing } }) => spacing(4)};
    height: ${({ theme: { spacing } }) => spacing(4)};
  }
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.palette.transparent};
    transition: all 300 ease;
    &:hover {
      background-color: ${({ theme }) => theme.palette.asphaltGrey}30;
    }
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.palette.asphaltGrey};
    background-clip: padding-box;
    border: 5px solid rgba(0, 0, 0, 0);
    border-radius: ${({ theme: { spacing } }) => spacing(2)};
    box-shadow: inset 0px 0px 0px 1px ${({ theme }) => theme.palette.wolfGrey};
    &:vertical {
      min-height: ${({ theme: { spacing } }) => spacing(8.5)};
    }
    &:horizontal {
      min-width: ${({ theme: { spacing } }) => spacing(8.5)};
    }
  }
  ::-webkit-scrollbar-corner {
    background-color: ${({ theme }) => theme.palette.transparent};
  }
`;
