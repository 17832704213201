import { FC, PropsWithChildren, useCallback } from 'react';
import { useDrawer } from '../../hooks/useDrawer/useDrawer.hook';
import { Icon } from '../Icon/Icon';
import { UiKitBaseSize } from '../../types/uikit';
import { Tooltip } from '../Tooltip/Tooltip';
import { Typography } from '../Typography/Typography';
import { DrawerClose } from './DrawerClose/DrawerClose';
import { DrawerFooter } from './DrawerFooter/DrawerFooter';
import { DrawerContent } from './DrawerContent/DrawerContent';
import { Root, Header, Subtitle, TitleLine } from './Drawer.style';
import { DrawerContext } from './Drawer.context';
import { getDrawerWidth } from './Drawer.utils';
import { DrawerVariant, DrawerAppendTo } from './Drawer.types';

const { Heading3 } = Typography;

type DrawerProps = PropsWithChildren<{
  name: string;
  variant?: DrawerVariant;
  title?: string;
  subtitle?: string;
  hint?: string;
  size?: UiKitBaseSize;
  onBeforeClose?: () => boolean;
  appendTo?: DrawerAppendTo;
  transparent?: boolean;
}>;

interface DrawerComponent extends FC<DrawerProps> {
  Close: typeof DrawerClose;
  Footer: typeof DrawerFooter;
  Content: typeof DrawerContent;
}

export const Drawer: DrawerComponent = ({
  name,
  hint,
  appendTo,
  children,
  size = 'xs',
  variant = 'default',
  title: initialTitle,
  transparent = false,
  subtitle: initialSubtitle,
  onBeforeClose = () => true,
}) => {
  const [{ isOpen, params }, toggle] = useDrawer(name);

  const defaultTitle = (() => {
    const tooltip = hint && (
      <Tooltip content={hint} placement="bottom">
        <span>
          <Icon name="info" size="sm" color="londonGrey" />
        </span>
      </Tooltip>
    );
    const title = initialTitle && <Heading3>{initialTitle}</Heading3>;
    return title ? (
      <Header>
        <TitleLine>
          {title}
          {tooltip}
        </TitleLine>
        {initialSubtitle && <Subtitle>{initialSubtitle}</Subtitle>}
      </Header>
    ) : undefined;
  })();

  const onClose = useCallback(() => {
    if (!onBeforeClose()) return;
    toggle(false, params);
  }, [toggle, onBeforeClose, params]);

  return (
    <DrawerContext.Provider value={{ name, onClose }}>
      <Root
        destroyOnClose
        footer={null}
        visible={isOpen}
        transparent={transparent}
        width={getDrawerWidth({ size, appendTo })}
        title={variant === 'default' && defaultTitle}
        closeIcon={<Icon name="close" />}
        onClose={onClose}
        getContainer={appendTo}
        style={appendTo && { position: 'absolute' }}
      >
        {children}
      </Root>
    </DrawerContext.Provider>
  );
};

Drawer.Close = DrawerClose;
Drawer.Footer = DrawerFooter;
Drawer.Content = DrawerContent;
