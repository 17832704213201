import styled from 'styled-components';

export const Root = styled('div')<{ height?: number }>`
  overflow: auto;
  overflow-x: hidden;
  .infinite-scroll-component__outerdiv {
    max-width: 100%;
    .infinite-scroll-component {
      overflow-x: hidden !important;
      overflow-y: auto !important;
    }
  }
`;

export const Loader = styled('div')`
  padding: ${({ theme: { spacing } }) => spacing(4)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
