import styled from 'styled-components';
import { Root as FormItemRoot } from '../FormItem/FormItem.style';

export const FormItem = styled(FormItemRoot)`
  margin-bottom: ${({ theme: { spacing } }) => spacing(3)};
  .ant-form-item-label {
    label {
      pointer-events: none;
      display: grid;
      grid-template-columns: auto min-content;
    }
  }
  ${FormItemRoot} {
    margin-bottom: ${({ theme: { spacing } }) => spacing(3)};
  }
`;
