import styled, { css } from 'styled-components';
import { Icon as UiKitIcon } from '../../Icon/Icon';

export const ActionHoverMixin = css`
  &:hover {
    color: ${({ theme }) => theme.palette.white};
  }
`;

export const Root = styled('span')(({ theme: { spacing } }) => ({
  width: spacing(6),
  height: spacing(6),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Icon = styled(UiKitIcon)<{ disabled: boolean }>`
  color: ${({ theme }) => theme.palette.londonGrey};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s;
  ${({ disabled }) => !disabled && ActionHoverMixin}
`;
