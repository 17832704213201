import styled from 'styled-components';

export const Root = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme: { spacing } }) => spacing(6)};
  align-items: center;
`;

export const Section = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(3),
}));
