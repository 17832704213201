import { useContext } from 'react';
import { UseMutation } from '@shared/types';
import { useCommonMutation } from '@shared/hooks';
import { IErrorResponse } from '../../__generated__/data-contracts';
import {
  HttpProviderContext,
  HttpServices,
} from '../../contexts/HttpProvider.context';

type CallbackReturn = (...args: any) => any;

export function useMutation<
  Req extends any[] = never[],
  Res = unknown,
  Err extends object = IErrorResponse
>(
  callback: (context: HttpServices) => CallbackReturn
): UseMutation<Req, Res, Err> {
  const { services } = useContext(HttpProviderContext);

  return useCommonMutation<HttpServices, Req, Res, Err>(services, callback);
}
