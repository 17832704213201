import { FC, Key, useContext, PropsWithChildren } from 'react';
import { ListViewContext } from '../ListView.context';
import { IconName } from '../../Icon/__generated__/icon.types';
import { Icon } from '../../Icon/Icon';
import { Root, Left, Right, Label, Description } from './ListViewOption.style';

interface ListViewOptionProps extends PropsWithChildren {
  value: Key;
  label?: string;
  description?: string;
  icon?: IconName;
}

export const ListViewOption: FC<ListViewOptionProps> = ({
  value,
  icon,
  label,
  description,
}) => {
  const { value: activeValue, onChange } = useContext(ListViewContext);

  const active = activeValue === value;

  return (
    <Root active={active} onClick={() => onChange(value)}>
      {icon && (
        <Left>
          <Icon name={icon} size="md" />
        </Left>
      )}
      <Right>
        <Label active={active}>{label}</Label>
        {description && (
          <Description active={active}>{description}</Description>
        )}
      </Right>
    </Root>
  );
};
