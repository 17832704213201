import {
  FC,
  useRef,
  MutableRefObject,
  useCallback,
  useLayoutEffect,
} from 'react';
import { UiKitBaseSize } from '../../types/uikit';
import { useDrawer } from '../../hooks/useDrawer/useDrawer.hook';
import { DrawerVariant, DrawerAppendTo } from './Drawer.types';
import { Drawer } from './Drawer';

interface WithDrawerParams {
  size?: UiKitBaseSize;
  variant?: DrawerVariant;
}

// @ts-ignore
interface DrawerCommonProps<Props extends object, Params> extends Props {
  name: string;
  title?: (params: Params) => string;
  subtitle?: (params: Params) => string;
  hint?: (params: Params) => string;
  appendTo?: DrawerAppendTo;
  transparent?: boolean;
  bodyScroll?: boolean;
}

// @ts-ignore
interface DrawerComponentParams<Props extends object, Params> extends Props {
  name: string;
  params: Params;
  drawer: MutableRefObject<{
    onBeforeClose: () => boolean;
  }>;
}

export const withDrawer =
  <Props extends object = {}, Params extends object = {}>({
    size = 'xs',
    variant = 'default',
  }: WithDrawerParams = {}) =>
  (Component: FC<DrawerComponentParams<Props, Params>>) =>
  ({
    name,
    appendTo,
    transparent,
    title = () => undefined,
    subtitle = () => undefined,
    hint = () => undefined,
    bodyScroll = false,
    ...props
  }: DrawerCommonProps<Props, Params>) => {
    const [{ params }] = useDrawer<Params>(name);

    const drawer = useRef({
      onBeforeClose: () => true,
    });

    const handleOnBeforeClose = useCallback(() => {
      if (bodyScroll) {
        document.body.style.removeProperty('overflow');
      }
      return drawer.current.onBeforeClose();
    }, [bodyScroll]);

    useLayoutEffect(() => {
      if (bodyScroll) {
        document.body.style.overflow = 'auto';
      }
    }, [bodyScroll]);

    return (
      <Drawer
        size={size}
        name={name}
        variant={variant}
        appendTo={appendTo}
        hint={hint(params)}
        title={title(params)}
        transparent={transparent}
        subtitle={subtitle(params)}
        onBeforeClose={handleOnBeforeClose}
      >
        <Component name={name} drawer={drawer} params={params} {...props} />
      </Drawer>
    );
  };
