import { FC, PropsWithChildren, useEffect, useReducer } from 'react';
import { createPortal } from 'react-dom';

export interface TooltipContainerProps extends PropsWithChildren {
  id: string;
}

export const TooltipContainer: FC<TooltipContainerProps> = ({
  id,
  children,
}) => {
  const forceUpdate = useReducer((x) => x + 1, 0)[1];

  useEffect(() => {
    const container = document.createElement('div');
    container.id = id;
    container.style.display = 'none';
    document.body.appendChild(container);
    forceUpdate();

    return () => {
      document.body.removeChild(container);
    };
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const container = document.getElementById(id);

  return container ? createPortal(children, container) : null;
};
