import { UserRole } from '../constants/user.const';
import { RoutePath } from '../constants/route-path.const';

export const USER_ROLE_DEFAULT_URL: Record<UserRole, string> = {
  [UserRole.Member]: RoutePath.MY_SONGS,
  [UserRole.AccountManager]: RoutePath.MY_SONGS,
  [UserRole.SuperAdmin]: RoutePath.MY_SONGS,
  [UserRole.TeamManager]: RoutePath.MY_SONGS,
};

export const SUPPORT_URL = 'support@spintel.io';
