import {
  forwardRef,
  useImperativeHandle,
  useRef,
  ForwardRefExoticComponent,
} from 'react';
import { Icon } from '../Icon/Icon';
import { Root, CloseIcon } from './Alert.style';
import { AlertVariant } from './Alert.types';
import { Snackbar } from './AlertSnackbar/AlertSnackbar';
import SnackbarObservable, {
  AlertItem,
} from './AlertSnackbar/AlertSnackbar.services';

export interface AlertProps {
  withIcon?: boolean;
  variant?: AlertVariant;
  message: React.ReactNode;
  closable?: boolean;
  className?: string;
  onClose?: () => void;
  afterClose?: () => void;
}

export interface AlertRefType {
  close: () => void;
}

export type AlertShow = (
  alert: Omit<AlertItem, 'id' | 'variant'>,
  variant: AlertVariant,
  timeout?: Parameters<typeof setTimeout>[1],
  reset?: boolean
) => void | ReturnType<typeof setTimeout>;

export type AlertAction = (
  alert: Omit<AlertItem, 'id' | 'variant'>,
  timeout?: Parameters<typeof setTimeout>[1]
) => void | ReturnType<typeof setTimeout>;

export interface AlertType
  extends ForwardRefExoticComponent<
    AlertProps & React.RefAttributes<AlertRefType>
  > {
  Snackbar?: typeof Snackbar;
  _show?: AlertShow;
  error?: AlertAction;
  clear?: () => void;
}

export const Alert: AlertType = forwardRef<AlertRefType, AlertProps>(
  function Alert(
    {
      message,
      withIcon,
      closable,
      className,
      variant = 'info',
      onClose,
      afterClose,
    },
    ref
  ) {
    const closeIcon = useRef<HTMLSpanElement>(null);

    useImperativeHandle(ref, () => ({
      close: () => {
        if (closeIcon.current) {
          closeIcon.current.click();
        }
      },
    }));

    return (
      <Root
        className={className}
        icon={<Icon name="warning" />}
        closeIcon={
          <CloseIcon ref={closeIcon} variant={variant} name="close" size="sm" />
        }
        onClose={onClose}
        afterClose={afterClose}
        type={variant}
        showIcon={withIcon}
        closable
        hiddenCloseIcon={!closable}
        description={message}
      />
    );
  }
);

Alert.Snackbar = Snackbar;

Alert._show = (alert, variant, timeout, reset) => {
  if (timeout) {
    return setTimeout(
      () => SnackbarObservable.add({ ...alert, variant }, reset),
      timeout
    );
  }

  return SnackbarObservable.add({ ...alert, variant }, reset);
};

Alert.error = (alert, timeout) => {
  Alert._show(alert, 'error', timeout, true);
};

Alert.clear = () => {
  SnackbarObservable.reset();
};
