import { Dispatch, SetStateAction } from 'react';
import { createContext } from 'use-context-selector';
import { noop } from 'lodash';
import { CatalogState } from './Catalog.types';

export interface CatalogContextProps {
  total: number;
  width?: number;
  loading: boolean;
  setState: Dispatch<SetStateAction<CatalogState>>;
}

export const CatalogContext = createContext<CatalogContextProps>({
  total: 0,
  loading: false,
  setState: noop,
});
