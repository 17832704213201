import { lazy } from 'react';
import { RoutePropsWithChildren } from '@spintel/navigation';
import { RoutePath } from '@common/constants';

const PanelCharts = lazy(() =>
  import('./PanelCharts/PanelCharts').then((m) => ({ default: m.PanelCharts }))
);

const PanelChartsChart = lazy(() =>
  import('./PanelCharts/Chart/Chart').then((m) => ({ default: m.Chart }))
);

export const ROUTES: RoutePropsWithChildren[] = [
  {
    childRoutes: [
      {
        element: <PanelChartsChart />,
        index: true,
      },
    ],
    element: <PanelCharts />,
    path: RoutePath.PANEL_CHARTS,
  },
];
