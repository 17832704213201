import styled from 'styled-components';
import { Typography } from '../../Typography/Typography';

const { Body3 } = Typography;

export const Root = styled('div')<{ bold: boolean; active: boolean }>(
  ({ active, theme: { spacing, palette } }) => ({
    display: 'flex',
    width: spacing(6),
    height: spacing(6),
    borderRadius: spacing(3),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: active && palette.spintelGreen,
  })
);

export const Text = styled(Body3)<{ active: boolean }>(({ active, theme }) => ({
  color: active ? theme.palette.metropolisGrey : theme.palette.white,
  opacity: active && '1 !important',
}));
