import { FC } from 'react';
import { useTranslation } from '@spintel/localization';
import { Root, Item } from './StationSubtitle.styles';

interface StationSubtitleProps {
  city?: string;
  state?: string;
  owner?: string;
}

export const StationSubtitle: FC<{ data: StationSubtitleProps }> = ({
  data: { city, state, owner },
}) => {
  const { t } = useTranslation();

  const getStationAddress = ({
    city,
    state,
  }: Pick<StationSubtitleProps, 'city' | 'state'>) => {
    if (city && state) {
      return t('forms.stateNameCity', {
        city,
        stateName: state,
      });
    }

    return city || state;
  };

  const list = [owner, getStationAddress({ city, state })].filter((v) => v);

  return (
    <Root>
      {list.map((v) => (
        <Item key={v}>{v}</Item>
      ))}
    </Root>
  );
};
