import { castArray, isEqual } from 'lodash';
import {
  TableColumnsType,
  TableColumnType,
  TableColumnDataIndex,
} from '../../Table.types';
import { TableFilterRangeForm } from '../../TableFilters/TableFilterRange/TableFilterRange.types';
import { TableFilterRangeComparator } from '../../TableFilters/TableFilterRange/TableFilterRange.const';

const getColumnsTreeMatch = (
  columns: TableColumnsType<object>,
  dataIndex: string | string[]
): TableColumnsType<object> => {
  return columns.reduce((res, props) => {
    switch (props.type) {
      case 'group':
        const children = getColumnsTreeMatch(props.children, dataIndex);
        return !!children.length ? [...res, { ...props, children }] : res;
      case 'column':
        return (() => {
          const value = castArray(props.dataIndex);
          const current = castArray(dataIndex);

          if (isEqual(value, current)) return [...res, props];
          return res;
        })();
      default:
        return res;
    }
  }, []);
};

const getColumnTreeTitles = (columns: TableColumnsType<object>): string[] => {
  return columns.reduce((res, props) => {
    switch (props.type) {
      case 'group':
        return [...res, props.title, ...getColumnTreeTitles(props.children)];
      case 'column':
        return [props.filter.label || props.title];
      default:
        return res;
    }
  }, []);
};

export const findColumnByDataIndex = (
  columns: TableColumnsType<object>,
  dataIndex: TableColumnDataIndex,
  initial: TableColumnType<object> = null
): TableColumnType<object> => {
  return columns.reduce((res: TableColumnType<object>, props) => {
    switch (props.type) {
      case 'group':
        return findColumnByDataIndex(props.children, dataIndex, res);
      case 'column':
        return isEqual(props.dataIndex, dataIndex) ? props : res;
      default:
        return res;
    }
  }, initial);
};

export const getTableFiltersHeaderTagTitle = (
  columns: TableColumnsType<object>,
  dataIndex: TableColumnDataIndex
): string => {
  const tree = getColumnsTreeMatch(columns, dataIndex);
  const titles = getColumnTreeTitles(tree);
  return titles.filter((v) => !!v).join(' / ');
};

export const getTableFiltersHeaderTagRangeValue = ({
  comparator,
  value,
}: TableFilterRangeForm) => {
  if (comparator === TableFilterRangeComparator.EQ) {
    return `=${value.eq}`;
  }
  if (comparator === TableFilterRangeComparator.GT) {
    return `>${value.gt}`;
  }
  if (comparator === TableFilterRangeComparator.LT) {
    return `<${value.lt}`;
  }
  if (comparator === TableFilterRangeComparator.BT) {
    return (
      <>
        {value.gt}&ndash;{value.lt}
      </>
    );
  }
  return '';
};
