import { useMemo } from 'react';
import { useTranslation } from '@spintel/localization';
import { RoutePath } from '@common/constants';
import { NavItem } from './Nav.types';

export const useNavConfig = (): NavItem[] => {
  const { t } = useTranslation();

  return useMemo<NavItem[]>(
    () => [
      {
        icon: 'songs',
        url: RoutePath.MY_SONGS,
        label: t('navigation.mySongs'),
      },
      {
        icon: 'song',
        url: RoutePath.SONG,
        label: t('navigation.songOverview'),
      },
      {
        icon: 'chart',
        url: RoutePath.PANEL_CHARTS,
        label: t('navigation.panelCharts'),
      },
      {
        icon: 'monitor',
        url: RoutePath.CHART_MONITOR,
        label: t('navigation.chartMonitor'),
      },
      {
        icon: 'radiostation',
        url: RoutePath.STATIONS,
        label: t('navigation.stations'),
      },
      {
        icon: 'owner',
        url: RoutePath.OWNERS,
        label: t('navigation.stationOwners'),
      },
      {
        icon: 'map',
        url: RoutePath.ROTATION_MAP,
        label: t('navigation.rotationGrid'),
      },
      {
        icon: 'adds',
        url: RoutePath.STATION_AVERAGE_ADDS,
        label: t('navigation.averageAdds'),
      },
      {
        icon: 'panels',
        url: RoutePath.PANELS,
        label: t('navigation.panels'),
      },
    ],
    [t]
  );
};
