import { InputProps } from 'antd/lib/input';
import { IconSize } from '../Icon/Icon.types';
import { SearchSize } from './Search.type';

export const SEARCH_SIZE_MAP: Record<SearchSize, InputProps['size']> = {
  md: 'middle',
  lg: 'large',
};

export const ICON_SIZE_MAP: Record<SearchSize, IconSize> = {
  md: 'sm',
  lg: 'md',
};
