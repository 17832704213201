import { useState } from 'react';
import { noop, get } from 'lodash';
import { useUpdateEffect } from 'react-use';
import { SegmentedValue } from '../Segmented.types';
import { SegmentedComponent } from '../Segmented.types';
import { Status } from '../../../styles/Status.style';
import { Root, Option } from './SegmentedSplit.style';

export const SegmentedSplit: SegmentedComponent = ({
  value: initialValue,
  options,
  disabled,
  size = 'md',
  layout = 'horizontal',
  onChange = noop,
  className,
  darken = false,
  wrap = false,
}) => {
  const defaultValue = get(options, ['0', 'value']);

  const [value, setValue] = useState<SegmentedValue>(
    initialValue || defaultValue
  );

  const handleOnChange = (v: SegmentedValue) => {
    setValue(v);
    onChange(v);
  };

  useUpdateEffect(() => {
    if (!Array.isArray(initialValue)) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <Root className={className} layout={layout} wrap={wrap}>
      {options.map(({ value: v, label, status }) => {
        return (
          <Option
            key={v}
            size={size}
            darken={darken}
            disabled={disabled}
            active={v === value}
            withStatus={!!status}
            variant={v === value ? 'secondary' : 'tertiary'}
            onClick={() => handleOnChange(v)}
          >
            {!!status && <Status status={status} size={size} />}
            {label}
          </Option>
        );
      })}
    </Root>
  );
};
