import { useEffect, Dispatch, SetStateAction, useContext } from 'react';
import { useLocalStorage } from 'react-use';
import { IJwtTokenResponseDto } from '../../__generated__/data-contracts';
import { HttpProviderContext } from '../../contexts/HttpProvider.context';
import { getDecodedJwt } from '../../utils/getDecodedJwt/getDecodedJwt.utils';
import { calcRefreshTimeout } from './useAuthToken.utils';

type UseAuthTokenReturnType = [
  IJwtTokenResponseDto & {
    refreshTokenInMs: number;
    inactivityTimeout: number;
  },
  Dispatch<SetStateAction<IJwtTokenResponseDto>>
];

export const useAuthToken = (): UseAuthTokenReturnType => {
  const [, setCachedToken] = useLocalStorage<IJwtTokenResponseDto>('token', {});
  const { token, setToken } = useContext(HttpProviderContext);

  const decodedJwt = getDecodedJwt(token?.token);
  const refreshTokenInMs = calcRefreshTimeout(decodedJwt);
  const { inactivityTimeout } = decodedJwt;

  useEffect(() => setCachedToken(token), [token, setCachedToken]);

  return [{ ...token, inactivityTimeout, refreshTokenInMs }, setToken];
};
