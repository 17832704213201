import { ChartOptions, ChartDataset, ScatterDataPoint } from 'chart.js';
import { THEME } from '../../../constants/theme.const';

export const defaultOptions: ChartOptions<'line'> = {
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};

export const defaultDataset: Omit<
  ChartDataset<'line', ScatterDataPoint[]>,
  'data'
> = {
  borderWidth: 4,
  pointBackgroundColor: THEME.palette.transparent,
  pointBorderColor: THEME.palette.transparent,
};
