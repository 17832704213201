import {
  ReactElement,
  useState,
  ReactNode,
  useCallback,
  PropsWithChildren,
} from 'react';
import { noop, get } from 'lodash';
import { useUpdateEffect } from 'react-use';
import { CardProps as AntdCardProps } from 'antd/lib/card';
import { Skeleton } from '../Skeleton/Skeleton';
import { TabCardTab, TabCardTabProps } from './TabCardTab/TabCardTab';
import { CardFooter } from './TabCardFooter/TabCardFooter';
import { CardContent } from './TabCardBody/TabCardContent';
import { Root } from './TabCard.style';
import { CardContext } from './TabCard.context';
import { CardPartitions } from './TabCard.types';

export type TabCardProps = PropsWithChildren<{
  tabs: TabCardTabProps[];
  activeTab?: string;
  onChange?: AntdCardProps['onTabChange'];
  className?: string;
  gutter?: number[];
  stretch?: boolean;
  loading?: boolean;
}>;

export interface TabCardComponent {
  (props: TabCardProps): ReactElement<any, any> | null;
  Footer: typeof CardFooter;
  Content: typeof CardContent;
}

export const TabCard: TabCardComponent = ({
  gutter,
  stretch,
  children,
  className,
  loading = false,
  onChange = noop,
  tabs: initialTabs,
  activeTab: initialActiveTab,
}) => {
  const [activeTab, setActiveTab] = useState(
    initialActiveTab || get(initialTabs, ['0', 'id'])
  );

  const [{ footer }, setPartitionState] = useState<CardPartitions>({});

  const total = initialTabs.reduce((res, { value = 0 }) => {
    return res + value;
  }, 0);

  const tabs: AntdCardProps['tabList'] = (() => {
    if (!loading) {
      return initialTabs.map(({ id, ...props }) => {
        return {
          key: `${id}`,
          tab: <TabCardTab id={id} total={total} {...props} />,
        };
      });
    }
    return [
      {
        key: 'skeleton',
        tab: <Skeleton loading={loading} variant="head" />,
      },
    ];
  })();

  const setPartition = useCallback(
    (key: keyof CardPartitions, value: ReactNode) => {
      setPartitionState((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const handleOnChange = (key: string) => {
    setActiveTab(key);
    onChange(key);
  };

  useUpdateEffect(() => setActiveTab(initialActiveTab), [initialActiveTab]);

  return (
    <CardContext.Provider value={{ activeTab, setPartition }}>
      <Root
        progress={loading}
        stretch={stretch}
        gutter={gutter}
        activeTabKey={`${activeTab}`}
        onTabChange={handleOnChange}
        barTabCount={tabs.length}
        bordered={false}
        tabList={tabs}
        actions={footer && [footer]}
        className={className}
      >
        {children}
      </Root>
    </CardContext.Provider>
  );
};

TabCard.Footer = CardFooter;
TabCard.Content = CardContent;
