import {
  Chart,
  ChartArea,
  ChartEvent,
  Interaction,
  InteractionItem,
  ScriptableContext,
} from 'chart.js';
import { DefaultTheme } from 'styled-components';
import { THEME } from '../../constants/theme.const';
import { getTransparentColorHex } from '../../utils/getTransparentHex/getTransparentHex.utils';

export function getCanvasTransparentGradient(
  color: keyof DefaultTheme['palette'],
  context: ScriptableContext<any>
): CanvasGradient | undefined {
  const { ctx, chartArea } = context.chart;
  if (chartArea) {
    const { top, bottom } = chartArea;
    const gradient = ctx.createLinearGradient(0, top, 0, bottom);
    gradient.addColorStop(
      0,
      getTransparentColorHex(THEME.palette[color], '40%')
    );
    gradient.addColorStop(
      1,
      getTransparentColorHex(THEME.palette[color], '0%')
    );
    return gradient;
  }
}

const useOffsetPos = (
  x: number,
  y: number,
  target: HTMLElement | EventTarget
) => (x > 0 || y > 0) && (!target || !(target as HTMLElement).shadowRoot);

function getCanvasPosition(
  e: Event | TouchEvent | MouseEvent,
  canvas: HTMLCanvasElement
): {
  x: number;
  y: number;
  box: boolean;
} {
  const touches = (e as TouchEvent).touches;
  const source = (touches && touches.length ? touches[0] : e) as MouseEvent;
  const { offsetX, offsetY } = source as MouseEvent;
  let box = false;
  let x, y;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useOffsetPos(offsetX, offsetY, e.target)) {
    x = offsetX;
    y = offsetY;
  } else {
    const rect = canvas.getBoundingClientRect();
    x = source.clientX - rect.left;
    y = source.clientY - rect.top;
    box = true;
  }
  return { x, y, box };
}

const positions = ['top', 'right', 'bottom', 'left'];
function getPositionedStyle(
  styles: CSSStyleDeclaration,
  style: string,
  suffix?: string
): ChartArea {
  const result = {} as any;
  suffix = suffix ? '-' + suffix : '';
  for (let i = 0; i < 4; i++) {
    const pos = positions[i];
    result[pos] = parseFloat(styles[(style + '-' + pos + suffix) as any]) || 0;
  }
  result.width = result.left + result.right;
  result.height = result.top + result.bottom;
  return result;
}

export function getRelativePosition(
  event: MouseEvent | TouchEvent | ChartEvent,
  chart: Chart
): { x: number; y: number } {
  if ('native' in event) {
    return event;
  }

  const { canvas, currentDevicePixelRatio } = chart;
  const style = getComputedStyle(canvas);
  const borderBox = style.boxSizing === 'border-box';
  const paddings = getPositionedStyle(style, 'padding');
  const borders = getPositionedStyle(style, 'border', 'width');
  const { x, y, box } = getCanvasPosition(event, canvas);
  const xOffset = paddings.left + (box && borders.left);
  const yOffset = paddings.top + (box && borders.top);

  let { width, height } = chart;
  if (borderBox) {
    width -= paddings.width + borders.width;
    height -= paddings.height + borders.height;
  }
  return {
    x: Math.round(
      (((x - xOffset) / width) * canvas.width) / currentDevicePixelRatio
    ),
    y: Math.round(
      (((y - yOffset) / height) * canvas.height) / currentDevicePixelRatio
    ),
  };
}

Interaction.modes.vertical = function (chart, e, _, useFinalPosition) {
  const position = getRelativePosition(e, chart);
  const items: InteractionItem[] = [];
  Interaction.evaluateInteractionItems(
    chart,
    'x',
    position,
    (element, datasetIndex, index) => {
      if (element.inXRange(position.x, useFinalPosition)) {
        items.push({ element, datasetIndex, index });
      }
    }
  );
  return items;
};
declare module 'chart.js' {
  interface InteractionModeMap {
    vertical: InteractionModeFunction;
  }
}
