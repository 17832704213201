import { noop } from 'lodash';
import { FC, useContext, PropsWithChildren } from 'react';
import { Button, ButtonProps } from '../../Button/Button';
import { ModalContext } from '../Modal.context';

type ModalCloseProps = PropsWithChildren<
  ButtonProps & {
    onClick?: () => void;
  }
>;

export const ModalClose: FC<ModalCloseProps> = ({
  children,
  onClick = noop,
  ...props
}) => {
  const { setVisible } = useContext(ModalContext);

  const handleClick = () => {
    setVisible(false);
    onClick();
  };

  return (
    <Button onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};
