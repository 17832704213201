import { Key } from 'react';
import { get } from 'lodash';
import { UiKitColor } from '../../types/uikit';
import { COLORS_LENGTH_MAP } from './useColorsHeatMap.const';

interface UseColorHeatMapParams<T extends object> {
  data: T[];
  key?: keyof T;
  last?: UiKitColor;
  first?: UiKitColor;
}

interface UseColorHeatMapReturnType {
  colors: Record<Key, UiKitColor>;
}

export const useColorHeatMap = <T extends object>({
  key,
  last,
  first,
  data = [],
}: UseColorHeatMapParams<T>): UseColorHeatMapReturnType => {
  const addons = [last, first].filter((v) => !!v);

  const length = data.length - addons.length;

  const colors = [first, ...get(COLORS_LENGTH_MAP, length, []), last].filter(
    (v) => !!v
  );

  const map = data.reduce((res, item, index) => {
    const prop = key ? get(item, key, null) : index;
    return { ...res, [prop]: colors[index] };
  }, {});

  return { colors: map };
};
