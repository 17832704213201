import { castArray, intersection, omit } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import {
  AuthProviderContext,
  AuthProviderContextProps,
} from '../../contexts/AuthProvider.context';

interface ObjectWithRole {
  role: string | string[];
}

interface UseOptionsByRoleParams<T extends object> {
  options: Array<T & ObjectWithRole>;
}

interface UseOptionsByRoleReturnType<T extends object> {
  options: T[];
}

export const useOptionsByRole = <T extends object>({
  options: initialOptions = [],
}: UseOptionsByRoleParams<T>): UseOptionsByRoleReturnType<T> => {
  const roles = useContextSelector<AuthProviderContextProps, unknown[]>(
    AuthProviderContext,
    ({ role }) => castArray(role)
  );

  const options = initialOptions
    .filter(({ role }) => !!intersection(role, roles).length)
    .map((item) => omit(item, 'role'));

  return { options: options as T[] };
};
