import styled from 'styled-components';
import { Icon as UiKitIcon } from '../../Icon/Icon';

export const Root = styled('span')<{ active: boolean }>(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const Sorter = styled('span')<{ down: boolean }>(
  ({ down, theme: { spacing } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: `0 0 ${spacing(3)}`,
    height: spacing(3),
    marginLeft: spacing(0.5),
    ...(down ? { transform: 'rotate(180deg)' } : {}),
  })
);

export const Icon = styled(UiKitIcon)<{ active: boolean }>(
  ({ active, theme }) => ({
    color: active ? theme.palette.spintelGreen : theme.palette.wolfGrey,
    visibility: active ? 'visible' : 'hidden',
  })
);
