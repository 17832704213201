import { PropsWithChildren, useMemo, ReactElement, useContext } from 'react';
import { TableGlobalColumnsType } from '../Table.types';
import { TableContext } from '../../../contexts/Table.context';
import { TableColumnSelection } from '../Table.types';
import { UiKitProviderContext } from '../../../contexts/UiKitProvider.context';
import { COLUMN_SELECT_ALL_DATA_INDEX } from '../Table.const';
import {
  getDefaultColumnSelection,
  decorateColumnsWithId,
  getTotalSelectionNodes,
} from './TableProvider.utils';
import { useTableConfig } from './TableProvider.hooks';

type TableProviderProps<T extends object> = PropsWithChildren<{
  id: string;
  columns?: TableGlobalColumnsType<T>;
  skeleton?: number;
}>;

interface TableProviderType {
  <T extends object>(props: TableProviderProps<T>): ReactElement<
    any,
    any
  > | null;
}

export const TableProvider: TableProviderType = ({
  id,
  children,
  skeleton,
  columns: data = [],
}) => {
  const { version = '1' } = useContext(UiKitProviderContext);

  const columns = useMemo(() => decorateColumnsWithId(data), [data]);

  const defaultSelection = useMemo(() => {
    const selection = getDefaultColumnSelection(columns);
    const total = getTotalSelectionNodes(columns);
    return selection.length === total
      ? [[COLUMN_SELECT_ALL_DATA_INDEX], ...selection]
      : selection;
  }, [columns]);

  const [selection, setSelection] = useTableConfig<TableColumnSelection>(
    `version:${version}.table:${id}(selection)`,
    defaultSelection
  );

  return (
    <TableContext.Provider
      value={{
        columns,
        skeleton,
        selection,
        setSelection,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
