import { FC } from 'react';
import { useMount } from 'react-use';
import { useRoute } from '@spintel/navigation';
import { Splash } from '@spintel/uikit';
import { useAuth, DTO } from '@spintel/network/auth';

export const Callback: FC = () => {
  const [, { handleLoginCallback }] = useAuth();
  const [{ search }] = useRoute<unknown, DTO.ILoginUserResponseDto>();

  useMount(() => {
    handleLoginCallback(search);
  });

  return <Splash />;
};
