import { FormRadioGroupOption } from '../../../Form/FormRadioGroup/FormRadioGroup.types';
import { TableFilterRangeComparator } from './TableFilterRange.const';

export const useFilterOptions = () => {
  const options: FormRadioGroupOption[] = [
    {
      label: 'is equal to',
      value: TableFilterRangeComparator.EQ,
    },
    {
      label: 'is between',
      value: TableFilterRangeComparator.BT,
    },
    {
      label: 'is greater than',
      value: TableFilterRangeComparator.GT,
    },
    {
      label: 'is less than',
      value: TableFilterRangeComparator.LT,
    },
  ];

  return { options };
};
