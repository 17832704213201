import { Typography } from '@spintel/uikit';
import styled from 'styled-components';

export const ContactsItem = styled('div').withConfig({
  shouldForwardProp: (prop) => !['isBlock'].includes(prop),
})<{ isBlock?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)} 0;
  flex-wrap: ${({ isBlock }) => (isBlock ? 'wrap ' : 'nowrap')};
`;

export const ContactsItemGroup = styled('div').withConfig({
  shouldForwardProp: (prop) => !['isBlock'].includes(prop),
})<{ isBlock?: boolean }>`
  display: flex;
  align-items: center;
  width: ${({ isBlock }) => (isBlock ? '100% ' : 'auto')};
  padding-bottom: ${({ isBlock, theme }) => theme.spacing(isBlock ? 2 : 0)};
`;

export const ContactsItemLabel = styled(Typography.Body3)`
  padding-left: ${({ theme }) => theme.spacing(1.5)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.stardustGrey};
`;

export const ContactsItemValue = styled(Typography.Subtitle2)`
  color: ${({ theme }) => theme.palette.white};
  max-width: calc(100% - 100px);
  line-height: 18px;

  & > a {
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 250px;
    overflow: hidden;
    display: block;
  }
`;
