import React, { FC } from 'react';
import src from './Logo.png';

interface LogoProps {
  width?: number;
}

export const Logo: FC<LogoProps> = ({ width = 33 }) => {
  return <img src={src} style={{ width, height: 'auto' }} alt="" />;
};
