import { forwardRef } from 'react';
import { Icon } from '../Icon/Icon';
import { Root, Info, Subtitle, Title, Pic } from './Userpic.style';
import { UserpicSize } from './Userpic.types';

export interface UserpicProps {
  avatar?: string;
  title: string;
  subtitle?: string;
  className?: string;
  size?: UserpicSize;
}

export const Userpic = forwardRef<HTMLDivElement, UserpicProps>(
  function Userpic(
    { avatar, title, subtitle, className, size = 'lg', ...props },
    ref
  ) {
    return (
      <Root ref={ref} className={className} {...props}>
        <Pic
          size={size}
          src={avatar}
          icon={<Icon name="user" color="icarusGrey" size={size} />}
        />
        <Info>
          <Title size={size}>{title}</Title>
          {subtitle && <Subtitle size={size}>{subtitle}</Subtitle>}
        </Info>
      </Root>
    );
  }
);
