import { noop } from 'lodash';
import { Icon } from '../../Icon/Icon';
import { SegmentedComponent } from '../Segmented.types';
import { SEGMENTED_SIZE_MAP, ICON_SIZE_MAP } from './SegmentedDefault.const';
import { Root, Option } from './SegmentedDefault.style';

export const SegmentedDefault: SegmentedComponent = ({
  value,
  options,
  disabled,
  size = 'md',
  onChange = noop,
}) => {
  const [{ value: defaultValue }] = options;

  return (
    <Root
      value={value}
      disabled={disabled}
      defaultValue={defaultValue}
      size={SEGMENTED_SIZE_MAP[size]}
      onChange={({ target: { value } }) => onChange(value)}
    >
      {options.map(({ value, label, icon, disabled }) => {
        return (
          <Option
            key={value}
            value={value}
            hasIcon={!!icon}
            size={size}
            disabled={disabled}
          >
            {icon ? <Icon name={icon} size={ICON_SIZE_MAP[size]} /> : label}
          </Option>
        );
      })}
    </Root>
  );
};
