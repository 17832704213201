import { DTO } from '@spintel/network/app';
import { useTranslation } from '@spintel/localization';
import { SegmentedOption } from '@spintel/uikit';

export const useDateRangeOptions = () => {
  const { t } = useTranslation();

  const options: SegmentedOption[] = [
    {
      label: t('options.all'),
      value: DTO.IDateRangeType.All,
    },
    {
      label: t('options.oneMonth'),
      value: DTO.IDateRangeType.Type1M,
    },
    {
      label: t('options.threeMonths'),
      value: DTO.IDateRangeType.Type3M,
    },
    {
      label: t('options.sixMonths'),
      value: DTO.IDateRangeType.Type6M,
    },
    {
      label: t('options.oneYear'),
      value: DTO.IDateRangeType.Type1Y,
    },
  ];

  return { options };
};
