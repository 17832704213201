import { FC, useCallback } from 'react';
import cs from 'classnames';
import { noop, get } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import { MenuProps as AntdMenuProps } from 'antd/lib/menu';
import {
  DropdownContext,
  DropdownContextProps,
} from '../../contexts/Dropdown.context';
import { Icon } from '../Icon/Icon';
import { Tooltip } from '../Tooltip/Tooltip';
import { Root } from './Menu.style';
import { MenuItem } from './Menu.types';

export interface MenuProps {
  width?: number;
  value?: string[];
  items: MenuItem[];
  className?: string;
  onClick?: (v: string) => void;
}

export const Menu: FC<MenuProps> = ({
  value,
  width,
  className,
  items = [],
  onClick = noop,
}) => {
  const menu = (function calc(items: MenuItem[]): any {
    return items.map((item) => {
      const hint = get(item, 'hint');
      const icon = get(item, 'icon');
      const label = get(item, 'label');
      const status = get(item, 'status');
      const children = get(item, 'children');

      return {
        key: get(item, 'key'),
        type: get(item, 'type'),
        onClick: get(item, 'onClick'),
        disabled: get(item, 'disabled'),
        children: children && calc(children),
        icon: icon ? <Icon size="sm" force name={icon} /> : null,
        label: hint ? <Tooltip content={hint}>{label}</Tooltip> : label,
        className: cs({
          [`with-status with-status-${status}`]: !!status,
          [`truncated-item`]: !!width,
        }),
        style: { maxWidth: width || '100%' },
      };
    });
  })(items);

  const toggleDropdown = useContextSelector<
    DropdownContextProps,
    DropdownContextProps['toggle']
  >(DropdownContext, ({ toggle }) => toggle);

  const handleOnClick: AntdMenuProps['onClick'] = useCallback(
    ({ key }) => {
      onClick(key);
      toggleDropdown(false);
    },
    [onClick, toggleDropdown]
  );

  return (
    <Root
      items={menu}
      width={width}
      selectedKeys={value}
      className={className}
      onClick={handleOnClick}
      expandIcon={<Icon name="arrow-triangular-right" size="sm" />}
    />
  );
};
