import styled from 'styled-components';
import AntdTable from 'antd/lib/table';

const {
  Summary: { Row: SummaryRow, Cell: SummaryCell },
} = AntdTable;

export const Row = styled(SummaryRow)``;

export const Cell = styled(SummaryCell)`
  &.no-summary {
    border-color: transparent !important;

    & + .ant-table-cell:not(.no-summary) {
      border-left: 1px solid ${({ theme }) => theme.palette.asphaltGrey};
    }
  }
`;
