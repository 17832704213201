import { FC, PropsWithChildren } from 'react';
import { Button } from '../../../Button/Button';
import { Root } from './FormListItem.style';

type FormListItemProps = PropsWithChildren<{
  onRemove: () => void;
  min: number;
  total: number;
}>;

export const FormListItem: FC<FormListItemProps> = ({
  min,
  total,
  children,
  onRemove,
}) => {
  return (
    <Root>
      {children}
      {total > min && (
        <Button
          circle
          variant="muted"
          icon="clear"
          size="lg"
          onClick={onRemove}
        />
      )}
    </Root>
  );
};
