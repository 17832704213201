import { FC, PropsWithChildren, ReactNode } from 'react';
import { Icon } from '../Icon/Icon';
import { IconName } from '../Icon/__generated__/icon.types';
import { Root, Image, Message } from './Empty.style';
import { EmptyBackground, EmptySize } from './Empty.types';

export type EmptyProps = PropsWithChildren<{
  title: ReactNode;
  fill?: boolean;
  icon?: IconName;
  size?: EmptySize;
  center?: boolean;
  subtitle?: ReactNode;
  className?: string;
  background?: EmptyBackground;
}>;

export const Empty: FC<EmptyProps> = ({
  title: initialTitle,
  subtitle,
  children,
  className,
  size = 'md',
  fill = false,
  icon = 'empty',
  center = false,
  background = 'transparent',
}) => {
  const image = (
    <Image>
      <Icon name={icon} size="md" color="anthraciteGrey" />
    </Image>
  );

  const title = (() => {
    if (typeof initialTitle === 'string') {
      return <Message>{initialTitle}</Message>;
    }
    return initialTitle;
  })();

  const description = (
    <div>
      {title}
      {subtitle && <Message>{subtitle}</Message>}
    </div>
  );

  return (
    <Root
      size={size}
      fill={fill}
      image={image}
      center={center}
      className={className}
      background={background}
      description={description}
    >
      {children}
    </Root>
  );
};
