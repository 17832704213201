import { forwardRef, PropsWithChildren, MouseEventHandler } from 'react';
import { noop } from 'lodash';
import { UiKitBaseSize } from '../../types/uikit';
import { Icon } from '../Icon/Icon';
import { IconName } from '../Icon/__generated__/icon.types';
import { ButtonVariant } from './Button.types';
import { Root } from './Button.style';
import {
  BUTTON_SHAPE_MAP,
  BUTTON_TYPE_MAP,
  BUTTON_SIZE_MAP,
  ICON_SIZE_MAP,
} from './Button.const';

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<any>, 'onClick'> {
  variant?: ButtonVariant;
  size?: UiKitBaseSize;
  circle?: boolean;
  icon?: IconName;
  loading?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  href?: string;
}

export const Button = forwardRef<HTMLElement, PropsWithChildren<ButtonProps>>(
  function Button(
    {
      circle,
      loading,
      disabled,
      children,
      icon,
      href,
      type = 'button',
      size = 'md',
      variant = 'tertiary',
      onClick = noop,
      ...props
    },
    ref
  ) {
    return (
      <Root
        ref={ref}
        muted={variant === 'muted'}
        xs={size === 'xs'}
        size={BUTTON_SIZE_MAP[size]}
        htmlType={type}
        loading={loading}
        disabled={disabled}
        ghost={variant === 'tertiary'}
        type={BUTTON_TYPE_MAP[variant]}
        shape={circle ? 'circle' : BUTTON_SHAPE_MAP[variant]}
        onClick={onClick}
        icon={icon && <Icon force name={icon} size={ICON_SIZE_MAP[size]} />}
        href={href}
        danger={variant === 'danger'}
        {...props}
      >
        {children}
      </Root>
    );
  }
);
