import { FC, PropsWithChildren, useId } from 'react';
import Scroll from 'react-infinite-scroll-component';
import { Spin } from '../Spin/Spin';
import { Root, Loader } from './InfiniteScroll.style';

type InfiniteScrollProps = PropsWithChildren<{
  total: number;
  length: number;
  className?: string;
  onScroll: () => void;
}>;

export const InfiniteScroll: FC<InfiniteScrollProps> = ({
  total,
  length,
  onScroll,
  children,
  className,
}) => {
  const id = useId();

  const loader = (
    <Loader>
      <Spin spinning />
    </Loader>
  );

  return (
    <Root id={id} className={className}>
      <Scroll
        next={onScroll}
        loader={loader}
        dataLength={length}
        scrollableTarget={id}
        hasMore={length < total}
      >
        {children}
      </Scroll>
    </Root>
  );
};
