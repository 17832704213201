import React, { FC } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { UiKitColor } from '../../types/uikit';
import { THEME } from '../../constants/theme.const';

type QRCodeCanvasProps = Parameters<typeof QRCodeCanvas>['0'];

interface QRCodeProps extends Omit<QRCodeCanvasProps, 'bgColor' | 'fgColor?'> {
  color?: UiKitColor;
  background?: UiKitColor;
}

export const QRCode: FC<QRCodeProps> = ({
  color = 'white',
  background = 'transparent',
  ...rest
}) => {
  return (
    <QRCodeCanvas
      fgColor={THEME.palette[color]}
      bgColor={THEME.palette[background]}
      {...rest}
    />
  );
};
