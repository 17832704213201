import { FC, ReactNode } from 'react';
import { GraphLegendItem, GraphTooltipConfig } from '../Graph.types';
import { GraphLegendBadge } from './GraphLegendBadge/GraphLegendBadge';
import { GraphLegendList } from './GraphLegendList/GraphLegendList';

export interface GraphLegendProps {
  variant: 'badge' | 'list';
  id?: string;
  label?: (params: { item: GraphLegendItem; index: number }) => ReactNode;
  disabled?: (params: { item: GraphLegendItem; index: number }) => boolean;
  hint?: (params: {
    item: GraphLegendItem;
    index: number;
  }) => string | undefined;
  tooltipConfig?: Omit<GraphTooltipConfig, 'id'>;
  onChange?: (data: string[]) => void;
}

export const GraphLegend: FC<GraphLegendProps> = ({ variant, ...props }) => {
  if (variant === 'badge') return <GraphLegendBadge {...props} />;
  if (variant === 'list') return <GraphLegendList {...props} />;

  return null;
};
