import { lazy } from 'react';
import {
  Routes,
  Route,
  Outlet,
  DefaultRedirect,
  Protected,
} from '@spintel/navigation';
import { Alert } from '@spintel/uikit';
import { Bootstrap, UserShell } from '@common/features';
import { RoutePath, USER_ROLES, UserRole } from '@common/constants';
import { ROUTES as AUTH_ROUTES } from './auth/routes';
import { ROUTES as PANEL_CHARTS_ROUTES } from './panel-charts/routes';
import { ROUTES as SONG_ROUTES } from './song/routes';
import { ROUTES as STATIONS_ROUTES } from './stations/routes';
import { ROUTES as MANAGEMENT_ROUTES } from './management/routes';
import { ROUTES as SYSTEM_ROUTES } from './system/routes';

const MySongs = lazy(() =>
  import('./my-songs/routes/MySongs/MySongs').then((m) => ({
    default: m.MySongs,
  }))
);

const ChartMonitor = lazy(() =>
  import('./chart-monitor/routes/ChartMonitor/ChartMonitor').then((m) => ({
    default: m.ChartMonitor,
  }))
);

const Owners = lazy(() =>
  import('./owners/routes/Owners/Owners').then((m) => ({
    default: m.Owners,
  }))
);

const RotationMap = lazy(() =>
  import('./rotation-map/routes/RotationMap/RotationMap').then((m) => ({
    default: m.RotationMap,
  }))
);

const StationAverageAdds = lazy(() =>
  import('./adds/routes/StationAverageAdds/StationAverageAdds').then((m) => ({
    default: m.StationAverageAdds,
  }))
);

const Panels = lazy(() =>
  import('./panels/routes/Panels/Panels').then((m) => ({
    default: m.Panels,
  }))
);

export const App = () => {
  return (
    <Bootstrap {...window.APP_CONFIG}>
      <Routes>
        <Route
          path="/"
          element={
            <Protected roles={USER_ROLES}>
              <Outlet />
            </Protected>
          }
        >
          <Route index element={<DefaultRedirect />} />

          {/* User Routes */}
          <Route
            path={RoutePath.ROOT}
            element={
              <Protected roles={USER_ROLES}>
                <UserShell />
              </Protected>
            }
          >
            <Route index element={<DefaultRedirect />} />

            {SONG_ROUTES.map(
              ({ path: rootPath, element, childRoutes = [] }) => {
                return (
                  <Route key={rootPath} path={rootPath} element={element}>
                    {childRoutes.map(({ index, path, element }) => {
                      return (
                        <Route
                          index={index}
                          key={path || rootPath}
                          path={path}
                          element={element}
                        />
                      );
                    })}
                  </Route>
                );
              }
            )}

            <Route path={RoutePath.MY_SONGS} element={<Outlet />}>
              <Route index element={<MySongs />} />
            </Route>

            {PANEL_CHARTS_ROUTES.map(
              ({ path: rootPath, element, childRoutes = [] }) => {
                return (
                  <Route key={rootPath} path={rootPath} element={element}>
                    {childRoutes.map(({ index, path, element }) => {
                      return (
                        <Route
                          index={index}
                          key={path || rootPath}
                          path={path}
                          element={element}
                        />
                      );
                    })}
                  </Route>
                );
              }
            )}

            <Route path={RoutePath.CHART_MONITOR} element={<Outlet />}>
              <Route index element={<ChartMonitor />} />
            </Route>

            {/* Stations routes */}
            {STATIONS_ROUTES.map(
              ({ path: rootPath, element, childRoutes = [] }) => {
                return (
                  <Route key={rootPath} path={rootPath} element={element}>
                    {childRoutes.map(({ index, path, element }) => {
                      return (
                        <Route
                          index={index}
                          key={path || rootPath}
                          path={path}
                          element={element}
                        />
                      );
                    })}
                  </Route>
                );
              }
            )}

            <Route path={RoutePath.OWNERS} element={<Outlet />}>
              <Route index element={<Owners />} />
            </Route>
            <Route path={RoutePath.ROTATION_MAP} element={<Outlet />}>
              <Route index element={<RotationMap />} />
            </Route>
            <Route path={RoutePath.STATION_AVERAGE_ADDS} element={<Outlet />}>
              <Route index element={<StationAverageAdds />} />
            </Route>
            <Route path={RoutePath.PANELS} element={<Outlet />}>
              <Route index element={<Panels />} />
            </Route>

            {/* Management Routes */}
            {MANAGEMENT_ROUTES.map(
              ({ path: rootPath, element, childRoutes = [] }) => {
                return (
                  <Route
                    key={rootPath}
                    path={rootPath}
                    element={
                      <Protected
                        roles={[
                          UserRole.AccountManager,
                          UserRole.TeamManager,
                          UserRole.SuperAdmin,
                        ]}
                      >
                        {element}
                      </Protected>
                    }
                  >
                    {childRoutes.map(({ index, path, element }) => {
                      return (
                        <Route
                          index={index}
                          key={path || rootPath}
                          path={path}
                          element={element}
                        />
                      );
                    })}
                  </Route>
                );
              }
            )}

            <Route path="*" element={<div>No Found</div>} />
          </Route>
        </Route>

        {/* Auth Routes */}
        {AUTH_ROUTES.map(({ path, element, childRoutes = [], roles }) => {
          return (
            <Route
              key={path}
              path={path}
              element={
                <Protected roles={roles} redirect>
                  <Alert.Snackbar />
                  {element}
                </Protected>
              }
            >
              {childRoutes.map(({ path, element, index }) => {
                return (
                  <Route
                    key={path}
                    index={index}
                    path={path}
                    element={element}
                  />
                );
              })}
            </Route>
          );
        })}

        {SYSTEM_ROUTES.map(({ path, element, childRoutes = [] }) => {
          return (
            <Route key={path} path={path} element={element}>
              {childRoutes.map(({ path, element }) => {
                return <Route key={path} path={path} element={element} />;
              })}
            </Route>
          );
        })}
      </Routes>
    </Bootstrap>
  );
};
