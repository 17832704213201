import { FC, useEffect } from 'react';
import { Search, Form, Empty, useForm, useFormWatch } from '@spintel/uikit';
import { useTranslation } from '@spintel/localization';
import { CHARS_CODE } from '@common/constants';
import { FormCheckboxGroupOption } from '../SongComparisonSelector.types';
import { MAXIMUM_COUNT_OF_SONGS } from '../SongComparisonSelector.const';
import {
  Overlay,
  Searchbar,
  Content,
} from './SongComparisonSelectorOverlay.style';
import { useSearch } from './SongComparisonSelectorOverlay.hooks';

interface SongComparisonSelectorOverlayProps {
  songId: number;
  selectedSongIds: number[];
  form: ReturnType<typeof useForm>['0'];
}

export const SongComparisonSelectorOverlay: FC<
  SongComparisonSelectorOverlayProps
> = ({ songId, selectedSongIds, form: formInstance }) => {
  const [form] = useForm(formInstance);
  const { t } = useTranslation();
  const selection: number[] = useFormWatch('songComparisons', form) || [];
  const [{ loading, searching, songs, total }, { load, search }] = useSearch({
    songId,
  });

  const options: FormCheckboxGroupOption[] = songs.map(
    ({ songId, rank, ...rest }) => ({
      label: t('options.rankSongNameArtistName', {
        ...rest,
        rank: rank || CHARS_CODE.MDASH,
      }),
      value: songId,
    })
  );

  useEffect(() => {
    form.setFieldsValue({
      songComparisons: selectedSongIds,
    });
  }, [form, selectedSongIds]);

  const disabledOption = ({ value }: FormCheckboxGroupOption): boolean => {
    if (selectedSongIds.includes(value)) return true;
    return (
      selection.length === MAXIMUM_COUNT_OF_SONGS && !selection.includes(value)
    );
  };

  return (
    <Form form={form} initialValues={{ songComparisons: selectedSongIds }}>
      <Overlay>
        <Searchbar>
          <Search
            debounce={300}
            loading={loading}
            onChange={search}
            placeholder={t('placeholders.song')}
          />
        </Searchbar>
        <Content onScroll={load} length={songs.length} total={total}>
          {!!options.length && (
            <Form.CheckboxGroup<FormCheckboxGroupOption>
              preserve
              options={options}
              name="songComparisons"
              disabled={disabledOption}
            />
          )}
          {searching && !options.length && (
            <Empty
              size="sm"
              icon="song"
              title={t('empty.noSongsFoundForYourRequest')}
            />
          )}
        </Content>
      </Overlay>
    </Form>
  );
};
