import cs from 'classnames';
import {
  camelCase,
  isNumber,
  get,
  castArray,
  isEqual,
  isUndefined,
  merge,
  invoke,
  toNumber,
  flattenDeep,
} from 'lodash';
import {
  ColumnsType as AntdColumnsType,
  TablePaginationConfig as AntdTablePaginationConfig,
} from 'antd/lib/table';
import { Pagination } from '../Pagination/Pagination';
import { TableCell } from './TableCell/TableCell';
import { TableColumnTitle } from './TableColumnTitle/TableColumnTitle';
import {
  TableColumnType,
  TableColumnsType,
  TableColumnGroupType,
  TableGlobalColumnsType,
  TablePagination,
  TableSorting,
  TableSortOrder,
  TableFilterValue,
  TableColumnSelection,
  TableRowType,
  TableGlobalColumnsExtendedType,
  TableGlobalColumnExtendedType,
  TableActions,
} from './Table.types';
import { getColumnFilter } from './TableFilters';
import { CellExpander, StickyColumnGroupHeader } from './Table.style';
import { DEFAULT_PAGINATION_PAGE_SIZE_OPTIONS } from './Table.const';
import { decorateColumnsWithId } from './TableProvider/TableProvider.utils';

interface GetColumnsConfigParams<T extends object, S> {
  data: readonly T[];
  columns: TableColumnsType<T>;
  sorting: S[];
  filters: Record<string, TableFilterValue>;
  hasGroup?: boolean;
  totalFixedLeftWidth: number;
  formatter: (v: unknown) => unknown;
}

interface DecorateColumnGroupParams<T extends object, S> {
  data: readonly T[];
  props: TableColumnGroupType<T>;
  sorting: S[];
  filters: Record<string, TableFilterValue>;
  totalFixedLeftWidth: number;
  formatter: (v: unknown) => unknown;
}

interface DecorateColumnParams<T, S> {
  data: readonly T[];
  props: TableColumnType<T>;
  sorting: S[];
  filters: Record<string, TableFilterValue>;
  hasGroup: boolean;
  isLastInGroup: boolean;
  formatter: (v: unknown) => unknown;
}

function getDefaultSortOrder<S = TableSorting>(
  dataIndex: string | string[],
  sorting: S | S[]
): TableSortOrder {
  const index = castArray(dataIndex);
  const result = castArray(sorting).find((item) => {
    const column = get(item, 'column');
    const compare = castArray(column);
    return isEqual(index, compare);
  });
  return get(result, 'order', null);
}

const decorateColumnGroup = <T extends object, S = TableSorting>({
  props,
  sorting,
  data,
  filters,
  totalFixedLeftWidth,
  formatter,
}: DecorateColumnGroupParams<T, S>): AntdColumnsType<T>['0'] => {
  return {
    ...props,
    children:
      props.children &&
      getColumnsConfig({
        formatter,
        columns: props.children,
        sorting,
        data,
        filters,
        hasGroup: true,
        totalFixedLeftWidth,
      }),
    className: cs(props.className, 'ant-table-cell-group-header', {
      'ant-table-cell-group-header-hidden': isUndefined(props.showHeader)
        ? false
        : !props?.showHeader,
      'ant-table-column-highlighted': props?.highlight,
    }),
    title: !!totalFixedLeftWidth ? (
      <StickyColumnGroupHeader left={totalFixedLeftWidth}>
        {props.title}
      </StickyColumnGroupHeader>
    ) : (
      props.title
    ),
  };
};

const decorateColumn = <T extends object, S = TableSorting>({
  sorting,
  data,
  filters,
  hasGroup,
  isLastInGroup,
  formatter,
  props: {
    empty,
    title,
    render,
    ellipsis,
    highlight,
    dataIndex = [],
    filter,
    ...props
  },
}: DecorateColumnParams<T, S>): AntdColumnsType<T>['0'] => {
  return {
    ...props,
    dataIndex: castArray(dataIndex).join('.'),
    sortOrder: getDefaultSortOrder(dataIndex, sorting),
    filteredValue: get(filters, dataIndex, []),
    title: (titleProps) => (
      <TableColumnTitle
        dataIndex={castArray(dataIndex).join('.')}
        title={title}
        sorter={props.sorter}
        filters={get(filters, dataIndex, [])}
        className={cs(props.className, {
          'ant-table-head-action': dataIndex === 'action',
        })}
        {...titleProps}
      />
    ),
    render: (_, item, index) => (
      <TableCell
        render={render}
        dataIndex={dataIndex}
        item={item}
        index={index}
        ellipsis={ellipsis}
        empty={empty}
        title={title}
      />
    ),
    className: cs({
      'ant-table-cell-has-group': hasGroup,
      'ant-table-cell-is-last-in-group': isLastInGroup,
      'ant-table-column-highlighted': highlight,
    }),
    ...getColumnFilter<T>({ filter, data, dataIndex, formatter }),
  };
};

export const getTreeDataValue = (values: string[]) => {
  return values
    .filter((v) => !!v)
    .map((val) => camelCase(val))
    .join('-');
};

export const getColumnsConfig = <T extends object, S = TableSorting>({
  columns,
  sorting,
  data,
  filters,
  hasGroup = false,
  totalFixedLeftWidth,
  formatter,
}: GetColumnsConfigParams<T, S>): AntdColumnsType<T> => {
  return columns.map((props, index, original) => {
    switch (props.type) {
      case 'group':
        return decorateColumnGroup<T, S>({
          formatter,
          props,
          sorting,
          data,
          filters,
          totalFixedLeftWidth,
        });
      case 'column':
        return decorateColumn<T, S>({
          formatter,
          props,
          sorting,
          data,
          filters,
          hasGroup,
          isLastInGroup: original.length - 1 === index,
        });
      default:
        return props;
    }
  });
};

export const getPaginationConfig = (
  pagination: TablePagination
): false | AntdTablePaginationConfig => {
  if (pagination) {
    const {
      pageNumber = 1,
      pageSize = 15,
      totalCount = 0,
      pageSizeOptions = DEFAULT_PAGINATION_PAGE_SIZE_OPTIONS,
    } = pagination;
    return {
      position: ['bottomLeft'],
      current: pageNumber,
      pageSize: pageSize,
      total: totalCount,
      defaultPageSize: pageSize,
      showSizeChanger: true,
      pageSizeOptions: pageSizeOptions,
      itemRender: (page, type, original) => (
        <Pagination.Item
          current={pageNumber}
          page={page}
          type={type}
          original={original}
        />
      ),
    };
  }
  return false;
};

export const getColumnsWidth = <T extends object>(
  columns: TableColumnsType<T>
): number[][] => {
  return columns.reduce((acc, cur) => {
    switch (cur.type) {
      case 'group':
        const children = getColumnsWidth(cur.children);
        if (children.length) {
          return [...acc, children];
        } else {
          return acc;
        }
      case 'column':
        return [...acc, cur.width];
      default:
        return acc;
    }
  }, []);
};

const getExpandLastColumn = <T extends object>(
  column: TableColumnGroupType<T> | TableColumnType<T>,
  extraSize: number
): TableColumnGroupType<T> | TableColumnType<T> => {
  if (column.type === 'column') return { ...column };

  const childs = column.children;
  if (!childs) return column;

  const children = childs.map((child, index, origin) => {
    if (index !== origin.length - 1) return { ...child };

    if (child.type === 'group') {
      return getExpandLastColumn(child, extraSize);
    } else {
      return { ...child, width: toNumber(child.width) + extraSize };
    }
  });

  return {
    ...column,
    children,
  };
};

const getTotalColumnsWidth = <T extends object>(
  children: TableColumnsType<T>
): number => {
  const columns = getColumnsWidth(children);
  return flattenDeep(columns).reduce((acc, curr) => acc + curr, 0);
};

export const getMinimalColumnSizes = <T extends object>(
  columns: TableColumnsType<T>
): TableColumnsType<T> => {
  return columns.reduce((acc, curr) => {
    switch (curr.type) {
      case 'group':
        const hasMinWidth = !!curr.minWidth;
        const children = getMinimalColumnSizes(curr.children);

        if (hasMinWidth && children.length) {
          const totalColumnsWidth = getTotalColumnsWidth(children);
          if (totalColumnsWidth >= curr.minWidth) {
            return [...acc, { ...curr, children }];
          } else {
            const extraSize = curr.minWidth - totalColumnsWidth;

            const column = getExpandLastColumn(curr, extraSize);
            return [...acc, column];
          }
        }
        if (!hasMinWidth && children.length) {
          return [...acc, { ...curr, children }];
        } else {
          return acc;
        }
      case 'column':
        return [...acc, curr];
      default:
        return acc;
    }
  }, []);
};

export const getVisibleColumns = <T extends object>(
  columns: TableColumnsType<T>
): TableColumnsType<T> => {
  return columns.reduce((res, props) => {
    switch (props.type) {
      case 'group':
        return (() => {
          const children = getVisibleColumns(props.children);
          return children.length ? [...res, { ...props, children }] : res;
        })();
      case 'column':
        return (() => {
          const visible = get(props, 'visible', true);
          return visible ? [...res, props] : res;
        })();
      default:
        return res;
    }
  }, [] as TableGlobalColumnsType<T>);
};

export const getShowSummary = <T extends object>(
  columns: TableColumnsType<T>
): boolean => {
  return columns.reduce((res, props) => {
    switch (props.type) {
      case 'group':
        return getShowSummary(props.children);
      case 'column':
        return !res ? !!props.summary : res;
      default:
        return res;
    }
  }, false);
};

export const getTotalFixedWidth = <T extends object>(
  columns: TableColumnsType<T>,
  pos: 'left' | 'right'
): number => {
  return columns.reduce((res, props) => {
    switch (props.type) {
      case 'group':
        return res + getTotalFixedWidth(props.children, pos);
      case 'column':
        return (() => {
          if (isEqual(props.fixed, pos) && isNumber(props.width)) {
            return res + props.width;
          }
          return res;
        })();
      default:
        return res;
    }
  }, 0);
};

export const getColumnSelectionTree = <T extends object>(
  columns: TableGlobalColumnsExtendedType<T>,
  selection: TableColumnSelection['0']
): object => {
  return columns.reduce((res, props) => {
    switch (props.type) {
      case 'group':
        return merge(
          {},
          res,
          getColumnSelectionTree(props.children, selection)
        );
      case 'column':
        return merge({}, res, {
          [props.id]: selection.includes(props.id),
        });
      default:
        return res;
    }
  }, {} as object);
};

export const getRowClassName = (row?: TableRowType<object>) => {
  return (item: object, index: number): string => {
    return cs({
      'ant-table-row-highlighted-bold':
        invoke(row, 'highlighted', { item, index }) === 'bold',
      'ant-table-row-highlighted-normal':
        invoke(row, 'highlighted', { item, index }) === 'normal',
    });
  };
};

export const appendColumn = <T extends object>(
  columns: TableColumnsType<T>,
  additionalColumn?: TableGlobalColumnExtendedType<T>
): TableGlobalColumnsExtendedType<T> => {
  const decoratedColumns = decorateColumnsWithId(columns);
  if (!columns.length || !additionalColumn) return decoratedColumns;

  const data = [...decoratedColumns];
  const lastIndex = data.length - 1;
  const lastElement = data[lastIndex];
  const isGroup = lastElement.type === 'group';
  const childs = isGroup && lastElement.children;

  if (childs) {
    data.splice(lastIndex, 1, {
      ...lastElement,
      children: appendColumn<T>(childs, additionalColumn),
    });
  }
  if (!childs) {
    return [...data, additionalColumn];
  }

  return [...data];
};

export const getExpanderColumns = <T extends object>({
  columns,
  expander = false,
}: {
  columns: TableColumnsType<T>;
  expander?: boolean;
}): TableColumnsType<T> => {
  if (!expander) return appendColumn<T>(columns);
  const expanderColumn: TableGlobalColumnExtendedType<T> = {
    id: 'id-expander',
    type: 'column',
    dataIndex: 'expander',
    width: 'auto',
    fixed: 'right',
    className: 'cell-expander',
    render: () => <CellExpander />,
  };

  return appendColumn<T>(columns, expanderColumn);
};

export const getActionsColumn = <T extends object>({
  columns,
  actions,
}: {
  columns: TableColumnsType<T>;
  actions: TableActions<T>;
}): TableGlobalColumnsExtendedType<T> => {
  if (!actions) return appendColumn<T>(columns);

  const actionColumn: TableGlobalColumnExtendedType<T> = {
    id: 'id-action',
    dataIndex: 'action',
    type: 'column',
    fixed: 'right',
    width: actions.width,
    render: (_, data) => actions({ item: data }),
  };

  return appendColumn<T>(columns, actionColumn);
};

export const getFlattenColumns = <T extends object>(
  columns: TableColumnsType<T>
): TableColumnType<T>[] => {
  return columns.reduce((res, props) => {
    switch (props.type) {
      case 'group':
        return [...res, ...getFlattenColumns(props.children)];
      case 'column':
        return [...res, props];
      default:
        return res;
    }
  }, []);
};

export const getTableScrollbarSizes = (
  element: HTMLElement,
  nodeClass = '.ant-table-body'
) => {
  const INITIAL_VALUES = { xScrollHeight: 0, yScrollWidth: 0 };
  if (!element) return INITIAL_VALUES;
  const nodeElement = element.querySelector<HTMLElement>(nodeClass);
  if (!nodeElement) return INITIAL_VALUES;
  const { offsetHeight, clientHeight, offsetWidth, clientWidth } = nodeElement;
  const xScrollHeight = offsetHeight - clientHeight;
  const yScrollWidth = offsetWidth - clientWidth;

  return { xScrollHeight, yScrollWidth };
};
