import { FC } from 'react';
import Tooltip from 'antd/lib/tooltip';
import { Badge } from '../../../Badge/Badge';
import { DEFAULT_GRAPH_ID } from '../../Graph.const';
import { GraphLegendItem } from '../../Graph.types';
import { GraphLegendProps } from '../GraphLegend';
import {
  Root,
  Item,
  Label,
  Value,
  ItemContainer,
} from './GraphLegendList.style';
import { useHover, useLegend } from './GraphLegendList.hooks';

export interface GraphLegendListProps
  extends Pick<GraphLegendProps, 'id' | 'label' | 'tooltipConfig'> {}

export const GraphLegendList: FC<GraphLegendListProps> = ({
  id = DEFAULT_GRAPH_ID,
  label = ({ item }) => <DefaultLabel item={item} />,
  tooltipConfig,
}) => {
  const { inactive, handleMouseEnterFactory, handleMouseLeave } = useHover();

  const legend = useLegend({ id, label });

  const getBadgeColor = (index: number) => {
    return inactive(index) ? 'asphaltGrey' : legend[index]?.status;
  };

  return (
    <Root>
      {legend.map((item, i) => (
        <Tooltip
          key={`${item.status}${item.label}`}
          overlay={i === tooltipConfig?.dataIndex ? tooltipConfig?.tooltip : ''}
          placement="top"
          overlayStyle={{ padding: '0', maxWidth: '100%' }}
          overlayInnerStyle={{ padding: '0' }}
        >
          <Item>
            <ItemContainer
              onMouseEnter={handleMouseEnterFactory(i)}
              onMouseLeave={handleMouseLeave}
            >
              <Badge variant="status" opacity={1} color={getBadgeColor(i)}>
                <Label inactive={inactive(i)}>{item.label}</Label>
              </Badge>
            </ItemContainer>
          </Item>
        </Tooltip>
      ))}
    </Root>
  );
};

const DefaultLabel: FC<{ item: GraphLegendItem }> = ({ item }) => (
  <>
    <Value>{item.value}</Value>
    {item.label}
  </>
);
