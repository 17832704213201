import styled from 'styled-components';
import { Dropdown } from '@spintel/uikit';

export const Root = styled.div`
  flex: 1 1 auto;
`;

export const Overlay = styled(Dropdown.Overlay)`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  margin-top: 0;
`;
