import { useCallback, useContext } from 'react';
import { get } from 'lodash';
import { NUMBER_FORMAT_MAP } from '../../constants/numbers.const';
import { LocalizationContext } from '../../contexts/Localization.context';
import { NumberFormat } from '../../types/number.types';

const formatter = require('number-formatter');

export const useNumber = () => {
  const { locale } = useContext(LocalizationContext);

  const f = useCallback(
    (format: NumberFormat, value: number) => {
      const res = get(NUMBER_FORMAT_MAP, [locale, format]);
      return formatter(res, value);
    },
    [locale]
  );

  return { f };
};
