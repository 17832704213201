import styled, { css } from 'styled-components';
import { Typography } from '../Typography/Typography';
import { Empty } from '../Empty/Empty';
import { Icon } from '../Icon/Icon';

const { Subtitle2, Body3 } = Typography;

export const Root = styled('div')`
  display: flex;
  flex-wrap: nowrap;
`;

export const ContentLeft = styled('div')<{ width?: number }>`
  display: flex;
  flex: 0 0
    ${({ width, theme: { spacing } }) => (width ? `${width}px` : spacing(66))};
`;

export const ContentRight = styled('div')`
  flex: 1 1 auto;
  display: flex;
`;

export const List = styled('div')`
  flex: 1 1 auto;
  overflow: hidden auto;
`;

export const OptionLeft = styled('div')(() => ({}));

export const OptionRight = styled('div')(() => ({}));

export const OptionLabel = styled(Subtitle2)<{ active: boolean }>(
  ({ theme: { palette }, active }) => ({
    color: active ? palette.white : palette.pearlGrey,
  })
);

export const OptionDescription = styled(Body3)<{ active: boolean }>(
  ({ active, theme: { palette } }) => ({
    color: active ? palette.stardustGrey : palette.pearlGrey,
  })
);
export const OptionIcon = styled(Icon)<{ active: boolean }>(
  ({ active, theme: { palette } }) => ({
    color: active ? palette.white : palette.pearlGrey,
  })
);

const RootActiveMixin = css`
  background: ${({ theme }) => theme.palette.anthraciteGrey};
  ${OptionLabel},
  ${OptionIcon} {
    color: ${({ theme }) => theme.palette.white};
  }
  ${OptionDescription} {
    color: ${({ theme }) => theme.palette.stardustGrey};
  }
`;

export const Option = styled('div')<{ active: boolean }>`
  display: flex;
  gap: ${({ theme: { spacing } }) => spacing(2)};
  padding: ${({ theme: { spacing } }) => `${spacing(4)}  ${spacing(6)}`};
  &:hover {
    cursor: pointer;
    color: ${({ theme, active }) => !active && theme.palette.white};
    ${OptionIcon},
    ${OptionLabel},
    ${OptionDescription} {
      color: ${({ theme, active }) => !active && theme.palette.stardustGrey};
    }
  }
  ${({ active }) => active && RootActiveMixin}
  transition: all ease 300ms;
`;

export const NoOptions = styled(Empty)`
  flex: 1;
`;

export const NoMatch = styled(Empty)`
  flex: 1;
  align-self: flex-start;
`;
