/* eslint-disable no-template-curly-in-string */

import {
  TableLocalization,
  FormItemPlaceholder,
  TooltipLocalization,
  EmptyLocalization,
} from '../types/localization.types';

const TYPE_TEMPLATE = "'${label}' is not a valid ${type}";

export const TABLE_LOCALIZATION: TableLocalization = {
  dropdownFilterBetweenAnd: 'And',
  dropdownFilterCancel: 'Cancel',
  dropdownFilterConfirm: 'Apply',
  dropdownFilterEmpty: 'No results match',
  dropdownFilterToggleAll: 'All',
  filtersAppliedText: 'Filters applied',
  filtersClearAllBtn: 'Clear all',
  filtersRangeBtLabel: 'Value',
  filtersRangeEqLabel: 'Value',
  filtersRangeGtLabel: 'Value',
  filtersRangeLtLabel: 'Value',
  noData: 'No data',
  columnsSelector: {
    toggle: 'All',
  },
};

export const DROPDOWN_LOCALIZATION = {
  confirmBtn: 'Apply',
  cancelBtn: 'Cancel',
};

export const TOOLTIP_LOCALIZATION: TooltipLocalization = {
  more: 'Read more',
};

export const EMPTY_LOCALIZATION: EmptyLocalization = {
  noData: 'No data',
  noMatch: 'No results match',
};

export const FORM_VALIDATE_MESSAGES = {
  default: "Validation error on field '${label}'",
  required: "'${label}' is required",
  enum: "'${label}' must be one of [${enum}]",
  whitespace: "'${label}' cannot be empty",
  date: {
    format: "'${label}' is invalid for format date",
    parse: "'${label}' could not be parsed as date",
    invalid: "'${label}' is invalid date",
  },
  types: {
    string: TYPE_TEMPLATE,
    method: TYPE_TEMPLATE,
    array: TYPE_TEMPLATE,
    object: TYPE_TEMPLATE,
    number: TYPE_TEMPLATE,
    date: TYPE_TEMPLATE,
    boolean: TYPE_TEMPLATE,
    integer: TYPE_TEMPLATE,
    float: TYPE_TEMPLATE,
    regexp: TYPE_TEMPLATE,
    email: TYPE_TEMPLATE,
    url: TYPE_TEMPLATE,
    hex: TYPE_TEMPLATE,
  },
  string: {
    len: "'${label}' must be exactly ${len} characters",
    min: "'${label}' must be at least ${min} characters",
    max: "'${label}' cannot be longer than ${max} characters",
    range: "'${label}' must be between ${min} and ${max} characters",
  },
  number: {
    len: "'${label}' must equal ${len}",
    min: "'${label}' cannot be less than ${min}",
    max: "'${label}' cannot be greater than ${max}",
    range: "'${label}' must be between ${min} and ${max}",
  },
  array: {
    len: "'${label}' must be exactly ${len} in length",
    min: "'${label}' cannot be less than ${min} in length",
    max: "'${label}' cannot be greater than ${max} in length",
    range: "'${label}' must be between ${min} and ${max} in length",
  },
  pattern: {
    mismatch: "'${label}' does not match pattern ${pattern}",
  },
};

export const FORM_ITEM_PLACEHOLDER: FormItemPlaceholder = {
  text: (label) => `Enter ${label.toLocaleLowerCase()}`,
  select: (label) => `Select ${label.toLocaleLowerCase()}`,
};
