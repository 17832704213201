import { PropsWithChildren, ReactElement, useRef } from 'react';
import { useMap } from 'react-use';
import { Chart } from 'chart.js';
import { GraphContext } from './Graph.context';
import {
  GraphDataset,
  GraphLabels,
  GraphLegendMap,
  GraphRangeMap,
  GraphRangeBoundsMap,
} from './Graph.types';
import { GraphLine } from './GraphLine/GraphLine';
import { GraphDoughnut } from './GraphDoughnut/GraphDoughnut';
import { ChartRange } from './ChartRange/ChartRange';
import { GraphLegend } from './GraphLegend/GraphLegend';
import { Root, Loader } from './Graph.style';

type GraphProps = PropsWithChildren<{
  className?: string;
  datasets: GraphDataset[];
  labels: GraphLabels;
  loading?: boolean;
}>;

interface GraphComponent {
  (props: GraphProps): ReactElement<any, any> | null;
  Line: typeof GraphLine;
  Doughnut: typeof GraphDoughnut;
  Range: typeof ChartRange;
  Legend: typeof GraphLegend;
}

export const Graph: GraphComponent = ({
  children,
  className,
  datasets,
  labels,
  loading = false,
}) => {
  const chart = useRef<Chart<any>>(null);

  const graph = useRef({
    chart,
  });

  const chartActions = useRef({});
  const legendActions = useRef({});

  const [legend, { set: setLegend }] = useMap<GraphLegendMap>({});
  const [range, { set: setRange }] = useMap<GraphRangeMap>({});
  const [rangeBounds, { set: setRangeBounds }] = useMap<GraphRangeBoundsMap>(
    {}
  );

  return (
    <GraphContext.Provider
      value={{
        range,
        rangeBounds,
        legend,
        graph,
        chartActions,
        legendActions,
        loading,
        datasets,
        labels,
        setLegend,
        setRangeBounds,
        setRange,
      }}
    >
      <Loader spinning={loading}>
        <Root className={className}>{children}</Root>
      </Loader>
    </GraphContext.Provider>
  );
};

Graph.Line = GraphLine;
Graph.Doughnut = GraphDoughnut;
Graph.Range = ChartRange;
Graph.Legend = GraphLegend;
