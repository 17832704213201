import {
  FC,
  useEffect,
  PropsWithChildren,
  cloneElement,
  ReactElement,
} from 'react';
import { useContextSelector } from 'use-context-selector';
import {
  DropdownContext,
  DropdownContextProps,
} from '../../../contexts/Dropdown.context';

type DropdownToggleProps = PropsWithChildren<{
  chevron?: boolean;
  loading?: boolean;
}>;

export const DropdownToggle: FC<DropdownToggleProps> = ({
  children,
  chevron = true,
  loading,
}) => {
  const set = useContextSelector<
    DropdownContextProps,
    DropdownContextProps['set']
  >(DropdownContext, ({ set }) => set);

  useEffect(() => {
    if (chevron) {
      set(
        'toggleNode',
        cloneElement(children as ReactElement, {
          loading,
          className: 'ant-dropdown-trigger-with-chevron',
        })
      );
    } else {
      set('toggleNode', children);
    }
  }, [children, chevron, loading, set]);

  return null;
};
