import { Component, PropsWithChildren, ErrorInfo, ReactElement } from 'react';
import {
  HttpProviderContext,
  HttpProviderContextProps,
} from '../../contexts/HttpProvider.context';
import { LoggerOrigin } from '../../constants/origin.const';
import { uuid } from './ErrorBoundary.utils';

interface ErrorBoundaryFallbackParams {
  id: string;
}

type ErrorBoundaryProps = PropsWithChildren<{
  params?: object;
  origin: LoggerOrigin;
  fallback: (v: ErrorBoundaryFallbackParams) => ReactElement;
}>;

interface ErrorBoundaryState {
  id: string;
  hasError: boolean;
  error: Error;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      hasError: false,
      id: null,
    };
  }

  static contextType = HttpProviderContext;

  static getDerivedStateFromError = (error: Error) => {
    return { hasError: true, error, id: uuid() };
  };

  public componentDidCatch = (error: Error, { componentStack }: ErrorInfo) => {
    const { Logger } = this.context as HttpProviderContextProps;
    const { href } = window.location;
    Logger.log({
      href,
      componentStack,
      id: this.state.id,
      errorName: error.name,
      errorStack: error.stack,
      origin: this.props.origin,
      errorMessage: error.message,
      ...this.props.params,
    });
  };

  public render = () => {
    if (this.state.hasError) {
      return this.props.fallback({ id: this.state.id });
    }
    return this.props.children;
  };
}
