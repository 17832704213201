import styled from 'styled-components';
import { Layout, Button, Badge } from '@spintel/uikit';

const { Header } = Layout;

export const Root = styled(Header)(({ theme: { spacing } }) => ({
  alignItems: 'center',
  display: 'flex',
  height: spacing(18),
  lineHeight: 1,
  padding: `0 ${spacing(6)}`,
  position: 'sticky',
  top: 0,
  zIndex: 600,
}));

export const ActivityButton = styled(Button)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}));

export const ActivityButtonBadge = styled(Badge)(({ theme: { spacing } }) => ({
  lineHeight: spacing(7),
}));
