import i18next, { i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';

export class I18N {
  private static i18n: i18n;

  public static init(lng: string, resources: PlainObject) {
    this.i18n = i18next.use(initReactI18next).use(intervalPlural);
    return this.i18n.init({
      debug: process.env.NODE_ENV === 'development',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
      react: {
        transSupportBasicHtmlNodes: false,
      },
      lng: lng,
      resources: Object.keys(resources).reduce((res, key) => {
        return {
          ...res,
          [key]: {
            translation: resources[key],
          },
        };
      }, {}),
    });
  }
}
