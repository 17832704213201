import { useContext } from 'react';
import { UseQuery, UseQueryOptions } from '@shared/types';
import { useCommonQuery } from '@shared/hooks';
import { IErrorResponse } from '../../__generated__/data-contracts';
import {
  HttpProviderContext,
  HttpServices,
} from '../../contexts/HttpProvider.context';

type CallbackReturn = (...args: any) => any;

export function useQuery<
  Req extends any[] = never[],
  Res = unknown,
  Err extends object = IErrorResponse
>(
  callback: (context: HttpServices) => CallbackReturn,
  params: Req,
  options?: UseQueryOptions
): UseQuery<Res, Err> {
  const { services } = useContext(HttpProviderContext);

  return useCommonQuery<HttpServices, Req, Res, Err>(
    services,
    callback,
    params,
    options
  );
}
