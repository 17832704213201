import styled from 'styled-components';
import { Dash as UiKitDash } from '../../Dash/Dash';

export const Root = styled('div')`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
`;

export const Dash = styled(UiKitDash)`
  color: ${({ theme }) => theme.palette.londonGrey};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;
