import {
  PropsWithChildren,
  Key,
  useState,
  useContext,
  useRef,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useImperativeHandle,
} from 'react';
import { noop } from 'lodash';
import { EmptyProps } from '../Empty/Empty';
import { UiKitLocalizationContext } from '../../contexts/UiKitLocalization.context';
import { CatalogSearch } from './CatalogSearch/CatalogSearch';
import { CatalogContent } from './CatalogContent/CatalogContent';
import { CatalogContext } from './Catalog.context';
import { CatalogOption, CatalogState, CatalogInstance } from './Catalog.types';
import {
  Root,
  ContentLeft,
  ContentRight,
  List,
  Option,
  OptionLeft,
  OptionRight,
  OptionLabel,
  OptionDescription,
  NoOptions,
  NoMatch,
  OptionIcon,
} from './Catalog.style';

export type CatalogProps = PropsWithChildren<{
  value?: Key;
  width?: number;
  loading?: boolean;
  empty?: EmptyProps;
  className?: string;
  searchRender?: () => JSX.Element;
  onChange?: (v: Key) => void;
  options: CatalogOption[];
}>;

interface CatalogComponent
  extends ForwardRefExoticComponent<
    CatalogProps & RefAttributes<CatalogInstance>
  > {
  Search?: typeof CatalogSearch;
  Content?: typeof CatalogContent;
}

export const Catalog: CatalogComponent = forwardRef<
  CatalogInstance,
  CatalogProps
>(function Catalog(
  {
    empty: emptyOptions,
    width,
    children,
    className,
    options = [],
    searchRender,
    onChange = noop,
    loading = false,
    value: catalogValue,
  },
  ref
) {
  const {
    empty: { noMatch },
  } = useContext(UiKitLocalizationContext);
  const activeRef = useRef<HTMLDivElement>();

  const [{ q, empty: emptySearch }, setState] = useState<CatalogState>({
    empty: {
      size: 'sm',
      title: noMatch,
      background: 'gradient',
    },
  });

  const total = options.length;

  const records = q
    ? options.filter(({ title }) =>
        title.toLocaleLowerCase().includes(q.toLocaleLowerCase())
      )
    : options;

  const hasNoOptions = !loading && total === 0 && emptyOptions;

  useImperativeHandle(ref, () => ({
    scrollToActive: () => {
      if (activeRef.current) {
        activeRef.current.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }
    },
  }));

  return (
    <CatalogContext.Provider value={{ width, total, loading, setState }}>
      <Root className={className}>
        {searchRender?.()}
        {!hasNoOptions && (
          <ContentLeft width={width}>
            {!loading && !!records.length && (
              <List>
                {records.map(({ icon, value, title, subtitle }) => {
                  const active = value === catalogValue;
                  return (
                    <Option
                      key={value}
                      ref={active ? activeRef : null}
                      active={active}
                      onClick={() => onChange(value)}
                    >
                      {icon && (
                        <OptionLeft>
                          <OptionIcon size="sm" name={icon} active={active} />
                        </OptionLeft>
                      )}
                      <OptionRight>
                        <OptionLabel active={active}>{title}</OptionLabel>
                        {subtitle && (
                          <OptionDescription active={active}>
                            {subtitle}
                          </OptionDescription>
                        )}
                      </OptionRight>
                    </Option>
                  );
                })}
              </List>
            )}
            {!loading && !records.length && <NoMatch {...emptySearch} />}
          </ContentLeft>
        )}
        {!hasNoOptions && <ContentRight>{children}</ContentRight>}
        {hasNoOptions && <NoOptions {...emptyOptions} />}
      </Root>
    </CatalogContext.Provider>
  );
});

Catalog.Search = CatalogSearch;
Catalog.Content = CatalogContent;
