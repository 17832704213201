import { FC, PropsWithChildren } from 'react';
import { HttpProvider as AppHttpProvider } from '@spintel/network/app';
import { HttpProvider as LoggerHttpProvider } from '@spintel/network/logger';

type HttpBootstrapProps = PropsWithChildren<Partial<Window['APP_CONFIG']>>;

export const HttpBootstrap: FC<HttpBootstrapProps> = ({
  children,
  apiBase,
  apiHost,
  loggingBase,
  loggingHost,
}) => {
  return (
    <LoggerHttpProvider host={loggingHost} base={loggingBase}>
      <AppHttpProvider host={apiHost} base={apiBase}>
        {children}
      </AppHttpProvider>
    </LoggerHttpProvider>
  );
};
