import { lazy } from 'react';
import { RoutePropsWithChildren } from '@spintel/navigation';
import { RoutePath } from '@common/constants';

const Song = lazy(() =>
  import('./Song/Song').then((m) => ({ default: m.Song }))
);

const SongOverview = lazy(() =>
  import('./Song/Overview/Overview').then((m) => ({ default: m.Overview }))
);

const SongStationAirplay = lazy(() =>
  import('./Song/StationAirplay/StationAirplay').then((m) => ({
    default: m.StationAirplay,
  }))
);

const SongOwnership = lazy(() =>
  import('./Song/Ownership/Ownership').then((m) => ({
    default: m.Ownership,
  }))
);

const SongAddOpportunities = lazy(() =>
  import('./Song/AddOpportunities/AddOpportunities').then((m) => ({
    default: m.AddOpportunities,
  }))
);

const SongPlayIncrease = lazy(() =>
  import('./Song/PlayIncrease/PlayIncrease').then((m) => ({
    default: m.PlayIncrease,
  }))
);

const SongComparison = lazy(() =>
  import('./Song/SongComparison/SongComparison').then((m) => ({
    default: m.SongComparison,
  }))
);

const SongCompetition = lazy(() =>
  import('./Song/Competition/Competition').then((m) => ({
    default: m.Competition,
  }))
);

export const ROUTES: RoutePropsWithChildren[] = [
  {
    element: <Song />,
    path: RoutePath.SONG,
    childRoutes: [
      {
        element: <SongOverview />,
        index: true,
      },
      {
        element: <SongStationAirplay />,
        path: RoutePath.SONG_STATION_AIRPLAY,
      },
      {
        element: <SongOwnership />,
        path: RoutePath.SONG_OWNERSHIP,
      },
      {
        element: <SongAddOpportunities />,
        path: RoutePath.SONG_ADD_OPPORTUNITIES,
      },
      {
        element: <SongPlayIncrease />,
        path: RoutePath.SONG_PLAY_INCREASE,
      },
      {
        element: <SongComparison />,
        path: RoutePath.SONG_SONG_COMPARISON,
      },
      {
        element: <SongCompetition />,
        path: RoutePath.SONG_COMPETITION,
      },
    ],
  },
];
