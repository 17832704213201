module.exports = {
  fileSuccessfullyExported: '{{filename}} has been successfully exported',
  invitationSent: 'Invitation has been send',
  invitationsSent: 'Invitations have been send',
  panelDeleted: '{{panelName}} has been deleted',
  panelRemovedFromTeam: '{{panelName}} has been removed from team',
  passwordSuccessfullyUpdated: 'Your password was successfully updated!',
  sessionTimeout: 'Your session has timed out. Please, login again.',
  somethingWentWrong: 'Something went wrong',
  songComparisonsSuccessfullyAdd: `
    (0-1)[{{songName}} - {{artistName}} has been added to song comparison];
    (2-inf)[{{count}} songs have been added to song comparison]`,
  songComparisonsSuccessfullyRemove:
    '{{songName}} - {{artistName}} has been removed from song comparison',
  stationChanged: '{{label}} has been changed',
  stationCreated: '{{label}} has been created',
  teamChanged: '{{oldName}} team changed name to {{newName}} team',
  teamCreated: '{{name}} team has been created',
  teamDeleted: '{{name}} team has been deleted',
  teamMemberAdded_one: '{{memberName}} has been added to {{teamName}} team',
  teamMemberAdded_other:
    '{{memberName}} +{{additionally}} members have been added to {{teamName}} team',
  teamMemberAdded_zero: 'You have been added to {{teamName}} team',
  teamMemberRemoved: '{{memberName}} has been removed from {{teamName}} team',
  teamPanelAdded_one: '{{name}} panel has been added to {{teamName}} team',
  teamPanelAdded_other:
    '{{name}} +{{additionally}} panel have been added to {{teamName}} team',
  teamPanelRemoved: '{{panelName}} has been removed from {{teamName}}',
  teamSongAdded_one:
    '{{rank}}. {{songName}} - {{artistName}} has been added to {{teamName}} team',
  teamSongAdded_other:
    '{{rank}}. {{songName}} - {{artistName}} +{{additionally}} songs have been added to {{teamName}} team',
  teamSongRemoved:
    '{{rank}}. {{songName}} - {{artistName}} has been removed from {{teamName}} team',
  userActivated: '{{name}} has been  activated',
  userDeactivated: '{{name}} has been deactivated',
  userInvited: '{{email}} has been invited',
};
