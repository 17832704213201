import { useEffect } from 'react';
import { useAuth } from '../useAuth/useAuth.hook';
import { useQuery } from '../useQuery/useQuery.hook';
import { useAuthToken } from '../useAuthToken/useAuthToken.hook';

export const useLogout = () => {
  const [user] = useAuth();
  const [, setToken] = useAuthToken();

  const query = useQuery(({ Auth }) => Auth.logoutCreate, [], {
    manual: !user,
  });

  const [{ data }] = query;

  useEffect(() => {
    if (data) setToken({});
  }, [data, setToken]);

  return query;
};
