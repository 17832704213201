import {
  FC,
  PropsWithChildren,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import { Typography } from '../../Typography/Typography';
import { Tooltip } from '../../Tooltip/Tooltip';
import { WithEllipsis } from './DescriptionValue.style';

type DescriptionValueProps = PropsWithChildren<{
  ellipsis: boolean;
}>;

const { Body3 } = Typography;

export const DescriptionValue: FC<DescriptionValueProps> = ({
  children,
  ellipsis,
}) => {
  const [tooltip, setTooltip] = useState<string>(null);
  const ref = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    const { current } = ref;
    if (current && current.offsetWidth < current.scrollWidth) {
      setTooltip(ref.current.innerText);
    }
  }, []);

  if (ellipsis) {
    return (
      <Tooltip content={tooltip} placement="top">
        <WithEllipsis ref={ref}>{children}</WithEllipsis>
      </Tooltip>
    );
  }

  return <Body3>{children}</Body3>;
};
