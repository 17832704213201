import styled, { css } from 'styled-components';
import { SegmentedLayout } from '../Segmented.types';
import { Button } from '../../Button/Button';

const RootVerticalMixin = css`
  flex-direction: column;
  align-items: flex-start;
`;

export const Root = styled('div').withConfig({
  shouldForwardProp: (prop) => !['wrap'].includes(prop),
})<{ layout: SegmentedLayout; wrap: boolean }>`
  display: flex;
  gap: ${({ theme: { spacing } }) => spacing(2)};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
  ${({ layout }) => layout === 'vertical' && RootVerticalMixin}
`;

const OptionSmMixin = css<{ withStatus: boolean }>`
  &.ant-btn {
    padding-left: ${({ withStatus, theme: { spacing } }) =>
      spacing(withStatus ? 0.75 : 3)};
  }
`;

const OptionDarkenMixin = css`
  &.ant-btn {
    background-color: ${({ theme }) => theme.palette.metropolisGrey};
    &:hover {
      background-color: ${({ theme }) => theme.palette.metropolisGrey};
    }
  }
`;

export const Option = styled(Button).withConfig({
  shouldForwardProp: (prop) =>
    !['withStatus', 'darken', 'active'].includes(prop),
})<{
  withStatus: boolean;
  darken: boolean;
  active: boolean;
}>`
  ${({ size }) => size === 'sm' && OptionSmMixin}
  ${({ darken, active }) => darken && !active && OptionDarkenMixin}
`;
