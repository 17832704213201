module.exports = {
  addedSongsNeverReachedRank: 'The songs you added never reached rank 90',
  addedSongsNoPlays: 'The songs you added have no plays for the comparison',
  comingSoon: {
    description:
      'This module will be available here soon. In the meantime, you can visit other helpful modules.',
    title: 'Coming soon...',
  },
  contentLoading: 'Content loading...',
  errorBoundary: {
    extra: 'Error ID: {{id}}',
    message:
      'If you continue to experience this issue, please reach out to Spintel <nl /> team for assistance and provide the error ID for faster resolution.',
    title: 'Oops, something went wrong!',
  },
  noAccess: 'No access to this view',
  noComparedSong: {
    description: 'Select song on graph to show comparison data',
    title: 'There’s no song to compare',
  },
  noData: 'No data',
  noMembers: {
    subtitle: 'Invite the first one by clicking on the button below.',
    title: 'No members yet',
  },
  noMembersFoundForYourRequest: 'No members found for your request',
  noMembersTeam: {
    subtitle: 'Add members by clicking on the button below',
    title: 'No members yet',
  },
  noOwnersToDisplay: 'No owners to display',
  noPanels: {
    subtitle: 'Create the first one by clicking on the button below',
    title: 'No panels yet',
  },
  noPanelsFoundForYourRequest: {
    title: 'No panels found for your request',
  },
  noResultsMatch: 'No results match',
  noResultsMatchThisFilterCriteria: 'No results match this filter criteria',
  noSongDataToDisplay: 'No song data to display',
  noSongs: {
    subtitle: 'Add songs by clicking on the button below',
    title: 'No songs yet',
  },
  noSongsFoundForYourRequest: 'No songs found for your request',
  noSongsToDisplay: 'No songs to display',
  noStations: {
    subtitle: 'No stations yet',
    title: 'Create the first one by clicking on the button below',
  },
  noStationsFoundForYourRequest: 'No stations found for your request',
  noStationsToDisplay: 'No stations to display',
  noTeams: {
    noAccess: 'You should be added to team to start work with',
    subtitle: 'Create the first one by clicking on the button below',
    title: 'No teams yet',
  },
  noTeamsFoundForYourRequest: 'No teams found for your request',
  noTeamsMySongs: {
    noAccess: 'You should be added to team to start work with “My songs”',
    title: 'No teams yet',
  },
  panelRemoved: {
    subtitle:
      'Panel was removed from your account. You can select a panel you want to continue with below.',
    title: 'Current panel was removed',
  },
  songComparison: {
    subtitle: `To add songs click "Add songs to compare" button placed below`,
    title: 'There’s no songs to compare',
  },
  teamsAddSong: 'Add a song to your team to start working on your progress',
  teamsStart:
    'To start working on your progress create a team and add your songs to this team',
  updating: 'Updating...',
};
