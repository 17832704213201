import { Key, useState, useContext, useEffect } from 'react';
import { isEqual } from 'lodash';
import AntdForm from 'antd/lib/form';
import { UiKitLocalizationContext } from '../../../../contexts/UiKitLocalization.context';
import { Form } from '../../../Form/Form';
import { Button } from '../../../Button/Button';
import { Empty } from '../../../Empty/Empty';
import { TableFilterComponent } from '../TableFilters.types';
import {
  Root,
  Content,
  Options,
  Group,
  Toggle,
  Footer,
  NoData,
} from '../TableFilters.style';

const { useForm, useWatch } = AntdForm;

export const TableFilterOptions: TableFilterComponent = ({
  filter: { options } = {},
  confirm,
  selectedKeys,
  setSelectedKeys,
  visible,
}) => {
  const [form] = useForm<{ selectedKeys: Key[] }>();
  const selected = useWatch('selectedKeys', form) || [];
  const [all, toggle] = useState(false);
  const { table } = useContext(UiKitLocalizationContext);

  const indeterminate = (() => {
    if (selected.length) {
      return selected.length !== options.length;
    }
    return false;
  })();

  const handleOnSubmit = ({ selectedKeys }: { selectedKeys: Key[] }) => {
    setSelectedKeys(selectedKeys);
    confirm({ closeDropdown: true });
  };

  const handleOnToggle = (v: boolean) => {
    form.setFields([
      {
        name: 'selectedKeys',
        value: v && !indeterminate ? options.map(({ value }) => value) : [],
      },
    ]);
    toggle(v && !indeterminate);
  };

  useEffect(() => {
    if (visible) form.setFieldsValue({ selectedKeys });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const hasOptions = !!options.length;

  useEffect(() => {
    toggle(!!selected.length);
  }, [selected.length]);

  return (
    <Root>
      <Form
        form={form}
        initialValues={{ selectedKeys }}
        onSubmit={handleOnSubmit}
      >
        <Content empty={!hasOptions}>
          {hasOptions && (
            <Toggle
              value={all}
              indeterminate={indeterminate}
              onChange={handleOnToggle}
            >
              {table.dropdownFilterToggleAll}
            </Toggle>
          )}
          {!hasOptions && (
            <NoData>
              <Empty title={table.dropdownFilterEmpty} />
            </NoData>
          )}
          {hasOptions && (
            <Options>
              <Group name="selectedKeys" options={options} />
            </Options>
          )}
        </Content>
        <Footer>
          <Button onClick={() => confirm({ closeDropdown: true })}>
            {table.dropdownFilterCancel}
          </Button>
          <Form.Submit
            variant="primary"
            disabled={isEqual(selectedKeys, selected)}
          >
            {table.dropdownFilterConfirm}
          </Form.Submit>
        </Footer>
      </Form>
    </Root>
  );
};
