import { UseQuery } from '@shared/types';
import { useQuery } from '../useQuery/useQuery.hook';
import {
  IEmailVerificationDto,
  IEmailVerificationResponseDto,
  IErrorResponse,
} from '../../__generated__/data-contracts';
import { RequestParams } from '../../__generated__/http-client';
import { useAuth } from '../useAuth/useAuth.hook';

import { UseEmailVerificationParams } from './useEmailVerification.types';

export const useEmailVerification = ({
  token,
}: UseEmailVerificationParams): UseQuery<
  IEmailVerificationResponseDto,
  IErrorResponse
> => {
  const [user] = useAuth();

  return useQuery<
    [IEmailVerificationDto, RequestParams],
    IEmailVerificationResponseDto
  >(
    ({ Auth }) => Auth.emailVerificationCreate,
    [
      { token },
      {
        headers: {
          Intercept: false,
        },
      },
    ],
    {
      manual: !!user,
    }
  );
};
