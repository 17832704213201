import { ReactElement, ReactNode, Dispatch, SetStateAction } from 'react';
import { noop } from 'lodash';
import { createContext } from 'use-context-selector';
import { DropdownState } from '../components/Dropdown/Dropdown.types';

export interface DropdownContextProps {
  set: (key: keyof DropdownState, el: ReactElement | ReactNode) => void;
  toggle: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

export const DropdownContext = createContext<DropdownContextProps>({
  set: noop,
  toggle: noop,
  isOpen: false,
});
