import { FC, PropsWithChildren } from 'react';
import { GridRow } from './GridRow/GridRow';
import { GridCol } from './GridCol/GridCol';
import { Root } from './Grid.style';

type GridProps = PropsWithChildren<{
  gap?: number;
}>;

interface GridComponents {
  Row: typeof GridRow;
  Col: typeof GridCol;
}

export const Grid: FC<GridProps> & GridComponents = ({
  gap = 24,
  children,
}) => {
  return <Root gap={gap}>{children}</Root>;
};

Grid.Row = GridRow;
Grid.Col = GridCol;
