import { FC, useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import {
  TableContext,
  TableContextProps,
  TableLocalContext,
  TableLocalContextProps,
} from '../../../../contexts/Table.context';
import { TableFilterValue } from '../../Table.types';
import {
  Root,
  Text,
  Title,
  Value,
  Remove,
} from './TableFiltersHeaderTag.style';
import {
  findColumnByDataIndex,
  getTableFiltersHeaderTagTitle,
  getTableFiltersHeaderTagRangeValue,
} from './TableFiltersHeaderTag.utils';

interface TableFiltersHeaderTagProps {
  onRemove: () => void;
  filter: TableFilterValue;
  dataIndex: string | string[];
}

export const TableFiltersHeaderTag: FC<TableFiltersHeaderTagProps> = ({
  onRemove,
  dataIndex,
  filter: [condition, ...rest],
}) => {
  const columns = useContextSelector<
    TableLocalContextProps,
    TableLocalContextProps['columns']
  >(TableLocalContext, ({ columns }) => columns);

  const globalColumns = useContextSelector<
    TableContextProps,
    TableContextProps['columns']
  >(TableContext, ({ columns }) => columns);

  const title = getTableFiltersHeaderTagTitle(
    columns.length ? columns : globalColumns,
    dataIndex
  );

  const column = useMemo(
    () =>
      findColumnByDataIndex(
        columns.length ? columns : globalColumns,
        dataIndex
      ),
    [columns, globalColumns, dataIndex]
  );

  const value = useMemo(() => {
    if (typeof condition === 'object' && condition !== null) {
      return getTableFiltersHeaderTagRangeValue(condition);
    } else {
      const options = column?.filter?.options || [];
      const label = !!options.length
        ? options.find(({ value }) => value === condition)?.label
        : condition;
      return [label].concat(rest.length ? [`+${rest.length}`] : []).join(', ');
    }
  }, [column, condition, rest.length]);

  return (
    <Root>
      <Text>
        <Title>{title}</Title>
        <Value>{value}</Value>
      </Text>
      <Remove name="close" size="sm" onClick={onRemove} />
    </Root>
  );
};
