import { useCallback, useState } from 'react';
import { useMount } from 'react-use';
import { DTO, useMutation } from '@spintel/network/app';

interface UseSongsListState {
  loading: boolean;
  total: number;
  songs: DTO.ISongSelectorModel[];
}

type UseSongsListReturnType = [
  UseSongsListState,
  {
    search: (query: string) => void;
    load: () => void;
  }
];

export const DEFAULT_REQUEST: DTO.IStringPagedRequest = {
  query: '',
  pageSize: 150,
  pageNumber: 1,
  sort: {
    column: 'rank',
    order: DTO.IPagedSortOrder.Ascend,
  },
};

export const useSongsList = (): UseSongsListReturnType => {
  const [songs, setSongs] = useState<DTO.ISongSelectorModel[]>([]);

  const [request, setRequest] =
    useState<DTO.IStringPagedRequest>(DEFAULT_REQUEST);

  const [{ loading }, fetch] = useMutation<
    [DTO.IStringPagedRequest],
    DTO.ISongSelectorModel[]
  >(({ SongSelector }) => SongSelector.searchCreate);

  const load = useCallback(() => {
    const req: DTO.IStringPagedRequest = {
      ...request,
      pageNumber: request.pageNumber + 1,
    };
    fetch([req], {
      onSuccess: ({ data }) => {
        setSongs((prev) => prev.concat(data));
        setRequest(req);
      },
    });
  }, [request, fetch]);

  const search = useCallback(
    (query: string) => {
      const req = { ...DEFAULT_REQUEST, query };
      fetch([{ ...DEFAULT_REQUEST, query }], {
        onSuccess: ({ data }) => {
          setSongs(data);
          setRequest(req);
        },
      });
    },
    [fetch]
  );

  const total = request.query && !songs.length ? 0 : Number.MAX_SAFE_INTEGER;

  useMount(() => {
    fetch([request], {
      onSuccess: ({ data }) => setSongs(data),
    });
  });

  return [
    { loading, songs, total },
    { load, search },
  ];
};
