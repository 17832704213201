import { FC, PropsWithChildren } from 'react';
import { merge } from 'lodash';
import {
  UiKitLocalizationContext,
  FormValidationErrorType,
  defaultFormValidationError,
} from '../../contexts/UiKitLocalization.context';
import {
  TableLocalization,
  FormItemPlaceholder,
  DropdownLocalization,
  TooltipLocalization,
  EmptyLocalization,
} from '../../types/localization.types';
import {
  TABLE_LOCALIZATION,
  FORM_VALIDATE_MESSAGES,
  FORM_ITEM_PLACEHOLDER,
  DROPDOWN_LOCALIZATION,
  TOOLTIP_LOCALIZATION,
  EMPTY_LOCALIZATION,
} from '../../constants/localization.const';

type UiKitLocalizationProviderProps = PropsWithChildren<{
  formValidationMessages?: typeof FORM_VALIDATE_MESSAGES;
  formValidationError?: FormValidationErrorType;
  dropdown?: DropdownLocalization;
  table?: TableLocalization;
  formItemPlaceholder?: FormItemPlaceholder;
  tooltip?: TooltipLocalization;
  empty?: EmptyLocalization;
}>;

export const UiKitLocalizationProvider: FC<UiKitLocalizationProviderProps> = ({
  children,
  empty = {},
  table = {},
  tooltip = {},
  dropdown = {},
  formValidationMessages = FORM_VALIDATE_MESSAGES,
  formValidationError = defaultFormValidationError,
  formItemPlaceholder = FORM_ITEM_PLACEHOLDER,
}) => {
  return (
    <UiKitLocalizationContext.Provider
      value={{
        formItemPlaceholder,
        formValidationError,
        formValidationMessages,
        empty: merge({}, EMPTY_LOCALIZATION, empty),
        dropdown: merge({}, DROPDOWN_LOCALIZATION, dropdown),
        table: merge({}, TABLE_LOCALIZATION, table),
        tooltip: merge({}, TOOLTIP_LOCALIZATION, tooltip),
      }}
    >
      {children}
    </UiKitLocalizationContext.Provider>
  );
};
