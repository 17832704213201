import { ScaleChartOptions } from 'chart.js';
import { THEME } from '../../../constants/theme.const';
import { GraphLineAxis, GraphLineVariant } from './GraphLine.types';

type GraphLineScales = ScaleChartOptions<'line'>['scales'];
type GraphLineScalesTicks = GraphLineScales['0']['ticks'];

const DEFAULT_SCALES_TICKS: DeepPartial<GraphLineScalesTicks> = {
  autoSkip: true,
  color: `${THEME.palette.white}40`,
  maxRotation: 0,
  minRotation: 0,
  font: {
    size: 10,
    weight: '400',
    lineHeight: '14px',
    family: 'Montserrat',
  },
};

export const DEFAULT_SCALES: Record<
  GraphLineAxis,
  DeepPartial<GraphLineScales['0']>
> = {
  x: {
    grid: {
      display: false,
      drawBorder: false,
    },
    ticks: DEFAULT_SCALES_TICKS,
  },
  y: {
    grid: {
      display: false,
      drawBorder: false,
    },
    ticks: DEFAULT_SCALES_TICKS,
  },
};

export const DEFAULT_VARIANT: GraphLineVariant = {
  color: 'spintelGreen',
  datalabel: {},
};

export const ACTIVE_LINE_WIDTH = 4;
export const DEFAULT_LINE_WIDTH = 2;
