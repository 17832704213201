import { FC, PropsWithChildren } from 'react';
import { DTO } from '@spintel/network/app';
import { useUser } from '@spintel/network/auth';
import { PanelPrompt } from './PanelPrompt/PanelPrompt';

type PromptProps = PropsWithChildren<{
  loading: boolean;
  panels: DTO.IPanelInfoModel[];
}>;

export const Prompt: FC<PromptProps> = ({ panels, loading, children }) => {
  const [
    {
      user: { selectedPanelId },
    },
  ] = useUser();

  const panelPrompt =
    !panels.find(({ id }) => id === selectedPanelId) && !loading;

  if (panelPrompt) {
    return <PanelPrompt />;
  }

  return <>{children}</>;
};
