import { createContext } from 'react';
import { noop } from 'lodash';

interface DrawerContextProps {
  name: string;
  onClose: () => void;
}

export const DrawerContext = createContext<DrawerContextProps>({
  name: null,
  onClose: noop,
});
