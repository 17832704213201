import { useRef, useCallback, useMemo } from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import { sortBy, merge } from 'lodash';
import { useQuery as useReactQuery, QueryObserverResult } from 'react-query';
import { UseQuery, UseQueryOptions } from '../../types/hooks.types';

type CallbackReturn = (...args: any) => any;

export function useCommonQuery<
  T,
  Req extends any[] = never[],
  Res = unknown,
  Err extends object = {}
>(
  context: T,
  callback: (context: T) => CallbackReturn,
  params: Req,
  {
    manual = false,
    cache = false,
    polling = 0,
    queryKeySalt = '',
  }: UseQueryOptions = {}
): UseQuery<Res, Err> {
  const { current: name } = useRef(
    `${callback.toString().replace(/\n/g, ' ').toLowerCase()}${queryKeySalt}`
  );

  const { data, isLoading, isFetching, isFetched, error, refetch } =
    useReactQuery<AxiosResponse<Res>, AxiosError<Err>>(
      [name, ...sortBy(params)],
      () => {
        return callback(context)(...params);
      },
      {
        enabled: !manual,
        refetchInterval: polling || false,
        refetchOnWindowFocus: false,
        retry: false,
        keepPreviousData: true,
        ...(!cache ? { cacheTime: 0 } : {}),
      }
    );

  const execute = useCallback(
    (
      requestParams = params
    ): Promise<QueryObserverResult<AxiosResponse<Res>, AxiosError>> => {
      return refetch({ queryKey: [name, requestParams] });
    },
    [params, name, refetch]
  );

  return useMemo(
    (): UseQuery<Res, Err> => [
      {
        data: data?.data,
        error: error
          ? merge(
              {
                message: 'Something went wrong',
              },
              error?.response?.data
            )
          : error?.response?.data,
        loading: isLoading || isFetching,
        isFetched,
      },
      execute,
    ],
    [data, isLoading, isFetching, isFetched, error, execute]
  );
}
