import styled from 'styled-components';
import { FormRowGutter } from './FormRow.types';
import { GUTTER_MAP } from './FormRow.const';

export const Root = styled('div')<{ cols: number[]; gutter: FormRowGutter }>`
  display: grid;
  box-sizing: border-box;
  grid-template-columns: ${({ cols }) =>
    cols
      .reduce((res, col) => {
        return [...res, `${col / 12}fr`];
      }, [])
      .join(' ')};
  gap: ${({ gutter }) => GUTTER_MAP[gutter]}px;
`;
