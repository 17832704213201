import { forwardRef, useRef, useImperativeHandle } from 'react';
import { difference, intersection } from 'lodash';
import { Dropdown, Button, DropdownInstance, useForm } from '@spintel/uikit';
import { useTranslation } from '@spintel/localization';
import { Tooltip } from '@common/features';
import { DTO, useMutation, useQuery } from '@spintel/network/app';
import { MAXIMUM_COUNT_OF_SONGS } from './SongComparisonSelector.const';
import { SongComparisonSelectorInstance } from './SongComparisonSelector.types';
import { SongComparisonSelectorOverlay } from './SongComparisonSelectorOverlay/SongComparisonSelectorOverlay';
import { StyledConfirm } from './SongComparisonSelector.styles';
import { useAddSongsNotification } from './SongComparisionSelector.hooks';

interface SongComparisonSelectorProps {
  songId: number;
  onChange: () => void;
  placement?: 'bottomCenter' | 'topCenter' | 'bottom';
  isEmptyStyle?: boolean;
}

export const SongComparisonSelector = forwardRef<
  SongComparisonSelectorInstance,
  SongComparisonSelectorProps
>(function StationDetailsSongComparisonSelector(
  { songId, onChange, placement, isEmptyStyle },
  ref
) {
  const { t } = useTranslation();
  const [form] = useForm();
  const dropdown = useRef<DropdownInstance>();

  const [{ data: songs = [], loading }, refetchSongs] = useQuery<
    [number],
    DTO.ISongComparisonModel[]
  >(({ SongComparison }) => SongComparison.songComparisonDetail, [songId]);

  const [{ loading: updating }, update] = useMutation<
    [number, DTO.ISongComparisonsRequestDto]
  >(({ SongComparison }) => SongComparison.postSongComparison);

  const disabled = loading || songs.length === MAXIMUM_COUNT_OF_SONGS;

  const [pushAddSongsNotification] = useAddSongsNotification({ songs });

  const handleOnConfirm = () => {
    const _current = songs.map(({ songId }) => songId);
    const _selection = form.getFieldValue('songComparisons');
    const _intersection = intersection(_selection, _current);
    const _difference = difference(_selection, _intersection);
    update([songId, { songComparisons: _difference }], {
      onSuccess: () => {
        pushAddSongsNotification(_difference);
        refetchSongs();
        onChange();
      },
    });
  };

  useImperativeHandle(ref, () => ({
    refresh: () => refetchSongs(),
    toggle: (v) => dropdown.current.toggle(v),
  }));

  const tooltip =
    songs.length === MAXIMUM_COUNT_OF_SONGS &&
    t('hints.songComparisonLimit', {
      count: MAXIMUM_COUNT_OF_SONGS,
    });

  return (
    <Dropdown ref={dropdown} disabled={disabled} placement={placement}>
      <Dropdown.Toggle chevron={false}>
        <div>
          <Tooltip content={tooltip}>
            <Button loading={updating} disabled={disabled}>
              {t('actions.addSongsToCompare')}
            </Button>
          </Tooltip>
        </div>
      </Dropdown.Toggle>
      <StyledConfirm
        width={400}
        onConfirm={handleOnConfirm}
        isEmptyStyle={isEmptyStyle}
        hint={t('hints.maximumCountOfSongs', {
          count: MAXIMUM_COUNT_OF_SONGS,
        })}
      >
        <SongComparisonSelectorOverlay
          form={form}
          songId={songId}
          selectedSongIds={songs.map(({ songId }) => songId)}
        />
      </StyledConfirm>
    </Dropdown>
  );
});
