import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useContext,
  CSSProperties,
} from 'react';
import { CardContext } from '../TabCard.context';
import { Root } from './TabCardFooter.style';

type CardFooterProps = PropsWithChildren<{
  className?: string;
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
}>;

export const CardFooter: FC<CardFooterProps> = ({
  children,
  className,
  align = 'center',
  justify = 'flex-end',
}) => {
  const { setPartition } = useContext(CardContext);

  useEffect(() => {
    setPartition(
      'footer',
      <Root align={align} justify={justify} className={className}>
        {children}
      </Root>
    );
    return () => setPartition('footer', null);
  }, [children, setPartition, className, align, justify]);

  return null;
};
