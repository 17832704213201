import { FC } from 'react';
import { Splash } from '@spintel/uikit';
import { useRoute } from '@spintel/navigation';
import { useLogout, useAuth, AuthEvent } from '@spintel/network/auth';
import { Redirect } from '@spintel/navigation';
import { AuthRoutePath } from '@common/constants';
import { LogoutRouteSearch } from './Logout.types';

export const Logout: FC = () => {
  const [{ data }] = useLogout();
  const [user] = useAuth();
  const [
    {
      search: { reason },
    },
  ] = useRoute<{}, LogoutRouteSearch>();

  if (!user && data) {
    if (reason === AuthEvent.sessionTimeout) {
      return <Redirect to={AuthRoutePath.SESSION_TIMEOUT} />;
    }
    if (reason === AuthEvent.userIsLocked) {
      return <Redirect to={AuthRoutePath.FORGOT_PASSWORD} />;
    }
    return <Redirect to={AuthRoutePath.LOGIN} />;
  }

  if (!user && !data) {
    return <Redirect to={AuthRoutePath.LOGIN} />;
  }

  return <Splash />;
};
