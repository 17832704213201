import { ReactNode } from 'react';
import { truncate } from 'lodash';
import { Text } from './Tooltip.style';
import { PREVIEW_TEXT_LENGTH } from './Tooltip.const';

export const getPreview = ({
  v,
  truncated,
}: {
  v: string[];
  truncated: boolean;
}): ReactNode[] => {
  if (!truncated) {
    return v.map((value, index) => (
      <Text key={`${v}-${index}`} color="metropolisGrey">
        {value}
      </Text>
    ));
  }

  const { value } = v.reduce(
    ({ max, value }, str) => {
      if (max > 0) {
        const text = truncate(str, { length: max });
        return { max: max - str.length, value: [...value, text] };
      }
      return { max, value };
    },
    { max: PREVIEW_TEXT_LENGTH, value: [] }
  );
  return value.map((v, index) => (
    <Text key={`${v}-${index}`} color="metropolisGrey">
      {v}
    </Text>
  ));
};
