import { FC } from 'react';
import { useTheme } from 'styled-components';
import Row, { RowProps } from 'antd/lib/grid/row';
import { calcGutter } from './GridRow.utils';

export const GridRow: FC<RowProps> = ({ gutter, ...props }) => {
  const theme = useTheme();
  const gutterAdjustedWithTheme = gutter ? calcGutter(gutter, theme) : 0;

  return <Row gutter={gutterAdjustedWithTheme} {...props} />;
};
