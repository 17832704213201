import { ReactElement } from 'react';
import { TableColumnEmpty, TableColumnType } from '../../Table.types';
import { Dash } from '../../../Dash/Dash';

interface TableSummaryCellProps<T extends object> {
  value?: null | number;
  empty?: TableColumnEmpty;
  render?: TableColumnType<T>['summary']['render'];
  data: readonly T[];
}

interface TableSummaryCellComponent {
  <T extends object>(props: TableSummaryCellProps<T>): ReactElement<
    any,
    any
  > | null;
}

export const TableSummaryCell: TableSummaryCellComponent = ({
  data,
  value,
  empty,
  render,
}) => {
  if (value === null && empty?.null === 'dash') {
    return <Dash />;
  }

  if (value === null && empty?.null === 'zero') {
    return <>0</>;
  }

  if (render) {
    return <>{render({ value, data })}</>;
  }

  return null;
};
