import styled, {
  css,
  FlattenInterpolation,
  ThemeProps,
  DefaultTheme,
} from 'styled-components';
import AntdEmpty from 'antd/lib/empty';
import { Typography } from '../Typography/Typography';
import { EmptyRoot, EmptySize, EmptyBackground } from './Empty.types';
import { SIZE_SPACING_MAP } from './Empty.const';

const { Body3 } = Typography;

interface RootProps {
  fill: boolean;
  size: EmptySize;
  center: boolean;
  background: EmptyBackground;
}

const RootCenterMixin = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const RootFillMixin = css`
  flex: 1 1 auto;
  height: 100%;
`;

const BACKGROUND_MAP_MIXINS: Record<
  EmptyBackground,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  'solid-outer': css`
    background: ${({ theme }) => theme.palette.anthraciteGrey};
  `,
  'solid-inner': css`
    background: ${({ theme }) => theme.palette.icarusGrey};
  `,
  gradient: css`
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.palette.icarusGrey} 0%,
      rgba(34, 34, 34, 0) 100%
    );
  `,
  transparent: css``,
};

export const Root = styled<EmptyRoot>(AntdEmpty).withConfig({
  shouldForwardProp: (prop) => !['center', 'fill'].includes(prop),
})<RootProps>`
  margin: 0;
  padding: ${({ size, theme: { spacing } }) => spacing(SIZE_SPACING_MAP[size])}
    0;
  .ant-empty-image {
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom: ${({ theme: { spacing } }) => spacing(4)};
  }
  .ant-empty-footer {
    margin-top: ${({ theme: { spacing } }) => spacing(4)};
  }
  ${({ background }) => BACKGROUND_MAP_MIXINS[background]}
  ${({ center }) => center && RootCenterMixin}
  ${({ fill }) => fill && RootFillMixin}
`;

export const Image = styled('span')`
  display: flex;
  width: ${({ theme: { spacing } }) => spacing(14)};
  height: ${({ theme: { spacing } }) => spacing(14)};
  border-radius: ${({ theme: { spacing } }) => spacing(7)};
  background: ${({ theme }) => theme.palette.londonGrey};
  align-items: center;
  justify-content: center;
`;

export const Message = styled(Body3)`
  color: ${({ theme }) => theme.palette.londonGrey};
  & + & {
    margin-top: ${({ theme: { spacing } }) => spacing(1)};
  }
`;
