import { useMemo } from 'react';
import { castArray, noop } from 'lodash';
import { AxiosResponse, AxiosError } from 'axios';
import { useMutation as useReactQueryMutation } from 'react-query';
import { UseDownload, UseDownloadOptions } from '../../types/hooks.types';
import { getFilename } from './useCommonDownload.utils';

type CallbackReturn = (...args: any) => any;

export function useCommonDownload<
  T,
  Req extends any[] = never[],
  Err extends object = {}
>(
  context: T,
  callback: (context: T) => CallbackReturn,
  { type, onSuccess = noop }: UseDownloadOptions
): UseDownload<Req, Blob, Err> {
  const { data, isLoading, error, mutate } = useReactQueryMutation<
    AxiosResponse<Blob>,
    AxiosError<Err>,
    Req
  >(
    (req) => {
      return callback(context)(...castArray(req));
    },
    {
      onSuccess: ({ headers, data }) => {
        const filename = getFilename(headers);
        const url = window.URL.createObjectURL(
          new Blob([data as Blob], { type })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        onSuccess({ filename });
        setTimeout(() => document.body.removeChild(link), 3000);
      },
    }
  );

  return useMemo(
    (): UseDownload<Req, Blob, Err> => [
      {
        data: data?.data,
        error: error?.response?.data,
        loading: isLoading,
      },
      mutate,
    ],
    [data, isLoading, error, mutate]
  );
}
