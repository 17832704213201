import {
  ReactNode,
  ReactElement,
  useRef,
  useLayoutEffect,
  useContext,
} from 'react';
import IMask from 'imask';
import AntdForm from 'antd/lib/form';
import { isUndefined } from 'lodash';
import { InputRef } from 'antd/lib/input';
import { useMount } from 'react-use';
import { FormItem, FormItemProps } from '../FormItem/FormItem';
import { FormInputValidationRules } from '../FormItem/FormItem.types';
import { RootInput as Input } from '../../Input/Input.style';
import { UiKitLocalizationContext } from '../../../contexts/UiKitLocalization.context';
import { getPrefixOrSuffix } from './FormInput.utils';

const { useFormInstance } = AntdForm;

interface FormInputProps<T> extends FormItemProps<FormInputValidationRules<T>> {
  prefix?: ReactNode;
  suffix?: ReactNode;
  mask?: string;
  maxLength?: number;
  autofocus?: boolean;
  transform?: (value: string) => string;
}

interface FormInputComponent {
  <T>(props: FormInputProps<T>): ReactElement<any, any> | null;
}

export const FormInput: FormInputComponent = ({
  disabled,
  placeholder: initialPlaceholder,
  suffix,
  prefix,
  mask,
  maxLength,
  autofocus,
  transform,
  ...props
}) => {
  const form = useFormInstance();
  const ref = useRef<InputRef>();
  const maskRef = useRef<
    IMask.InputMask<{
      mask: string;
      commit: (value: string) => void;
    }>
  >();
  const { formItemPlaceholder } = useContext(UiKitLocalizationContext);

  useLayoutEffect(() => {
    if (mask && ref.current) {
      maskRef.current = IMask(ref.current.input, {
        mask,
        commit: (value: string) => {
          const errors = form.getFieldError(props.name);
          form.setFields([{ name: props.name, value, errors }]);
          form.validateFields([props.name]);
        },
      });

      return () => {
        if (maskRef.current) {
          maskRef.current.destroy();
          maskRef.current = null;
        }
      };
    }
    return () => {};
  }, [mask, props.name, form]);

  useMount(() => {
    if (autofocus && ref.current) {
      ref.current.focus({
        cursor: 'start',
      });
    }
  });

  const placeholder = (() => {
    if (isUndefined(initialPlaceholder)) {
      return formItemPlaceholder.text(props.label || '');
    }
    return initialPlaceholder;
  })();

  const handleChangeCapture = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    target.value = transform ? transform(target.value) : target.value;
  };

  return (
    <FormItem hasFeedback {...props}>
      <Input
        ref={ref}
        disabled={disabled}
        placeholder={placeholder}
        prefix={prefix && getPrefixOrSuffix(prefix)}
        suffix={suffix && getPrefixOrSuffix(suffix)}
        maxLength={maxLength}
        onChangeCapture={handleChangeCapture}
      />
    </FormItem>
  );
};
