import styled, { css } from 'styled-components';
import AntdAlert from 'antd/lib/alert';
import { Icon } from '../Icon/Icon';
import { AlertComponent, AlertVariant } from './Alert.types';

const RootErrorMixin = css`
  .ant-alert-content {
    color: ${({ theme }) => theme.palette.feverRed};
  }
  .ant-alert-close-icon {
    margin-left: ${({ theme: { spacing } }) => spacing(6)};
    position: relative;
    &:before {
      background: ${({ theme }) => theme.palette.feverRed};
    }
  }
`;

const RootInfoMixin = css`
  .ant-alert-close-icon {
    &:before {
      background: ${({ theme }) => theme.palette.white};
    }
  }
`;

const RootClosableMixin = css`
  &.ant-alert-with-description {
    padding-right: 0px;
  }
`;

export const Root = styled<AlertComponent>(AntdAlert)`
  border: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  &.ant-alert-with-description {
    padding-left: ${({ theme: { spacing } }) => spacing(6)};
    padding-right: ${({ theme: { spacing } }) => spacing(6)};
  }
  .ant-alert-content {
    font-size: 14px;
    line-height: 16px;
  }
  .ant-alert-action {
    margin-left: ${({ theme: { spacing } }) => spacing(4)};
    + {
      .ant-alert-close-icon {
        margin-left: ${({ theme: { spacing } }) => spacing(10)};
      }
    }
  }
  .ant-alert-close-icon {
    visibility: ${(props) => (props.hiddenCloseIcon ? 'hidden' : 'visible')};
    margin-left: ${({ theme: { spacing } }) => spacing(10)};
    margin-right: ${({ theme: { spacing } }) => spacing(4)};
    position: relative;
    overflow: visible;
    &:before {
      content: '';
      display: flex;
      width: 1px;
      position: absolute;
      height: ${({ theme: { spacing } }) => spacing(1250)};
      top: -${({ theme: { spacing } }) => spacing(625)};
      left: -${({ theme: { spacing } }) => spacing(4)};
    }
  }
  ${({ type }) => type === 'error' && RootErrorMixin}
  ${({ type }) => type === 'info' && RootInfoMixin}
  ${({ closable }) => closable && RootClosableMixin}
`;

export const CloseIcon = styled(Icon)<{ variant: AlertVariant }>(
  ({ variant, theme: { palette } }) => ({
    color: variant === 'error' ? palette.feverRed : palette.white,
  })
);
