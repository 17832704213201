import styled from 'styled-components';
import {
  MetropolisGreyRightToLeft,
  MetropolisGreyLeftToRight,
} from '../../styles/Gradient.style';

export const Root = styled('div')`
  position: relative;
`;

export const List = styled('div').withConfig({
  shouldForwardProp: (prop) => !['gap'].includes(prop),
})<{ gap?: number }>`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  gap: ${({ gap, theme: { spacing } }) => spacing(gap)};

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  & > * {
    flex-shrink: 0;
  }
`;

export const PrevArrowOverlay = styled('div').withConfig({
  shouldForwardProp: (prop) => !['width'].includes(prop),
})<{ width?: number }>`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${({ width }) => width}px;
  height: 100%;
  ${MetropolisGreyRightToLeft}
`;

export const NextArrowOverlay = styled('div').withConfig({
  shouldForwardProp: (prop) => !['width'].includes(prop),
})<{ width?: number }>`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: ${({ width }) => width}px;
  height: 100%;
  ${MetropolisGreyLeftToRight}
`;
