/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IErrorResponse,
  IListIdsRequestDto,
  ITeamDetailsResponse,
  ITeamRequestDto,
  ITeamResponse,
  ITeamSongGridResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Team<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Team
   * @name TeamsList
   * @summary Get list of teams
   * @request GET:/teams
   * @secure
   */
  teamsList = (params: RequestParams = {}) =>
    this.http.request<ITeamResponse[], IErrorResponse>({
      path: `/teams`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Team
   * @name TeamsCreate
   * @summary Add new team
   * @request POST:/teams
   * @secure
   */
  teamsCreate = (data: ITeamRequestDto, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/teams`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Team
   * @name TeamsDetail
   * @summary Get team details
   * @request GET:/teams/{teamId}
   * @secure
   */
  teamsDetail = (teamId: number, params: RequestParams = {}) =>
    this.http.request<ITeamDetailsResponse, IErrorResponse>({
      path: `/teams/${teamId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Team
   * @name TeamsUpdate
   * @summary Update team
   * @request PUT:/teams/{teamId}
   * @secure
   */
  teamsUpdate = (
    teamId: number,
    data: ITeamRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/teams/${teamId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Team
   * @name TeamsDelete
   * @summary Remove team
   * @request DELETE:/teams/{teamId}
   * @secure
   */
  teamsDelete = (teamId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/teams/${teamId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Team
   * @name MembersCreate
   * @summary Add new team members
   * @request POST:/teams/{teamId}/members
   * @secure
   */
  membersCreate = (
    teamId: number,
    data: IListIdsRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/teams/${teamId}/members`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Team
   * @name MembersDelete
   * @summary Remove team member
   * @request DELETE:/teams/{teamId}/members/{userId}
   * @secure
   */
  membersDelete = (
    teamId: number,
    userId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/teams/${teamId}/members/${userId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Team
   * @name SongsCreate
   * @summary Add new team songs
   * @request POST:/teams/{teamId}/songs
   * @secure
   */
  songsCreate = (
    teamId: number,
    data: IListIdsRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/teams/${teamId}/songs`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Team
   * @name SongsDetail
   * @summary Get team songs grid
   * @request GET:/teams/{teamId}/songs
   * @secure
   */
  songsDetail = (teamId: number, params: RequestParams = {}) =>
    this.http.request<ITeamSongGridResponse[], IErrorResponse>({
      path: `/teams/${teamId}/songs`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags Team
   * @name SongsDelete
   * @summary Remove team song
   * @request DELETE:/teams/{teamId}/songs/{songId}
   * @secure
   */
  songsDelete = (teamId: number, songId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/teams/${teamId}/songs/${songId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Team
   * @name SongsExportCreate
   * @summary Export team songs
   * @request POST:/teams/{teamId}/songs/export
   * @secure
   */
  songsExportCreate = (teamId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/teams/${teamId}/songs/export`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Team
   * @name PanelsCreate
   * @summary Add new panels to team
   * @request POST:/teams/{teamId}/panels
   * @secure
   */
  panelsCreate = (
    teamId: number,
    data: IListIdsRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/teams/${teamId}/panels`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Team
   * @name PanelsDelete
   * @summary Remove panel from team
   * @request DELETE:/teams/{teamId}/panels/{panelId}
   * @secure
   */
  panelsDelete = (
    teamId: number,
    panelId: number,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/teams/${teamId}/panels/${panelId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
}
