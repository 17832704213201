import { FC, useCallback } from 'react';
import { useKeyPressEvent } from 'react-use';
import { AuthRoutePath, StaticUrl } from '@common/constants';
import { Redirect, useRoute } from '@spintel/navigation';
import { useMfaSetup } from '@spintel/network/auth';
import {
  Button,
  QRCode,
  Description,
  KeyCode,
  Splash,
  Prompt,
} from '@spintel/uikit';
import { Translate, useTranslation } from '@spintel/localization';
import { Text, QRContainer, Link } from './MfaSetup.styles';

export const MfaSetup: FC = () => {
  const { t } = useTranslation();

  const [
    {
      search: { token },
    },
    { push },
  ] = useRoute<{}, { token: string }>();

  const [{ data: qrCode, error }] = useMfaSetup({ token });

  const subtitle = (
    <Translate
      i18nKey="mfa.setupSubtitle"
      components={{
        appStoreLink: (
          <Link
            target="_blank"
            href={StaticUrl.GOOGLE_AUTHENTICATOR_APPLE_STORE}
          />
        ),
        googlePlayLink: (
          <Link
            target="_blank"
            href={StaticUrl.GOOGLE_AUTHENTICATOR_GOOGLE_PLAY}
          />
        ),
      }}
    />
  );

  const handleClickNext = useCallback(() => {
    push({ search: { token: token }, to: AuthRoutePath.MFA_CONFIRM });
  }, [push, token]);

  useKeyPressEvent(KeyCode.VALUE_ENTER, handleClickNext);

  if (error) {
    return <Redirect to={AuthRoutePath.LOGIN} />;
  }

  if (!qrCode) {
    return <Splash />;
  }

  return (
    <Prompt title={t('forms.multiFactorAuthentication')} subtitle={subtitle}>
      <Prompt.Content>
        <QRContainer>
          <QRCode width={200} height={200} value={qrCode?.url} />
        </QRContainer>
        <Text>{t('mfa.setupHelp')}</Text>
        <Description
          ellipsis
          column={2}
          items={[
            {
              label: t('mfa.setupAccount'),
              value: qrCode?.email,
            },
            {
              label: t('mfa.setupKey'),
              value: qrCode?.textCode,
            },
          ]}
        />
      </Prompt.Content>
      <Prompt.Actions>
        <Button size="lg" variant="primary" onClick={handleClickNext}>
          {t('actions.next')}
        </Button>
      </Prompt.Actions>
    </Prompt>
  );
};
