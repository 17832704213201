import styled from 'styled-components';
import { InfiniteScroll } from '@spintel/uikit';

export const Overlay = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const Searchbar = styled('div')`
  padding: ${({ theme: { spacing } }) => spacing(4)};
  border-bottom: 1px solid ${({ theme }) => theme.palette.wolfGrey};
  flex: 0 0 1;
`;

export const Content = styled(InfiniteScroll)`
  flex: 1 1 auto;
  max-height: ${({ theme: { spacing } }) => spacing(62)};
  overflow: auto;
  padding: ${({ theme: { spacing } }) => `${spacing(2)} ${spacing(4)}`};
`;
