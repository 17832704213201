import { FC } from 'react';
import { Trans, TransProps } from 'react-i18next';

const defaultComponents = {
  bold: <strong />,
  nl: <br />,
};

export type TranslateProps = Pick<
  TransProps<any>,
  'components' | 'i18nKey' | 'values'
>;

export const Translate: FC<TranslateProps> = ({ components, ...props }) => {
  return (
    <Trans
      components={{
        ...defaultComponents,
        ...components,
      }}
      {...props}
    />
  );
};
