import { FC, useEffect } from 'react';
import { useContextSelector } from 'use-context-selector';
import { MenuProps as AntdMenuProps } from 'antd/lib/menu';
import {
  DropdownContext,
  DropdownContextProps,
} from '../../../contexts/Dropdown.context';
import { Menu, MenuProps } from '../../Menu/Menu';

export type AntdDropdownMenuItems = AntdMenuProps['items'];

export const DropdownMenu: FC<MenuProps> = (props) => {
  const set = useContextSelector<
    DropdownContextProps,
    DropdownContextProps['set']
  >(DropdownContext, ({ set }) => set);

  useEffect(() => {
    set('overlayNode', <Menu {...props} />);
  }, [props, set]);

  return null;
};
