import styled from 'styled-components';
import AntdSlider from 'antd/lib/slider';

export const Root = styled('div')`
  height: ${({ theme: { spacing } }) => spacing(16)};
  position: relative;
`;

export const Slider = styled(AntdSlider)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  &.ant-slider {
    &:hover {
      .ant-slider-track {
        background: rgba(85, 85, 85, 0.3);
      }
      .ant-slider-rail {
        background: transparent;
      }
    }
    .ant-slider-rail {
      background: transparent;
    }
    .ant-slider-track {
      height: 100%;
      background: rgba(85, 85, 85, 0.3);
      border: 1px solid ${({ theme }) => theme.palette.white};
    }
    .ant-slider-handle {
      bottom: 0;
      top: calc(50% - 12px);
      margin: 0;
      padding: 0;
      background: ${({ theme }) => theme.palette.white};
      height: 24px;
      width: 9px;
      border-radius: 0;
      border: none;
      &::after {
        display: inline-block;
        width: 3px;
        height: 8px;
        border-left: 1px solid ${({ theme }) => theme.palette.black};
        border-right: 1px solid ${({ theme }) => theme.palette.black};
        position: absolute;
        left: calc(50% - 1.5px);
        top: calc(50% - 4px);
        pointer-events: none;
        cursor: pointer;
      }
    }
  }
`;
