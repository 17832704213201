import { FC, PropsWithChildren, ReactNode } from 'react';
import { get } from 'lodash';
import { useQuery } from '../../../hooks/useQuery/useQuery.hook';
import { useAuthToken } from '../../../hooks/useAuthToken/useAuthToken.hook';
import { AuthProviderContext } from '../../../contexts/AuthProvider.context';
import { ICurrentUserResponse } from '../../../__generated__/data-contracts';
import { SessionManager } from './SessionManager/SessionManager';

export type UserManagerProps = PropsWithChildren<{
  fallback: ReactNode;
  logoutUrl: string;
}>;

export const UserManager: FC<UserManagerProps> = ({
  children,
  fallback,
  logoutUrl,
}) => {
  const [{ token }] = useAuthToken();

  const [{ data, error, loading }, refetch] = useQuery<
    [],
    ICurrentUserResponse
  >(({ User }) => User.getUser, [], {
    cache: true,
    manual: !token,
  });

  const ready = data || error || !token;
  const user = token ? data : null;
  const role = get(user, 'role', null);

  return (
    <AuthProviderContext.Provider value={{ user, role, refetch, loading }}>
      {ready ? (
        <SessionManager logoutUrl={logoutUrl}>{children}</SessionManager>
      ) : (
        fallback
      )}
    </AuthProviderContext.Provider>
  );
};
