import { FC, PropsWithChildren } from 'react';
import { TableSkeleton } from './SkeletonTable/SkeletonTable';
import { Root, Title, Subtitle, Head } from './Skeleton.style';

export type SkeletonProps = PropsWithChildren<{
  loading?: boolean;
  className?: string;
  variant?: 'default' | 'grid' | 'catalog' | 'title' | 'subtitle' | 'head';
}>;

export const Skeleton: FC<SkeletonProps> = ({
  children,
  className,
  loading = false,
  variant = 'default',
}) => {
  if (variant === 'grid') {
    return <TableSkeleton className={className} />;
  }
  if (variant === 'catalog') {
    return null;
  }
  if (variant === 'title') {
    return (
      <Title active paragraph={false} loading={loading} className={className}>
        {children}
      </Title>
    );
  }
  if (variant === 'subtitle') {
    return (
      <Subtitle
        active
        paragraph={false}
        loading={loading}
        className={className}
      >
        {children}
      </Subtitle>
    );
  }
  if (variant === 'head') {
    return (
      <Head active paragraph={false} loading={loading} className={className}>
        {children}
      </Head>
    );
  }
  return (
    <Root active paragraph={false} loading={loading}>
      {children}
    </Root>
  );
};
