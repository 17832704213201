import { ChartDataset } from 'chart.js';
import { get } from 'lodash';
import { GraphDataset, GraphRangeItem } from '../Graph.types';

interface GetRangeDatasetsParams {
  datasets: GraphDataset[];
  variants: GraphRangeItem[];
}

export const getRangeDatasets = ({
  datasets,
  variants,
}: GetRangeDatasetsParams): ChartDataset<'line'>[] => {
  return datasets.map((dataset, index): ChartDataset<'line'> => {
    return {
      ...dataset,
      pointRadius: 0,
      borderWidth: get(variants, [index, 'borderWidth']),
      borderColor: get(variants, [index, 'borderColor']),
    };
  });
};
