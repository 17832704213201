import { ColumnsType as AntdColumnsType } from 'antd/lib/table';

export const DEFAULT_PAGINATION_PAGE_SIZE_OPTIONS = [15, 50, 150];

export const DEFAULT_GUTTER = [6, 6, 6, 6];

export const SUMMARY_ROW_HEIGHT = 44;

export const EMPTY_COLUMNS_PLACEHOLDER: AntdColumnsType<object> = [{}];

export const ADDITIONAL_ROWS_COUNT = 7;

export const COLUMN_SELECT_ALL_DATA_INDEX = 'all';
