import { FC, useEffect, useState, useCallback } from 'react';
import { ChartType } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useContextSelector } from 'use-context-selector';
import { GraphContext, GraphContextProps } from '../Graph.context';
import { DEFAULT_GRAPH_ID } from '../Graph.const';
import { GraphRangeItem } from '../Graph.types';
import { Root, Slider } from './ChartRange.style';
import { getRangeDatasets } from './ChartRange.utils';

interface ChartRangeProps {
  id?: string;
  type: ChartType;
  className?: string;
}

export const ChartRange: FC<ChartRangeProps> = ({
  type,
  className,
  id = DEFAULT_GRAPH_ID,
}) => {
  const initialDatasets = useContextSelector<
    GraphContextProps,
    GraphContextProps['datasets']
  >(GraphContext, ({ datasets }) => datasets);

  const labels = useContextSelector<
    GraphContextProps,
    GraphContextProps['labels']
  >(GraphContext, ({ labels }) => labels);

  const variants = useContextSelector<GraphContextProps, GraphRangeItem[]>(
    GraphContext,
    ({ range }) => range[id] || []
  );

  const setRangeBounds = useContextSelector<
    GraphContextProps,
    GraphContextProps['setRangeBounds']
  >(GraphContext, ({ setRangeBounds }) => setRangeBounds);

  const [value, setValue] = useState<[number, number]>([0, 1]);

  const datasets = (() => {
    if (type === 'line') {
      return getRangeDatasets({ datasets: initialDatasets, variants });
    }
  })();

  const handleOnChange = useCallback(
    ([start, end]: [number, number]) => {
      setRangeBounds(id, { start, end });
      setValue(() => [start, end]);
    },
    [id, setRangeBounds]
  );

  useEffect(
    () => handleOnChange([0, labels.length - 1]),
    [labels, handleOnChange]
  );

  return (
    <Root className={className}>
      <Chart
        type={type}
        data={{
          labels,
          datasets,
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        }}
      />
      <Slider
        min={0}
        value={value}
        tooltipVisible={false}
        max={labels.length - 1}
        onChange={handleOnChange}
        range={{ draggableTrack: true }}
      />
    </Root>
  );
};
