import { ReactElement, PropsWithChildren } from 'react';
import { StationContacts } from './StationContacts/StationContacts';
import { StationHeader } from './StationHeader/StationHeader';
import { StationDetailsContext } from './StationDetails.context';
import { IStationListItem, IStationDetails } from './StationDetails.types';

export type StationDetailsProps = PropsWithChildren<{
  data: IStationDetails;
  loading: boolean;
  stations?: IStationListItem[];
  switchable?: boolean;
}>;

interface StationDetailsComponent {
  (props: StationDetailsProps): ReactElement<any, any> | null;
  Header: typeof StationHeader;
  Contacts: typeof StationContacts;
}

export const StationDetails: StationDetailsComponent = ({
  children,
  data,
  loading,
  stations,
  switchable = false,
}) => {
  return (
    <StationDetailsContext.Provider
      value={{
        data,
        loading,
        stations,
        switchable,
      }}
    >
      {children}
    </StationDetailsContext.Provider>
  );
};

StationDetails.Header = StationHeader;
StationDetails.Contacts = StationContacts;
