import { FC } from 'react';
import { Source, Layer } from 'react-map-gl';
import { Position } from 'geojson';
import { UiKitColor } from '../../../types/uikit';
import { THEME } from '../../../constants/theme.const';
import { useOnClick } from '../Map.hooks';

interface MapPolygonProps {
  id: string;
  positions: Position[];
  color?: UiKitColor;
  opacity?: number;
  onClick?: () => void;
  tolerance?: number;
}

export const MapPolygon: FC<MapPolygonProps> = ({
  id,
  positions,
  opacity = 0.4,
  color = 'pearlGrey',
  tolerance = 0,
  onClick,
}) => {
  useOnClick({ id, onClick });

  return (
    <Source
      type="geojson"
      data={{
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [positions],
        },
        properties: {},
      }}
      tolerance={tolerance}
    >
      <Layer
        id={id}
        type="fill"
        paint={{
          'fill-color': THEME.palette[color],
          'fill-opacity': opacity,
          'fill-outline-color': THEME.palette.transparent,
        }}
      />
    </Source>
  );
};
