import { AlertProps } from '../Alert';

let id = 0;

export interface AlertItem extends AlertProps {
  id: number;
  timeout?: number;
}

export interface SnackbarObserver {
  (alerts: AlertItem[]): void;
}

class SnackbarObservable {
  private alerts: AlertItem[] = [];
  private observers: SnackbarObserver[] = [];

  private notify() {
    this.observers.forEach((observer) => observer(this.alerts));
  }

  public subscribe(observer: SnackbarObserver) {
    if (!this.observers) {
      this.observers = [];
    }

    this.observers.push(observer);
  }

  public unsubscribe(observer: SnackbarObserver) {
    this.observers = this.observers.filter(
      (_observer) => observer !== _observer
    );
  }

  public add(alert: Omit<AlertItem, 'id'>, reset: boolean = false) {
    this.alerts = reset
      ? [{ ...alert, id: id++ }]
      : [{ ...alert, id: id++ }, ...this.alerts];

    this.notify();
  }

  public remove(id: number) {
    const alerts = this.alerts.filter((alert) => id !== alert.id);
    const changed = alerts.length < this.alerts.length;

    if (changed) {
      this.alerts = alerts;
      this.notify();
    }
  }

  public reset() {
    this.alerts = [];
    this.notify();
  }
}

export default new SnackbarObservable();
