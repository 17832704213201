import { Key, useCallback, useMemo, useRef } from 'react';
import { get, merge, invoke } from 'lodash';
import { CoreChartOptions, Plugin, ChartDataset, ChartOptions } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { useContextSelector } from 'use-context-selector';
import { Listener } from 'chartjs-plugin-datalabels/types/options';
import {
  GraphDataset,
  GraphLabels,
  GraphLegendItem,
  GraphRangeItem,
} from '../Graph.types';
import { THEME } from '../../../constants/theme.const';
import { GraphContext, GraphContextProps } from '../Graph.context';
import { PALETTE_COLOR_NAME_MAP } from '../../../constants/theme.const';
import { DATAPOINT_ANNOTATION_ID } from '../Graph.const';
import {
  GraphLineDatapoint,
  GraphLineScales,
  GraphLineAxis,
  GraphLineScaleConfig,
  GraphLineVariant,
  GraphLineTooltip,
  GraphLineDataValue,
  GraphLineOnClickParams,
} from './GraphLine.types';
import { DEFAULT_VARIANT, DEFAULT_SCALES } from './GraphLine.const';
import {
  getBorderWidth,
  getPointRadius,
  getPointHitRadius,
  getBackgroundColor,
  getDatalabelDisplay,
  getDatalabelBackgroundColor,
} from './GraphLine.utils';

type UseDatasetsReturnType = [
  {
    datasets: ChartDataset<'line'>[];
  },
  {
    onClick: ChartOptions['onClick'];
    setActiveDatalabel: (v: GraphLineDatapoint) => void;
    setActiveDataLine: (v: GraphLineDataValue) => void;
  }
];

interface UseDatasetsParams {
  datapoint?: GraphLineDatapoint;
  datasets: GraphDataset[];
  variants: GraphLineVariant[];
  onGraphClick?: (v: GraphLineOnClickParams) => void;
}

export const useDatasets = ({
  variants,
  onGraphClick,
  datasets: initialDatasets,
  datapoint: initialDatapoint = null,
}: UseDatasetsParams): UseDatasetsReturnType => {
  const datapoint = useRef<GraphLineDatapoint>(initialDatapoint);
  const selected = useRef<number>(-1);

  const handleOnDatalabelMouseEnter: Listener = useCallback((ctx) => {
    ctx.chart.canvas.style.cursor = 'pointer';
  }, []);

  const handleOnDatalabelMouseLeave: Listener = useCallback((ctx) => {
    ctx.chart.canvas.style.cursor = 'default';
  }, []);

  const datasets: ChartDataset<'line'>[] = useMemo(() => {
    return initialDatasets.map(
      ({ label, data }, index): ChartDataset<'line'> => {
        const {
          active,
          datalabel: datalabelOptions,
          background,
          color = 'spintelGreen',
        } = get(variants, index, DEFAULT_VARIANT);
        return {
          data,
          label,
          fill: 'start',
          borderColor: THEME.palette[color],
          borderWidth: getBorderWidth({
            active,
            selected,
          }),
          pointRadius: getPointRadius({
            datapoint: datapoint,
            datalabel: datalabelOptions,
          }),
          pointHoverRadius: getPointRadius({
            datapoint: datapoint,
            datalabel: datalabelOptions,
          }),
          pointBackgroundColor: getDatalabelBackgroundColor({
            color,
            datapoint,
          }),
          pointBorderWidth: 0,
          pointHoverBorderWidth: 0,
          pointHitRadius: getPointHitRadius({
            selected,
            datalabel: datalabelOptions,
          }),
          backgroundColor: getBackgroundColor({
            color,
            active,
            selected,
            background,
          }),
          datalabels: {
            offset: 0,
            clamp: false,
            clip: false,
            display: getDatalabelDisplay({
              active,
              selected,
              datapoint,
            }),
            color: () => THEME.palette.black,
            backgroundColor: THEME.palette[color],
            padding: { top: 4, right: 8, bottom: 4, left: 8 },
            font: {
              size: 10,
              lineHeight: '14px',
              family: 'Montserrat',
            },
            formatter: datalabelOptions?.formatter
              ? (value) => invoke(datalabelOptions, 'formatter', { value })
              : null,
            listeners: {
              enter: handleOnDatalabelMouseEnter,
              leave: handleOnDatalabelMouseLeave,
            },
          },
        };
      }
    );
  }, [
    variants,
    initialDatasets,
    handleOnDatalabelMouseEnter,
    handleOnDatalabelMouseLeave,
  ]);

  const setActiveDatalabel = useCallback((value: GraphLineDatapoint) => {
    datapoint.current = value;
  }, []);

  const setActiveDataLine = useCallback(
    ({ datasetIndex }: GraphLineDataValue) => {
      selected.current = datasetIndex;
    },
    []
  );

  const onClick: ChartOptions['onClick'] = useCallback(
    (e, _, chart) => {
      if (onGraphClick) {
        const {
          data: { labels },
        } = chart;
        const [{ index: dataIndex, datasetIndex }] =
          chart.getElementsAtEventForMode(
            e.native,
            'nearest',
            { intersect: false },
            true
          );
        onGraphClick({
          dataIndex,
          datasetIndex,
          x: labels[dataIndex] as Key,
        });
      }
    },
    [onGraphClick]
  );

  return [{ datasets }, { onClick, setActiveDatalabel, setActiveDataLine }];
};

interface UseOptionsParams {
  id: string;
  labels: GraphLabels;
  scales: GraphLineScales;
  tooltip: GraphLineTooltip;
  datapoint?: GraphLineDatapoint;
}

type UseOptionsReturnType = [
  {
    legend: Plugin<'line'>;
    range: Plugin<'line'>;
    scales: ChartOptions<'line'>['scales'];
    tooltip: ChartOptions<'line'>['plugins']['tooltip'];
    interaction: CoreChartOptions<'line'>['interaction'];
    annotation: ChartOptions<'line'>['plugins']['annotation'];
  },
  {
    mouseLine: Plugin<'line'>;
  }
];

export const useOptions = ({
  labels,
  datapoint,
  id: graphId,
  tooltip: tooltipOptions,
  scales: initialScales,
}: UseOptionsParams): UseOptionsReturnType => {
  const hovered = useRef<Key>();

  const setLegend = useContextSelector<
    GraphContextProps,
    GraphContextProps['setLegend']
  >(GraphContext, ({ setLegend }) => setLegend);

  const setRange = useContextSelector<
    GraphContextProps,
    GraphContextProps['setRange']
  >(GraphContext, ({ setRange }) => setRange);

  const tooltip: ChartOptions<'line'>['plugins']['tooltip'] = useMemo(
    () => ({
      caretSize: 0,
      mode: 'index',
      enabled: !!tooltipOptions?.display,
      intersect: false,
      cornerRadius: 0,
      boxPadding: 8,
      backgroundColor: THEME.palette.icarusGrey,
      padding: { top: 12, bottom: 12, left: 16, right: 16 },
      bodyFont: {
        size: 12,
        lineHeight: '16px',
        family: 'Montserrat',
      },
      titleFont: {
        size: 12,
        lineHeight: '16px',
        family: 'Montserrat',
        weight: '600',
      },
      titleMarginBottom: 8,
      bodySpacing: 8,
      usePointStyle: true,
      callbacks: {
        title: tooltipOptions?.title
          ? function ([{ chart, dataIndex }]) {
              return invoke(tooltipOptions, 'title', {
                label: chart.data.labels[dataIndex],
              });
            }
          : () => null,
        label: tooltipOptions?.label
          ? ({ raw, dataset }) => {
              return invoke(tooltipOptions, 'label', {
                label: dataset?.label,
                value: raw,
              });
            }
          : undefined,
        labelPointStyle: () => ({
          rotation: 0,
          pointStyle: 'circle',
        }),
      },
    }),
    [tooltipOptions]
  );

  const interaction: CoreChartOptions<'line'>['interaction'] = useMemo(
    () => ({
      intersect: false,
      mode: 'dataset',
      axis: 'xy',
      includeInvisible: false,
    }),
    []
  );

  const scales: ChartOptions<'line'>['scales'] = Object.entries(
    DEFAULT_SCALES
  ).reduce((res, [key, value]) => {
    const { label, ...config } = initialScales[key as GraphLineAxis] || {};
    const formatter: GraphLineScaleConfig = label?.format && {
      ticks: {
        callback: function (value: number): string | number {
          // @ts-ignore
          return label.format(this.getLabelForValue(value));
        },
      },
    };
    return { ...res, [key]: merge({}, config, value, formatter, {}) };
  }, {});

  const annotation: ChartOptions<'line'>['plugins']['annotation'] =
    useMemo(() => {
      const annotations: AnnotationOptions[] = labels.map(
        (id): AnnotationOptions => ({
          id: `${id}`,
          value: id,
          type: 'line',
          scaleID: 'x',
          display: () => hovered.current === id,
          borderWidth: 1,
          borderDash: [3],
          borderColor: THEME.palette.wolfGrey,
        })
      );

      if (labels.includes(datapoint?.x)) {
        annotations.push({
          type: 'line',
          scaleID: 'x',
          borderWidth: 1,
          borderDash: [3],
          value: datapoint?.x,
          display: () => !!datapoint?.x,
          id: DATAPOINT_ANNOTATION_ID,
          borderColor: THEME.palette.wolfGrey,
        });
      }

      return {
        clip: false,
        animations: null,
        annotations,
      };
    }, [datapoint, labels]);

  const mouseLine: Plugin<'line'> = useMemo(
    () => ({
      id: 'mouseLine',
      beforeTooltipDraw: (chart, { tooltip: { dataPoints, opacity } }) => {
        const [{ label }] = dataPoints;
        const newHovered = opacity > 0.09 ? label : null;
        if (newHovered !== hovered.current) {
          hovered.current = newHovered;
          chart.update();
        }
      },
    }),
    [hovered]
  );

  const legend: Plugin<'line'> = useMemo(
    () => ({
      id: 'legend',
      afterUpdate: (chart) => {
        const legend: GraphLegendItem[] =
          chart.data.datasets.map(
            ({ label, borderColor }, index): GraphLegendItem => ({
              label,
              value: label,
              status: get(PALETTE_COLOR_NAME_MAP, `${borderColor}`),
              hidden: chart.getDatasetMeta(index).hidden,
            })
          ) || [];
        setLegend(graphId, legend);
      },
    }),
    [setLegend, graphId]
  );

  const range: Plugin<'line'> = useMemo(
    () => ({
      id: 'range',
      afterUpdate: (chart) => {
        const range: GraphRangeItem[] =
          chart.data.datasets.map(
            ({ borderColor, borderWidth }): GraphRangeItem => ({
              // @ts-ignore
              borderColor,
              // @ts-ignore
              borderWidth,
            })
          ) || [];
        setRange(graphId, range);
      },
    }),
    [setRange, graphId]
  );

  return [
    { interaction, tooltip, scales, legend, annotation, range },
    { mouseLine },
  ];
};
