import { FC } from 'react';
import { useTranslation } from '@spintel/localization';
import { Button, Empty } from '@spintel/uikit';
import { useUser, UserHasRole } from '@spintel/network/auth';
import { useRoute } from '@spintel/navigation';
import { UserRole, RoutePath } from '@common/constants';

export interface MySongsEmptyProps {
  noTeam: boolean;
  noSongs: boolean;
}

export const MySongsEmpty: FC<MySongsEmptyProps> = ({ noTeam, noSongs }) => {
  return (
    <>
      <UserHasRole
        value={[
          UserRole.SuperAdmin,
          UserRole.AccountManager,
          UserRole.TeamManager,
        ]}
      >
        {noTeam && <EmptyTeam />}
        {!noTeam && noSongs && <EmptySong />}
      </UserHasRole>
      <UserHasRole value={[UserRole.Member]}>
        {noTeam && <EmptyMemberTeam />}
        {!noTeam && noSongs && <EmptyMemberSong />}
      </UserHasRole>
    </>
  );
};

const EmptyTeam = () => {
  const { t } = useTranslation();
  const [, { push }] = useRoute();

  return (
    <Empty
      icon="team"
      title={t('empty.teamsStart')}
      background="gradient"
      size="lg"
      fill
    >
      <Button onClick={() => push({ to: RoutePath.MANAGEMENT_TEAMS })}>
        {t('actions.createTeam')}
      </Button>
    </Empty>
  );
};

const EmptySong = () => {
  const { t } = useTranslation();
  const [, { push }] = useRoute();
  const [{ user }] = useUser();

  return (
    <Empty
      icon="song"
      title={t('empty.teamsAddSong')}
      background="gradient"
      size="lg"
      fill
    >
      <Button
        onClick={() =>
          push({
            to: RoutePath.MANAGEMENT_TEAMS,
            search: { teamId: user?.selectedTeamId },
          })
        }
      >
        {t('actions.addSong')}
      </Button>
    </Empty>
  );
};

const EmptyMemberTeam = () => {
  const { t } = useTranslation();

  return (
    <Empty
      icon="team"
      title={t('empty.noTeamsMySongs.title')}
      subtitle={t('empty.noTeamsMySongs.noAccess')}
      background="gradient"
      size="lg"
      fill
    />
  );
};

const EmptyMemberSong = () => {
  const { t } = useTranslation();

  return (
    <Empty
      icon="song"
      title={t('empty.noSongs.title')}
      background="gradient"
      size="lg"
      fill
    />
  );
};
