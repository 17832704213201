import { Typography } from '@spintel/uikit';
import styled from 'styled-components';

export const Root = styled(Typography.Body3)`
  color: ${({ theme }) => theme.palette.pearlGrey};
`;

export const Item = styled('span')`
  & + & {
    &:before {
      content: '';
      display: inline-flex;
      vertical-align: middle;
      margin-right: ${({ theme: { spacing } }) => spacing(1)};
      margin-left: ${({ theme: { spacing } }) => spacing(1)};
      width: 3px;
      height: 3px;
      background: ${({ theme }) => theme.palette.pearlGrey};
      border-radius: 50%;
    }
  }
`;
