import { get } from 'lodash';
import { TableColumnDataIndex } from '../Table.types';
import { TableFilterRangeForm } from './TableFilterRange/TableFilterRange.types';
import { TableFilterRangeComparator } from './TableFilterRange/TableFilterRange.const';

interface GetRangeFilterPredicate {
  formatter: (v: unknown) => unknown;
  dataIndex: TableColumnDataIndex;
}

export const getSearchFilterPredicate =
  <T>(dataIndex: TableColumnDataIndex) =>
  (value: string | number | boolean, record: T) =>
    get(record, dataIndex) === value;

export const getRangeFilterPredicate =
  <T>({ dataIndex, formatter }: GetRangeFilterPredicate) =>
  (value: string | number | boolean, record: T) => {
    const form = value as TableFilterRangeForm;
    if (form.comparator && form.comparator === TableFilterRangeComparator.EQ) {
      return formatter(get(record, dataIndex)) === form?.value?.eq;
    }
    if (form.comparator && form.comparator === TableFilterRangeComparator.GT) {
      return formatter(get(record, dataIndex)) > form?.value?.gt;
    }
    if (form.comparator && form.comparator === TableFilterRangeComparator.LT) {
      return formatter(get(record, dataIndex)) < form?.value?.lt;
    }
    if (form.comparator && form.comparator === TableFilterRangeComparator.BT) {
      return (
        formatter(get(record, dataIndex)) <= form?.value?.lt &&
        formatter(get(record, dataIndex)) >= form?.value?.gt
      );
    }
    return true;
  };
