import { invoke, kebabCase } from 'lodash';
import {
  TableGlobalColumnsExtendedType,
  TableColumnsSelectOption,
  TableColumnSelectionTree,
} from '../../Table.types';
import { TableColumnsSelectFormLabel } from './TableColumnsSelectFormLabel/TableColumnsSelectFormLabel';

interface GetColumnSelectOptionsParams<T extends object> {
  columns: TableGlobalColumnsExtendedType<T>;
  selectionTree: TableColumnSelectionTree;
  parent?: string;
}

export const getColumnSelectOptions = <T extends object>({
  columns,
  parent,
  selectionTree,
}: GetColumnSelectOptionsParams<T>): TableColumnsSelectOption[] => {
  return columns.reduce((res, props, index) => {
    switch (props.type) {
      case 'group':
        const columnSelectOptions = getColumnSelectOptions({
          selectionTree,
          parent: props.id,
          columns: props.children,
        });

        return props.showHeader !== false &&
          props.title &&
          columnSelectOptions.length
          ? [
              ...res,
              {
                label: (
                  <TableColumnsSelectFormLabel
                    index={index}
                    parent={parent}
                    group={kebabCase(props.title)}
                  >
                    {props.title}
                  </TableColumnsSelectFormLabel>
                ),
                value: props.id,
                children: columnSelectOptions,
              },
            ]
          : [...res, ...columnSelectOptions];
      case 'column':
        return props.select
          ? [
              ...res,
              {
                label: (
                  <TableColumnsSelectFormLabel
                    index={index}
                    parent={parent}
                    group={props.select.group}
                  >
                    {props.title}
                  </TableColumnsSelectFormLabel>
                ),
                dataIndex: props.dataIndex,
                value: props.id,
                disabled: !!invoke(props.select, 'disabled', {
                  selection: selectionTree,
                }),
              },
            ]
          : res;
      default:
        return res;
    }
  }, [] as TableColumnsSelectOption[]);
};
