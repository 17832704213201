import { FC } from 'react';
import { Spin } from '../Spin/Spin';
import { Root } from './Splash.style';

export const Splash: FC = () => {
  return (
    <Root>
      <Spin spinning />
    </Root>
  );
};
