module.exports = {
  accountManager: 'Account manager',
  active: 'Active',
  admin: 'Admin',
  all: 'All',
  behind: 'Behind',
  chooseWeek: 'Choose week',
  currentWeek: 'Current week',
  currents: 'Currents',
  drops: 'Drops',
  exclusive: 'Exclusive',
  historical: 'Historical',
  inRange: 'In range',
  inactive: 'Inactive',
  lastWeek: 'Last week',
  map: 'Map',
  maxPlays: 'Max plays',
  member: 'Member',
  nationalPanel: 'National panel',
  newAdds: 'New adds',
  on: 'On',
  onBoth: 'On both',
  onDeck: 'On deck',
  onExclusive: 'On exclusive',
  oneMonth: '1m',
  oneYear: '1y',
  other: 'Other',
  outlook: 'Outlook',
  panel: 'Panel',
  personal: 'Personal',
  playlistSize: 'Playlist size',
  plays: 'Plays',
  playsAT: 'Plays AT',
  playsChange: 'Plays +/-',
  playsDay: 'Plays day',
  playsDayPercent: 'Plays day %',
  playsNight: 'Plays night',
  playsOVN: 'Plays OVN',
  playsOVNPercent: 'Plays OVN %',
  points: 'Points',
  possibleSpace: 'Possible space',
  rank: 'Rank',
  rankChange: 'Rank +/-',
  rankPlaysComparison: 'Rank play comparison',
  rankSongName: '{{rank}}. {{songName}}',
  rankSongNameArtistName: '{{rank}}. {{songName}} – {{artistName}}',
  relative: 'Relative',
  rotation: 'Rotation',
  rotationHeatmap: 'Rotation heatmap',
  sixMonths: '6m',
  songNameArtistName: '{{songName}} – {{artistName}}',
  spintel: 'Spintel',
  standard: 'Standard',
  stationOwnerNameStateCity: '{{ownerName}} • {{state}}, {{city}}',
  stations: 'Stations',
  stationsOn: 'Stations on',
  table: 'Table',
  team: 'Team',
  teamManager: 'Team manager',
  teams: 'Teams',
  thisWeek: 'This week',
  threeMonths: '3m',
  weekNumber: 'Week {{number}}',
};
