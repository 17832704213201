import styled from 'styled-components';

export const Root = styled('div').withConfig({
  shouldForwardProp: (prop) => !['height'].includes(prop),
})<{ height: number }>`
  width: 100%;
  max-width: 100%;
  position: relative;
  height: ${({ height }) => height}px;
`;

export const Container = styled('div')<{ gap: number }>`
  position: absolute;
  left: 0;
  right: 0;
  .slick-slider {
    .slick-list {
      .slick-track {
        display: flex;
        gap: ${({ gap }) => gap}px;
      }
    }
  }
`;

export const PrevArrow = styled('div')`
  left: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  height: auto;
  transform: none;
  z-index: 2;
  width: ${({ theme: { spacing } }) => spacing(12)};
  &.slick-disabled {
    display: none !important;
  }
  &:before {
    display: none;
  }
`;

export const PrevArrowContent = styled('div')`
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  background: linear-gradient(
    -88.63deg,
    rgba(17, 17, 17, 0) 0.95%,
    #111111 49.28%
  );
  svg {
    margin-left: -2px;
  }
`;

export const NextArrow = styled('div')`
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  height: auto;
  transform: none;
  z-index: 2;
  width: ${({ theme: { spacing } }) => spacing(12)};
  &.slick-disabled {
    display: none !important;
  }
  &:before {
    display: none;
  }
`;

export const NextArrowContent = styled('div')`
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  background: linear-gradient(
    88.63deg,
    rgba(17, 17, 17, 0) 0.95%,
    #111111 49.28%
  );
  svg {
    margin-right: -2px;
  }
`;
