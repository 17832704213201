import { PropsWithChildren, ReactElement } from 'react';
import { Root } from './Section.style';
import { SectionHeader } from './SectionHeader/SectionHeader';
import { SectionContent } from './SectionContent/SectionContent';

type SectionProps = PropsWithChildren<{
  className?: string;
  stretch?: boolean;
}>;

interface SectionComponent {
  (props: SectionProps): ReactElement<any, any> | null;
  Header: typeof SectionHeader;
  Content: typeof SectionContent;
}

export const Section: SectionComponent = ({
  children,
  className,
  stretch = false,
}) => {
  return (
    <Root stretch={stretch} className={className}>
      {children}
    </Root>
  );
};

Section.Header = SectionHeader;
Section.Content = SectionContent;
