import { FC } from 'react';
import { Root } from './Header.style';
import { Panels } from './Panels/Panels';
import { User } from './User/User';
import { SongSelector } from './SongSelector/SongSelector';

interface HeaderProps {}

export const Header: FC<HeaderProps> = () => {
  return (
    <Root>
      <SongSelector />
      <Panels />
      <User />
    </Root>
  );
};
