import styled from 'styled-components';
import { Spin } from '../Spin/Spin';

export const Root = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme: { spacing } }) => spacing(6)};
`;

export const Loader = styled(Spin)`
  &.ant-spin-spinning {
    max-height: min-content !important;
    background-color: ${({ theme }) => theme.palette.anthraciteGrey};
  }
  .ant-spin-blur {
    opacity: 0;
  }
`;
