import { FC, PropsWithChildren } from 'react';
import { Root, Indicator } from './Spin.style';

type SpinProps = PropsWithChildren<{
  spinning?: boolean;
  className?: string;
}>;

export const Spin: FC<SpinProps> = ({
  children,
  className,
  spinning = false,
}) => {
  return (
    <Root
      spinning={spinning}
      className={className}
      wrapperClassName={className}
      indicator={<Indicator size="md" name="spinner" color="white" />}
    >
      {children}
    </Root>
  );
};
