import { FC } from 'react';
import type { Feature, Point, Polygon } from 'geojson';
import { noop } from 'lodash';
import { useContext } from 'use-context-selector';
import { Map } from '../Map';
import { MapEventData } from '../Map.types';
import { MapContext } from '../Map.context';

interface MapGeoProps {
  circles: Feature<Point>[];
  polygons: Feature<Polygon>[];
  onClick?: (data: MapEventData) => void;
}

export const MapGeo: FC<MapGeoProps> = ({
  circles = [],
  polygons = [],
  onClick = noop,
}) => {
  const { loading } = useContext(MapContext);
  return (
    <>
      <Map.Circles
        data={circles}
        styles={{ opacity: loading ? 0 : 1 }}
        onClick={onClick}
      />
      <Map.Polygons data={polygons} styles={{ opacity: loading ? 0 : 0.4 }} />
    </>
  );
};
