import { FC, useRef } from 'react';
import { CommonQueryProvider, CommonHttpProvider } from '@shared/components';
import { CommonHttpProviderProps } from '@shared/types';
import { CommonHttpContext } from '@shared/contexts/CommonHttp.context';
import { HttpClient } from '../../__generated__/http-client';
import {
  HttpProviderContext,
  HttpProviderContextProps,
} from '../../contexts/HttpProvider.context';
import { Logger } from '../../services/Logger.service';

export const HttpProvider: FC<CommonHttpProviderProps> = ({
  host,
  base,
  children,
  ...rest
}) => {
  const { current: httpClient } = useRef<HttpClient>(
    new HttpClient({
      baseURL: host,
    })
  );

  const { current: services } = useRef<HttpProviderContextProps>({
    Logger: new Logger(httpClient, base),
  });

  return (
    <CommonHttpContext.Provider value={{ httpClient: httpClient }}>
      <CommonQueryProvider>
        <CommonHttpProvider name="app" instance={httpClient.instance} {...rest}>
          <HttpProviderContext.Provider value={services}>
            {children}
          </HttpProviderContext.Provider>
        </CommonHttpProvider>
      </CommonQueryProvider>
    </CommonHttpContext.Provider>
  );
};
