import { FC, PropsWithChildren, useEffect } from 'react';
import { DTO } from '@spintel/network/auth';
import { useTranslation } from '@spintel/localization';
import { Alert } from '@spintel/uikit';
import { useRoute } from '@spintel/navigation';
import { AuthRoutePath } from '@common/constants';
import { ALLOWED_ERRORS } from './AuthPage.const';

type AuthPageProps = PropsWithChildren<{
  errorCode?: DTO.IErrorCode;
}>;

export const AuthPage: FC<AuthPageProps> = ({ children, errorCode }) => {
  const { t } = useTranslation();
  const [, { push }] = useRoute();

  useEffect(() => {
    if (errorCode && ALLOWED_ERRORS.includes(errorCode)) {
      Alert.error({
        message: t(`httpErrorCode.${errorCode}`),
        closable: true,
      });
    }
    if (errorCode === DTO.IErrorCode.UserNotActivated) {
      push({ to: AuthRoutePath.DEACTIVATED });
    }
  }, [errorCode, push, t]);

  return <>{children}</>;
};
