import { createContext } from 'react';
import { Logger } from '../services/Logger.service';

export interface HttpProviderContextProps {
  Logger: Logger;
}

export const HttpProviderContext = createContext<HttpProviderContextProps>({
  Logger: null,
});
