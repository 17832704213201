import { FC, PropsWithChildren } from 'react';
import { template } from 'lodash';
import { UiKitProvider } from '@spintel/uikit';
import { HttpBootstrap } from './HttpBootstrap/HttpBootstrap';
import { UserBootstrap } from './UserBootstrap/UserBootstrap';
import { LocalizationBootstrap } from './LocalizationBootstrap/LocalizationBootstrap';
import { AuthBootstrap } from './AuthBootstrap/AuthBootstrap';
import { ErrorBoundaryBootstrap } from './ErrorBoundaryBootstrap/ErrorBoundaryBootstrap';
import { tableCellFormatter } from './Bootstrap.utils';

type BootstrapProps = PropsWithChildren<Window['APP_CONFIG']>;

export const Bootstrap: FC<BootstrapProps> = ({
  children,
  apiBase,
  basename,
  mapboxStyleId,
  mapboxTenantId,
  mapboxToken,
  build,
  apiHost: initialApiHost,
  loggingBase,
  loggingHost,
}) => {
  const apiHost = template(initialApiHost)({
    tenant: document.location.hostname.split('.')['0'],
  });

  return (
    <UiKitProvider
      version={build}
      mapbox={{
        token: mapboxToken,
        styleId: mapboxStyleId,
        tenantId: mapboxTenantId,
      }}
      table={{
        cell: {
          formatter: tableCellFormatter,
        },
      }}
    >
      <HttpBootstrap
        basename={basename}
        apiBase={apiBase}
        apiHost={apiHost}
        loggingBase={loggingBase}
        loggingHost={loggingHost}
      >
        <AuthBootstrap apiBase={apiBase} apiHost={apiHost}>
          <UserBootstrap basename={basename}>
            <LocalizationBootstrap>
              <ErrorBoundaryBootstrap>{children}</ErrorBoundaryBootstrap>
            </LocalizationBootstrap>
          </UserBootstrap>
        </AuthBootstrap>
      </HttpBootstrap>
    </UiKitProvider>
  );
};
