import { FC, ReactNode, PropsWithChildren } from 'react';
import { isUndefined } from 'lodash';
import { Dropdown } from '../Dropdown/Dropdown';
import { Button } from '../Button/Button';
import { Skeleton } from '../Skeleton/Skeleton';
import { Avatar, AvatarProps } from '../Avatar/Avatar';
import { MenuItem } from '../Menu/Menu.types';
import { Root, Left, Right, Content, Subtitle, Title } from './Subheader.style';

type SubheaderProps = PropsWithChildren<{
  loading?: boolean;
  title?: ReactNode;
  truncate?: boolean;
  subtitle?: ReactNode;
  avatar?: AvatarProps;
  actions?: MenuItem[];
}>;

export const Subheader: FC<SubheaderProps> = ({
  title,
  avatar,
  actions,
  subtitle,
  truncate,
  children,
  loading = false,
}) => {
  return (
    <Root>
      {avatar && <Avatar {...avatar} />}
      <Left>
        <Content loading={loading}>
          <Title truncate={truncate}>
            <Skeleton variant="title" loading={loading}>
              {title}
            </Skeleton>
          </Title>
          {!isUndefined(subtitle) && (
            <Subtitle>
              <Skeleton variant="subtitle" loading={loading}>
                {subtitle}
              </Skeleton>
            </Subtitle>
          )}
        </Content>
      </Left>
      <Right>{children}</Right>
      {actions && (
        <Dropdown placement="bottomRight">
          <Dropdown.Toggle chevron={false}>
            <Button circle icon="more" />
          </Dropdown.Toggle>
          <Dropdown.Menu items={actions} />
        </Dropdown>
      )}
    </Root>
  );
};
