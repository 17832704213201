import { createContext, Key } from 'react';
import { noop } from 'lodash';

interface ListViewContextProps {
  value: Key;
  onChange: (value: Key) => void;
}

export const ListViewContext = createContext<ListViewContextProps>({
  value: null,
  onChange: noop,
});
