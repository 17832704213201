module.exports = {
  accountManager:
    'Invites members, creates teams, creates panels, includes team manager permissions',
  enterTotpCode:
    'Enter the code generated by <bold>Google Authenticator</bold>',
  member: 'Creates notes and talking points',
  password:
    'At least 8 characters long, include both uppercase and lowercase letters, numbers, and at least one special character.',
  sendInvitation: 'You can do it later manually on members list page',
  teamManager:
    'Assigns team members, assigns songs to the teams, includes member permissions',
  week: 'Available weeks {{min}}-{{max}}',
};
