import { FC, useRef, useState } from 'react';
import { CommonHttpProviderProps } from '@shared/types';
import { CommonQueryProvider, CommonHttpProvider } from '@shared/components';
import { HttpClient } from '../../__generated__/http-client';
import { Auth } from '../../__generated__/Auth';
import { User } from '../../__generated__/User';
import { ILoginUserResponseDto } from '../../__generated__/data-contracts';
import {
  HttpServices,
  HttpProviderContext,
} from '../../contexts/HttpProvider.context';
import { UserManagerProps, UserManager } from './UserManager/UserManager';

interface AuthProviderProps extends CommonHttpProviderProps, UserManagerProps {
  httpClient?: HttpClient;
}

export const AuthProvider: FC<AuthProviderProps> = ({
  host,
  base,
  fallback,
  children,
  logoutUrl,
  httpClient: initialHttpClient,
  ...rest
}) => {
  const [token, setToken] = useState<ILoginUserResponseDto>(() => {
    const value = localStorage.getItem('token') || '{}';
    const parsed = JSON.parse(value) as ILoginUserResponseDto;
    return parsed;
  });

  const httpClient = useRef<HttpClient>(
    initialHttpClient ||
      new HttpClient({
        baseURL: `${host}${!!base ? base : ''}`,
      })
  );

  const { current: services } = useRef<HttpServices>({
    Auth: new Auth(httpClient.current),
    User: new User(httpClient.current),
  });

  return (
    <CommonQueryProvider>
      <CommonHttpProvider
        name="auth"
        instance={httpClient.current.instance}
        {...rest}
      >
        <HttpProviderContext.Provider
          value={{ httpClient: httpClient.current, services, token, setToken }}
        >
          <UserManager fallback={fallback} logoutUrl={logoutUrl}>
            {children}
          </UserManager>
        </HttpProviderContext.Provider>
      </CommonHttpProvider>
    </CommonQueryProvider>
  );
};
