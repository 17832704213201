import { FC } from 'react';
import { Help } from '../../Checkbox/Checkbox.style';
import { FormItem, FormItemProps } from '../FormItem/FormItem';
import { RootCheckbox as Checkbox } from '../../Input/Input.style';
import { FormCheckboxValidationRules } from '../FormItem/FormItem.types';

interface FormCheckboxProps
  extends FormItemProps<FormCheckboxValidationRules> {}

export const FormCheckbox: FC<FormCheckboxProps> = ({
  help,
  label,
  disabled,
  ...props
}) => {
  return (
    <FormItem valuePropName="checked" {...props}>
      <Checkbox disabled={disabled}>
        {label}
        {help && <Help>{help}</Help>}
      </Checkbox>
    </FormItem>
  );
};
