import moment from 'moment';
import { JwtClaims } from '../../types/jwt.types';

export const calcRefreshTimeout = ({ exp, nbf }: JwtClaims): number => {
  const nowTimestamp = moment.utc().valueOf();
  const atTimestamp = moment(nbf + (exp - nbf) / 2)
    .utc()
    .valueOf();
  const ms = atTimestamp - nowTimestamp;
  return ms < 0 ? 0 : ms;
};
