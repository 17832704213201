import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  useContext,
} from 'react';
import { createPortal } from 'react-dom';
import { ChartContext } from '../Chart.context';

type TooltipProps = PropsWithChildren<{
  children: React.ReactNode;
}>;

export const Tooltip: FC<TooltipProps> = ({ children }) => {
  const { tooltipRef, tooltipDataIndex } = useContext(ChartContext);
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child as ReactElement, {
        dataIndex: tooltipDataIndex,
        key: tooltipDataIndex,
      });
    }
    return child;
  });

  return tooltipRef?.current
    ? createPortal(childrenWithProps, tooltipRef.current.node)
    : null;
};
