import { Chart, ScatterDataPoint } from 'chart.js';
import { noop } from 'lodash';
import { createContext, RefObject } from 'react';
import { UiKitColor } from '../../types/uikit';
import { ChartLineOnClickParams, TooltipContainerRefType } from './Chart.types';

export type Range = { x: [number, number] };

export type RangeParams = Partial<Range>;

export interface ChartContextProps {
  data: ScatterDataPoint[];
  range: Range;
  reverse?: boolean;
  setRange: (val: RangeParams) => void;
  labels: string[];
  onClick?: ({ dataIndex }: ChartLineOnClickParams) => void;
  color: UiKitColor;
  instance: Chart<any>;
  setInstance: (val: Chart<any>) => void;
  selectedIndex: number;
  tooltipRef: RefObject<TooltipContainerRefType>;
  tooltipDataIndex: number;
  setTooltipDataIndex: (val: number) => void;
}

export const ChartContext = createContext<ChartContextProps>({
  data: null,
  range: null,
  setRange: noop,
  labels: null,
  onClick: null,
  color: null,
  instance: null,
  setInstance: noop,
  selectedIndex: -1,
  tooltipRef: null,
  tooltipDataIndex: null,
  setTooltipDataIndex: noop,
});
