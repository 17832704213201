/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IAddUserRequestDto,
  ICurrentUserResponse,
  IErrorResponse,
  IMemberModel,
  IUpdateRoleRequestDto,
  IUpdateUserPanelRequestDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class User<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags User
   * @name GetUser
   * @summary Get current user
   * @request GET:/users/me
   * @secure
   */
  getUser = (params: RequestParams = {}) =>
    this.http.request<ICurrentUserResponse, IErrorResponse>({
      path: `/users/me`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name MembersList
   * @summary Get members list.
   * @request GET:/users/members
   * @secure
   */
  membersList = (params: RequestParams = {}) =>
    this.http.request<IMemberModel[], IErrorResponse>({
      path: `/users/members`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name PostUser
   * @summary Add new user
   * @request POST:/users/add
   * @secure
   */
  postUser = (data: IAddUserRequestDto, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/users/add`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name RoleUpdate
   * @summary Update user roles
   * @request PUT:/users/{id}/role
   * @secure
   */
  roleUpdate = (
    id: number,
    data: IUpdateRoleRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/users/${id}/role`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name SendInviteCreate
   * @summary Send invitation to specified user.
   * @request POST:/users/{id}/send-invite
   * @secure
   */
  sendInviteCreate = (id: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/users/${id}/send-invite`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name SendMultipleInviteCreate
   * @summary Send multiple invitations.
   * @request POST:/users/send-multiple-invite
   * @secure
   */
  sendMultipleInviteCreate = (params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/users/send-multiple-invite`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name ActivateCreate
   * @summary Activate user.
   * @request POST:/users/{id}/activate
   * @secure
   */
  activateCreate = (id: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/users/${id}/activate`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name DeactivateCreate
   * @summary Deactivate user.
   * @request POST:/users/{id}/deactivate
   * @secure
   */
  deactivateCreate = (id: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/users/${id}/deactivate`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name TeamsCreate
   * @summary Update selected team for user
   * @request POST:/users/teams/{teamId}
   * @secure
   */
  teamsCreate = (teamId: number, params: RequestParams = {}) =>
    this.http.request<void, IErrorResponse>({
      path: `/users/teams/${teamId}`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags User
   * @name PanelUpdate
   * @summary Update selected panel for user.
   * @request PUT:/users/panel
   * @secure
   */
  panelUpdate = (
    data: IUpdateUserPanelRequestDto,
    params: RequestParams = {}
  ) =>
    this.http.request<void, IErrorResponse>({
      path: `/users/panel`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
