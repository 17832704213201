import styled from 'styled-components';
import AntdAvatar from 'antd/lib/avatar';
import { getContrastYIQ } from '../../utils/getContrastYIQ/getContrastYIQ.utils';
import { RootComponent } from './Avatar.types';

export const Root = styled<RootComponent>(AntdAvatar)`
  background-color: ${({ color, theme: { palette } }) =>
    color ? palette[color] : palette.wolfGrey};
  color: ${({ color, theme: { palette } }) =>
    getContrastYIQ(color ? palette[color] : palette.wolfGrey)};
  &.ant-avatar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
