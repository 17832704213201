import styled from 'styled-components';
import { Layout } from '@spintel/uikit';

const { Content: UiKitContent } = Layout;

export const Root = styled(Layout)`
  min-width: ${({ theme }) => theme.spacing(320)};
`;

export const Content = styled(UiKitContent)`
  padding-top: ${({ theme: { spacing } }) => spacing(4)};
  padding-left: ${({ theme: { spacing } }) => spacing(6)};
  padding-right: ${({ theme: { spacing } }) => spacing(6)};
`;
