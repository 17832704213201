import { THEME } from '../../../constants/theme.const';
import { UiKitColor } from '../../../types/uikit';
import {
  DEFAULT_DOUGHNUT_VARIANT,
  DEFAULT_DOUGHNUT_VARIANT_BACKGROUND_COLORS,
  DEFAULT_DOUGHNUT_VARIANT_RADIUS,
  DEFAULT_DOUGHNUT_VARIANT_ANGLE_DEGREE_EXPANSION,
  DEFAULT_DOUGHNUT_VARIANT_RADIUS_LENGTH_EXPANSION,
} from './GraphDoughnut.const';
import { GraphDoughnutVariant } from './GraphDoughnut.types';

export const getMappedColors = (colors: UiKitColor[]): string[] => {
  return (colors || []).map((c) => THEME.palette[c]);
};

export const getDefinedVariant = (
  variant: GraphDoughnutVariant
): GraphDoughnutVariant => {
  const {
    backgroundColors = DEFAULT_DOUGHNUT_VARIANT_BACKGROUND_COLORS,
    radius = DEFAULT_DOUGHNUT_VARIANT_RADIUS,
    angleDegreeExpansion = DEFAULT_DOUGHNUT_VARIANT_ANGLE_DEGREE_EXPANSION,
    radiusLengthExpansion = DEFAULT_DOUGHNUT_VARIANT_RADIUS_LENGTH_EXPANSION,
  } = variant || DEFAULT_DOUGHNUT_VARIANT;

  return {
    backgroundColors,
    radius,
    angleDegreeExpansion,
    radiusLengthExpansion,
  };
};

export const defaultFormatter = (value: number) =>
  value % 1 !== 0 ? value.toFixed(2) : `${value}`;
