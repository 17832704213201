import { FC, Key, PropsWithChildren, useEffect } from 'react';
import { castArray, intersection, toString } from 'lodash';
import { useContextSelector } from 'use-context-selector';
import { SwitchContext, SwitchContextProps } from '../UserSwitchRole.context';

type UserSwitchRoleCaseProps = PropsWithChildren<{
  value: Key | Key[];
}>;

export const UserSwitchRoleCase: FC<UserSwitchRoleCaseProps> = ({
  children,
  value: initialValue,
}) => {
  const roles = useContextSelector<SwitchContextProps, unknown[]>(
    SwitchContext,
    ({ roles }) => roles
  );

  const values = castArray(initialValue);

  const set = useContextSelector<SwitchContextProps, SwitchContextProps['set']>(
    SwitchContext,
    ({ set }) => set
  );

  const key = toString(values);

  const value = !!intersection(values, roles).length;

  useEffect(() => set(key, value), [key, value, set]);

  return value ? <>{children}</> : null;
};
