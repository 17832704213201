import { has } from 'lodash';
import { Params } from 'react-router-dom';

export function decoder(value: string) {
  const keywords: Record<string, boolean | undefined | null> = {
    false: false,
    null: null,
    true: true,
    undefined: undefined,
  };

  if (/^(\d+|\d*\.\d+)$/.test(value)) {
    return parseFloat(value);
  }

  if (has(keywords, value)) {
    return keywords[value];
  }

  return decodeURI(value);
}

export const getPath = (pathname: string, params: Params): string => {
  if (!Object.keys(params).length) {
    return pathname;
  }

  return Object.entries(params).reduce((res, [paramName, paramValue]) => {
    return res.replace(paramValue, `:${paramName}`);
  }, pathname);
};
