import {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useContext,
  useState,
} from 'react';
import { noop, get } from 'lodash';
import AntdForm, { FormInstance } from 'antd/lib/form';
import { UiKitLocalizationContext } from '../../contexts/UiKitLocalization.context';
import { FormCheckbox } from './FormCheckbox/FormCheckbox';
import { FormRadio } from './FormRadio/FormRadio';
import { FormInput } from './FormInput/FormInput';
import { FormPassword } from './FormPassword/FormPassword';
import { FormTextarea } from './FormTextarea/FormTextarea';
import { FormSubmit } from './FormSubmit/FormSubmit';
import { FormInputAction } from './FormInputAction/FormInputAction';
import { FormCheckboxGroup } from './FormCheckboxGroup/FormCheckboxGroup';
import { FormRadioGroup } from './FormRadioGroup/FormRadioGroup';
import { FormNumber } from './FormNumber/FormNumber';
import { FormList } from './FormList/FormList';
import { FormRow } from './FormRow/FormRow';
import { FormLabels, FormErrors } from './Form.types';
import { FormContext, FormContextSetLabelProps } from './Form.context';
import { FormSelect } from './FormSelect/FormSelect';
import { FormCheckboxSelection } from './FormCheckboxSelection/FormCheckboxTable';

const { useForm } = AntdForm;

type FormProps<T> = PropsWithChildren<{
  initialValues?: T;
  errors?: FormErrors;
  form?: FormInstance;
  onSubmit?: (form: T) => void;
  requiredMark?: true | 'optional';
}>;

interface FormType {
  <T>(props: FormProps<T>): ReactElement<any, any> | null;
  Checkbox: typeof FormCheckbox;
  Input: typeof FormInput;
  Password: typeof FormPassword;
  Radio: typeof FormRadio;
  Submit: typeof FormSubmit;
  Textarea: typeof FormTextarea;
  InputAction: typeof FormInputAction;
  CheckboxGroup: typeof FormCheckboxGroup;
  RadioGroup: typeof FormRadioGroup;
  Number: typeof FormNumber;
  List: typeof FormList;
  Row: typeof FormRow;
  Select: typeof FormSelect;
  CheckboxSelection: typeof FormCheckboxSelection;
}

export const Form: FormType = ({
  errors,
  children,
  onSubmit = noop,
  initialValues = {},
  form: formInstance,
  requiredMark = 'optional',
}) => {
  const [validateTrigger, setValidateTrigger] = useState('onSubmit');
  const [labels, setLabels] = useState<FormLabels>({});
  const [form] = useForm(formInstance);
  const { formValidationError, formValidationMessages } = useContext(
    UiKitLocalizationContext
  );

  const setLabel = useCallback(({ name, label }: FormContextSetLabelProps) => {
    setLabels((prev) => ({ ...prev, [name]: label }));
  }, []);

  useEffect(() => {
    if (errors) {
      const values = form.getFieldsValue();
      const fields = Object.entries(errors).map(
        ([name, errors]): Parameters<typeof form.setFields>['0']['0'] => {
          return {
            name,
            errors: errors.map((message) =>
              formValidationError({ name, message, label: get(labels, name) })
            ),
            value: values[name],
          };
        }
      );
      form.setFields(fields);
    }
  }, [errors, form, formValidationError, labels]);

  return (
    <FormContext.Provider value={{ setLabel, errors, instance: form }}>
      <AntdForm
        form={form}
        size="large"
        layout="vertical"
        onFinish={onSubmit}
        requiredMark={requiredMark}
        initialValues={initialValues}
        validateTrigger={validateTrigger}
        validateMessages={formValidationMessages}
        onFinishFailed={() => setValidateTrigger('onChange')}
      >
        {children}
      </AntdForm>
    </FormContext.Provider>
  );
};

Form.Checkbox = FormCheckbox;
Form.Input = FormInput;
Form.Password = FormPassword;
Form.Submit = FormSubmit;
Form.Radio = FormRadio;
Form.Textarea = FormTextarea;
Form.InputAction = FormInputAction;
Form.CheckboxGroup = FormCheckboxGroup;
Form.RadioGroup = FormRadioGroup;
Form.Number = FormNumber;
Form.List = FormList;
Form.Row = FormRow;
Form.Select = FormSelect;
Form.CheckboxSelection = FormCheckboxSelection;
