import { Transition } from 'mapbox-gl';
import { MapPosition } from './Map.types';

export const DEFAULT_MAP_CENTER: MapPosition = {
  lat: 51.505,
  lng: -0.09,
};

export const LAYERS_ID_PREFIX = 'SOURCE_LAYER_PREFIX';
export const SOURCE_ID_PREFIX_CIRCLE = 'SOURCE_CIRCLES';
export const SOURCE_ID_PREFIX_POLYGON = 'SOURCE_POLYGONS';

export const DEFAULT_MAP_TRANSITION: Transition = {
  delay: 0,
  duration: 1000,
};
