import styled, { css } from 'styled-components';

const monoMixin = {
  fontVariant: 'tabular-nums',
};

const HeadingDropdownToggleMixin = css`
  span {
    &.ant-dropdown-trigger {
      cursor: pointer;
      display: flex;
      align-items: center;
      &.ant-dropdown-trigger-with-chevron {
        &:after {
          margin-left: ${({ theme: { spacing } }) => spacing(2.5)};
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: ${({ theme }) => theme.palette.white} transparent
            transparent transparent;
        }
      }
    }
  }
`;

export interface TypographyProps {
  monospaced?: boolean;
}

export const Heading1 = styled('h1')(() => ({
  fontWeight: 700,
  fontSize: 40,
  lineHeight: '44px',
  letterSpacing: '-0.02em',
}));

export const Heading2 = styled('h2')`
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -0.02em;
  ${HeadingDropdownToggleMixin}
`;

export const Heading3 = styled('h3')<TypographyProps>(() => ({
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '24px',
  letterSpacing: '-0.02em',
}));

export const Heading4 = styled('h4')<TypographyProps>(() => ({
  fontWeight: 700,
  fontSize: 18,
  lineHeight: '22px',
  letterSpacing: '-0.01em',
}));

export const Heading5 = styled('h5')<TypographyProps>(({ monospaced }) => ({
  fontWeight: 700,
  fontSize: 16,
  lineHeight: '20px',
  letterSpacing: '-0.01em',
  ...(monospaced && monoMixin),
}));

export const Heading6 = styled('h6')<TypographyProps>(
  ({ monospaced, theme: { palette } }) => ({
    color: palette.white,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: '-0.01em',
    ...(monospaced && monoMixin),
  })
);

export const Subtitle1 = styled('h4')<TypographyProps>(({ monospaced }) => ({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '18px',
  ...(monospaced && monoMixin),
}));

export const Subtitle2 = styled('h5')<TypographyProps>(({ monospaced }) => ({
  fontWeight: 600,
  fontSize: 12,
  lineHeight: '16px',
  ...(monospaced && monoMixin),
}));

export const Body1 = styled('p')<TypographyProps>(({ monospaced }) => ({
  fontSize: 16,
  lineHeight: '20px',
  ...(monospaced && monoMixin),
}));

export const Body2 = styled('p')<TypographyProps>(({ monospaced }) => ({
  fontSize: 14,
  lineHeight: '16px',
  ...(monospaced && monoMixin),
}));

export const Body3 = styled('p')<TypographyProps>(({ monospaced }) => ({
  fontSize: 12,
  lineHeight: '16px',
  ...(monospaced && monoMixin),
}));

export const Body4 = styled('p')<TypographyProps>(({ monospaced }) => ({
  fontSize: 10,
  lineHeight: '14px',
  ...(monospaced && monoMixin),
}));
