import { useCallback, useState } from 'react';
import { useMount } from 'react-use';
import { DTO, useMutation } from '@spintel/network/app';

interface UseSearchParams {
  songId: number;
}

interface UseSearchState {
  loading: boolean;
  searching: boolean;
  total: number;
  songs: DTO.ISearchSongComparisonModel[];
}

type UseSearchReturnType = [
  UseSearchState,
  {
    search: (query: string) => void;
    load: () => void;
  }
];

const DEFAULT_REQUEST = {
  query: '',
  pageNumber: 1,
  pageSize: 150,
  sort: {
    column: 'rank',
    order: DTO.IPagedSortOrder.Ascend,
  },
};

export const useSearch = ({ songId }: UseSearchParams): UseSearchReturnType => {
  const [songs, setSongs] = useState<DTO.ISearchSongComparisonModel[]>([]);
  const [request, setRequest] =
    useState<DTO.IStringPagedRequest>(DEFAULT_REQUEST);

  const [{ loading }, fetch] = useMutation<
    [number, DTO.IStringPagedRequest],
    DTO.ISearchSongComparisonModel[]
  >(({ SongComparison }) => SongComparison.searchCreate);

  const load = useCallback(() => {
    const _request: DTO.IStringPagedRequest = {
      ...request,
      pageNumber: request.pageNumber + 1,
    };

    return fetch([songId, _request], {
      onSuccess: ({ data }) => {
        setSongs((prev) => prev.concat(data));
        setRequest(_request);
      },
    });
  }, [songId, request, fetch]);

  const search = useCallback(
    (query: string) => {
      const _request = { ...DEFAULT_REQUEST, query };

      return fetch([songId, { ...DEFAULT_REQUEST, query }], {
        onSuccess: ({ data }) => {
          setSongs(data);
          setRequest(_request);
        },
      });
    },
    [songId, fetch]
  );

  useMount(() => {
    fetch([songId, request], {
      onSuccess: ({ data }) => setSongs(data),
    });
  });

  const searching = !!request.query;
  const total = searching ? 0 : Number.MAX_SAFE_INTEGER;

  return [
    { loading, searching, songs, total },
    { search, load },
  ];
};
