import { Menu, Search } from '@spintel/uikit';
import styled from 'styled-components';

export const Searchbar = styled(Search)`
  width: auto;
  margin: ${({ theme: { spacing } }) => spacing(4)};
  flex: 0 0 auto;
`;

export const List = styled(Menu)`
  flex: 1 1 auto;
  overflow: scroll;
  overflow: auto;
  max-height: ${({ theme: { spacing } }) => spacing(67)};
  padding-top: 0;
`;
