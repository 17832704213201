module.exports = {
  above: 'Above',
  activate: 'Activate',
  activity: 'Activity',
  add: 'Add',
  addsCW: 'Adds CW',
  addsTW: 'Adds TW',
  allSongs: 'All songs',
  am: 'AM',
  and: 'and',
  apc: 'APC',
  artist: 'Artist',
  at: 'AT',
  averageStationPlays: 'Average station plays',
  avg: 'Avg.',
  avgPlayCom: 'AVG PLAY COM',
  avgPlaylistSize: 'AVG playlist size',
  avgStationPlaysOffsetTW: 'AVG station plays offset TW',
  avgStationPlaysTW: 'AVG station plays TW',
  below: 'Below',
  biggestSongDecreases: 'Biggest song decreases',
  biggestSongIncreases: 'Biggest song increases',
  businessLine: 'Business line',
  city: 'City',
  countMembers_one: '{{count}} member',
  countMembers_other: '{{count}} members',
  countPanels_one: '{{count}} panel',
  countPanels_other: '{{count}} panels',
  countSongs_one: '{{count}} song',
  countSongs_other: '{{count}} songs',
  countStations: '{{count}} stations',
  currentPassword: 'Current password',
  currentWeek: 'Current week',
  currents: 'Currents',
  cw: 'CW',
  cwAm: 'CW AM',
  cwAmChange: 'CW AM +/-',
  cwChange: 'CW +/-',
  cwDay: 'CW day',
  cwDayChange: 'CW day +/-',
  cwEve: 'CW EVE',
  cwEveChange: 'CW EVE +/-',
  cwMid: 'CW MID',
  cwMidChange: 'CW MID +/-',
  cwNight: 'CW night',
  cwNightChange: 'CW night +/-',
  cwOvn: 'CW OVN',
  cwOvnChange: 'CW OVN +/-',
  cwPm: 'CW PM',
  cwPmChange: 'CW PM +/-',
  cwTotal: 'CW (total)',
  date: 'Date',
  daypart: 'Daypart',
  dayparts: 'Dayparts',
  deactivate: 'Deactivate',
  decreases: 'Decreases',
  diffPercent: 'Diff. %',
  drops: 'Drops',
  dropsTW: 'Drops TW',
  duplicatePanel: 'Duplicate panel',
  editPanel: 'Edit panel',
  editTeam: 'Edit team',
  email: 'Email',
  eve: 'EVE',
  facebook: 'Facebook',
  filtersApplied: 'Filters applied',
  firstName: 'First name',
  fullName: '{{firstName}} {{lastName}}',
  headroom: 'Headroom',
  heavy: 'Heavy',
  high: 'High',
  higher: 'Higher',
  increases: 'Increases',
  instagram: 'Instagram',
  inviteNewMember: 'Invite new member',
  label: 'Label',
  labelValue: '{{label}}: {{value}}',
  lastName: 'Last name',
  lastUpd: 'Last upd.',
  light: 'Light',
  logIn: 'Log in',
  longShot: 'Long shot',
  lostPlaysTW: 'Lost plays TW',
  low: 'Low',
  lower: 'Lower',
  lw: 'LW',
  lwAm: 'LW AM',
  lwAmChange: 'LW AM +/-',
  lwChange: 'LW  +/-',
  lwDay: 'LW day',
  lwDayChange: 'LW day +/-',
  lwEve: 'LW EVE',
  lwEveChange: 'LW EVE +/-',
  lwMid: 'LW MID',
  lwMidChange: 'LW MID +/-',
  lwNight: 'LW night',
  lwNightChange: 'LW night +/-',
  lwOvn: 'LW OVN',
  lwOvnChange: 'LW OVN +/-',
  lwPm: 'LW PM',
  lwPmChange: 'LW PM +/-',
  lwTotal: 'LW (total)',
  market: 'Market',
  maxPlays: 'Max. plays',
  medium: 'Medium',
  memberActivate: {
    subtitle: 'Are you sure you want to activate {{userName}}?',
    title: 'Activate member',
  },
  memberDeactivate: {
    subtitle: 'Are you sure you want to deactivate {{userName}}?',
    title: 'Deactivate member',
  },
  membersCount: '(0-1)[{{count}} member];(2-inf)[{{count}} members];',
  mid: 'MID',
  multiFactorAuthentication: 'Multi-factor authentication',
  nSongs: '{{ number }} songs',
  nStations: '# Stations',
  nStationsOn: '# Stations on',
  name: 'Name',
  natDiff: 'Nat diff.',
  nationalPlaysCW: 'National plays CW',
  nationalPlaysTW: 'National plays TW',
  nationalRankTW: 'National rank TW',
  neg: 'Neg',
  newAdds: 'New adds',
  newPanel: 'New panel',
  newPassword: 'New password',
  newTeam: 'New team',
  no: 'No',
  noChange: 'No change',
  noChanges: 'No changes',
  none: 'None',
  notOn: 'Not on',
  officeAddress: 'Office address',
  offsetPlays: 'Offset plays',
  onTheMap: 'On the map',
  outlook: 'Outlook',
  ovn: 'OVN',
  owner: 'Owner',
  ownerPercent: 'Owner %',
  ownerPlays: 'Owner plays',
  ownerPoints: 'Owner points',
  ownerRank: 'Owner rank',
  pDaysPlaysTW: '% Days plays TW',
  pOVNPlaysTW: '% OVN plays TW',
  pOfPanelPlays: '% of Panel plays',
  pPlayOffsetTW: '% Play offset TW',
  pSongPlaysTW: '% Song plays TW',
  pStationsOnTW: '% Stations on TW',
  panel: 'Panel',
  panelPercent: 'Panel %',
  panelPlays: 'Panel plays',
  panelPoints: 'Panel points',
  panelRank: 'Panel rank',
  panelsCount: '(0-1)[{{count}} panel];(2-inf)[{{count}} panels];',
  password: 'Password',
  passwordConfirm: 'Confirm password',
  percent: '%',
  phone: 'Phone',
  phoneNumber: 'Phone number',
  playlist: 'Playlist',
  playlistSize: 'Playlist size',
  plays: 'Plays',
  playsAT: 'Plays AT',
  playsCW: 'Plays CW',
  playsChange: 'Plays +/-',
  playsDay: 'Plays Day',
  playsFromAddsCW: 'Plays from adds CW',
  playsFromAddsTW: 'Plays from adds TW',
  playsNight: 'Plays night',
  playsOVN: 'Plays OVN',
  playsTW: 'Plays TW',
  playsTWDay: 'Plays TW day',
  playsTWNight: 'Plays TW night',
  playsTWOVN: 'Plays TW OVN',
  pm: 'PM',
  points: 'Points',
  pointsCW: 'Points CW',
  pointsTW: 'Points TW',
  pos: 'Pos',
  posSpce: 'Pos. space',
  possible: 'Possible',
  probable: 'Probable',
  proveIt: 'Prove it',
  ps: 'PS',
  psAvg: 'PS Avg.',
  rank: 'Rank',
  rankAway: 'Rank away',
  rankCW: 'Rank CW',
  rankChange: 'Rank +/-',
  rankPlayComparison: 'Rank play comparison',
  rankSongArtist: '{{rank}}. {{songName}} - {{artistName}}',
  rankTW: 'Rank TW',
  recentlyAdded: 'Recently added',
  recentlyDropped: 'Recently dropped',
  repeatNewPassword: 'Repeat new password',
  resetPasswordTitle: 'Forgot password? Get instructions!',
  resetYourPassword: 'Reset your password',
  rnkComp: 'RNK COMP',
  roles: 'Roles',
  rotation: 'Rotation',
  rpc: 'RPC',
  selectSong: 'Select song',
  selectStations: 'Select stations',
  sendInvitation: 'Send invitation',
  signUp: 'Sign up. It’s quick and easy.',
  song: 'Song',
  songComparisonSelect: 'Station play comparison VS',
  songHistory: 'Song history',
  songsAt: 'Songs AT',
  songsCount: '(0-1)[{{count}} song];(2-inf)[{{count}} songs];',
  state: 'State',
  station: 'Station',
  stationAdded: 'Station added',
  stationAdds: 'Station adds',
  stationDetails: 'Station details',
  stationDrops: 'Station drops',
  stationOn: 'Station on',
  stationPlays: 'Station plays',
  stationPoints: 'Station points',
  stationRank: 'Station rank',
  stations: 'Stations',
  stationsDrops: 'Stations Drops',
  stationsOn: 'Stations on',
  stationsOnCW: 'Stations on CW',
  stationsOnPercent: 'Stations on %',
  stationsOnTW: 'Stations on TW',
  status: 'Status',
  studioLine: 'Studio line',
  teamName: 'Team name',
  teams: 'Teams',
  testing: 'Testing',
  thisWeek: 'This week',
  total: 'Total',
  totals: 'Totals',
  tw: 'TW',
  twAm: 'TW AM',
  twAmChange: 'TW AM +/-',
  twAmPercent: 'TW AM %',
  twAt: 'TW AT',
  twAvg: 'TW Avg.',
  twChange: 'TW +/-',
  twDay: 'TW day',
  twDayChange: 'TW day +/-',
  twDayPercent: 'TW day %',
  twEve: 'TW EVE',
  twEveChange: 'TW EVE +/-',
  twEvePercent: 'TW EVE %',
  twMid: 'TW MID',
  twMidChange: 'TW MID +/-',
  twMidPercent: 'TW MID %',
  twNight: 'TW night',
  twNightChange: 'TW night +/-',
  twNightPercent: 'TW night %',
  twOvn: 'TW OVN',
  twOvnChange: 'TW OVN +/-',
  twOvnPercent: 'TW OVN %',
  twPlaysChange: 'Plays TW +/-',
  twPm: 'TW PM',
  twPmChange: 'TW PM +/-',
  twPmPercent: 'TW PM %',
  twRankChange: 'Rank TW +/-',
  twTotal: 'TW (total)',
  twitter: 'Twitter',
  undo: 'Undo',
  userRole: 'User role',
  value: 'Value',
  website: 'Website',
  week: 'Week',
  weekValue: 'Week: {{value}}',
  weight: 'Weight',
  wl: 'W/L',
  youtube: 'Youtube',
};
