import { createContext, ReactNode } from 'react';
import { noop } from 'lodash';
import { CardPartitions } from './TabCard.types';

interface CardContextProps {
  activeTab: string;
  setPartition: (key: keyof CardPartitions, value: ReactNode) => void;
}

export const CardContext = createContext<CardContextProps>({
  activeTab: null,
  setPartition: noop,
});
