import { FC, useContext, Key } from 'react';
import { toString } from 'lodash';
import { CardContext } from '../TabCard.context';
import { Typography } from '../../Typography/Typography';
import { Diff } from '../../Diff/Diff';
import { UiKitColor } from '../../../types/uikit';
import {
  Root,
  Content,
  Header,
  Body,
  Value,
  ValueDiff,
  Bar,
} from './TabCardTab.style';

const { Subtitle2, Body3 } = Typography;

export interface TabCardTabProps {
  id: Key;
  label: string;
  value?: number;
  color?: UiKitColor;
  diff?: number;
  total?: number;
}

export const TabCardTab: FC<TabCardTabProps> = ({
  id,
  label,
  value,
  color,
  diff = 0,
  total = 100,
}) => {
  const { activeTab } = useContext(CardContext);

  const active = toString(activeTab) === toString(id);

  return (
    <Root>
      <Content>
        <Header active={active}>
          {active ? <Subtitle2>{label}</Subtitle2> : <Body3>{label}</Body3>}
        </Header>
        <Body>
          <Value active={active}>{value}</Value>
          {!!diff && (
            <ValueDiff>
              <Diff value={diff} />
            </ValueDiff>
          )}
        </Body>
      </Content>
      <Bar color={color} total={total} value={value} />
    </Root>
  );
};
