import jwtDecode from 'jwt-decode';
import { toNumber } from 'lodash';
import { JwtClaims, JwtDecoded } from '../../types/jwt.types';

export const getDecodedJwt = (token: string): JwtClaims => {
  if (token) {
    const {
      exp = 0,
      nbf = 0,
      inactivitytimeout: inactivityTimeout = '0',
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress':
        email,
    } = jwtDecode<JwtDecoded>(token);
    return {
      email,
      exp: exp * 1000,
      inactivityTimeout: toNumber(inactivityTimeout) * 1000 * 60,
      nbf: nbf * 1000,
    };
  }
  return { exp: 0, inactivityTimeout: 0, nbf: 0, email: null };
};
