import { ReactElement, useContext } from 'react';
import { isArray, isUndefined, noop } from 'lodash';
import { FormItem, FormItemProps } from '../FormItem/FormItem';
import { FormSelectValidationRules } from '../FormItem/FormItem.types';
import { UiKitLocalizationContext } from '../../../contexts/UiKitLocalization.context';
import { SelectOptions } from '../../Select/Select.types';
import { Root as Select } from '../../Select/Select.style';
import { Empty } from '../../Empty/Empty';
import { useFormInstance, useFormWatch } from '../Form.hooks';
import { getOptions } from '../../Select/Select.utils';
import { SelectArrow, Tag } from './FormSelect.style';
import { SelectOption } from './FormSelect.types';

interface FormSelectProps extends FormItemProps<FormSelectValidationRules> {
  loading?: boolean;
  multiple?: boolean;
  placeholder?: string;
  searchable?: boolean;
  options: SelectOptions;
  onChange?: (value: string, option: SelectOption) => void;
}

interface FormSelectComponent {
  (props: FormSelectProps): ReactElement<any, any> | null;
}

export const FormSelect: FormSelectComponent = ({
  loading,
  options: initialOptions,
  searchable,
  onChange = noop,
  multiple = false,
  placeholder: initialPlaceholder,
  disabled,
  ...props
}) => {
  const { formItemPlaceholder, empty } = useContext(UiKitLocalizationContext);
  const form = useFormInstance();
  const value = useFormWatch(props.name, form);

  const options = getOptions({
    value,
    multiple,
    options: initialOptions,
  });

  const placeholder = (() => {
    if (isUndefined(initialPlaceholder)) {
      return formItemPlaceholder.select(props.label || '');
    }
    return initialPlaceholder;
  })();

  const notFoundContent = (
    <Empty title={!initialOptions.length ? empty.noData : empty.noMatch} />
  );

  const filterOption = (input: string, option: Record<string, string>) =>
    option.label?.toLocaleLowerCase().includes(input.toLocaleLowerCase());

  const getTagSuffix = (v: any) => {
    if (!isArray(value)) return '';
    return v === value[value.length - 1] ? '' : ',';
  };

  return (
    <FormItem {...props}>
      <Select
        variant="default"
        loading={loading}
        options={options}
        withStatus={false}
        disabled={disabled}
        showArrow={!searchable}
        showSearch={searchable}
        optionFilterProp="label"
        maxTagCount="responsive"
        placeholder={placeholder}
        filterOption={filterOption}
        menuItemSelectedIcon={null}
        dropdownStyle={{ marginTop: 10 }}
        notFoundContent={notFoundContent}
        mode={multiple ? 'multiple' : undefined}
        onChange={(value, option) => onChange(value, option)}
        suffixIcon={<SelectArrow name="arrow-triangular-down" />}
        tagRender={({ label, value }) => (
          <Tag suffix={getTagSuffix(value)}>{label}</Tag>
        )}
      />
    </FormItem>
  );
};
