import { ReactNode } from 'react';
import AntdNotification from 'antd/lib/notification';
import { uniqueId } from 'lodash';
import { UiKitProvider } from '../../UiKitProvider/UiKitProvider';
import { Button } from '../../Button/Button';
import { Icon } from '../../Icon/Icon';
import { Content, Message, Actions } from './NotificationShow.style';

const { open, close } = AntdNotification;

interface ShowNotificationAction {
  onClick: () => void;
  label: string;
  variant?: 'secondary' | 'tertiary';
}

interface ShowNotificationParams {
  message: ReactNode;
  actions?: ShowNotificationAction[];
  id?: string;
}

export const showNotification = ({
  message,
  actions,
  id,
}: ShowNotificationParams) => {
  const key = id || uniqueId('notify');

  open({
    key,
    message: (
      <UiKitProvider>
        <Content>
          <Message>{message}</Message>
          {actions?.length && (
            <Actions>
              {actions.map(({ label, variant = 'tertiary', onClick }) => {
                return (
                  <Button key={label} variant={variant} onClick={onClick}>
                    {label}
                  </Button>
                );
              })}
            </Actions>
          )}
        </Content>
      </UiKitProvider>
    ),
    closeIcon: <Icon name="close" size="sm" />,
    placement: 'bottomLeft',
    bottom: 12,
  });
};

export const hideNotification = (id: string) => close(id);
