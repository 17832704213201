import Modal from 'antd/lib/modal';
import { noop } from 'lodash';
import { ConfirmTitle } from './Modal.style';
import { ModalVariant } from './Modal.types';

const { confirm } = Modal;

interface ModalConfirmParams {
  title?: string;
  message: React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmBtn?: string;
  cancelBtn?: string;
  variant?: ModalVariant;
}

export const modalConfirm = ({
  title,
  message,
  onConfirm,
  onCancel = noop,
  variant = 'primary',
  // TODO: Add localization
  confirmBtn = 'Confirm',
  cancelBtn = 'Cancel',
}: ModalConfirmParams) => {
  confirm({
    title: title && <ConfirmTitle>{title}</ConfirmTitle>,
    icon: null,
    closable: false,
    content: message,
    onOk: onConfirm,
    onCancel: onCancel,
    okText: confirmBtn,
    cancelText: cancelBtn,
    cancelButtonProps: {
      size: 'large',
      type: 'ghost',
      shape: 'round',
    },
    okButtonProps: {
      size: 'large',
      danger: variant === 'danger',
      shape: 'round',
    },
  });
};
