import styled, { css } from 'styled-components';
import { Userpic, Dropdown } from '@spintel/uikit';

const UserpicMixin = css`
  max-width: ${({ theme: { spacing } }) => spacing(63)};
  overflow: hidden;
  text-overflow: ellipsis;
  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &::after {
    margin-left: 0 !important;
  }
`;

export const Root = styled('div')`
  margin-left: ${({ theme: { spacing } }) => spacing(8)};
`;

export const UserMenuToggle = styled(Userpic)`
  ${UserpicMixin}
`;

export const UserMenuContent = styled(Userpic)`
  ${UserpicMixin}
  padding: ${({ theme: { spacing } }) => spacing(2)} 0;
`;

export const Menu = styled(Dropdown.Menu)`
  width: 200px;
`;
