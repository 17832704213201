import { SegmentedProps } from 'antd/lib/segmented';
import { IconSize } from '../../Icon/Icon.types';
import { SegmentedSize } from '../Segmented.types';

export const SEGMENTED_SIZE_MAP: Record<SegmentedSize, SegmentedProps['size']> =
  {
    md: 'middle',
    sm: 'small',
  };

export const SEGMENTED_HEIGHT_MAP: Record<SegmentedSize, number> = {
  md: 32,
  sm: 24,
};

export const ICON_SIZE_MAP: Record<SegmentedSize, IconSize> = {
  md: 'sm',
  sm: 'xs',
};
