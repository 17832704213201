import { date } from '@spintel/localization';
import { DTO } from '@spintel/network/app';

/**
 * NOTE: works with starts of weeks
 */
export const getDateRangeByWeek = (week: string, lastWeek: string) => {
  const current = date.utc(week).startOf('week').toISOString();
  const end = date.utc(lastWeek).startOf('week').toISOString();

  const timePeriods = [
    { period: DTO.IDateRangeType.Type1M, duration: [28, 'd'] },
    { period: DTO.IDateRangeType.Type3M, duration: [28 * 3, 'd'] },
    { period: DTO.IDateRangeType.Type6M, duration: [28 * 6, 'd'] },
    { period: DTO.IDateRangeType.Type1Y, duration: [28 * 12, 'd'] },
  ];

  for (const { period, duration } of timePeriods) {
    const start = date.utc(end).subtract(...duration);
    if (date.utc(current).isBetween(start, end, null, '[]')) return period;
  }

  return DTO.IDateRangeType.All;
};
