import { useCallback } from 'react';
import { useMutation } from '../useMutation/useMutation.hook';
import {
  ISignUpUserRequestDto,
  ISignUpUserResponseDto,
} from '../../__generated__/data-contracts';
import { RequestParams } from '../../__generated__/http-client';
import { SignupFunction, UseLoginReturnType } from './useSignup.types';

export const useSignup = (): UseLoginReturnType => {
  const [state, execute] = useMutation<
    [ISignUpUserRequestDto, RequestParams],
    ISignUpUserResponseDto
  >(({ Auth }) => Auth.signupCreate);

  const signup: SignupFunction = useCallback(
    (form) => {
      execute([
        form,
        {
          headers: {
            Intercept: false,
          },
        },
      ]);
    },
    [execute]
  );

  return [state, signup];
};
