import styled from 'styled-components';
import { Dropdown, MenuProps, SearchProps } from '@spintel/uikit';

export { MenuProps, SearchProps };

export const Frequency = styled('div')`
  display: flex;
  align-items: baseline;
  gap: ${({ theme: { spacing } }) => spacing(0.5)};
`;

export const Overlay = styled(Dropdown.Overlay)`
  display: flex;
  flex-direction: column;
  margin-top: 0;
`;

export const Dot = styled('span')`
  display: inline-flex;
  vertical-align: middle;
  margin: 0 ${({ theme: { spacing } }) => spacing(1)};
  width: ${({ theme: { spacing } }) => spacing(0.75)};
  height: ${({ theme: { spacing } }) => spacing(0.75)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.londonGrey};
`;

export const Title = styled('div')`
  display: inline-flex;
`;
