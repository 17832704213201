import React, { PropsWithChildren, Key } from 'react';
import cn from 'classnames';
import { stringify } from 'qs';
import { noop } from 'lodash';
import { generatePath } from 'react-router';
import { NavLink } from 'react-router-dom';

export interface LinkProps {
  to: string;
  activeClassName?: string;
  className?: string;
  params?: Record<Key, string | number | boolean>;
  search?: Record<string, unknown>;
  onClick?: () => void;
}

export function Link({
  children,
  to,
  className,
  activeClassName,
  params = {},
  search: s = {},
  onClick = noop,
}: PropsWithChildren<LinkProps>): React.ReactElement {
  // @ts-ignore
  const pathname = generatePath(to, params);
  const search = stringify(s, {
    addQueryPrefix: true,
    encode: false,
    strictNullHandling: true,
  });

  return (
    <NavLink
      className={({ isActive }) => cn(className, isActive && activeClassName)}
      to={{ pathname, search }}
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
}
