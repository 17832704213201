import styled from 'styled-components';
import { Skeleton } from '../../Skeleton/Skeleton';

export const Root = styled('div').withConfig({
  shouldForwardProp: (prop) => !['loading'].includes(prop),
})<{ loading: boolean }>`
  flex: 1;
  padding: ${({ theme: { spacing } }) => spacing(6)};
  background: ${({ theme }) => theme.palette.anthraciteGrey};
  overflow: hidden ${({ loading }) => (loading ? 'hidden' : 'auto')};
  position: relative;
`;

export const Loader = styled(Skeleton)`
  z-index: 3;
  left: ${({ theme: { spacing } }) => spacing(6)};
  right: ${({ theme: { spacing } }) => spacing(6)};
  top: ${({ theme: { spacing } }) => spacing(6)};
  bottom: ${({ theme: { spacing } }) => spacing(6)};
`;
