import styled, { css } from 'styled-components';
import Button from 'antd/lib/button';
import { rotateAnimation } from '../../styles/Rotate.style';
import { ButtonComponent, ButtonComponentProps } from './Button.types';
import {
  SPINNER_SIZE_MAP,
  SPINNER_BORDER_WIDTH_MAP,
  CHEVRON_HEIGHT_MAP,
  CHEVRON_WIDTH_MAP,
} from './Button.const';

const RootPrimaryMixin = css`
  &.ant-btn {
    &:hover {
      color: ${({ theme }) => theme.palette.black};
      background: ${({ theme }) => theme.palette.guacamoleGreen};
      border-color: ${({ theme }) => theme.palette.guacamoleGreen};
    }
    &:active {
      > * {
        opacity: 0.4;
      }
    }
    &[ant-click-animating-without-extra-node='true']::after {
      display: none;
    }
    &[disabled],
    &[disabled]:hover {
      color: ${({ theme }) => theme.palette.metropolisGrey};
      border-color: ${({ theme }) => theme.palette.guacamoleGreen};
      background: ${({ theme }) => theme.palette.guacamoleGreen};
      opacity: 0.4;
    }
    &.ant-btn-loading {
      background: ${({ theme }) => theme.palette.guacamoleGreen};
    }
    &.ant-btn-dangerous {
      color: ${({ theme }) => theme.palette.metropolisGrey};
      background: ${({ theme }) => theme.palette.feverRed};
      &.ant-btn-loading {
        background: ${({ theme }) => theme.palette.vividRed};
        border-color: ${({ theme }) => theme.palette.vividRed};
      }
      &:hover,
      &:active {
        color: ${({ theme }) => theme.palette.black};
        background: ${({ theme }) => theme.palette.vividRed};
        border-color: ${({ theme }) => theme.palette.vividRed};
      }
      &[disabled],
      &[disabled]:hover {
        border-color: ${({ theme }) => theme.palette.feverRed};
        background: ${({ theme }) => theme.palette.feverRed};
      }
    }
  }
`;

const RootSecondaryMixin = css`
  &.ant-btn {
    &:hover {
      color: ${({ theme }) => theme.palette.black};
      background: ${({ theme }) => theme.palette.stardustGrey};
      border-color: ${({ theme }) => theme.palette.stardustGrey};
    }
    &:focus {
      color: ${({ theme }) => theme.palette.metropolisGrey};
      border-color: ${({ theme }) => theme.palette.white};
    }
    &:active {
      > * {
        opacity: 0.4;
      }
    }
    &[ant-click-animating-without-extra-node='true']::after {
      display: none;
    }
    &[disabled],
    &[disabled]:hover {
      color: ${({ theme }) => theme.palette.metropolisGrey};
      border-color: ${({ theme }) => theme.palette.stardustGrey};
      background: ${({ theme }) => theme.palette.stardustGrey};
      opacity: 0.4;
    }
    &.ant-btn-loading {
      background: ${({ theme }) => theme.palette.stardustGrey};
    }
  }
`;

const RootTernaryMixin = css`
  &.ant-btn {
    &:hover {
      color: ${({ theme }) => theme.palette.white};
      border-color: ${({ theme }) => theme.palette.white};
    }
    &:active {
      color: ${({ theme }) => theme.palette.white};
      border-color: ${({ theme }) => theme.palette.white};
      > * {
        opacity: 0.4;
      }
    }
    &[ant-click-animating-without-extra-node='true']::after {
      display: none;
    }
    &:focus {
      color: ${({ theme }) => theme.palette.white}D8;
      border-color: ${({ theme }) => theme.palette.wolfGrey};

      &:hover {
        color: ${({ theme }) => theme.palette.white};
        border-color: ${({ theme }) => theme.palette.white};
      }
    }
    &[disabled],
    &[disabled]:hover {
      color: ${({ theme }) => theme.palette.white};
      border-color: ${({ theme }) => theme.palette.wolfGrey};
      opacity: 0.4;
    }
    &.ant-dropdown-trigger {
      &.ant-dropdown-trigger-with-chevron {
        > span {
          &:after {
            border-top-color: ${({ theme }) => theme.palette.white};
          }
        }
      }
      &.ant-dropdown-open {
        border-color: ${({ theme }) => theme.palette.guacamoleGreen};
        box-shadow: 0px 0px 0px 1px
          ${({ theme }) => theme.palette.guacamoleGreen};
        color: ${({ theme }) => theme.palette.white};
      }
    }
  }
`;

const RootLinkMixin = css`
  &.ant-btn {
    padding-left: 0;
    padding-right: 0;
    color: ${({ theme }) => theme.palette.spintelGreen};
    &:hover {
      color: ${({ theme }) => theme.palette.guacamoleGreen};
    }
    &:active {
      color: ${({ theme }) => theme.palette.guacamoleGreen};
      > * {
        opacity: 0.4;
      }
    }
    &[disabled],
    &[disabled]:hover {
      opacity: 0.4;
    }
    &.ant-btn-lg {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const RootTextMixin = css`
  &.ant-btn {
    padding-left: 0 !important;
    padding-right: 0 !important;
    color: ${({ theme }) => theme.palette.white};
    &:hover {
      color: ${({ theme }) => theme.palette.stardustGrey};
    }
    &:active {
      color: ${({ theme }) => theme.palette.stardustGrey};
      > * {
        opacity: 0.4;
      }
    }
    &[disabled],
    &[disabled]:hover {
      color: ${({ theme }) => theme.palette.white};
      opacity: 0.4;
    }
    &.ant-dropdown-trigger {
      &.ant-dropdown-trigger-with-chevron {
        > span {
          &:after {
            border-top-color: ${({ theme }) => theme.palette.white};
          }
        }
      }
      &.ant-dropdown-open {
        color: ${({ theme }) => theme.palette.spintelGreen};
        &.ant-dropdown-trigger-with-chevron {
          > span {
            &:after {
              border-top-color: ${({ theme }) => theme.palette.spintelGreen};
            }
          }
        }
      }
    }
  }
`;

const RootMutedMixin = css`
  &.ant-btn {
    padding-left: 0;
    padding-right: 0;
    color: ${({ theme }) => theme.palette.londonGrey};
    &:hover {
      color: ${({ theme }) => theme.palette.pearlGrey};
    }
    &:active {
      color: ${({ theme }) => theme.palette.pearlGrey};
      > * {
        opacity: 0.4;
      }
    }
    &[disabled],
    &[disabled]:hover {
      color: ${({ theme }) => theme.palette.londonGrey};
      opacity: 0.4;
    }
  }
`;

const RootSpinnerMixin = css<ButtonComponentProps>`
  &.ant-btn {
    &.ant-btn-loading {
      > *:not(.ant-btn-loading-icon) {
        opacity: 0;
      }
      > .ant-btn-loading-icon {
        opacity: 0.4 !important;
      }
      &:before {
        display: none;
      }
      &.ant-dropdown-trigger {
        &.ant-dropdown-trigger-with-chevron {
          > span {
            &::after {
              display: none;
            }
          }
        }
      }
    }
    > .ant-btn-loading-icon {
      position: absolute;
      font-size: ${({ size }) => SPINNER_SIZE_MAP[size]}px;
      color: inherit;
      left: 50%;
      top: 50%;
      width: ${({ size }) => SPINNER_SIZE_MAP[size]}px !important;
      height: ${({ size }) => SPINNER_SIZE_MAP[size]}px !important;
      margin-left: -${({ size }) => SPINNER_SIZE_MAP[size] / 2}px;
      margin-top: -${({ size }) => SPINNER_SIZE_MAP[size] / 2}px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-width: ${({ size }) => SPINNER_BORDER_WIDTH_MAP[size]}px;
      border-style: solid;
      border-bottom-color: transparent;
      border-radius: 50%;
      animation: ${rotateAnimation} 1s linear infinite;
      opacity: 0.4;
      > * {
        display: none;
      }
    }
  }
`;

const RootXsMixin = css`
  &.ant-btn {
    height: ${({ theme: { spacing } }) => spacing(4)};
  }
`;

export const Root = styled<ButtonComponent>(Button).withConfig({
  shouldForwardProp: (prop) => !['xs'].includes(prop),
})`
  &.ant-btn {
    font-size: 12px;
    display: inline-flex;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    align-items: center;
    gap: ${({ theme: { spacing } }) => spacing(2)};
    &.ant-btn-text,
    &.ant-btn-link {
      + .ant-btn-text,
      + .ant-btn-link {
        margin-left: ${({ theme: { spacing } }) => spacing(2)};
      }
    }
    &.ant-btn-circle {
      justify-content: center;
    }
    &.ant-btn-lg {
      font-size: 14px;
      padding: ${({ theme: { spacing } }) => `${spacing(3)} ${spacing(5)}`};
    }

    &.ant-dropdown-trigger {
      &.ant-dropdown-trigger-with-chevron {
        align-items: center;
        > span {
          display: flex;
          align-items: center;
          &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-top-width: ${({ size }) => CHEVRON_HEIGHT_MAP[size]}px;
            border-right-width: ${({ size }) => CHEVRON_WIDTH_MAP[size] / 2}px;
            border-left-width: ${({ size }) => CHEVRON_WIDTH_MAP[size] / 2}px;
            border-color: ${({ theme }) => theme.palette.transparent};
            margin-left: ${({ theme: { spacing } }) => spacing(2)};
            margin-top: ${({ theme: { spacing } }) => spacing(0.5)};
            transition: all 300ms ease;
          }
        }
      }
    }
  }
  ${RootSpinnerMixin}
  ${({ type }) => type === 'primary' && RootPrimaryMixin};
  ${({ type, ghost }) => type === 'default' && !ghost && RootSecondaryMixin};
  ${({ type, ghost }) => type === 'default' && ghost && RootTernaryMixin};
  ${({ type }) => type === 'link' && RootLinkMixin}
  ${({ type, muted }) => type === 'text' && !muted && RootTextMixin}
  ${({ type, muted }) => type === 'text' && muted && RootMutedMixin}
  ${({ xs }) => xs && RootXsMixin}
`;
