import { FC } from 'react';
import { NavigationControl, ControlPosition } from 'react-map-gl';

interface MapZoomProps {
  position?: ControlPosition;
}

export const MapZoom: FC<MapZoomProps> = ({ position = 'bottom-right' }) => {
  return <NavigationControl showCompass={false} position={position} />;
};
