import styled from 'styled-components';
import { Typography, InfiniteScroll } from '@spintel/uikit';

export const Searchbar = styled('div')`
  padding: ${({ theme: { spacing } }) => spacing(4)};
  flex: 0 0 auto;
  border-bottom: 1px solid ${({ theme }) => theme.palette.wolfGrey};
`;

export const Content = styled(InfiniteScroll)`
  flex: 1 1 auto;
  max-height: ${({ theme: { spacing } }) => spacing(74)};
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: ${({ theme: { spacing } }) => spacing(4)};
  padding-bottom: ${({ theme: { spacing } }) => spacing(4)};
  max-width: 100%;
`;

export const Subtitle = styled(Typography.Subtitle2)`
  padding-left: ${({ theme: { spacing } }) => spacing(4)};
  padding-right: ${({ theme: { spacing } }) => spacing(4)};
`;
