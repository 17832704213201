module.exports = {
  invalid_token: 'Invalid token',
  password_invalid:
    'Incorrect current password, please, provide correct current password',
  password_reset_failed: 'Unable to reset password, please try again',
  signin_failed:
    'Incorrect email ID or password. Type the correct user ID and password, and try again.',
  user_is_locked:
    'You have reached tries attempt limit. Please reset your password using "Forgot password" button or try to login later.',
  user_not_found: 'User not found',
  user_not_authenticated: 'User is not authenticated.',
};
